<template>
    <div style="height: 100%;min-height:350px;">
        <div>
            <div class="app-container">
                <div class="box">
                    <div class="box-left">
                        <el-input
                            clearable
                            style="width: 70%; margin-right: 10px"
                            :placeholder='$i18n.t("common.Pleaseenteracolumnname")'
                            v-model="filterText"
                            oninput="value=value.replace(/[%]/g,'')"
                        >
                        </el-input>

                        <el-button type="success" @click="handleCreateInformation">{{ $i18n.t("common.Add1") }}</el-button>

                        <el-tree
                            class="filter-tree"
                            :data="tree"
                            :props="defaultProps"
                            default-expand-all
                            :filter-node-method="filterNode"
                            node-key="id"
                            :expand-on-click-node="false"
                            :render-content="renderContent"
                            ref="tree"
                        >
                        </el-tree>
                    </div>
                    <div class="box-right">
                        <div class="filter-container">
                            <el-input
                                clearable
                                v-model="query.title"
                                :placeholder='$i18n.t("common.title")'
                                style="width: 200px"
                                class="filter-item"
                                @keyup.enter.native="handleQuery"
                            />

                            <el-select
                                clearable
                                style="width: 200px"
                                class="filter-item"
                                v-model="query.isShowBottom"
                                :placeholder='$i18n.t("common.Isitdisplayedatthebottomofthewebsite")'
                            >
                                <el-option :label='$i18n.t("common.yes")' value="true"></el-option>
                                <el-option :label='$i18n.t("common.no")'  value="false"></el-option>
                            </el-select>

                            <!-- 
                            <dataSelect
                                class="filter-item"
                                v-model="query.brandCode"
                                style="width: 200px"
                                clearable
                                :codeType="'brand'"
                                :placeholder='$i18n.t("common.brand")'
                            ></dataSelect>
                            -->
                            <dataSelect
                                style="width: 200px"
                                class="filter-item"
                                v-model="query.countryCode"
                                clearable
                                :codeType="'countrys'"
                                :placeholder='$i18n.t("common.country")'
                            ></dataSelect>


                            <el-button class="filter-item" icon="el-icon-search" type="primary" @click="handleQuery"> {{ $i18n.t("common.search") }}  </el-button>

                            <el-button
                                class="filter-item"
                                icon="el-icon-search"
                                type="primary"
                                @click="cleanQuery"
                                style="margin-left: 10px"
                                size="small "
                            >
                                      {{ $i18n.t("common.Reset") }}
                            </el-button>
                        </div>
                        <el-table
                            v-loading="listLoading"
                            height="calc(100vh - 260px)"
                            :data="list"
                            @selection-change="selectionChange"
                            border
                            stripe
                            style="width: 100%"
                        >
                            <el-table-column label="Id" prop="informationid" align="center" width="160">
                                <template slot-scope="{ row }">
                                    <span>{{ row.informationId }}</span>
                                </template>
                            </el-table-column>

                            <el-table-column :label='$i18n.t("common.ShowCountry")' prop="name" align="center" width="200px">
                                <template slot-scope="{ row }">
                                    <el-tag style="margin: 5px" v-for="item in row.informationCountryList" :key="item.informationCountryId"
                                        >{{ $dict.getDictValue('countrys', item.countryCode) }}
                                    </el-tag>
                                </template>
                            </el-table-column>
                            <el-table-column :label='$i18n.t("common.title")' prop="title" align="center">
                                <template slot-scope="{ row }">
                                    <span>{{ row.informationTitleList[0].title }}</span>
                                </template>
                            </el-table-column>

                            <!--
                            <el-table-column  :label='$i18n.t("common.brand")'  prop="title" align="center" width="200">

                                <template slot-scope="{ row }">
                               
                                    <el-tag style="margin: 5px" v-for="item in row.informationBrandList" :key="item.informationBrandId"
                                        >{{ item.brand }}
                                    </el-tag>
                                </template>
                            </el-table-column>


                            <el-table-column :label='$i18n.t("common.Isitdisplayedatthebottomofthewebsite")'  prop="isshowbottom" align="center">

                            -->
                            <el-table-column :label='$i18n.t("common.Isitdisplayedatthebottomofthewebsite")'   prop="isshowbottom" align="center">

                                <template slot-scope="{ row }">
                                    <span>{{ row.isShowBottom ? $i18n.t("common.yes") : $i18n.t("common.no") }}</span>
                                </template>
                            </el-table-column>
                            <el-table-column :label='$i18n.t("common.sort")' prop="sort" align="center" width="80">
                                <template slot-scope="{ row }">
                                    <span>{{ row.sort }}</span>
                                </template>
                            </el-table-column>
                            <el-table-column :label='$i18n.t("common.Creationtime")' prop="createtime" align="center" width="150">
                                <template slot-scope="{ row }">
                                    <span>{{ new Date(row.createTime).toLocaleString() }}</span>
                                </template>
                            </el-table-column>
                            <el-table-column :label='$i18n.t("common.Updatetime")' prop="updatetime" align="center" width="150">
                                <template slot-scope="{ row }">
                                    <span v-if="row.updateTime != null">{{ new Date(row.updateTime).toLocaleString() }}</span>
                                </template>
                            </el-table-column>

                            <el-table-column :label='$i18n.t("common.operation")' align="center" width="150">
                                <template slot-scope="{ row }">
                                    <el-button type="primary" size="mini" @click="handleUpdate(row)" class="table-inner-button"
                                        >{{ $i18n.t("common.edit") }}</el-button
                                    >
                                    <el-button
                                        v-if="isDeleteBtnShow(row.informationId)"
                                        type="danger"
                                        size="mini"
                                        @click="handleDeleteSingle(row.informationId)"
                                        class="table-inner-button"
                                        >{{ $i18n.t("common.delete") }}</el-button
                                    >
                                </template>
                            </el-table-column>
                        </el-table>
                        <el-pagination
                            style="margin-top: 10px"
                            background
                            v-show="total > 0"
                            @current-change="doQueryList"
                            :current-page="query.pageNo"
                            :page-size="query.pageSize"
                            layout="total, prev, pager, next, jumper"
                            :total="total"
                        ></el-pagination>
                    </div>
                </div>

                <!-- 新建表单 -->
                <information-add ref="informationAdd" @created="doQueryList(1)" />
                <!-- 编辑表单 -->
                <information-edit ref="informationEdit" @updated="doQueryList(1)" />
                <!-- 查看表单 -->
                <information-show ref="informationShow" />

                <addOrEditInformaion ref="addOrEditInformation" @created="doQueryList(1)" />
            </div>
        </div>
    </div>
</template>

<script>
import informationAdd from './add';
import informationEdit from './edit';
import informationShow from './show';
import addOrEditInformaion from '@/views/information/addOrEditInformaion';
import Pagination from '@/components/common/Pagination';
import DataSelect from '@/components/common/DataSelect';

export default {
    name: 'InformationTable',
    components: {
        DataSelect,
        Pagination,
        informationAdd,
        informationEdit,
        informationShow,
        addOrEditInformaion
    },

    data() {
        return {
            list: [],
            total: 0,
            listLoading: true,
            query: {
                page: 1,
                limit: 10,
                parentId: null,
                name: null,
                title: null,
                countrycode: null,
                isShowBottom: null,
                brandCode: null,
                selectNodeId: []
            },

            defaultProps: {
                children: 'children',
                label: 'label'
            },
            selectItems: [],
            forbiddenId : process.env.VUE_APP_PRIVATE_POLICY,
            forbiddenId2 : process.env.VUE_APP_ITEM_AND_CONDITION,
            filterText: '',
            tree: []
        };
    },
    created() {
        this.doQueryList(1);
    },
    watch: {
        filterText(val) {
            this.query.selectNodeId = [];
            this.$refs.tree.filter(val);
            this.doQueryList(1);
            // this.doQueryList({ pageNo: 1 });
        }
    },
    mounted() {
        this.getTree();
    },
    methods: {
        renderContent(h, { node, data, store }) {
            let edit = this.$i18n.t("common.edit")
            let deleteTex = this.$i18n.t("common.delete")
            return (
                <span class="custom-tree-node">
                    <span class="label" on-click={() => this.doQueryList(1, data.id)}>
                        {node.label}
                    </span>
                    <span class="button">
                        <el-button size="mini" type="text" on-click={() => this.editInformationNode(data)}>
                            {edit}
                        </el-button>
                        <el-button size="mini" type="text" on-click={() => this.handleDeleteSingle(data.id)}>
                            {deleteTex}
                        </el-button>
                    </span>
                </span>
            );
        },
        editInformationNode(data) {
            let params = {
                filterInformationId: data.id
            };
            this.$store.dispatch('getTree', params).then((res) => {
                this.$refs.addOrEditInformation.catalogueFilter = res;
                this.$refs['addOrEditInformation'].resetForm();

                this.$store.dispatch('getInformationById', data.id).then((res) => {
                    //赋值过程
                    this.$refs['addOrEditInformation'].form.name = res.name;
                    this.$refs['addOrEditInformation'].form.isShowBottom = res.isShowBottom;
                    this.$refs['addOrEditInformation'].form.sort = res.sort;
                    this.$refs['addOrEditInformation'].form.parentId = res.parentId;
                    this.$refs['addOrEditInformation'].form.informationId = res.informationId;

                    //赋值国家列表信息
                    for (const key in res.informationCountryList) {
                        const element = res.informationCountryList[key];
                        this.$refs['addOrEditInformation'].checkedCountrys.push(element.countryCode);
                    }

                    //赋值品牌信息
                    for (const key in res.informationBrandList) {
                        const element = res.informationBrandList[key];
                        this.$refs['addOrEditInformation'].checkedBrands.push(element.brand);
                    }

                    for (const key in res.informationTitleList) {
                        const element = res.informationTitleList[key];
                        console.log(element);
                        this.$refs['addOrEditInformation'].titles[key].title = element.title;
                    }

                    this.$refs['addOrEditInformation'].formVisible = true;
                });
            });
        },
        getTree() {
            this.$store.dispatch('getTree', {}).then((res) => {
                this.tree = res;
            });
        },
        filterNode(value, data) {
            if (!value) return true;
            let result = data.label.indexOf(value) !== -1;
            if (result) {
                this.query.selectNodeId.push(data.id);
            }
            return result;
        },
        /**
         * 重置操作
         */
        cleanQuery() {
            this.query = [];
            this.doQueryList(1);
        },
        /**
         * 选择框变化
         */
        selectionChange(val) {
            this.selectItems = val;
        },
        /**
         * 触发搜索操作
         */
        handleQuery() {
            this.doQueryList(1);
        },
        /**
         * 执行列表查询
         */
        doQueryList(page, parentId) {
            this.query.page = page;
            this.query.parentId = parentId;
            this.listLoading = true;

            this.$store
                .dispatch('getInformationPage', this.query)
                .then((res) => {
                    this.list = res.list;
                    this.total = res.total;
                })
                .finally(() => {
                    this.listLoading = false;
                });
        },
        /**
         * 删除单条记录
         */
        handleDeleteSingle(id) {
            return this.$confirm(this.$i18n.t("common.deleteconfirmation")).then(() => {
                this.$store.dispatch('deleteInformation', id).then((res) => {
                    this.$message({
                        message:   this.$i18n.t("common.Successfullydeleted"),
                        type: 'success'
                    });
                    this.doQueryList(1);
                    this.getTree();
                });
            });
        },
        isDeleteBtnShow(id){
            if(this.forbiddenId == id || this.forbiddenId2 == id){
                return false;
            }else{
                return true;
            }
        },
        /**
         * 批量删除记录
         */
        handleDeleteBatch() {
            if (this.selectItems.length <= 0) {
                this.$common.showMsg('warning',  this.$i18n.t("common.Pleaseselectaninformationservice"));
                return;
            }
            return this.$common
                .confirm( this.$i18n.t("common.Areyousuretodelete"))
                .then(() => informationApi.deleteBatch(this.selectItems.map((row) => row.informationid)))
                .then(() => {
                    this.$common.showMsg('success',  this.$i18n.t("common.Successfullydeleted"));
                    return this.doQueryList(1);
                });
        },
        /**
         * 打开新建表单
         */
        handleCreate() {
            this.$refs.informationAdd.resetForm();
            this.$refs.informationAdd.handleCreate();
        },
        handleCreateInformation() {
            this.$store.dispatch('getTree', {}).then((res) => {
                this.$refs.addOrEditInformation.catalogueFilter = res;
                this.$refs.addOrEditInformation.handleCreate();
            });
        },
        /**
         * 打开查看表单
         */
        handleShow(row) {
            this.$refs.informationShow.handleShow(row.informationid);
        },
        /**
         * 打开编辑表单
         */
        handleUpdate(row) {
            this.$refs['informationAdd'].resetForm();
            let params = {
                filterInformationId: row.informationId
            };
            this.$store.dispatch('getTree', params).then((res) => {
                this.$refs.informationAdd.catalogueFilter = res;
                this.$refs['informationAdd'].formVisible = true;
                this.$store.dispatch('getInformationById', row.informationId).then((res) => {
                    console.log(res);

                    //赋值过程
                    this.$refs['informationAdd'].form.name = res.name;
                    this.$refs['informationAdd'].form.isShowBottom = res.isShowBottom;
                    this.$refs['informationAdd'].form.sort = res.sort;
                    this.$refs['informationAdd'].form.parentId = res.parentId;
                    this.$refs['informationAdd'].form.informationId = res.informationId;

                    console.log(this.$refs['informationAdd'].form);

                    //赋值国家列表信息
                    for (const key in res.informationCountryList) {
                        const element = res.informationCountryList[key];
                        this.$refs['informationAdd'].checkedCountrys.push(element.countryCode);
                    }

                    //赋值品牌信息
                    for (const key in res.informationBrandList) {
                        const element = res.informationBrandList[key];
                        this.$refs['informationAdd'].checkedBrands.push(element.brand);
                    }

                    //赋值多语言信息列表
                    let arr1 = [];
                    for (const key in res.informationLangDtoList) {
                        const element = res.informationLangDtoList[key];
                        arr1.push(element.content);
                    }
                    this.$refs['informationAdd'].contentArr = arr1;
                    this.$refs['informationAdd'].init();
                });
            });
        }
    }
};
</script>
<style scoped>
.filter-container {
    padding: auto;
}

.filter-item {
    margin-right: 10px;
    margin-bottom: 10px;
}

.box-left {
    width: 15vw;
    margin: 10px;
}

.box-right {
    width: 70vw;
    margin: 10px;
}

.box {
    display: flex;
}
</style>
<style>
.custom-tree-node {
    overflow: hidden;
    flex-shrink: 1;
    flex-grow: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 13px;
    padding-right: 8px;
}
.custom-tree-node .label {
    flex-shrink: 1;
    overflow: hidden;
    text-overflow: ellipsis;
}

.custom-tree-node .button {
    flex-grow: 0;
    flex-shrink: 0;
}
.table-inner-button{
    margin-bottom: 10px;
}
</style>
