var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-upload",
        {
          ref: "upload1",
          staticClass: "upload-demo",
          attrs: {
            action: _vm.ossSignature.host,
            "auto-upload": false,
            "list-type": _vm.listType,
            limit: _vm.limit,
            "on-change": _vm.handleChange,
            "on-remove": _vm.handleRemove,
            accept: _vm.uploadType,
            "before-upload": _vm.beforeUpload,
            "show-file-list": this.fileType != 2,
            "file-list": _vm.uploadFiles
          }
        },
        [
          _c(
            "el-button",
            {
              staticClass: "float-l",
              attrs: { size: "small", type: "primary" }
            },
            [_vm._v("点击上传")]
          ),
          _c("span", [_vm._v("  " + _vm._s(_vm.remark))])
        ],
        1
      ),
      _c(
        "el-button",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: this.fileType == 2 && _vm.videoUrl != "",
              expression: "this.fileType == 2 && videoUrl != ''"
            }
          ],
          staticClass: "float-r",
          attrs: { size: "small", type: "primary" },
          on: { click: _vm.removeVideo }
        },
        [_vm._v("移除")]
      ),
      _c(
        "video",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: this.fileType == 2 && _vm.videoUrl != "",
              expression: "this.fileType == 2 && videoUrl != ''"
            }
          ],
          staticClass: "float-l",
          staticStyle: { width: "100%" },
          attrs: { src: _vm.videoUrl, controls: "controls" }
        },
        [_vm._v("\n        您的浏览器不支持视频播放\n    ")]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }