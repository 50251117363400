<template>
    <div>
        <el-upload
            class="multiple-upload"
            :action="ossInfo.ossSignature.host"
            :show-file-list="true"
            :auto-upload="true"
            multiple
            ref="upload"
            :before-upload="beforeAvatarUpload"
            :on-success="handleSuccess"
            :on-error="handleError"
            :on-remove="handleFileRemove"
            :http-request="customRequest"
            :on-exceed="handleExceed"
            :accept="accept"
            :limit="limit"
        >
            <el-button type="success" size="small" :loading="load">{{ $i18n.t('common.Uploadimages') }}</el-button>
            <slot></slot>
        </el-upload>
    </div>
</template>

<script>
import X2JS from 'x2js'; //解析xml
import axios from 'axios'
export default {
    props: {
        accept: {
            type: String,
            default: '.jpg, .jpeg, .png, .gif'
        },
        limit: {
            type: Number,
            default: 1
        }
    },
    data() {
        return {
            ossInfo: {
                ossHost: process.env.VUE_APP_OSS_Head,
                ossSignature: {
                    host: ''
                }
            },
            fileDataList: [],
            totalFileNum: 0,
            successFileNum: 0,
            load: true,
            errorFileDataList: [],
            maxSizeMessage: ""
        };
    },
    methods: {

        //获取OSS签名
        getOssSignature() {
            this.$store
                .dispatch('getSignature', {
                    dir: 'b2c'
                })
                .then((res) => {
                    this.ossInfo.ossSignature = res;
                    this.ossInfo.ossSignature.host = this.ossInfo.ossHost;
                });
        },
        /**
         * 生成随机文件名
         * @param len
         * @returns {string}
         */
        randomString(len) {
            len = len || 32;
            let chars = 'ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678';
            let maxPos = chars.length;
            let pwd = '';
            for (let i = 0; i < len; i++) {
                pwd += chars.charAt(Math.floor(Math.random() * maxPos));
            }
            return pwd;
        },
        openUploadDialog() {

            this.$refs['upload'].$refs['upload-inner'].handleClick();
            setTimeout(()=>{
                this.load = false;
            },500)
        },
        handleFileRemove(file, fileList) {

        },
        handleExceed(files, fileList) {

            this.$message.warning(this.$i18n.t("common.LimitWereSelected",{limitNum: this.limit, selectNum: files.length} ));
            // this.$message.warning(`当前限制选择${this.limit}个文件，本次选择了${files.length}个文件`);
        },
        handleSuccess(response, file) {

            let type="图片"
            var x2js = new X2JS();
            var jsonObj = x2js.xml2js(response);
            let ossUrl = jsonObj.PostResponse.Location;
            let ETag = jsonObj.PostResponse.ETag.toLowerCase();

            this.fileDataList.forEach(f => {

                if (f.fileName === file.name) {

                    f.type = type;
                    f.imageUrl = ossUrl;
                    f.main = false;
                    f.showed = false;
                    f.imageMD5 = ETag;
                    f.lang = "";
                    f.sort = 0;
                    f.updateTime = Date.parse(new Date());
                }
            })

            this.successFileNum++;

            if (this.totalFileNum == this.successFileNum) {

                //全部上传成功
                this.uploadAllSuccess();
                this.$refs.upload.clearFiles();

                if (this.errorFileDataList && this.errorFileDataList.length > 0) {
                    var errorFileName = '';
                    this.errorFileDataList.forEach(fileName => {
                        errorFileName += fileName + ",";
                    });
                    this.$message.error(errorFileName + this.$i18n.t("common.Thesizeoftheuploadedimagecannotexceed") + this.maxSizeMessage);
                }

            }

        },
        handleError(error, file) {
            this.fileDataList = this.fileDataList.filter(f => f.fileName !== file.name);
            this.totalFileNum--;
            if (this.totalFileNum == this.successFileNum) {

                //全部上传成功
                this.uploadAllSuccess();
                this.$refs.upload.clearFiles();
            }
        },
        beforeAvatarUpload(file){

            if (file.size> 2 * 1024 * 1024) {

                this.errorFileDataList.push(file.name);
                this.maxSizeMessage = "2MB!";
                return false;
            }

            var reader = new FileReader();
            reader.onload = (event)=>{
                var txt = event.target.result;
                var img = document.createElement("img");
                img.src = txt;
                img.onload =()=>{

                    let fileData={
                        fileWidth:img.width,
                        fileHeight:img.height,
                        fileName:file.name
                    }

                    this.fileDataList.push(fileData);
                }
            };
            reader.readAsDataURL(file);
            this.totalFileNum++;
        },
        customRequest({ file, onProgress, onSuccess, onError}) {

            let formData = new FormData();
            let dataObj = {
                name: file.name,
                key:
                    this.ossInfo.ossSignature.dir + '/' + new Date().getFullYear() + '/' + this.randomString() + this.getSuffix(file.name),
                policy: this.ossInfo.ossSignature['policy'],
                OSSAccessKeyId: this.ossInfo.ossSignature['access_id'],
                success_action_status: '201',
                callback: '',
                signature: this.ossInfo.ossSignature['signature'],
                file: file
            };

            for(let key in dataObj){
                formData.append(key,dataObj[key]);
            }

            axios.post(this.ossInfo.ossSignature.host, formData, {
                headers: {'Content-Type':'multipart/form-data'},
                onUploadProgress: (progressEvent) => {
                    let percent = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                    onProgress({ percent });
                }
            }).then(response => {
                onSuccess(response.data);
            }).catch(error => {
                onError(error,file);
            });
        },
        uploadAllSuccess() {
            this.$emit('uploadAllSuccess',this.fileDataList);
        }
    },
    mounted() {
        this.getOssSignature();
        this.openUploadDialog();
    }
};
</script>

<style scoped>
.multiple-upload /deep/ .el-upload--picture-card {
    width: auto;
    height: 0px;
    line-height: 12px;
    border: 0px dashed #c0ccda;
}
</style>
