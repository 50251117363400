var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.addVisible
    ? _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.$i18n.t("common.Rechargepatrioticcoins"),
            visible: _vm.addVisible,
            "close-on-click-modal": false
          },
          on: {
            "update:visible": function($event) {
              _vm.addVisible = $event
            },
            close: function($event) {
              return _vm.closeForm()
            }
          }
        },
        [
          _c(
            "el-form",
            {
              ref: "dataForm",
              staticStyle: { width: "400px", "margin-left": "50px" },
              attrs: {
                rules: _vm.formRules,
                model: _vm.addRecharge,
                "label-position": "left",
                size: "small",
                "label-width": "100px"
              }
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: _vm.$i18n.t("common.UserID") } },
                [
                  _c("el-input", {
                    attrs: {
                      placeholder: _vm.$i18n.t("common.UserID"),
                      disabled: true
                    },
                    model: {
                      value: _vm.addRecharge.userId,
                      callback: function($$v) {
                        _vm.$set(_vm.addRecharge, "userId", $$v)
                      },
                      expression: "addRecharge.userId"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: _vm.$i18n.t("common.mailbox") } },
                [
                  _c("el-input", {
                    attrs: {
                      placeholder: _vm.$i18n.t("common.mailbox"),
                      disabled: true
                    },
                    model: {
                      value: _vm.addRecharge.email,
                      callback: function($$v) {
                        _vm.$set(_vm.addRecharge, "email", $$v)
                      },
                      expression: "addRecharge.email"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    required: true,
                    label: _vm.$i18n.t("common.country"),
                    prop: "countryCode"
                  }
                },
                [
                  _c("dataSelect", {
                    staticClass: "filter-item",
                    staticStyle: { width: "100%" },
                    attrs: {
                      clearable: "",
                      codeType: "countrys",
                      placeholder: _vm.$i18n.t("common.country")
                    },
                    model: {
                      value: _vm.addRecharge.countryCode,
                      callback: function($$v) {
                        _vm.$set(_vm.addRecharge, "countryCode", $$v)
                      },
                      expression: "addRecharge.countryCode"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: _vm.$i18n.t("common.Numberofpatrioticcoins"),
                    prop: "amount"
                  }
                },
                [
                  _c("el-input", {
                    staticStyle: { width: "100%" },
                    attrs: {
                      oninput:
                        "if(value){value=value.replace(/[^\\d]/g,'')} if(value<=0){value=''} if(value>1000){value=1000}",
                      placeholder: _vm.$i18n.t(
                        "common.Enteraquantitybetween0and1000"
                      )
                    },
                    on: {
                      blur: function($event) {
                        _vm.addRecharge.amount = $event.target.value
                      }
                    },
                    model: {
                      value: _vm.addRecharge.amount,
                      callback: function($$v) {
                        _vm.$set(_vm.addRecharge, "amount", $$v)
                      },
                      expression: "addRecharge.amount"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: _vm.$i18n.t("common.ValidityPeriod"),
                    prop: "expireNum"
                  }
                },
                [
                  _c(
                    "div",
                    { staticStyle: { display: "flex" } },
                    [
                      _c("el-input", {
                        staticStyle: { "flex-basis": "600px" },
                        attrs: {
                          oninput:
                            "if(value){value=value.replace(/[^\\d]/g,'')} if(value<=0){value=''}",
                          placeholder: _vm.$i18n.t("common.EndofValidity")
                        },
                        on: {
                          blur: function($event) {
                            _vm.addRecharge.expireNum = $event.target.value
                          }
                        },
                        model: {
                          value: _vm.addRecharge.expireNum,
                          callback: function($$v) {
                            _vm.$set(_vm.addRecharge, "expireNum", $$v)
                          },
                          expression: "addRecharge.expireNum"
                        }
                      }),
                      _c(
                        "el-select",
                        {
                          staticStyle: { "padding-left": "20px" },
                          model: {
                            value: _vm.timeOption,
                            callback: function($$v) {
                              _vm.timeOption = $$v
                            },
                            expression: "timeOption"
                          }
                        },
                        [
                          _c("el-option", {
                            attrs: {
                              label: _vm.$i18n.t("common.Year"),
                              value: "year"
                            }
                          }),
                          _c("el-option", {
                            attrs: {
                              label: _vm.$i18n.t("common.Month"),
                              value: "month"
                            }
                          }),
                          _c("el-option", {
                            attrs: {
                              label: _vm.$i18n.t("common.Day"),
                              value: "day"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ]
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: _vm.$i18n.t("common.Remarks"),
                    prop: "remark"
                  }
                },
                [
                  _c("el-input", {
                    attrs: {
                      maxlength: "1000",
                      "show-word-limit": "",
                      type: "textarea",
                      autosize: { minRows: 2, maxRows: 4 },
                      placeholder: _vm.$i18n.t("common.Remarks")
                    },
                    model: {
                      value: _vm.addRecharge.remark,
                      callback: function($$v) {
                        _vm.$set(_vm.addRecharge, "remark", $$v)
                      },
                      expression: "addRecharge.remark"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function($event) {
                      return _vm.closeForm()
                    }
                  }
                },
                [_vm._v(" " + _vm._s(_vm.$i18n.t("common.cancel")) + " ")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function($event) {
                      return _vm.handleSubmit()
                    }
                  }
                },
                [_vm._v(" " + _vm._s(_vm.$i18n.t("common.confirm1")) + " ")]
              )
            ],
            1
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }