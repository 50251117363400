<template>
  <div class="app-container">
    <div class="filter-container" >


      <el-select v-model="query.registerStatus" :placeholder='$i18n.t("common.RegisterOrNot")' clearable style="width: 180px;margin-left: 10px;" class="filter-item">
        <el-option :label="$t('common.yes')" value="1"></el-option>
        <el-option :label="$t('common.no')" value="0"></el-option>
      </el-select>



        <el-date-picker v-model="timePicker" style="margin-left: 10px;" clearable  type="datetimerange" range-separator="-"
						:start-placeholder='$i18n.t("common.FromSubscriptionTime")'
            :end-placeholder='$i18n.t("common.EndOfSubscriptionPeriod")'  
						format="yyyy-MM-dd HH:mm:ss"  value-format="yyyy-MM-dd HH:mm:ss"/>

 
				 

      <el-input v-model="query.email"  :placeholder='$i18n.t("common.Emailaddress")' 
                style="width: 180px;margin-left: 10px;" class="filter-item"
                clearable
                @keyup.enter.native="handleQuery"/>

      <el-select v-model="query.countryCode" :multiple="true" :placeholder='$i18n.t("common.ShowCountry")'  style="width: 180px;margin-left: 10px;" class="filter-item">

                <el-option v-for="cc in countrysDicts" :key="cc.code" :label="cc.name" :value="cc.code"></el-option>
            </el-select>
      <el-select v-model="query.status" :placeholder="$i18n.t('common.SubscriptionStatus')" clearable style="width: 180px;margin-left: 10px;" class="filter-item">
        <el-option  :label='$i18n.t("common.Subscribing")' value="1"></el-option>
        <el-option :label='$i18n.t("common.Canceled")'   value="0"></el-option>
      </el-select>
      <el-button class="filter-item" icon="el-icon-search" type="primary"
                 @click="handleQuery" style="margin-left: 10px;">
                 {{ $i18n.t("common.query") }}
      </el-button>
      <el-button class="filter-item" icon="el-icon-search" type="primary"
                 @click="cleanQuery" style="margin-left: 10px;" size="small" >
                 {{ $i18n.t("common.Reset") }}
      </el-button>
      <el-button class="filter-item"  type="primary"
                 @click="handleExport" style="margin-left: 10px;" size="small" >
          {{ $i18n.t("common.export") }}
      </el-button>
    </div>

    <el-table 
            v-loading="listLoading"
            :data="list.list"
            border
            stripe
            style="width: 100%; margin-top: 20px">
      <el-table-column label="ID"
                       prop="subId"
                       align="center"
                       show-overflow-tooltip
      >        
      </el-table-column>
      <el-table-column      :label='$i18n.t("common.mailbox")'
                       prop="email"
                       align="center"
                       show-overflow-tooltip>
      </el-table-column>
      <el-table-column prop="countryCode"  :label='$i18n.t("common.country")' show-overflow-tooltip align="center">    
      </el-table-column>
      
      <el-table-column  :label='$i18n.t("common.SubscriptionSource")'
                       prop="subFrom"
                       align="center"
                       show-overflow-tooltip>
          <template slot-scope="{row}">
            <span v-if="row.subFrom == '注册订阅' " >{{ $i18n.t("common.SignUpForSubscription") }}</span>
            <span v-if="row.subFrom == '输入邮箱订阅' " >{{ $i18n.t("common.EnterYourEmailAddressToSubscribe") }}</span>
          </template>
      </el-table-column>



      <el-table-column :label='$i18n.t("common.RegisterOrNot")' prop="registerCount" align="center" show-overflow-tooltip>
        <template slot-scope="{row}">
          <span v-if="row.registerCount == 0 " >{{ $i18n.t("common.no") }}</span>
          <span v-if="row.registerCount > 0 " >{{ $i18n.t("common.yes") }}</span>
        </template>
      </el-table-column>

      <el-table-column :label='$i18n.t("common.state")' 
                       prop="status"
                       align="center"
                       show-overflow-tooltip>
        <template slot-scope="{row}">
          <span v-if="row.status == false " style="color:red;">{{ $i18n.t("common.Canceled") }}</span>
          <span v-if="row.status == true " style="color:green;">{{ $i18n.t("common.Subscribing")}}</span>
        </template>
      </el-table-column>
      <el-table-column :label='$i18n.t("common.Subscriptiontime")'
                       prop="subTime"
                       align="center"
                       show-overflow-tooltip
                       :formatter="formatDate">
      </el-table-column>
      <el-table-column :label='$i18n.t("common.Updatetime")'
                       prop="updateTime"
                       align="center"
                       show-overflow-tooltip
                       :formatter="formatDate">
      </el-table-column>
      <el-table-column :label='$i18n.t("common.operation")' align="center" width="230">
        <template slot-scope="{row}">
          <el-button v-if="row.status" type="primary" size="mini"
                     @click="handleUpdate(row)" class="table-inner-button">{{ $i18n.t("common.Unsubscribe") }}</el-button>
          <el-button v-else type="danger" size="mini"
                     @click="handleUpdate(row)" class="table-inner-button">{{ $i18n.t("common.Resubscribe") }}</el-button>
        </template>
      </el-table-column>
    </el-table>
    <div class="block">
            <el-pagination
                style="margin-top: 10px"
                background
                @current-change="handleCurrentChange"
                :current-page="query.page"
                :page-size="query.size"
                layout="total, prev, pager, next, jumper"
                :total="list.total"
            ></el-pagination>
    </div>
  </div>
</template>

<script>
import dataSelect from '@/components/common/DataSelect.vue';
import dataText from '@/components/common/DataText.vue';
import Pagination from '@/components/common/Pagination/'
import moment from 'moment';
import ossUploader from '@/components/page/OssEleUploder.vue';


export default {
  name: 'subscribe',
  components: {
    dataSelect, dataText,
    Pagination,
    moment,
    ossUploader
  },
  data() {
    var checkDateTime = (rule, value, callback) => {
            if (this.valuePicker.length>0) {
                callback();
            } else {
                callback(new Error(this. $i18n.t("common.Pleaseselectatimerange")));
            }
    };
    return {
      list: [],
      total: 0,
      listLoading: true,
      countrysDicts: [],
      query: {
        page: 1,
        limit: 10,
        email: '',
        countryCode:[],
        status: null,
        beginTime: null,
        endTime: null,
        registerStatus: null
      },
      //时间选择器
      timePicker: [],
      ossHost: process.env.VUE_APP_OSS_Head,
      ossSignature: { host: '' },
    }
  },
  created() {
    this.doQueryList({ page: 1 })
    this.getDictItems();
    
  },
  mounted() {
      this.getDictItems();
  },
  methods: {
    /**
     * 分页
     */
    handleCurrentChange(val) {
            this.query.page = val;
            console.log(val);
            this.doQueryList({ page: val})
    },
    /**
     * 时间戳转码
     */
    formatDate(row, column) {
            // 获取单元格数据
            let data = row[column.property];
            if (!data) {
                return '';
            } else {
                return moment(data).format('YYYY-MM-DD HH:mm:ss');
            }
        },
    /**
     * 触发搜索操作
     */
    handleQuery() {
      this.doQueryList({ page: 1 })
    },
    /**
     * 执行列表查询
     */
    doQueryList({ page, limit }) {
      if (page) {
                this.query.page = page;
            }
            if (limit) {
                this.query.limit = limit;
            }

      if (this.timePicker != null && this.timePicker.length > 0) {
        this.query.beginTime = new Date(this.timePicker[0]).getTime();
        this.query.endTime =   new Date(this.timePicker[1]).getTime();
      } else {
        this.query.beginTime = null;
        this.query.endTime = null;
      }
            
      this.listLoading = true
      this.$store.dispatch('getSubscribePage', this.query)
              .then((res) => {
                
                    this.list = res;
                })
                .finally(() => {
                    this.listLoading = false;
                });
    },
    /**
     * 打开编辑表单
     */
    handleUpdate(row) {
      this.doUpdate(row);
    },
    doUpdate(row) {
        console.log(row.subId);
        this.$store.dispatch('subscribeEdit',{id:row.subId}).then((res) =>{
            this.doQueryList({ page: 1 });
            this.$message({
                    message: this. $i18n.t("common.Editedsuccessfully"),
                    type: 'success'
            });
        });
      
    },
    
      
    getDictItems() {
        var _this = this;
        this.$store.dispatch('getDicItems', { tag: 'countrys' }).then(res => {
            if (res != null) _this.countrysDicts = res;
        });
    },
    cleanQuery(){
      this.query.email = '';
      this.query.countryCode = [];
      this.query.status = null;
      this.query.beginTime = null;
      this.query.endTime = null;
      this.query.registerStatus = null;
      this.timePicker = null;
      this.doQueryList({ page: 1 })
    },
    /**
     * 导出excel
     */
    handleExport() {
      this.query.fileName =  this.$i18n.t("common.SubscriptionManagementExport") +'.xlsx';

     
      this.$confirm(this.$i18n.t("common.Areyousuretoexportthefile"), this.$i18n.t("common.prompt"), {
          confirmButtonText: this.$i18n.t("common.confirm1"),
          cancelButtonText: this.$i18n.t("common.cancel"),
          type: 'warning'
      })
      .then(() => {
        this.$store.dispatch('exportSubscribeExcel', this.query).then((res) => {
            this.$message({type: 'success',message: this.$i18n.t("common.Exportsuccessful")});
        });
      })
      .catch(() => {
        this.$message({type: 'info',message: this.$i18n.t("common.Canceled")});
      });
    },
  }
}
</script>
<style scoped>
.filter-container {
    padding: auto;
    margin-left: 20px;
    padding-top: 20px;
}
</style>
