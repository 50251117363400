<template>
    <div style="height: 100%;min-height:350px;" class="app-container">
        <div class="filter-container">
            <el-form :model="query" :inline="true" size="small" label-width="140px">
                <el-form-item>
                    <el-input
                        clearable
                        v-model="query.title"
                        :placeholder='$i18n.t("common.title")'
                        style="width: 180px"
                        oninput="value=value.replace(/[%]/g,'')"
                        class="filter-item"
                        @keyup.enter.native="handleQuery"
                    />
                </el-form-item>
                <el-form-item>
                    <dataSelect
                        style="width: 180px; margin-left: 10px"
                        class="filter-item"
                        v-model="query.articleType"
                        clearable
                        :codeType="'article_type'"
                        :placeholder='$i18n.t("common.column")'
                    ></dataSelect>
                </el-form-item>
                <el-form-item>
                    <el-select
                        v-model="query.countryCode"
                        :multiple="true"
                        :placeholder='$i18n.t("common.ShowCountry")'
                        style="width: 180px; margin-left: 10px"
                        class="filter-item"
                    >
                        <el-option
                            v-for="cc in countrysDicts"
                            :key="cc.code"
                            :label="cc.name + '(' + cc.code + ')'"
                            :value="cc.code"
                        ></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item>
                    <el-select
                        v-model="query.fileType"
                        p  :placeholder='$i18n.t("common.type")'
                        clearable
                        style="width: 180px; margin-left: 10px"
                        class="filter-item"
                    >
                        <el-option :label='$i18n.t("common.Pictureandtext")' value="image"></el-option>
                        <el-option :label='$i18n.t("common.video")' value="video"></el-option>
                    </el-select>
                </el-form-item>
               <!--  <el-form-item>
                    <el-select
                        v-model="query.brandCode"
                        :multiple="true"
                        :placeholder='$i18n.t("common.brand")'
                        style="width: 180px; margin-left: 10px"
                        class="filter-item"
                    >
                        <el-option v-for="cc in brandDicts" :key="cc.code" :label="cc.name" :value="cc.code"></el-option>
                    </el-select>
                </el-form-item>
                -->
                <el-form-item>
                    <el-select
                        v-model="query.publishStatus"
                        :placeholder='$i18n.t("common.state")'
                        clearable
                        style="width: 180px; margin-left: 10px"
                        class="filter-item"
                    >
                        <el-option :label='$i18n.t("common.Comingsoon")' value="0"></el-option>
                        <el-option :label='$i18n.t("common.Publishinginprogress")' value="1"></el-option>
                        <el-option :label='$i18n.t("common.Ended")' value="2"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item>
                    <el-select
                        v-model="query.status"
                        :placeholder='$i18n.t("common.Enableornot")'
                        clearable
                        style="width: 180px; margin-left: 10px"
                        class="filter-item"
                    >
                        <el-option :label='$i18n.t("common.yes")' value="1"></el-option>
                        <el-option :label='$i18n.t("common.no")' value="0"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item>
                    <el-button class="filter-item" icon="el-icon-search" type="primary" @click="handleQuery" style="margin-left: 10px">
                        {{ $i18n.t("common.query") }}
                    </el-button>
                    <el-button
                        class="filter-item"
                        icon="el-icon-search"
                        type="primary"
                        @click="cleanQuery"
                        style="margin-left: 10px"
                        size="small"
                    >
                    {{ $i18n.t("common.Reset") }}
                    </el-button>
                </el-form-item>
                <div>
                    <el-button
                        class="filter-item"
                        style="margin: 0px 0px 0px 0px"
                        type="success"
                        icon="el-icon-edit"
                        @click="handleCreate"
                    >

                    {{ $i18n.t("common.Add1") }}

                    </el-button>
                </div>
            </el-form>
        </div>

        <el-table v-loading="listLoading" height="calc(100% - 170px)" :data="list.list" border stripe style="width: 100%; margin-top: 20px">
            <el-table-column :label='$i18n.t("common.column") ' prop="articleType" align="center" show-overflow-tooltip>
                <template slot-scope="scope">
                    <dataText v-model="scope.row.articleType" :codeType="'article_type'"></dataText>
                </template>
            </el-table-column>
            <el-table-column prop="countryCodes" :label='$i18n.t("common.ShowCountry") '  show-overflow-tooltip align="center">
                <template slot-scope="scope">
                    <span v-for="(item, index) in scope.row.countryCodes" :key="index">
                        {{ item }}
                        <span v-if="scope.row.countryCodes.length != 1 && index != scope.row.countryCodes.length - 1">/</span>
                    </span>
                </template>
            </el-table-column>
            <el-table-column :label='$i18n.t("common.title") '  prop="title" align="center" show-overflow-tooltip> </el-table-column>
            <el-table-column  :label='$i18n.t("common.type")' prop="fileType" align="center" show-overflow-tooltip>
                <template slot-scope="{ row }">
                    <span v-if="row.fileType == 'image'">{{ $i18n.t("common.Pictureandtext") }}</span>
                    <span v-if="row.fileType == 'video'">{{ $i18n.t("common.video") }}</span>
                </template>
            </el-table-column>
            <el-table-column :show-overflow-tooltip="true" prop="imageUrl" :label=' $i18n.t("common.cover")' align="center">
                <template slot-scope="scope">
<!--                    <el-image-->
<!--                        style="width: 100px; height: 100px"-->
<!--                        v-if="scope.row.imageUrl"-->
<!--                        :src="ossHost + scope.row.imageUrl"-->
<!--                        fit="fill"-->
<!--                    ></el-image>-->
                    <listImage :imgUrl="scope.row.imageUrl"></listImage>
                </template>
            </el-table-column>

            <!--  
            <el-table-column prop="brandCodes" label="品牌" show-overflow-tooltip align="center">
                <template slot-scope="scope">
                    <span v-for="(item, index) in scope.row.brandCodes" :key="index">
                        {{ item }}
                        <span v-if="scope.row.brandCodes.length != 1 && index != scope.row.brandCodes.length - 1">/</span>
                    </span>
                </template>
            </el-table-column>

            -->
            <el-table-column :label='$i18n.t("common.state")'  prop="publishStatus" align="center" show-overflow-tooltip>

                <template slot-scope="{ row }">
                    <span v-if="row.publishStatus == 0" style="color: red">{{ $i18n.t("common.Comingsoon") }}</span>
                    <span v-if="row.publishStatus == 1" style="color: green">{{  $i18n.t("common.Publishinginprogress") }}</span>
                    <span v-if="row.publishStatus == 2" style="color: gray">{{  $i18n.t("common.Ended") }}</span>
                </template>
            </el-table-column>
            <el-table-column :label='$i18n.t("common.starttime")' prop="startTime" align="center" show-overflow-tooltip :formatter="formatDate">
            </el-table-column>
            <el-table-column  :label='$i18n.t("common.Endtime")' prop="endTime" align="center" show-overflow-tooltip :formatter="formatDate">
            </el-table-column>
            <el-table-column :label='$i18n.t("common.sort")' prop="sort" align="center" show-overflow-tooltip sortable>
                <template slot-scope="{ row }">
                    <span>{{ row.sort }}</span>
                </template>
            </el-table-column>
            <el-table-column prop="status" :label='$i18n.t("common.Enableornot")' show-overflow-tooltip width="100" align="center">
                <template slot-scope="scope">
                    <el-switch v-model="scope.row.status" active-color="#13ce66" disabled> </el-switch>
                </template>
            </el-table-column>
            <el-table-column :label='$i18n.t("common.operation")' align="center" width="230">
                <template slot-scope="{ row }">
                    <el-button type="primary" size="mini" @click="handleUpdate(row)" class="table-inner-button">{{$i18n.t("common.edit")}}</el-button>
                    <el-button type="danger" size="mini" @click="handleDeleteSingle(row)" class="table-inner-button">{{$i18n.t("common.delete")}}</el-button>
                </template>
            </el-table-column>
        </el-table>
        <div class="block">
            <el-pagination
                style="margin-top: 10px"
                background
                @current-change="handleCurrentChange"
                :current-page="query.page"
                :page-size="query.size"
                layout="total, prev, pager, next, jumper"
                :total="list.total"
            ></el-pagination>
        </div>
        <!-- 新建表单 -->
        <el-dialog
            :title="formTitle"
            :visible.sync="addVisible"
            @close="closeForm()"
            v-if="addVisible"
            :close-on-click-modal="false"
            width="950px"
        >
            <el-form
                ref="dataForm"
                :rules="formRules"
                :model="addArticle"
                label-position="left"
                size="small"
                label-width="150px"
                style="width: 400px; margin-left: 50px"
            >
                <el-form-item :label='$i18n.t("common.column")' prop="articleType">
                    <dataSelect
                        style="width: 180px"
                        class="filter-item"
                        v-model="addArticle.articleType"
                        clearable
                        :codeType="'article_type'"
                        :placeholder='$i18n.t("common.column")'
                        @change='handleArticleTypeChange'
                    ></dataSelect>
                </el-form-item>
                <el-form-item :label='$i18n.t("common.ShowCountry")'  prop="countryCodes">
                    <el-checkbox :indeterminate="isIndeterminate" v-model="checkAll" @change="handleCheckAllChange">{{ $i18n.t("common.SelectAll") }}</el-checkbox>
                    <el-checkbox-group v-model="addArticle.countryCodes" @change="handleCheckedCitiesChange" style="width: 600px">
                        <el-checkbox v-for="country in countrysDicts" :label="country.code" :key="country.code"
                            >{{ country.name }}({{ country.code }})</el-checkbox
                        >
                    </el-checkbox-group>
                </el-form-item>
                <el-form-item   :label='$i18n.t("common.type")'  prop="fileType">
                    <el-radio-group v-model="addArticle.fileType" @change="fileTypeChange()">
                        <el-radio label="image" :disabled='ugcSelected'>{{ $i18n.t("common.Pictureandtext") }}</el-radio>
                        <el-radio label="video">{{ $i18n.t("common.video") }}</el-radio>
                    </el-radio-group>
                </el-form-item>


                <!--
                <el-form-item label="品牌" prop="brandCodes">

                    <dataSelect
                        style="width: 180px"
                        class="filter-item"
                        v-model="addArticle.brandCodes"
                        clearable
                        multiple
                        :codeType="'brand'"
                        :placeholder='$i18n.t("common.brand")'
                    ></dataSelect>
                </el-form-item>
                 -->

                <el-form-item :label='$i18n.t("common.Releasetime")'  prop="startTime">

                    <el-date-picker
                        v-model="valuePicker"
                        type="datetimerange"
                        :range-separator='$i18n.t("common.to")'
                        :start-placeholder='$i18n.t("common.starttime")'
                        :end-placeholder='$i18n.t("common.Endtime")'
                        :picker-options="pickerOptions"
                    >
                    </el-date-picker>
                </el-form-item>
                <el-form-item prop="imageUrl" :label='$i18n.t("common.picture")' style="width: 600px">
                    <el-col :span="10">
                        <imgUpload ref="imageUpload" :img="imageUrl" @event="changeUrl"> </imgUpload>
                    </el-col>
                    <el-col :span="10" style="margin-top: 150px">
                        <span v-if='ugcSelected'>{{$i18n.t("common.Suggestedsize")}}：255*453，<=200KB</span>
                        <span v-else>{{$i18n.t("common.Suggestedsize")}}：466*280，<=200KB</span>
                    </el-col>
                </el-form-item>
                <el-form-item :label='$i18n.t("common.sort")' prop="sort">
                    <el-input-number v-model="addArticle.sort" style="width: 100%"></el-input-number>
                </el-form-item>
                <el-form-item :label='$i18n.t("common.Enableornot")' prop="status">
                    <el-switch v-model="addArticle.status" active-color="#13ce669"></el-switch>
                </el-form-item>
                <!-- </div> -->

                <el-form-item :label='$i18n.t("common.Multilingualconfiguration")' v-if="addArticle.fileType == 'image'" style="width: 800px">
                    <el-menu :default-active="contentIndex" class="el-menu-demo" mode="horizontal" @select="handleContentSelect">
                        <el-menu-item v-for="(item, index) in langs" :key="item.code" :index="item.code">
                            {{ item.name }}
                        </el-menu-item>
                    </el-menu>
                    <div>
                        <span>{{$i18n.t("common.title")}}：</span>
                        <el-input style="margin-top: 20px; width: 450px" v-model="title" :placeholder='$i18n.t("common.Pleaseenteratitle")' :label='$i18n.t("common.title")'></el-input>
                    </div>
                    <div style="margin-top: 20px">
                        <span>{{$i18n.t("common.content")}}：</span>
                        <wangEditer ref="wangEditer" :conten="contentValue" style="margin-top: 10px" />
                    </div>
                </el-form-item>
                <el-form-item :label='$i18n.t("common.video")' v-if="addArticle.fileType == 'video'" style="width: 800px">
                    <el-menu :default-active="contentIndex" class="el-menu-demo" mode="horizontal" @select="handleVideoSelect">
                        <el-menu-item v-for="(item, index) in langs" :key="item.code" :index="item.code">
                            {{ item.name }}
                        </el-menu-item>
                    </el-menu>
                    <div>
                        <span>{{$i18n.t("common.title")}}：</span>
                        <el-input style="margin-top: 20px; width: 450px" v-model="title" :placeholder='$i18n.t("common.Pleaseenteratitle")' :label='$i18n.t("common.title")'></el-input>
                    </div>
                    <div style="margin-top: 20px">
                        <span>{{$i18n.t("common.UploadVideo")}}：</span>
                        <videoUpload ref="videoUpload" :video="videoUrl" @event="changeVideoUrl" />
                    </div>
                    <div>
                        <span>{{$i18n.t("common.VideoScript")}}：</span>
                        <el-input
                            type="textarea"
                            style="margin-top: 20px; width: 450px"
                            v-model="videoOutsideUrl"
                            :placeholder='$i18n.t("common.PleaseEnterOrPasteTheVideoScript")'
                            :label='$i18n.t("common.VideoScript")'
                        ></el-input>
                    </div>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="closeForm()">  {{ $i18n.t("common.cancel") }} </el-button>
                <el-button type="primary" @click="handleSubmit()">  {{ $i18n.t("common.confirm") }} </el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import dataSelect from '@/components/common/DataSelect.vue';
import dataText from '@/components/common/DataText.vue';
import Pagination from '@/components/common/Pagination/';
import moment from 'moment';
import ossUploader from '@/components/page/OssEleUploder.vue';
import imgUpload from '@/views/country/imgUpload';
import X2JS from 'x2js'; //解析xml
import wangEditer from '@/components/common/WangEditer/index';
import videoUpload from '@/components/common/ossVideo/video';
import listImage from '@/components/common/ListImage.vue';

function initAddBean() {
    const addBean = {
        articleId: null,
        articleType: '',
        fileType: '',
        imageUrl: '',
        startTime: null,
        endTime: null,
        sort: 0,
        status: true,
        countryCodes: [],
        langs: [],
        brandCodes: []
    };
    return addBean;
}

export default {
    name: 'HomeArticleTable',
    components: {
        dataSelect,
        dataText,
        Pagination,
        moment,
        ossUploader,
        imgUpload,
        wangEditer,
        videoUpload,
        listImage
    },
    data() {
        var checkDateTime = (rule, value, callback) => {
            if (this.valuePicker.length > 0) {
                callback();
            } else {
                callback(new Error(this.$i18n.t("common.Pleaseselectatimerange")));
            }
        };
        return {
            fileType: 'image',
            contentDetails: [],
            formTitle: '',
            old: initAddBean(),
            addArticle: initAddBean(),
            list: [],
            total: 0,
            listLoading: true,
            countrysDicts: [],
            brandDicts: [],
            query: {
                page: 1,
                limit: 10,
                articleType: '',
                title: '',
                fileType: '',
                publishStatus: undefined,
                countryCode: [],
                brandCode: '',
                status: null
            },
            ossHost: process.env.VUE_APP_OSS_Head,
            ossSignature: { host: '' },
            addVisible: false,
            checkAll: false,
            ossVideoUrl: '',
            videoUploadPercent: 0,
            fileDataObj2: {},
            ossInfo: {
                ossHost: process.env.VUE_APP_OSS_Head,
                ossSignature: { host: '' },
                activityTranslates: [],
                langs: [],
                seoIndex: '0',
                newFiles: []
            },
            isIndeterminate: true,
            valuePicker: [],
            countryLang: [],
            imageUrl: '',
            videoUrl: '',
            editorOption: {
                label: 'name',
                value: 'code'
            },
            //富文本内容的主要参数
            contentValue: '',
            contentIndex: 'zh',
            title: '',
            videoOutsideUrl: '',
            langsArr: [],
            langs: [],
            ugcSelected: false,
            formRules: {

                articleType: [{ required: true, message: this.$i18n.t("common.Pleaseselectacolumn"), trigger: 'blur' }],
                fileType: [{ required: true, message: this.$i18n.t("common.Pleaseselectatype"), trigger: 'blur' }],
                // brandCodes: [{ required: true, message: this.$i18n.t("common.Pleaseselectabrand"), trigger: 'change' }],
                countryCodes: [{ required: true, message: this.$i18n.t("common.Pleaseselectacountry"), trigger: 'change' }],

                startTime: [{ validator: checkDateTime, required: true }]
            },
            pickerOptions: {
                shortcuts: [
                    {
                        text: this.$i18n.t("common.today"),
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime());
                            picker.$emit('pick', [start, end]);
                        }
                    },
                    {
                        text:  this.$i18n.t("common.Lastweek"),
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 6);
                            picker.$emit('pick', [start, end]);
                        }
                    },
                    {
                        text:  this.$i18n.t("common.Thismonth"),
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date(new Date().getFullYear(), new Date().getMonth(), 1);
                            picker.$emit('pick', [start, end]);
                        }
                    },
                    {
                        text:  this.$i18n.t("common.Lastmonth1"),
                        onClick(picker) {
                            const start = new Date(new Date().getFullYear(), new Date().getMonth() - 1, 1);
                            const end = new Date(new Date().getFullYear(), new Date().getMonth(), 1);
                            end.setDate(end.getDate() - 1);
                            picker.$emit('pick', [start, end]);
                        }
                    },
                    {
                        text:  this.$i18n.t("common.thisyear"),
                        onClick(picker) {
                            const start = new Date(new Date().getFullYear(), 0, 1);
                            const end = new Date();
                            picker.$emit('pick', [start, end]);
                        }
                    }
                ]
            }
        };
    },
    mounted() {
        this.doQueryList({ page: 1 });
        this.getOssSignature();
        this.getDictItems();
        this.getLangDictItems();
    },
    methods: {
        /**
         * 类型为图文时赋值
         */
        initImage() {
            var cont = '';
            for (var item of this.langsArr) {
                if (item.lang == this.contentIndex) {
                    this.title = item.title;
                    cont = item.content;
                }
            }
            setTimeout(() => {
                this.$refs['wangEditer'].initData(cont);
            }, 200);
        },
        /**
         * 类型为视频时赋值
         */
        initVideo() {
            for (var item of this.langsArr) {
                if (item.lang == this.contentIndex) {
                    this.title = item.title;
                    this.videoUrl = item.content;
                    this.videoOutsideUrl = item.videoOutsideUrl;
                    setTimeout(() => {
                        this.$refs['videoUpload'].initData(this.videoUrl);
                        this.$refs['videoUpload'].videoUploadPercent = 0;
                    }, 200);
                }
            }
        },
        /**
         * 重置多语言
         */
        initLangsArr() {
            for (var item of this.langs) {
                let param = {
                    lang: item.code,
                    content: '',
                    title: '',
                    videoOutsideUrl: ''
                };
                this.langsArr.push(param);
            }
        },
        handleContentSelect(key, keyPath) {
            let result = '';
            //获取组件中的内容
            if (this.$refs['wangEditer'].getHtml() != undefined) {
                result = this.$refs['wangEditer'].getHtml().slice(0, 9999);
            }

            let titleResult = '';
            if (this.title != undefined) {
                titleResult = this.title.slice(0, 199);
            }

            //赋值
            for (const index in this.langsArr) {
                if (this.langsArr[index].lang == this.contentIndex) {
                    this.langsArr[index].title = titleResult;
                    this.langsArr[index].content = result;
                }
            }

            //更新富文本内容
            this.contentIndex = key;
            for (var item of this.langsArr) {
                if (item.lang == this.contentIndex) {
                    this.title = item.title;
                    this.$refs['wangEditer'].initData(item.content);
                }
            }
        },
        handleVideoSelect(key, keyPath) {
            //重置视频上传组件
            //赋值
            for (const index in this.langsArr) {
                if (this.langsArr[index].lang == this.contentIndex) {
                    this.langsArr[index].title = this.title;
                    this.langsArr[index].videoOutsideUrl = this.videoOutsideUrl;
                }
            }

            //更新视频内容
            this.contentIndex = key;
            for (var item of this.langsArr) {
                if (item.lang == this.contentIndex) {
                    this.title = item.title;
                    this.videoOutsideUrl = item.videoOutsideUrl;
                    this.videoUrl = item.content == undefined ? '' : item.content;
                    this.$refs['videoUpload'].initData(this.videoUrl);
                    this.$refs['videoUpload'].videoUploadPercent = 0;
                }
            }
        },
        /**
         * 上传路径
         */
        changeUrl(data) {
            this.addArticle.imageUrl = data;
            console.log(data);
        },
        changeVideoUrl(data) {
            console.log(data);
            //赋值
            for (const index in this.langsArr) {
                if (this.langsArr[index].lang == this.contentIndex) {
                    this.langsArr[index].content = data;
                    this.videoUrl = data;
                }
            }
            console.log(this.langsArr);
        },
        /**
         * 分页
         */
        handleCurrentChange(val) {
            this.query.page = val;
            this.doQueryList({ page: val });
        },
        closeForm() {
            this.addVisible = false;
            this.doQueryList({ page: 1 });
        },
        /**
         * 时间戳转码
         */
        formatDate(row, column) {
            // 获取单元格数据
            let data = row[column.property];
            if (!data) {
                return '';
            } else {
                return moment(data).format('YYYY-MM-DD HH:mm:ss');
            }
        },
        /**
         * 触发搜索操作
         */
        handleQuery() {
            this.doQueryList({ page: 1 });
        },
        /**
         * 执行列表查询
         */
        doQueryList({ page, limit }) {
            if (page) {
                this.query.page = page;
            }
            if (limit) {
                this.query.limit = limit;
            }
            // if (this.query.title.includes('%')) {
            //     this.list = [];
            //     return;
            // }
            this.listLoading = true;
            this.$store
                .dispatch('getArticlePage', this.query)
                .then((res) => {
                    this.list = res;
                })
                .finally(() => {
                    this.listLoading = false;
                });
        },
        /**
         * 删除单条记录
         */
        handleDeleteSingle(row) {
            this.$confirm( this.$i18n.t("common.confirmdeletion"), this.$i18n.t("common.prompt"), {
                // confirmButtonText: '确定',
                // cancelButtonText: '取消',
                type: 'warning'
            })
                .then(() => {
                    this.$store.dispatch('deleteArticle', row.articleId).then((res) => {
                        this.doQueryList({ page: 1 });
                        this.$message({
                            message:  this.$i18n.t("common.Successfullydeleted"),
                            type: 'success'
                        });
                    });
                })
                .catch(() => {});
        },
        /**
         * 打开新建表单
         */
        handleCreate() {
            this.handleAdd();
        },
        /**
         * 打开编辑表单
         */
        handleUpdate(row) {
            this.formTitle =  this.$i18n.t("common.edit");
            this.old = row;
            this.resetForm();
            // this.resetEditForm();
            for (const key in initAddBean()) {
                this.addArticle[key] = this.old[key];
            }
            this.imageUrl = this.addArticle.imageUrl;
            this.valuePicker[0] = moment(row.startTime).format('yyyy-MM-DD HH:mm:ss');
            this.valuePicker[1] = moment(row.endTime).format('yyyy-MM-DD HH:mm:ss');
            this.addVisible = true;
            //初始化多语言
            for (var item of this.addArticle.langs) {
                for (const key in this.langsArr) {
                    if (item.lang == this.langsArr[key].lang) {
                        this.langsArr[key].title = item.title;
                        this.langsArr[key].videoOutsideUrl = item.videoOutsideUrl;
                        this.langsArr[key].content = item.content;
                    }
                }
            }
            console.log(this.langsArr);
            //赋值多语言信息列表
            if (this.addArticle.fileType == 'image') {
                this.initImage();
            }
            if (this.addArticle.fileType == 'video') {
                this.initVideo();
            }
            // ugc专区不支持图文，只支持视频
            if(this.addArticle.articleType === 'ugc'){
                this.ugcSelected = true
            }else{
                this.ugcSelected = false
            }
            // 全选
            let checkedCount = row.countryCodes.length;
            this.checkAll = checkedCount >= this.countrysDicts.length;
            this.isIndeterminate = checkedCount > 0 && checkedCount < this.countrysDicts.length;
             
        },
        /**
         * 重置表单
         */
        resetEditForm() {
            this.fileType = '';
            this.addArticle = initAddBean();
            this.valuePicker = [];
            this.addArticle.langs = [];
            this.contentIndex = 'zh';
            this.langsArr = [];
            this.initLangsArr();
        },
        /**
         * 重置表单
         */
        resetForm() {
            this.fileType = '';
            this.isIndeterminate = false;
            this.valuePicker = [];
            this.checkAll = false;
            this.addArticle = initAddBean();
            this.addArticle.langs = [];
            this.imageUrl = '';
            this.contentIndex = 'zh';
            this.videoUrl = '';
            this.title = '';
            this.videoOutsideUrl = '';
            this.contentValue = '';
            this.langsArr = [];
            this.ugcSelected = false
            this.initLangsArr();
        },
        /**
         * 打开新建表单
         */
        handleAdd() {
            this.formTitle = this.$i18n.t("common.Add1");
            this.resetForm();
            this.addVisible = true;
        },
        /**
         * 执行新建操作
         */
        handleSubmit() {
            this.addArticle.brandCodes = [];
            this.addArticle.brandCodes.push(this.$tenantBrand.getBrand());
            //上传图片校验
            if (this.addArticle.imageUrl == '' || this.addArticle.imageUrl == null) {
                this.$message({
                    showClose: false,
                    message:this.$i18n.t("common.Picturesmustbetransmitted"),
                    type: 'warning'
                });
                return;
            }

            if (this.addArticle.fileType == 'image') {
                //取富文本值
                let result = this.$refs['wangEditer'].getHtml();

                //当前选项多语言信息赋值
                for (const key in this.langsArr) {
                    if (this.langsArr[key].lang == this.contentIndex) {
                        this.langsArr[key].title = this.title.slice(0, 199);
                        this.langsArr[key].content = result.slice(0, 9999);
                    }
                }
            }
            if (this.addArticle.fileType == 'video') {
                //当前选项多语言信息赋值
                for (const key in this.langsArr) {
                    if (this.langsArr[key].lang == this.contentIndex) {
                        this.langsArr[key].title = this.title.slice(0, 199);
                        this.langsArr[key].content = this.videoUrl;
                        this.langsArr[key].videoOutsideUrl = this.videoOutsideUrl;
                    }
                }
            }

            this.addArticle.langs = this.langsArr;

            this.addArticle.startTime = moment(this.valuePicker[0]).unix() * 1000;
            this.addArticle.endTime = moment(this.valuePicker[1]).unix() * 1000;

            //多语言校验
            var countryLangs = [];
            for (var item of this.addArticle.countryCodes) {
                for (var langItem of this.countryLang) {
                    if (item == langItem.code) {
                        countryLangs.push(langItem.name);
                    }
                }
            }
            this.addArticle.langs.filter((x) => x.lang);
            for (var item of this.addArticle.langs) {
                // ugc专区 标题非必填
                if (this.addArticle.articleType !== 'ugc' &&  countryLangs.includes(item.lang) && item.title == '') {
                    countryLangs = [];
                    this.$message({
                        showClose: false,
                        message: this.$i18n.t("common.Selectedcountrytitleisrequired"),
                        type: 'warning'
                    });
                    return;
                }
                if (countryLangs.includes(item.lang)) {

                    if (this.addArticle.fileType == 'video' && item.videoOutsideUrl == '' && item.content == '') {
                        countryLangs = [];
                        this.$message({
                            showClose: false,
                            message: this.$i18n.t("common.PleaseUploadAVideoFileOrFillInTheVideoScript"),
                            type: 'warning'
                        });
                        return;
                    }
                    if (this.addArticle.fileType == 'image'  && item.content == '') {
                        countryLangs = [];
                        this.$message({
                            showClose: false,
                            message: this.$i18n.t("common.Selectedcountrycontentisrequired"),
                            type: 'warning'
                        });
                        return;
                    }

                    
                }
            }

            if (this.formTitle == this.$i18n.t("common.Add1")) {
                this.$refs['dataForm'].validate((valid) => {
                    if (valid) {
                        this.$store.dispatch('getHomeArticle', this.addArticle).then((data) => {
                            this.addVisible = false;
                            this.doQueryList({ page: 1 });
                            this.$message({
                                message: this.$i18n.t("common.Successfullysaved"),
                                type: 'success'
                            });
                        });
                    } else {
                        this.$message({
                            message: this.$i18n.t("common.Savefailed"),
                            type: 'error'
                        });
                        return false;
                    }
                });
            } else {
                this.$refs['dataForm'].validate((valid) => {
                    if (valid) {
                        this.$store.dispatch('homeArticleEdit', this.addArticle).then((data) => {
                            this.addVisible = false;
                            this.doQueryList({ page: 1 });
                            this.$message({
                                message:this.$i18n.t("common.Successfullyedited"),
                                type: 'success'
                            });
                        });
                    } else {
                        this.$message({
                            message:this.$i18n.t("common.Editingfailed"),
                            type: 'error'
                        });
                        return false;
                    }
                });
            }
        },
        getOssSignature() {
            var _this = this;
            this.$store.dispatch('getSignature', { dir: 'b2c' }).then((res) => {
                _this.ossSignature = res;
                _this.ossSignature.host = _this.ossHost;
            });
        },
        // getDictItems() {
        //         var _this = this;
        //         this.$store.dispatch('getDicItems', { tag: 'countrys' }).then(res => {
        //             if (res != null) _this.countrysDicts = res;
        //         });
        // },
        //国家全选
        handleCheckAllChange(val) {
            if (val) {
                this.addArticle.countryCodes = [];
                for (var item of this.countrysDicts) {
                    this.addArticle.countryCodes.push(item.code);
                }
            } else {
                this.addArticle.countryCodes = [];
            }

            this.isIndeterminate = false;
        },
        // 栏目切换
        handleArticleTypeChange(column){
            if(column === 'ugc'){
                this.ugcSelected = true
                // ugc专区不支持图文，只支持视频，切换成ugc需要选中视频类型和清空图文数据
                this.addArticle.fileType = 'video';
                this.fileTypeChange()
            }else{
                this.ugcSelected = false
            }

        },
        handleCheckedCitiesChange(value) {
            let checkedCount = value.length;
            this.checkAll = checkedCount === this.countrysDicts.length;
            this.isIndeterminate = checkedCount > 0 && checkedCount < this.countrysDicts.length;
        },

        getDictItems() {
            var _this = this;
            this.$store.dispatch('getDicItems', { tag: 'brand' }).then((res) => {
                if (res != null) _this.brandDicts = res;
            });
            this.$store.dispatch('getDicItems', { tag: 'countrys' }).then((res) => {
                if (res != null) _this.countrysDicts = res;
            });
            this.$store.dispatch('getDicItems', { tag: 'countrylang' }).then((res) => {
                if (res != null) _this.countryLang = res;
            });
        },
        getLangDictItems() {
            var _this = this;
            this.$store.dispatch('getDicItems', { tag: 'lang' }).then((res) => {
                _this.langs = res;
            });
        },
        cleanQuery() {
            this.query.title = '';
            this.query.articleType = '';
            this.query.countryCode = [];
            this.query.fileType = '';
            this.query.brandCode = '';
            this.query.status = null;
            this.query.publishStatus = undefined;
            this.doQueryList({ page: 1 });
        },

        fileTypeChange() {
            this.fileType = this.addArticle.fileType;

            for (var item of this.langsArr) {
                item.content =''
            }

        }
    }
};
</script>
<style scoped>
.filter-container {
    padding: auto;
    margin-left: 20px;
    padding-top: 20px;
}
.avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    width: 178px !important;
    height: 178px !important;
}

.avatar-uploader .el-upload:hover {
    border-color: #409eff;
}

.avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
}

/deep/ .avatar-uploader .el-upload--picture-card {
    width: 178px !important;
    height: 178px !important;
}
/deep/ .avatar-uploader .el-progress--circle {
    width: 176px !important;
    height: 176px !important;
    margin-top: 0px !important;
}
/deep/ .avatar-uploader .el-progress {
    width: 176px !important;
    height: 176px !important;
    margin-top: 0px !important;
}
/deep/ .avatar-uploader .el-progress-circle {
    width: 176px !important;
    height: 176px !important;
    margin-top: 0px !important;
}
</style>
