<template>
    <div class="app-container">
        <el-table v-loading="listLoading" :data="list" border stripe style="width: 100%; margin-top: 20px">
            <el-table-column label="ID" type="index" align="center" width="50px"> </el-table-column>
            <el-table-column :label='$i18n.t("common.name")'  prop="title" align="center" show-overflow-tooltip>
              <template slot-scope="scope">
                <span>{{ getTitleById(scope.row.remarkId) }}</span>
              </template>
            </el-table-column>
            <el-table-column :label='$i18n.t("common.Enableornot")'  prop="postCode" align="center" show-overflow-tooltip>
                <template slot-scope="scope">
                    <span v-show="scope.row.status">{{ $i18n.t("common.yes") }}</span>
                    <span v-show="scope.row.status == false">{{ $i18n.t("common.no") }}</span>
                </template>
            </el-table-column>
            <el-table-column :label='$i18n.t("common.Updatetime") ' prop="updateTime" align="center" show-overflow-tooltip :formatter="formatDate">
            </el-table-column>
            <el-table-column :label='$i18n.t("common.Operator") ' prop="updateUserName" align="center" show-overflow-tooltip> </el-table-column>
            <el-table-column :label='$i18n.t("common.operation") ' align="center" fixed="right" width="400">
                <template slot-scope="scope">
                    <el-button @click="openRule(scope.row)" type="text" size="small">{{ $i18n.t("common.RuleConfiguration") }}</el-button>
                    <el-button @click="openLang(scope.row)" type="text" size="small">{{ $i18n.t("common.Multilingualconfiguration") }}</el-button>
                </template>
            </el-table-column>
        </el-table>
        <!-- 规则配置表单 -->
        <el-dialog :title='$i18n.t("common.RuleConfiguration")' :visible.sync="ruleVisible" @close="closeForm()" v-if="ruleVisible" :close-on-click-modal="false">
            <el-form label-position="right" label-width="220px">
                <el-form-item  :label='$i18n.t("common.name")' >
                    {{ title }}
                </el-form-item>
                <el-form-item :label='$i18n.t("common.ObtainingPatrioticCoinObject")'>
                    {{ target }}
                </el-form-item>
                <el-form-item :label='$i18n.t("common.Rewardconditionsobtained")'>
                    {{ condition }}
                </el-form-item>
                <el-form-item :label='$i18n.t("common.Obtainpatrioticcoinallocation")'>
                    <span style="color: red">{{ $i18n.t("common.Numberoflocalcurrencies") }}</span>
                </el-form-item>
            </el-form>
            <el-form
                ref="dataForm"
                :model="ruleEdit"
                label-position="left"
                size="small"
                label-width="100px"
                style="width: 400px; margin-left: 140px; margin-top: 20px"
            >
                <el-form-item
                    v-for="(item, index) in countrysDicts"
                    :key="item.code"
                    :index="item.code"
                    :label="item.name + '：'"
                    prop="countryCode"
                >
                    <el-input-number
                        v-model="addCountryCount[item.code]"
                        :placeholder='$i18n.t("common.Inputquantity")'
                        style="margin-left: -35px; width: 190px"
                        :min="0"
                        :max="999"
                        :controls="false"
                    ></el-input-number>
                </el-form-item>
                <!-- </div> -->
            </el-form>
            <div style="margin-left: 80px">
                {{ $i18n.t("common.Enableornot") }}
                <el-switch v-model="ruleEdit.status" active-color="#13ce669" style="margin-left: 10px"></el-switch>
            </div>

            <div slot="footer" class="dialog-footer">
                <el-button @click="closeForm()"> {{ $i18n.t("common.cancel") }}</el-button>
                <el-button type="primary" @click="handleSubmitRule()">  {{ $i18n.t("common.confirm") }} </el-button>
            </div>
        </el-dialog>
        <!-- 多语言配置 -->
        <el-dialog :title=' $i18n.t("common.Multilingualconfiguration")' :visible.sync="langVisible" @close="closeForm()" v-if="langVisible" :close-on-click-modal="false">
            <el-form
                ref="dataForm"
                :model="langEdit"
                label-position="left"
                size="small"
                label-width="120px"
                style="width: 400px; margin-left: 140px; margin-top: 20px"
            >
                <el-form-item  :label='$i18n.t("common.name")' >
                    <span style="margin-left: -35px">
                        {{ title }}
                    </span>
                </el-form-item>
                <el-form-item v-for="(item, index) in lang" :key="item.code" :index="item.code" :label="item.name + '：'" prop="lang">
                    <el-input v-model="addLang[item.code]" :placeholder=' $i18n.t("common.EnterName")'  style="margin-left: -35px; width: 190px"></el-input>
                </el-form-item>
                <!-- </div> -->
            </el-form>

            <div slot="footer" class="dialog-footer">
                <el-button @click="closeForm()">  {{ $i18n.t("common.cancel") }} </el-button>
                <el-button type="primary" @click="handleSubmitLang()"> {{ $i18n.t("common.confirm") }} </el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import dataSelect from '@/components/common/DataSelect.vue';
import dataText from '@/components/common/DataText.vue';
import Pagination from '@/components/common/Pagination/';
import moment from 'moment';
import X2JS from 'x2js'; //解析xml


export default {
    name: 'RestrictedArea',
    components: {
        dataSelect,
        dataText,
        Pagination,
        moment
    },
    data() {
        return {
            formTitle: '',
            ruleEdit: {
                countryCount: [],
                remarkId: null,
                status:false,
            },
            langEdit: {
                lang: []
            },
            addCountryCount:[],
            addLang:[],
            list: [],
            total: 0,
            listLoading: false,
            countrysDicts: [],
            brandDicts: [],
            lang: [],
            query: {
                page: 1,
                limit: 10,
                postCode: null,
                countryCode: null
            },
            ossHost: process.env.VUE_APP_OSS_Head,
            ossSignature: { host: '' },
            ruleVisible: false,
            langVisible: false,

            title: this.$i18n.t("common.Registrationsuccessfulnot"),
            target: this.$i18n.t("common.Registeredaccount"),
            condition: this.$i18n.t("common.Registrationsuccessfulnot1"),

            countryLang: [],
            //字典取的语言
            langs: []
        };
    },
    created() {
        this.doQueryList({ page: 1 });
        this.getDictItems();
    },
    mounted() {
        this.getOssSignature();
        this.getDictItems();
    },
    methods: {
        openRule(row) {
            console.log(row);
            this.title = this.getTitleById(row.remarkId);
            this.target = this.getTargetById(row.remarkId);
            this.condition = this.getConditionById(row.remarkId);
            this.ruleEdit.remarkId = row.remarkId;
            this.addCountryCount = [];
            if(row.status == 1){
                this.ruleEdit.status = true;
            }else{
                this.ruleEdit.status = false;
            }
            for(var item in row.countryCount){
                this.addCountryCount[item] = row.countryCount[item];
            }
            this.ruleVisible = true;
            console.log(this.ruleEdit);
        },
        handleSubmitRule() {
            console.log(this.addCountryCount);
            this.ruleEdit.countryCount = [];
            for(var item of this.countrysDicts){
                this.ruleEdit.countryCount.push({
                    countryCode:item.code,
                    count:this.addCountryCount[item.code]
                })
            }
            if(this.ruleEdit.status){
                this.ruleEdit.status = 1;
            }else{
                this.ruleEdit.status = 0;
            }
            console.log(this.ruleEdit);
            this.$store.dispatch('ruleEdit', this.ruleEdit).then((data) => {
                this.ruleVisible = false;
                this.doQueryList({ page: 1 });
                this.$message({
                    message:  this.$i18n.t("common.Successfullysaved"),
                    type: 'success'
                });
            });
        },
        openLang(row) {
            this.title = this.getTitleById(row.remarkId)
            this.langVisible = true;
            this.addLang = [];
            this.langEdit.remarkId = row.remarkId;
            console.log(row);
            for(var item in row.lang){
                this.$set(this.addLang,item,row.lang[item])
            }
            console.log(this.addLang);
        },
        handleSubmitLang(){
            console.log(this.addLang);
            this.langEdit.lang = [];
            for(var item of this.lang){
                this.langEdit.lang.push({
                    lang:item.code,
                    content:this.addLang[item.code]
                })
            }
            console.log(this.langEdit);
            this.$store.dispatch('langEdit', this.langEdit).then((data) => {
                this.langVisible = false;
                this.doQueryList({ page: 1 });
                this.$message({
                    message:  this.$i18n.t("common.Successfullysaved"),
                    type: 'success'
                });
            });

        },
        closeForm() {
            this.ruleVisible = false;
            this.langVisible = false;
            this.doQueryList({ page: 1 });
        },
        /**
         * 时间戳转码
         */
        formatDate(row, column) {
            // 获取单元格数据
            let data = row[column.property];
            if (!data) {
                return '';
            } else {
                return moment(data).format('YYYY-MM-DD HH:mm:ss');
            }
        },
        /**
         * 触发搜索操作
         */
        handleQuery() {
            this.doQueryList({ page: 1 });
        },
        /**
         * 执行列表查询
         */
        doQueryList({ page, limit }) {
            this.$store.dispatch('getCruuencyConfig').then((res) => {
                this.list = res;
            });
        },
        /**
         * 删除单条记录
         */
        handleDeleteSingle(row) {
            this.$confirm( this.$i18n.t("common.confirmdeletion"),  this.$i18n.t("common.prompt"), {
                confirmButtonText: this.$i18n.t("common.confirm"),
                cancelButtonText: this.$i18n.t("common.cancel"),
                type: 'warning'
            })
                .then(() => {
                    this.$store.dispatch('deleteRestricted', { id: row.areaId }).then((res) => {
                        this.doQueryList({ page: 1 });
                        this.$message({
                            message:   this.$i18n.t("common.Successfullydeleted"),
                            type: 'success'
                        });
                    });
                })
                .catch(() => {});
        },
        /**
         * 执行新建操作
         */
        handleSubmit() {
            console.log(this.ruleEdit);

            if (this.formTitle == this.$i18n.t("common.Add1") ){
                this.$refs['dataForm'].validate((valid) => {
                    if (valid) {
                        this.$store.dispatch('ruleEdit', this.ruleEdit).then((data) => {
                            this.ruleVisible = false;
                            this.doQueryList({ page: 1 });
                            this.$message({
                                message:   this.$i18n.t("common.Successfullysaved"),
                                type: 'success'
                            });
                        });
                    } else {
                        this.$message({
                            message:   this.$i18n.t("common.Savefailed"),
                            type: 'error'
                        });
                        return false;
                    }
                });
            } else {
                this.$refs['dataForm'].validate((valid) => {
                    if (valid) {
                        console.log(this.ruleEdit);
                        this.$store.dispatch('editRestricted', this.ruleEdit).then((data) => {
                            this.ruleVisible = false;
                            this.doQueryList({ page: 1 });
                            this.$message({
                                message:  this.$i18n.t("common.Successfullyedited"),
                                type: 'success'
                            });
                        });
                    } else {
                        this.$message({
                            message:  this.$i18n.t("common.Editingfailed"),
                            type: 'error'
                        });
                        return false;
                    }
                });
            }
        },
        getOssSignature() {
            var _this = this;
            this.$store.dispatch('getSignature', { dir: 'b2c' }).then((res) => {
                _this.ossSignature = res;
                _this.ossSignature.host = _this.ossHost;
            });
        },

        getDictItems() {
            var _this = this;
            this.$store.dispatch('getDicItems', { tag: 'brand' }).then((res) => {
                if (res != null) _this.brandDicts = res;
            });
            this.$store.dispatch('getDicItems', { tag: 'countrys' }).then((res) => {
                if (res != null) _this.countrysDicts = res;
            });
            this.$store.dispatch('getDicItems', { tag: 'countrylang' }).then((res) => {
                if (res != null) _this.countryLang = res;
            });
            this.$store.dispatch('getDicItems', { tag: 'lang' }).then((res) => {
                if (res != null) _this.lang = res;
            });
        },
        cleanQuery() {
            this.query.postCode = null;
            this.query.countryCode = null;
            this.doQueryList({ page: 1 });
        },
        getTitleById(id) {
          if(id=='1001'){
            return this.$i18n.t("common.Registrationsuccessfulnot")
          }else if(id=='1002'){
            return this.$i18n.t("common.Inviteduserplacesanorder")
          }else if(id=='1008'){
            return this.$i18n.t("common.Registrationsuccessful")
          }
        },
        getTargetById(id) {
          if(id=='1001'){
            return this.$i18n.t("common.Registeredaccount")
          }else if(id=='1002'){
            return this.$i18n.t("common.Inviteraccount")
          }else if(id=='1008'){
            return this.$i18n.t("common.Registeredaccount")
          }
        },
        getConditionById(id) {
          if(id=='1001'){
            return this.$i18n.t("common.Registrationsuccessfulnot1")
          }else if(id=='1002'){
            return this.$i18n.t("common.Provideinvitationcode")
          }else if(id=='1008'){
            return this.$i18n.t("common.Usinganinvitationcode")
          }
        },
    }
};
</script>
<style scoped>
.filter-container {
    padding: auto;
    margin-left: 20px;
    padding-top: 20px;
}
</style>
