<template>
    <div style="height: 100%;min-height:350px;">
		<filter-container :arg="arg" :defaultListQuery="defaultListQuery" :dictionary="dictionary" :placeholder="placeholder" @handleSearchList='handleSearchList' @handleResetSearch='handleResetSearch'></filter-container>
		<div style="margin: 20px 0px 20px 20px;">
			<el-button @click="add" type="success" size="small" v-if="$common.hasPermission('Create',module_code)">{{ $i18n.t('common.Add') }}</el-button>
		</div>
        <el-table
            ref="pagedata"
            :data="pageData.list"
            border
            style="width: 100%"
            height="calc(100% - 190px)"
        >
            <el-table-column prop="goodsVariantId" label="ID" align="center" show-overflow-tooltip> </el-table-column>
            <el-table-column prop="title" :label="$i18n.t('common.name')" align="center" show-overflow-tooltip></el-table-column>
            <el-table-column prop="count" :label="$i18n.t('common.Productquantity')" align="center" show-overflow-tooltip>
				<template slot-scope="scope">
				    <span v-if="scope.row.count">{{scope.row.count}}</span>
				</template>
			</el-table-column>         
            <el-table-column prop="createTime" :label="$i18n.t('common.Creationtime')" align="center" show-overflow-tooltip>
				<template slot-scope="scope">
				    <span>{{scope.row.createTime | formatDate}}</span>
				</template>
			</el-table-column>
            <el-table-column prop="updateTime" :label="$i18n.t('common.Updatetime')" align="center" show-overflow-tooltip>
				<template slot-scope="scope">
                    <span v-if="scope.row.updateTime">{{scope.row.updateTime | formatDate}}</span>
                </template>
			</el-table-column>
            <el-table-column :label="$i18n.t('common.operation')" align="center" fixed="right">
                <template slot-scope="scope">
                    <el-button @click="edit(scope.row)" type="text" size="small" v-if="$common.hasPermission('Update',module_code)">{{ $i18n.t('common.edit') }}</el-button>
                    <!-- <el-button @click="preview(scope.row)" type="text" size="small">预览</el-button> -->
                    <el-button @click="del(scope.row)" type="text" size="small" v-if="$common.hasPermission('Delete',module_code)">{{ $i18n.t('common.delete') }}</el-button>
                </template>
            </el-table-column>
        </el-table>
        <div class="block">
            <el-pagination
                @current-change="handleCurrentChange"
                :current-page="listQuery.page"
                :page-size="listQuery.limit"
                layout="total, prev, pager, next, jumper"
                :total="pageData.total"            
            >
            </el-pagination>
        </div>
        <el-dialog :title="isEdit?$i18n.t('common.edit'):$i18n.t('common.Add')" :visible.sync="dialogFormVisible" :before-close="closeDialog" :close-on-click-modal="false">
			<div v-if="dialogFormVisible">
				<varoant-dialog :dictionary="dictionary" :isEdit='isEdit' :formListQuery='formListQuery' @handleSearchList="handleSearchList" @closeDialog='closeDialog'/>
			</div>          
        </el-dialog>
    </div>
</template>
<script>
import config from "@/utils/config.js";
import tenantBrand from '@/utils/tenantBrand'
import VaroantDialog from './components/VaroantDialog.vue';
import FilterContainer from '@/components/common/FilterContainer.vue';
import i18n from '@/i18n/i18n.js';
import dictionaryUtil from '@/utils/dictionaryUtil';
const defaultFormListQuery={
	goodsVariantId:"0",
	brandCode:tenantBrand.getBrand(),//'AigoStar',
	title:"",
	parameterList:[],
	goodsList:[]
}						
const defaultDictionary={
		// 国家
		country:dictionaryUtil.getDict('countrys'),
		//品牌
		brandCode:dictionaryUtil.getDict('brand'),
		//促销
		promotion:[{
			id:'1',
			name:i18n.t('common.delete'),
			value:true
		},{
			id:'2',
			name:i18n.t('common.no'),
			value:false
		}],
		state:[{
			id:'1',
			name:i18n.t('common.Listed'),
			value:true
		},{
			id:'2',
			name:i18n.t('common.Notlisted'),
			value:false
		}]
	};
	
export default {
    components: { VaroantDialog,FilterContainer },
    name: 'varoant',
    data() {
        return {
			module_code:config.module_code.variant,
            listQuery:{
				"offset":"",
				"beginTime": "",
				"endTime": '',
				"goodsVariantId": "",
				//"brandCode": "",
				"title":"",
				"page": 1,
				"limit": 10
            },
            defaultListQuery:{
				"offset":"",
				"beginTime": "",
				"endTime": '',
				"goodsVariantId": "",
				//"brandCode": "",
				"title":"",
				"page": 1,
				"limit": 10  
            },
			//组件标签是否显示
			arg:{
				name:true,
				//brand:false,
				date:true				
			},
			placeholder:{
				date:{
					start:this.$i18n.t('common.Updatetime'),
					end:this.$i18n.t('common.Updatetime')
				}
			},
			//编辑or新增
			isEdit:false,
			formListQuery:Object.assign({}, defaultFormListQuery),
			dictionary:Object.assign({}, defaultDictionary),
            //表单数据
            formItem: {},
            platformList: [],
            pageData: {},
            dialogFormVisible: false,
            title: '',
            ossHost: process.env.VUE_APP_OSS_Head,
            //字典下拉框
            brandDicts: [],
            countrysDicts: [],
            platformDicts: [],
            webSiteUrl: process.env.VUE_APP_WEBSITE
        };
    },
    mounted() {
		this.handleSearchList()
    },
    methods: {
		//搜索列表
		handleSearchList(listQuery){
			if(listQuery){
				this.listQuery.page=1			
				this.listQuery={
					"offset":"",
					"beginTime":listQuery.date?new Date(listQuery.date[0]).getTime():'',
					"endTime": listQuery.date?new Date(listQuery.date[1]).getTime():'',
					"goodsVariantId": "",
					//"brandCode": listQuery.brand,
					"title":listQuery.title,
					"page": 1,
					"limit": 10  
				}				
			}
			this.$store
				.dispatch('getGoodsVarList', this.listQuery)
				.then(res => {					
					this.pageData=res
				});				
		},
		//打开新增
		add(){		
			this.isEdit=false
			this.dialogFormVisible=true
		},
		//打开编辑
		edit(row){
			this.formListQuery=Object.assign({}, row)
			this.isEdit=true
			this.dialogFormVisible=true	
		},
        preview(row) {
            console.log(this.webSiteUrl);
            var url = `${this.webSiteUrl}promotionTheme?id=${row.activityId}`;
            console.log(url);
            window.open(url, '_blank');
        },
        del(row) {
            var _this = this;
            this.$confirm(this.$i18n.t("common.confirmdeletion"), this.$i18n.t("common.prompt"), {
                confirmButtonText: this.$i18n.t('common.confirm'),
				cancelButtonText: this.$i18n.t('common.cancel'),
                type: 'warning'
            })
                .then(() => {
                    this.$store.dispatch('deleteGoodsAdVariant', { id: row.goodsVariantId }).then((res) => {
                            this.$message.success(this.$i18n.t('common.Successfullydeleted'));
							this.handleSearchList()
                    });
                })
                .catch(() => {});
        },
		closeDialog(){
			this.dialogFormVisible=false
			this.formListQuery=Object.assign({}, defaultFormListQuery)
		},
        handleCurrentChange(val) {
            this.listQuery.page = val;
            this.handleSearchList();
        },
		handleResetSearch(){
			this.listQuery = Object.assign({}, this.defaultListQuery);
			this.handleSearchList()
		}
    }
};
</script>
