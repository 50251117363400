<template> 
    <div>
        <el-form :model="formData"   ref="formItem">     
            <el-table ref="pagedata" :data="formData.prices" border style="width: 100%">
                <el-table-column prop="countryCode" :label="$t('common.Country')" align="center" show-overflow-tooltip>
                    <template slot-scope="scope">
                        <span> {{ $dict.getDictValue('countrys', scope.row.countryCode) }} </span>
                    </template>
                </el-table-column>
                <el-table-column prop="lowestPrice" :label="$t('common.Lowestprice')" align="center" show-overflow-tooltip>
                    <template slot-scope="scope">
                        <span v-if="scope.row.lowestPrice>=0"> {{ Number(scope.row.lowestPrice).toFixed(2) }} </span>
                    </template>
                </el-table-column>
                <el-table-column prop="fixedPrice" :label="$t('common.Fixedprice1')" align="center" show-overflow-tooltip>
                    

                    <template slot-scope="scope" >
                        
                        <el-form-item>
                            <el-input v-model="scope.row.fixedPrice" :ref="'fixedPrice_'+scope.$index"   :id="'fixedPrice_'+scope.$index" 
                            style="width: 100%;" 
                            @input="check_num(scope.row)"
                            @blur="check_blurNum(scope.$index,scope.row)"
                            @focus="focusStyle(scope.$index,scope.row)"
                            ></el-input>
                        </el-form-item>
                      
                    </template>

                </el-table-column>
                <el-table-column :label="$t('common.FixedPriceGrossMargin')" align="center" show-overflow-tooltip>
                    <template slot-scope="scope">
                        <span v-if="scope.row.fixedPriceProfit>=0"> {{ Number(scope.row.fixedPriceProfit).toFixed(2) }} </span>
                    </template>
                </el-table-column>
                <el-table-column prop="resultPrice" :label="$t('common.Pricing_unitprice')" align="center" show-overflow-tooltip>
                    <template slot-scope="scope">
                        <span v-if="scope.row.resultPrice"> {{ Number(scope.row.resultPrice).toFixed(2) }} </span>
                    </template>
                </el-table-column>
                <el-table-column prop="salePriceEbp" :label="$t('common.price1')" align="center" show-overflow-tooltip>
                    <template slot-scope="scope">
                        <span v-if="scope.row.salePriceEbp || scope.row.salePriceEbp >= 0">
                            {{ Number(scope.row.salePriceEbp).toFixed(2) }} </span>
                    </template>
                </el-table-column>
                <el-table-column :label="$t('common.SellingPriceGrossProfitMargin')" align="center" show-overflow-tooltip>
                    <template slot-scope="scope">
                        <span v-if="scope.row.salePriceEbpProfit || scope.row.salePriceEbpProfit >= 0">
                            {{ Number(scope.row.salePriceEbpProfit).toFixed(2) }} </span>
                    </template>
                </el-table-column>
                <el-table-column prop="promotionPrice" :label="$t('common.Promotionprice_unitprice')" align="center" show-overflow-tooltip>
                    <template slot-scope="scope">
                        <span v-if="scope.row.promotionPrice"> {{ Number(scope.row.promotionPrice).toFixed(2) }} </span>
                    </template>
                </el-table-column>
                <el-table-column :label="$t('common.PromotionalPriceGrossProfitMargin')" align="center" show-overflow-tooltip>
                    <template slot-scope="scope">
                        <span v-if="scope.row.promotionPriceProfit"> {{ Number(scope.row.promotionPriceProfit).toFixed(2) }} </span>
                    </template>
                </el-table-column>
            </el-table>
        </el-form>

        <span slot="footer" class="dialog-footer">
            <el-button style="width: 20%;" @click="fixedPriceClose">{{$t('common.Cancel')}}</el-button>
            <el-button style="width: 20%;" type="primary" :loading="fixedPriceLoading" @click="postFixedPrice">{{$t('common.Confirm')}}</el-button>
        </span>
 
      



        <el-dialog :title="$t('common.Prompt')" :visible.sync="dialogLowestPriceVisible" width="20%" append-to-body
            :before-close="handleLowestPriceClose">
            <div v-if="dialogLowestPriceVisible">
                <h4><span>{{ $t('common.FixedPriceCannotBeLowerThanTheLowestPrice') }}：</span></h4>
                <div style="width: 100%; display: flex; justify-content: center; align-items: center">
                    <div style="width: 80%">
                    <div v-for="(item, index) in checkLowestPrice" :key="index" style="margin: 10px 10px">
                        {{ $dict.getDictValue('countrys', item.countryCode) }}:<span style="color: red">{{
                        item.price.toFixed(2) }}</span>
                    </div>
                    </div>
                </div>

                <span slot="footer" class="dialog-footer">
                    <el-button @click="updateLowestPrice">{{$t('common.Confirm')}}</el-button>
                </span>
            </div>
        </el-dialog>

    </div>
</template>


<script>
 
import scene from "@/utils/scene";

export default {
    components: {  
    },
    props: {
        formData: {
            type: Object,
            default: () => {
            return {}
            }
        },
        defaultFormData: {
            type: Object,
            default: () => {
            return {}
            }
        },
    },
    data() {
        return {
 
            fixedPriceLoading: false,
            checkLowestPrice: [],//校验最低价格
            dialogLowestPriceVisible: false,
            priceSetting: []
        };
    },
    computed: {
        scene() {
            return scene
        }
    },
    mounted() {
        this.init()
    },
    methods: {
        init(){
            this.$store.dispatch('listPriceSettings').then((res) => {
                this.priceSetting = res
            });
        },
        //限制
        check_num(val) {
            //禁止录入整数部分两位以上，但首位为0
            val.fixedPrice = val.fixedPrice.replace(/^([1-9]\d*(\.[\d]{0,2})?|0(\.[\d]{0,2})?)[\d.]*/g, '$1');
            //禁止录入整数部分超过6位
            val.fixedPrice = val.fixedPrice.replace(/^([1-9]\d{5})\d*/g, '$1');
            //替换除了数字和.以外的字符
            val.fixedPrice = val.fixedPrice.replace(/[^\d\.]/g, '');
            //第一个输入.  则自动变为0.
            val.fixedPrice = val.fixedPrice.replace(/^\./g, '0.');
            //保证.只出现一次，而不能出现两次以上 2..    2.2.2
            val.fixedPrice = val.fixedPrice.replace('.', '$#$').replace(/\./g, '').replace('$#$', '.');
            //只能输入两个小数
            val.fixedPrice = val.fixedPrice.replace(/^(\-)*(\d+)\.(\d\d).*$/, '$1$2.$3');
        },
        focusStyle(index,row){
            const placeholder = document.querySelector('#' + 'fixedPrice_' + index);
            if(row.lowestPrice != null  && row.fixedPrice >= row.lowestPrice){
                placeholder.style.borderColor = '#4290f7'; 
            }else{
                if(row.fixedPrice != null && row.fixedPrice != ''){
                     
                } else {
                    placeholder.style.borderColor = '#4290f7'; 
                } 
            }    
        },
        check_blurNum(index,row) {
            const placeholder = document.querySelector('#' + 'fixedPrice_' + index);      
            // 1. 可编辑，失去焦点时，判断是否低于最低价格，若是，toast提示（原提示文本）且不可失去焦点，输入框边框为红；若否，计算出固定价格毛利率
            if(row.lowestPrice != null  && row.fixedPrice >= row.lowestPrice){
                placeholder.style.borderColor = '#DCDFE6'; 
                // 计算出固定价格毛利率
                if(row.fixedPrice > 0){ 
                    var fixedPriceProfit = this.calculateProfit(row.feightFee, row.originalPriceSaleDouble, row.countryCode ,row.fixedPrice );
                    row.fixedPriceProfit = fixedPriceProfit
                }
            } else {
                if(row.fixedPrice != null && row.fixedPrice != ''){
                    placeholder.style.borderColor = '#F56C6C';
                    this.$message({
                        message: this.$i18n.t('common.FixedPriceCannotBeLowerThanTheLowestPrice'),
                        type: 'warning',
                        duration: 1000
                    });
                    this.$refs['fixedPrice_' + index].focus();
                    return;
                } else {
                    placeholder.style.borderColor = '#DCDFE6'; 
                } 
            }
        },
        // 计算毛利率 毛利率=（价格*（1-税率/（1+税率）-佣金）-运费-成本价）/价格
        calculateProfit(feightFee, originalPrice , countryCode,price ) {
            let profit = 0;
            this.priceSetting.forEach((ps) => {
                if (ps.countryCode === countryCode) {
                    if (price > 0) {
                        const taxRate = ps.taxRate / 100
                        const commission = ps.commission / 100
                        // 计算毛利率（和后端公式保持一致）
                        profit = (1 - (taxRate / (taxRate + 1)).toFixed(7) - commission - ((feightFee + originalPrice) / price).toFixed(7)) * 100  
                    } 
                    return;
                }
            });
            return profit.toFixed(2);
        },
 
        //关闭固定价格
        fixedPriceClose() {
            this.$emit('closePricesDialog');
        },
 
        //编辑固定价格
        postFixedPrice() {
          
            for (let item of  this.formData.prices) {
                if( item.lowestPrice != null && item.lowestPrice != '' && item.fixedPrice != null && item.fixedPrice != '' &&  item.fixedPrice < item.lowestPrice ){
                    return;
                }
            }

            if (scene.isNonSelf) {
                let countryCodes = this.formData.goodsSpecials.filter(goodsSpecial => goodsSpecial.shelf).map(goodsSpecial => goodsSpecial.countryCode);
                let subFlag = true;
                this.formData.prices.forEach(price => {
                    if (countryCodes.includes(price.countryCode) && !price.fixedPrice) {
                        subFlag = false;
                        return
                    }
                })
                if (!subFlag) {
                    this.$message({
                        message: '请配置固定价格',
                        type: 'warning'
                    })
                    return
                }
            }


            this.fixedPriceLoading = true;
            let checkPrice = []
            this.formData.prices.forEach(item => {
                if (item.fixedPrice) {
                    checkPrice.push(
                        {
                            price: item.fixedPrice,
                            countryCode: item.countryCode
                        }
                    )
                }
            });
            let checkQuery = {
                goodsId: this.formData.goodsId,
                priceList: checkPrice
            }
            this.$store.dispatch('postCheckLowestPrice', checkQuery).then((resCheck) => {
                if (resCheck) {
                    //校验最低价
                    if (resCheck.result == false) {
                        this.fixedPriceLoading = false;
                        this.checkLowestPrice = resCheck.priceList
                        if (this.checkLowestPrice && this.checkLowestPrice.length > 0) {
                            this.dialogLowestPriceVisible = true
                        }
                        return
                    }
                    this.$store.dispatch("putFixedPrice", {
                        goodsIdList: [this.formData.goodsId],
                        fixedPriceDtoList: this.formData.prices
                    }).then(res => {
                        this.$message({
                            message: this.$i18n.t('common.SubmittedSuccessfully'),
                            type: 'success',
                            duration: 1000
                        });
                        this.fixedPriceClose()
                        this.$emit("handleSearchList")
                        this.fixedPriceLoading = false;
                    }).catch(err => {
                        this.fixedPriceLoading = false;
                    });
                }
            }).catch(err => {
                this.fixedPriceLoading = false;
            });
        },
        handleLowestPriceClose(){
            this.dialogLowestPriceVisible = false;
        },
        //更新固定价格为最低价
        updateLowestPrice(){
            this.formData.prices.forEach(item=>{
                this.checkLowestPrice.forEach(item2=>{
                    if(item.countryCode==item2.countryCode && item2.price){
                        item.fixedPrice=item2.price.toFixed(2)
                        if(item.fixedPrice > 0){ 
                            var fixedPriceProfit = this.calculateProfit(item.feightFee, item.originalPriceSaleDouble, item.countryCode ,item.fixedPrice );
                            item.fixedPriceProfit = fixedPriceProfit
                        }
                    }
                })
            })
            this.dialogLowestPriceVisible = false;
        },
         
    },
    watch: {     
    }
};
</script>
<style scoped>
 

.dialog-footer {
    margin-top: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
}



 

</style>