<template>
    <div>

		<el-upload class='avatar-uploader' 
			:action="ossInfo.ossSignature.host"
			ref="uploadImg"
			:auto-upload="false"      
			list-type="picture-card"
            :on-error="handleError"
            :on-success="handleSuccess"
            :on-progress='uploadVideoProcess'
			:data="fileDataObj"         
			accept=".jpg, .jpeg, .png, .mp4"
			:limit="1"
			:on-change="handleChange"	
		>
		    
            <el-progress v-if='videoUploadPercent>0' type='circle' :percentage='(videoUploadPercent)'
				        tyle='margin-top:30px;width: 178px;height: 178px;'></el-progress>

            <i  v-else slot="default"  class="el-icon-plus avatar-uploader-icon"></i>

		</el-upload>    
 
    </div>
</template>

<script>

import X2JS from 'x2js'; //解析xml

export default {
    props: {
 
    },
    data() {
        return {
            imageUrl:'',
            videoUploadPercent: 0,
            fileDataObj: {},
            ossInfo: {
				ossHost: process.env.VUE_APP_OSS_Head,				
				ossSignature: {
					host: ''
				},
                activityTranslates: [],
                langs: [],
                seoIndex: '0',
                newFiles: []
            },
            ossHost: process.env.VUE_APP_OSS_Head  
        };
    },
    created() {
        this.getOssSignature();
    },
    mounted() {   
       
    },
    methods: {
        
        handleError(err, file, fileList){
            console.log('handleError',err,file,fileList)
            this.videoUploadPercent = 0
            this.$refs.uploadImg.clearFiles();
        },
        handleSuccess(response, file, fileList) {
            console.log('handleSuccess',response,file,fileList)
            var x2js = new X2JS();
            var jsonObj = x2js.xml2js(file.response);
            let ossUrl = jsonObj.PostResponse.Location;
            let fileType =file.type;
            this.videoUploadPercent = 0
            this.$refs.uploadImg.clearFiles();
            this.$emit('uploadSuccess', ossUrl , fileType); 
        },


        handleChange(file, fileList) {
            let status = file.status; 
           
            let suffix =  this.getSuffix(file.name).toLowerCase();
            console.log('handleChange',status,suffix)
            
            if(suffix != '.jpg' && suffix != '.jpeg' && suffix != '.png' && suffix != '.mp4'){
                this.$refs.uploadImg.clearFiles();
                this.$message.error( this.$i18n.t("common.PictureVideoSize") ); // '图片、视频尺寸或者格式不对'
                return; 
            }

            if(this.getSuffix(file.name).toUpperCase() == '.MP4'){
                file.type = 'Video';
            }else{
                file.type = 'Image';
                const isLt = file.size / 1024 / 1024 <= 5;
                if (!isLt) {
                    this.$refs.uploadImg.clearFiles();
                    this.$message.error(this.$i18n.t("common.PictureVideoSize")); // '图片、视频尺寸或者格式不对'
                    return;
                }
            }

            let dataObj = {
                name: file.name,
                key: this.ossInfo.ossSignature.dir + '/' + new Date().getFullYear() + '/' + this.randomString() + this.getSuffix(file.name),
                policy: this.ossInfo.ossSignature['policy'],
                OSSAccessKeyId: this.ossInfo.ossSignature['access_id'],
                success_action_status: '201',
                callback: '',
                signature: this.ossInfo.ossSignature['signature']
            };
            
            this.fileDataObj = dataObj;
            let that = this;
            setTimeout(() => {
                if(status== 'ready'){
                    this.$refs.uploadImg.submit();
                }   
            }, 500);
        },


        // 上传进度
        uploadVideoProcess(event, file, fileList) {
            this.videoUploadPercent = parseInt(file.percentage.toFixed(0));
        },


        //获取OSS签名
        getOssSignature() {
            this.$store
                .dispatch('getSignature', {
                    dir: 'b2c'
                })
                .then((res) => {
                    // console.log(res);
                    this.ossInfo.ossSignature = res;
                    this.ossInfo.ossSignature.host = this.ossInfo.ossHost;
                });
        },
 
    }
};
</script>

<style>


.avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
}
.avatar-uploader .el-upload:hover {
    border-color: #409eff;
}
.avatar {
    width: 178px;
    height: 178px;
    display: block;
  }


.img-center {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    object-fit: contain
}

.hide .el-upload--picture-card {
    display: none;
}

</style>
