<template>
    <!-- 新建表单 -->
    <el-dialog
        :title="$i18n.t('common.Rechargepatrioticcoins')"
        :visible.sync="addVisible"
        @close="closeForm()"
        v-if="addVisible"
        :close-on-click-modal="false"
    >
        <el-form
            ref="dataForm"
            :rules="formRules"
            :model="addRecharge"
            label-position="left"
            size="small"
            label-width="100px"
            style="width: 400px; margin-left: 50px"
        >
            <el-form-item :label="$i18n.t('common.UserID')">
                <el-input v-model="addRecharge.userId" :placeholder="$i18n.t('common.UserID')" :disabled="true"></el-input>
            </el-form-item>
            <el-form-item :label="$i18n.t('common.mailbox')">
                <el-input v-model="addRecharge.email" :placeholder="$i18n.t('common.mailbox')" :disabled="true"></el-input>
            </el-form-item>
            <el-form-item :required="true" :label="$i18n.t('common.country')" prop="countryCode">
                <dataSelect
                    style="width: 100%"
                    class="filter-item"
                    v-model="addRecharge.countryCode"
                    clearable
                    :codeType="'countrys'"
                    :placeholder="$i18n.t('common.country')"
                ></dataSelect>
            </el-form-item>
            <el-form-item :label="$i18n.t('common.Numberofpatrioticcoins')" prop="amount">
                <el-input
                    v-model="addRecharge.amount"
                    oninput="if(value){value=value.replace(/[^\d]/g,'')} if(value<=0){value=''} if(value>1000){value=1000}"
                    @blur="addRecharge.amount = $event.target.value"
                    style="width: 100%"
                    :placeholder="$i18n.t('common.Enteraquantitybetween0and1000')"
                ></el-input>
            </el-form-item>
            <el-form-item :label="$i18n.t('common.ValidityPeriod')" prop="expireNum">
                <div style="display: flex">
                    <el-input
                        v-model="addRecharge.expireNum"
                        oninput="if(value){value=value.replace(/[^\d]/g,'')} if(value<=0){value=''}"
                        @blur="addRecharge.expireNum = $event.target.value"
                        style="flex-basis: 600px"
                        :placeholder="$i18n.t('common.EndofValidity')"
                    ></el-input>
                    <el-select v-model="timeOption" style="padding-left: 20px">
                        <el-option :label="$i18n.t('common.Year')" value="year"></el-option>
                        <el-option :label="$i18n.t('common.Month')" value="month"></el-option>
                        <el-option :label="$i18n.t('common.Day')" value="day"></el-option>
                    </el-select>
                </div>
            </el-form-item>
            <el-form-item :label="$i18n.t('common.Remarks')" prop="remark">
                <el-input
                    maxlength="1000"
                    show-word-limit
                    type="textarea"
                    :autosize="{ minRows: 2, maxRows: 4 }"
                    v-model="addRecharge.remark"
                    :placeholder="$i18n.t('common.Remarks')"
                ></el-input>
            </el-form-item>
            <!-- </div> -->
        </el-form>
        <div slot="footer" class="dialog-footer">
            <el-button @click="closeForm()"> {{ $i18n.t('common.cancel') }} </el-button>
            <el-button type="primary" @click="handleSubmit()"> {{ $i18n.t('common.confirm1') }} </el-button>
        </div>
    </el-dialog>
</template>

<script>
import dataSelect from '@/components/common/DataSelect.vue';
import dataText from '@/components/common/DataText.vue';
import moment, { now } from 'moment';

function initAddBean() {
    const addBean = {
        userId: null,
        email: null,
        countryCode: null,
        amount: null,
        remark: null,
        expireTime: null,
        expireNum:null
    };
    return addBean;
}

export default {
    name: 'rechargeHandle',
    components: {
        dataSelect,
        dataText
    },
    data() {
        var isTrue = (rule, value, callback) => {
            if (!value) {
                return callback(new Error(this.$i18n.t('common.Inputcannotbeempty')));
            }
            setTimeout(() => {
                if (!Number(value)) {
                    callback(new Error(this.$i18n.t('common.Pleaseenterapositiveinteger')));
                } else {
                    const re = /^[0-9]*[1-9][0-9]*$/;
                    const rsCheck = re.test(value);
                    if (!rsCheck) {
                        callback(new Error(this.$i18n.t('common.Pleaseenterapositiveinteger')));
                    } else if (value > 1000) {
                        this.addRecharge.amount = 1000;
                        callback();
                    } else {
                        callback();
                    }
                }
            }, 0);
        };

        return {
            collection: [],
            countrysDicts: [],
            brandDicts: [],
            addRecharge: initAddBean(),
            addVisible: false,
            listLoading: true,
            expireTime: null,
            timeOption: 'year',

            formRules: {
                amount: [
                    { required: true, message: this.$i18n.t('common.Pleaseenterthequantityofpatrioticcoins'), trigger: 'blur' },
                    { validator: isTrue, trigger: 'blur' }
                ],
                countryCode: [{ required: true, message: this.$i18n.t('common.Pleaseselectacountry'), trigger: 'change' }],
                remark: [{ required: true, message: this.$i18n.t('common.Pleaseenteracomment'), trigger: 'blur' }],
                expireNum: [{ required: true, message: this.$i18n.t('common.EndofValidity'), trigger: 'blur' }]
            }
        };
    },
    mounted() {
        this.getDictItems();
    },
    methods: {
        getDictItems() {
            var _this = this;
            this.$store.dispatch('getDicItems', { tag: 'countrys' }).then((res) => {
                if (res != null) {
                    _this.countrysDicts = res;
                }
            });
            this.$store.dispatch('getDicItems', { tag: 'brand' }).then((res) => {
                if (res != null) {
                    _this.brandDicts = res;
                }
            });
        },
        /**
         * 充值爱国币处理
         */
        rechargeHandle(userId, email) {
            this.addRecharge = initAddBean();
            this.addRecharge.userId = userId;
            this.addRecharge.email = email;
            console.log(this.countrysDicts);
            this.addRecharge.countryCode = 'ES';
            this.addRecharge.expireTime = null;
            this.addRecharge.expireNum = null;
            this.timeOption = 'year';
            this.addVisible = true;
        },
        handleSubmit() {
            this.addRecharge.expireTime = moment.utc().add(this.addRecharge.expireNum, this.timeOption).format('yyyy-MM-DD 23:59:59');
            this.$refs['dataForm'].validate((valid) => {
                if (valid) {
                    this.$store.dispatch('rechargeCurrency', this.addRecharge).then((res) => {
                        if (res == 1) {
                            this.$message({
                                message: this.$i18n.t('common.Successfullysubmitted'),
                                type: 'success'
                            });
                            this.addVisible = false;
                            this.$parent.doQueryList({ page: 1 });
                        }
                        if (res == -1) {
                            this.$message({
                                message: this.$i18n.t('common.Nopatrioticcoinrulesconfigured'),
                                type: 'error'
                            });
                        }
                        if (res == 0) {
                            this.$message({
                                message: this.$i18n.t('common.Rechargefailed'),
                                type: 'error'
                            });
                        }
                    });
                } else {
                    this.$message({
                        message: this.$i18n.t('common.Savefailed'),
                        type: 'error'
                    });
                    return false;
                }
            });
        },
        closeForm() {
            this.addVisible = false;
        }
    }
};
</script>
