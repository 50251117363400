<template>
	<div>
		<el-upload class="upload-demo"
			v-loading.fullscreen.lock="fullscreenLoading"
			:action="ossInfo.ossSignature.host" :show-file-list="false"
			:auto-upload="false"
			:disabled='percent>0'
			:on-progress='uploadVideoProcess'
			:before-upload="beforeAvatarUpload"
			list-type="picture-card" ref="uploadImg" :on-change="handleChange" :data="fileDataObj"
			:accept="accept">
			<slot></slot>		
		</el-upload>
	</div>
</template>

<script>
	import X2JS from "x2js"; //解析xml
	export default {
		props: {
			accept:{
				type:String,
				default:".jpg, .jpeg, .png, .gif"
			},
			imgInfo:{
				type: Object,
				default: () => {
					return {}
				}
			},
			bigImgList: {
				//大图表格数据
				type: Array,
				default: () => {
					return []
				}
			},
			itT:{
				type:String,
				default:"itT"	
			},
			percent:{
				type:Number,
				default:0	
			}
		},
		data() {
			return {
				fullscreenLoading: false,
				fileWidth:"",
				fileHeight:"",
				isEmit:true,
				imageUrl:"",
				ossVideoUrl:"",
				fileDataObj:{},
				fileDataObj2:{},
				ossInfo: {
					ossHost: process.env.VUE_APP_OSS_Head,
					ossSignature: {
						host: ''
					},
					activityTranslates: [],
					langs: [],
					seoIndex: '0',
					newFiles: []
				},
			}
		},
		methods:{
			//获取OSS签名
			getOssSignature(){
			  this.$store
			    .dispatch("getSignature", {
			      dir: 'b2c'
			    })
			    .then((res) => {
			      this.ossInfo.ossSignature = res;
			      this.ossInfo.ossSignature.host = this.ossInfo.ossHost;
			    });
			},
			handleChange(file, fileLis) {
				this.fullscreenLoading=true
				if (file.status == 'success') {
					this.percent = 0;
					//延迟200ms结束，不然极限操作监听会有数据错乱，这个不是好的方案，好的方案需要比较长的时间调整，暂时这么处理
					setTimeout(() => {
						this.fullscreenLoading=false
					}, 200);
					this.$emit('update:percent',this.percent)
				}else if(file.status == 'fail') {
					this.$message.error(this.$i18n.t("common.UploadFailed") + '!');
					this.percent = 0;
					this.fullscreenLoading=false
					this.$emit('update:percent',this.percent)
					return
				}							
				this.isEmit=true
				if(this.itT=='itT'){
					const fileType = ["image/jpg", "image/jpeg", "image/png", "image/gif"]
					if (fileType.indexOf(file.raw.type) == -1) {
						this.$message.error(this.$i18n.t("common.Uploadingimagesonlysupports"));
						this.isEmit=false
						this.$refs.uploadImg.clearFiles();
						this.fullscreenLoading=false
						this.$emit('update:imgInfo')
						return;
					}
					if (file.size> 2 * 1024 * 1024) {
						this.$message.error(this.$i18n.t("common.Thesizeoftheuploadedimagecannotexceed") + ' 2MB!');
						this.$refs.uploadImg.clearFiles();
						this.$emit('update:imgInfo')
						this.isEmit=false
						this.fullscreenLoading=false
						return
					}				
				}else if(this.itT=='itV'){
					const fileType = ["video/mp4"]
					if (fileType.indexOf(file.raw.type) == -1) {
						this.$message.error(this.$i18n.t("common.OnlyMp4FormatIsSupportedForVideoUpload"));
						this.$refs.uploadImg.clearFiles();
						this.$emit('update:imgInfo')
						this.isEmit=false
						this.fullscreenLoading=false
						return;
					}
					if (file.size>1024 * 1024 * 500) {
						this.$message.error(this.$i18n.t("common.TheUploadedVideoSizeCannotExceed500MB",{num:500}));
						this.$refs.uploadImg.clearFiles();
						this.$emit('update:imgInfo')
						this.isEmit=false
						this.fullscreenLoading=false
						return
					}				
				}else if(this.itT=='itTV') {

					const fileType = ["image/jpg", "image/jpeg", "image/png", "video/mp4"];
					if (fileType.indexOf(file.raw.type) == -1) {
						this.$message.error(this.$i18n.t("common.ImportFile") + 'jpg、jpeg、png、mp4');
						this.$refs.uploadImg.clearFiles();
						this.$emit('update:imgInfo')
						this.isEmit=false
						this.fullscreenLoading=false
						return;
					}
					//视频不能超过10M，图片不能超过3MB
					if ("video/mp4" === file.raw.type) {

						if(file.size > 1024 * 1024 * 10) {
							this.$message.error(this.$i18n.t("common.TheUploadedVideoSizeCannotExceed500MB",{num:10}));
							this.$refs.uploadImg.clearFiles();
							this.$emit('update:imgInfo')
							this.isEmit=false
							this.fullscreenLoading=false
							return
						}
					}else {

						if(file.size > 1024 * 1024 * 3) {
							this.$message.error(this.$i18n.t("common.TheUploadedImageSizeCannotExceed5MB",{num:3}));
							this.$refs.uploadImg.clearFiles();
							this.$emit('update:imgInfo')
							this.isEmit=false
							this.fullscreenLoading=false
							return
						}
					}
				}
				
				let dataObj = {
				  name: file.name,
				  key:
				    this.ossInfo.ossSignature.dir +
				    "/" +
				    new Date().getFullYear() +
				    "/" +
				    this.randomString() +
				    this.getSuffix(file.name),
				  policy: this.ossInfo.ossSignature["policy"],
				  OSSAccessKeyId: this.ossInfo.ossSignature["access_id"],
				  success_action_status: "201",
				  callback: "",
				  signature: this.ossInfo.ossSignature["signature"],
				  // file: file.file,
				};
				
				file.type = "add";
				this.fileDataObj = dataObj;	
				setTimeout(() => {
				  this.$refs.uploadImg.submit();
				  var x2js = new X2JS();
				  var jsonObj = x2js.xml2js(file.response);
				  let ossUrl = jsonObj.PostResponse.Location;
				  let ETag = jsonObj.PostResponse.ETag.toLowerCase();

				  this.imageUrl = ossUrl;			  
					let imglist = ['png', 'jpg', 'jpeg', 'bmp', 'gif'];
					let videolist = ['mp4', 'm2v', 'mkv'];
					let index = file.name.lastIndexOf(".");
					let str = file.name.substring(index + 1, file.name .length);
					
				  let type="图片"
				  if(imglist.indexOf(str)!==-1){
					  type="图片"
				  }else if(videolist.indexOf(str)!==-1){
					  type="视频"
				  }
				  let data2={
					fileWidth:this.fileWidth,
					fileHeight:this.fileHeight,  
					type:type,
				  	imageUrl: ossUrl,
					main:false,
					showed:false,
					imageMD5:ETag,
					lang:"",
					sort:0,
					updateTime:Date.parse(new Date()),
					goodsId:"",
					fileName:file.name
				  }
				  this.handlImgEmit(data2)
				}, 500); 					
			},
			/**
			 * 生成随机文件名
			 * @param len
			 * @returns {string}
			 */
			randomString(len) {
			  len = len || 32;
			  let chars = "ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678";
			  let maxPos = chars.length;
			  let pwd = "";
			  for (let i = 0; i < len; i++) {
			    pwd += chars.charAt(Math.floor(Math.random() * maxPos));
			  }
			  return pwd;
			},
			
			getSuffix(filename) {
			  let pos = filename.lastIndexOf(".");
			  let suffix = "";
			  if (pos != -1) {
			    suffix = filename.substring(pos);
			  }
			  return suffix;
			},
			uploadVideoProcess(event, file, fileList) {
				this.$emit('update:percent',parseInt(file.percentage.toFixed(0)))
			},
			beforeAvatarUpload(file){
				var reader = new FileReader();
				    reader.onload = (event)=>{
				        var txt = event.target.result;
				        var img = document.createElement("img");
				        img.src = txt;
				        img.onload =()=>{
							this.fileWidth=img.width
							this.fileHeight=img.height
				        }
				    };
				    reader.readAsDataURL(file);			                    
			},
			handlImgEmit(data){
				if(this.isEmit){
					this.$emit('update:imgInfo',data)
				}else{
					this.$emit('update:imgInfo')
				}
				
			}
		},
		mounted() {
			this.getOssSignature()
		}
	}
</script>

<style scoped>
	.upload-demo /deep/ .el-upload--picture-card {
		width: auto;
		height: 0px;
		line-height: 12px;
		border: 0px dashed #c0ccda;
	}
</style>
