<template>
    <div style="height: 100%;min-height:350px;" class="app-container">
        <div class="filter-container">
            <el-input
                v-model="query.barCode"
                placeholder="EAN"
                style="width: 200px"
                class="filter-item"
                controls-position="right"
                oninput="value=value.replace(/[%]/g,'')"
                clearable
            ></el-input>

            <el-button class="filter-item" icon="el-icon-search" type="primary" @click="handleQuery"> {{ $i18n.t('common.search') }}</el-button>

            <el-button class="filter-item" style="margin-left: 10px" type="success" icon="el-icon-edit" @click="handleClearQuery()">
                {{ $i18n.t('common.Reset') }}
            </el-button>

            <div>
                <el-button class="filter-item" type="success" @click="showRecipesImage" style="margin-left: 0px;margin-top:10px">

                    {{ $i18n.t("common.RecipeDisplayImage") }}
                </el-button>
            </div>

        </div>

        <el-table
            v-loading="listLoading"
            height="calc(100% - 160px)"
            :data="recipesList"
            border
            stripe
            ref="multipleTable"
            style="width: 100%; margin-top: 10px"
        >
            <el-table-column type="selection" width="50" />
            <el-table-column label="ID" prop="recipesId" align="center" width="160">
                <template slot-scope="{ row }">
                    <span>{{ row.recipesId }}</span>
                </template>
            </el-table-column>

            <el-table-column :label="$i18n.t('common.FileName')"  prop="fileName" align="center" width="300">
                <template slot-scope="{ row }">
                    <span>{{ row.fileName }}</span>
                </template>
            </el-table-column>

            <el-table-column :label="$i18n.t('common.FileSize')" prop="fileSize"
                             align="center"
                             width="200px"
                             :formatter="formatSize">
            </el-table-column>

            <el-table-column :label="$i18n.t('common.language')" prop="langConvert" align="center">
                <template slot-scope="{ row }">
                    <span >{{ row.langConvert }}<br /></span>
                </template>
            </el-table-column>

            <el-table-column :label="$i18n.t('common.BarcodeAssociated')" prop="barCode" align="center" width="150">
                <template slot-scope="{ row }">
                    <span>{{ row.barCode }}</span>
                </template>
            </el-table-column>

            <el-table-column :label="$i18n.t('common.FirstUploadTime')" prop="createTime" align="center" width="150">
                <template slot-scope="{ row }">
                    <span>{{ row.createTime }}</span>
                </template>
            </el-table-column>

            <el-table-column :label="$i18n.t('common.EditTime')" prop="updateTime" align="center" width="150">
                <template slot-scope="{ row }">
                    <span>{{ row.updateTime }}</span>
                </template>
            </el-table-column>

            <el-table-column :label="$i18n.t('common.TagType')" prop="tagTypesName" align="center" width="120">
                <template slot-scope="{ row }">
                    <span>{{ row.tagTypesName }}</span>
                </template>
            </el-table-column>

            <el-table-column :label="$i18n.t('common.operation')" align="center" width="160" fixed="right">
                <template slot-scope="{ row }">
                    <el-button type="primary" size="mini" @click="handleEdit(row)" class="table-inner-button">{{ $i18n.t('common.edit') }} </el-button>
                </template>
            </el-table-column>
        </el-table>
        <pagination
            v-show="total > 0"
            :total="total"
            :pageNo.sync="query.current"
            :pageSize.sync="query.limit"
            @pagination="doQueryList"
        />

        <el-dialog :title="dialogTitle" :visible.sync="dialogFormVisible" v-if="dialogFormVisible" width="800px">

            <el-tabs v-model="activeName">
                <el-tab-pane :label="$i18n.t('common.FileName1')" name="1">
                    <el-form ref="fileNameFrom" label-width="100px">

                        <el-form-item :label="$i18n.t('common.FileName1')" prop="fileName">
                            {{ this.fileName }}
                        </el-form-item>

                        <el-form-item v-for="(item,index) in recipesLangList" :key="item.id" :label="item.name">
                            <el-input v-model="item.fileName" @input="forceUpdate($event)"
                                      :placeholder="$i18n.t('common.EnterName')"  maxlength="40" show-word-limit></el-input>
                        </el-form-item>
                    </el-form>
                </el-tab-pane>
                <el-tab-pane :label="$i18n.t('common.CoverPicture')" name="2">

                    <el-form ref="coverImageFrom" label-width="100px">

                        <el-form-item prop="imageUrl" :label='$i18n.t("common.picture")'>
                            <el-col :span="10">
                                <imgUpload ref="imageUpload" :img="imageUrl" @event="changeUrl"> </imgUpload>
                            </el-col>
                            <el-col :span="10" style="margin-top: 135px;margin-left: 10px;">
                                <span>{{$i18n.t("common.Imagesize")}}：1*1024，＜ 1M</span>
                            </el-col>
                        </el-form-item>

                    </el-form>

                </el-tab-pane>
            </el-tabs>

            <div slot="footer" class="dialog-footer">
                <el-button style="width: 20%;" @click="dialogFormVisible = false">{{ $i18n.t('common.cancel') }}</el-button>
                <el-button style="width: 20%;" type="primary" @click="saveRecipes">{{ $i18n.t('common.confirm1') }}</el-button>
            </div>
        </el-dialog>


        <el-dialog :title="showRecipesImageTitle" :visible.sync="dialogShowRecipesImageVisible" v-if="dialogShowRecipesImageVisible" width="800px">

            <el-form ref="showImageFrom" label-width="100px">

                <el-form-item :label='$i18n.t("common.country")'  prop="countryCodes">
                    <el-checkbox :indeterminate="isIndeterminate" v-model="checkAll" @change="handleCheckAllChange">{{ $i18n.t("common.SelectAll") }}</el-checkbox>
                    <el-checkbox-group v-model="recipeShowImageForm.countryCodes" @change="handleCheckedCitiesChange" style="width: 600px">
                        <el-checkbox v-for="country in dictionary.country" :label="country.code" :key="country.code"
                        >{{ country.name }}({{ country.code }})</el-checkbox>
                    </el-checkbox-group>
                </el-form-item>

                <el-form-item :label='$i18n.t("common.Enableornot")' prop="enableStatus">
                    <el-switch v-model="recipeShowImageForm.status" active-color="#13ce66" inactive-color="#ff4949"></el-switch>
                </el-form-item>

                <el-form-item prop="showImageUrl" :label='$i18n.t("common.picture")'>
                    <el-col :span="10">
                        <imgUpload ref="imageUpload" :img="recipeShowImageForm.showImageUrl" @event="changeShowImageUrl"> </imgUpload>
                    </el-col>
                    <el-col :span="10" style="margin-top: 135px;margin-left: 10px;">
                        <span>{{$i18n.t("common.Imagesize")}}：1434*325px，＜ 1M</span>
                    </el-col>
                </el-form-item>

            </el-form>


            <div slot="footer" class="dialog-footer">
                <el-button style="width: 20%;" @click="dialogShowRecipesImageVisible = false">{{ $i18n.t('common.cancel') }}</el-button>
                <el-button style="width: 20%;" type="primary" @click="saveRecipesShowImage">{{ $i18n.t('common.confirm1') }}</el-button>
            </div>
        </el-dialog>

    </div>
</template>

<script>
import Pagination from '@/components/common/Pagination';
import DataSelect from '@/components/common/DataSelect';
import dataText from '@/components/common/DataText.vue';
import scene from '@/utils/scene';
import imgUpload from '@/views/recipes/imgUpload';
import dictionaryUtil from '@/utils/dictionaryUtil';

const defaultDictionary = {
    // 国家
    country: dictionaryUtil.getDict('countrys'),
    langs: dictionaryUtil.getDict('lang')
};

export default {
    name: 'RecipesTable',
    components: {
        Pagination,
        dataText,
        DataSelect,
        imgUpload
    },
    data() {
        return {
            recipesList: [],
            total: 0,
            listLoading: true,
            query: {
                current: 1,
                limit: 10,

                barCode: null
            },
            dialogTitle: '',
            activeName: '1',
            imageUrl: '',
            recipesId: '0',
            fileName: '',
            dialogFormVisible: false,
            recipesLangList: [],
            dictionary: Object.assign({}, defaultDictionary),
            dialogShowRecipesImageVisible: false,
            showRecipesImageTitle: '',
            recipeShowImageForm: {
                showImageUrl: '',
                status: true,
                countryCodes: []
            },
            isIndeterminate: true,
            checkAll: false,
        };
    },
    created() {
        this.routerHandler();
    },
    watch: {
        $route(to, from) {
            if (to.path === '/b2c/recipes/index') {
                this.routerHandler();
            }
        },

        deep: true
    },
    mounted() {

        this.dictionary.langs.forEach((item, index) => {

            this.recipesLangList.push({
                fileName: "",
                lang: item.code,
                name: item.name,
                id: item.id
            })
        })

    },
    computed: {
        scene() {
            return scene;
        }
    },
    methods: {
        /**
         * 处理路由跳转
         */
        routerHandler() {
            this.doQueryList({ pageNo: 1 });
        },
        showRecipesImage() {

            //清空数据
            this.recipeShowImageForm = {
                showImageUrl: '',
                status: true,
                countryCodes: []
            };
            this.isIndeterminate = false;
            this.checkAll = false;

            this.$store.dispatch('getRecipesShowImageVo', {}).then((res) => {

                this.recipeShowImageForm = res;
                this.handleCheckedCitiesChange(this.recipeShowImageForm.countryCodes);
                this.showRecipesImageTitle = this.$i18n.t("common.NewRecipeDisplayImage");
                this.dialogShowRecipesImageVisible = true;
            });

        },
        saveRecipesShowImage() {

            this.$store.dispatch('saveRecipesShowImage', this.recipeShowImageForm).then(() => {
                this.$message({
                    message: this.$i18n.t("common.Successfullysaved"),
                    type: 'success'
                });
                this.dialogShowRecipesImageVisible = false;
            });
        },
        saveRecipes() {

            //保存更新文件名称
            if (this.activeName == '1') {

                var params = {};
                params.recipesId = this.recipesId;
                params.recipesLangDtoList = this.recipesLangList;
                this.$store.dispatch('saveRecipesLang', params).then(() => {
                    this.$message({
                        message: this.$i18n.t("common.Successfullysaved"),
                        type: 'success'
                    });
                    this.dialogFormVisible = false;
                    this.doQueryList({});
                });

            }else if (this.activeName == '2') { //保存更新封面图

                var params = {};
                params.recipesId = this.recipesId;
                params.coverImageUrl = this.imageUrl;
                this.$store.dispatch('saveRecipesCustomInfo', params).then(() => {
                    this.$message({
                        message: this.$i18n.t("common.Successfullysaved"),
                        type: 'success'
                    });
                    this.dialogFormVisible = false;
                    this.doQueryList({});
                });
            }
        },
        /**
         * 触发搜索操作
         */
        handleQuery() {

            this.doQueryList({ pageNo: 1 });
        },
        /**
         * 执行列表查询
         */
        doQueryList({ pageNo, pageSize }) {
            if (pageNo) {
                this.query.current = pageNo;
            }
            if (pageSize) {
                this.query.size = pageSize;
            }
            this.listLoading = true;

            this.$store
                .dispatch('getRecipesPage', this.query)
                .then((res) => {
                    this.recipesList = res.list;
                    this.total = res.total;
                })
                .finally(() => {
                    this.listLoading = false;
                });
        },

        handleClearQuery() {
            let params = {
                current: 1,
                limit: 10,

                barCode: null,
            };
            this.query = params;
            this.handleQuery();
        },
        handleEdit(row) {

            //清空值
            this.imageUrl = "";
            this.activeName = "1";
            this.recipesLangList.forEach(recipesLang => {

                recipesLang.fileName = "";
            });

            this.recipesId = row.recipesId;
            var params = {};
            params.recipesId = row.recipesId;

            this.$store
                .dispatch('getRecipesCustomInfoByRecipesId', params)
                .then((res) => {

                    this.recipesLangList.forEach(recipesLang => {
                        if (res.recipesLangDtoList) {

                            res.recipesLangDtoList.forEach(recipesLangDto => {

                                if (recipesLangDto.lang == recipesLang.lang) {

                                    recipesLang.fileName = recipesLangDto.fileName;
                                }
                            })
                        }
                    });
                    this.fileName = res.fileName;
                    this.imageUrl = res.coverImageUrl;
                    this.dialogTitle = this.$i18n.t("common.RecipeConfiguration");
                    this.dialogFormVisible = true;
                })
                .finally(() => {

                });

        },
        /**
         * 时间戳转码
         */
        formatSize(row, column) {
            // 获取单元格数据
            let data = row[column.property];
            if (!data) {
                return '';
            } else {
                return (data / 1024 / 1024).toFixed(2) + "M";
            }
        },
        //强行刷新
        forceUpdate(e) {
            this.$forceUpdate()
        },
        /**
         * 上传路径
         */
        changeUrl(data) {
            this.imageUrl = data;
        },
        changeShowImageUrl(data) {

            this.recipeShowImageForm.showImageUrl = data;
        },
        //国家全选
        handleCheckAllChange(val) {
            if (val) {
                this.recipeShowImageForm.countryCodes = [];
                for (var item of this.dictionary.country) {
                    this.recipeShowImageForm.countryCodes.push(item.code);
                }
            } else {
                this.recipeShowImageForm.countryCodes = [];
            }

            this.isIndeterminate = false;
        },
        handleCheckedCitiesChange(value) {
            if (value) {
                let checkedCount = value.length;
                this.checkAll = checkedCount === this.dictionary.country.length;
                this.isIndeterminate = checkedCount > 0 && checkedCount < this.dictionary.country.length;
            }
        }
    }
};
</script>
<style scoped>
.box {
    width: 600px;
    display: flex;
}
.filter-container {
    padding: auto;
    margin-left: 20px;
    padding-top: 20px;
}

/deep/ .avatar-uploader .el-upload--picture-card {
    width: 178px !important;
    height: 178px !important;
}

.dialog-footer {
    margin-top: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.filter-item {
    margin-right: 10px;
    margin-top: 10px;
}
</style>
