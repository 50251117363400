var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-form",
        {
          ref: "variantFrom",
          attrs: { model: _vm.formListQuery, rules: _vm.rules }
        },
        [
          _c(
            "el-form-item",
            {
              attrs: {
                label: _vm.$i18n.t("common.name"),
                prop: "title",
                "label-width": _vm.formLabelWidth
              }
            },
            [
              _c("el-input", {
                attrs: { autocomplete: "off" },
                model: {
                  value: _vm.formListQuery.title,
                  callback: function($$v) {
                    _vm.$set(_vm.formListQuery, "title", $$v)
                  },
                  expression: "formListQuery.title"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              staticClass: "must",
              attrs: {
                label: _vm.$i18n.t("common.Selectvariant"),
                "label-width": _vm.formLabelWidth
              }
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "success" },
                  on: {
                    click: function($event) {
                      return _vm.handleChange("para")
                    }
                  }
                },
                [_vm._v(_vm._s(_vm.$i18n.t("common.Selectparameters")))]
              )
            ],
            1
          ),
          _c("el-form-item", { attrs: { "label-width": _vm.formLabelWidth } }, [
            _vm.checkedKeys.length > 0
              ? _c(
                  "div",
                  [
                    _c(
                      "el-table",
                      {
                        ref: "pagedata",
                        staticStyle: { width: "100%" },
                        attrs: { data: _vm.checkedKeys, border: "" }
                      },
                      [
                        _c("el-table-column", {
                          attrs: {
                            label: _vm.$i18n.t("common.Serialnumber"),
                            type: "index",
                            width: "50",
                            align: "center"
                          }
                        }),
                        _c("el-table-column", {
                          attrs: {
                            prop: "title",
                            label: _vm.$i18n.t("common.name"),
                            align: "center"
                          }
                        }),
                        _c("el-table-column", {
                          attrs: {
                            align: "center",
                            label: _vm.$i18n.t("common.sort"),
                            "show-overflow-tooltip": ""
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function(scope) {
                                  return [
                                    scope.$index !== 0
                                      ? _c(
                                          "el-button",
                                          {
                                            attrs: { type: "text" },
                                            on: {
                                              click: function($event) {
                                                return _vm.upMove(
                                                  scope.$index,
                                                  scope.row
                                                )
                                              }
                                            }
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$i18n.t("common.MoveUp")
                                              ) + "\n\t\t\t\t\t\t\t\t"
                                            )
                                          ]
                                        )
                                      : _vm._e(),
                                    scope.$index !== _vm.checkedKeys.length - 1
                                      ? _c(
                                          "el-button",
                                          {
                                            attrs: { type: "text" },
                                            on: {
                                              click: function($event) {
                                                return _vm.upDown(
                                                  scope.$index,
                                                  scope.row
                                                )
                                              }
                                            }
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$i18n.t("common.MoveDown")
                                              )
                                            )
                                          ]
                                        )
                                      : _vm._e()
                                  ]
                                }
                              }
                            ],
                            null,
                            false,
                            4231522980
                          )
                        }),
                        _c("el-table-column", {
                          attrs: {
                            label: _vm.$i18n.t("common.operation"),
                            width: "150",
                            align: "center"
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function(scope) {
                                  return [
                                    _c(
                                      "el-button",
                                      {
                                        attrs: { type: "text", size: "small" },
                                        on: {
                                          click: function($event) {
                                            return _vm.delCheckedKeys(scope.row)
                                          }
                                        }
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(_vm.$i18n.t("common.delete"))
                                        )
                                      ]
                                    )
                                  ]
                                }
                              }
                            ],
                            null,
                            false,
                            1995773205
                          )
                        })
                      ],
                      1
                    )
                  ],
                  1
                )
              : _vm._e()
          ]),
          _c(
            "el-form-item",
            {
              staticClass: "must",
              attrs: {
                label: _vm.$i18n.t("common.commodity"),
                "label-width": _vm.formLabelWidth
              }
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "success" },
                  on: {
                    click: function($event) {
                      return _vm.handleChange("goods")
                    }
                  }
                },
                [_vm._v(_vm._s(_vm.$i18n.t("common.Selectproduct")))]
              )
            ],
            1
          ),
          _c("el-form-item", { attrs: { "label-width": _vm.formLabelWidth } }, [
            _vm.pageDataSelectList.length > 0
              ? _c(
                  "div",
                  [
                    _c(
                      "el-table",
                      {
                        ref: "pageDataSelect",
                        staticStyle: { width: "100%" },
                        attrs: { data: _vm.pageDataSelectList, border: "" }
                      },
                      [
                        _c("el-table-column", {
                          attrs: {
                            label: _vm.$i18n.t("common.Serialnumber"),
                            type: "index",
                            width: "50",
                            align: "center"
                          }
                        }),
                        _c("el-table-column", {
                          attrs: {
                            prop: "goodsId",
                            label: _vm.$i18n.t("common.ProductID1"),
                            align: "center",
                            "show-overflow-tooltip": ""
                          }
                        }),
                        _c("el-table-column", {
                          attrs: {
                            prop: "barCode",
                            label: "EAN",
                            align: "center",
                            "show-overflow-tooltip": ""
                          }
                        }),
                        _vm._l(_vm.checkedKeys, function(item, index) {
                          return _vm.flag
                            ? _c("el-table-column", {
                                key: item.variantParameterId,
                                attrs: {
                                  label: item.title,
                                  prop: item.title,
                                  align: "center",
                                  "show-overflow-tooltip": ""
                                }
                              })
                            : _vm._e()
                        }),
                        _c("el-table-column", {
                          attrs: {
                            label: _vm.$i18n.t("common.operation"),
                            width: "150",
                            fixed: "right",
                            align: "center"
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function(scope) {
                                  return [
                                    _c(
                                      "el-button",
                                      {
                                        attrs: { type: "text", size: "small" },
                                        on: {
                                          click: function($event) {
                                            return _vm.delPageData(scope.row)
                                          }
                                        }
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(_vm.$i18n.t("common.delete"))
                                        )
                                      ]
                                    )
                                  ]
                                }
                              }
                            ],
                            null,
                            false,
                            661981461
                          )
                        })
                      ],
                      2
                    )
                  ],
                  1
                )
              : _vm._e()
          ])
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer"
        },
        [
          _c(
            "el-button",
            { staticStyle: { width: "20%" }, on: { click: _vm.closeDialog } },
            [_vm._v(_vm._s(_vm.$i18n.t("common.cancel")))]
          ),
          _c(
            "el-button",
            {
              staticStyle: { width: "20%" },
              attrs: { type: "primary" },
              on: {
                click: function($event) {
                  return _vm.onSubmit("variantFrom")
                }
              }
            },
            [_vm._v(_vm._s(_vm.$i18n.t("common.confirm")))]
          )
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.$i18n.t("common.Selectvariantparameters"),
            visible: _vm.paraVisible,
            width: "35%",
            "append-to-body": ""
          },
          on: {
            "update:visible": function($event) {
              _vm.paraVisible = $event
            }
          }
        },
        [
          _vm.paraVisible
            ? _c(
                "div",
                { staticClass: "flex-center" },
                [
                  _c("el-tree", {
                    ref: "tree",
                    attrs: {
                      props: _vm.defaultProps,
                      data: _vm.classifyTree,
                      "node-key": "variantParameterId",
                      "default-checked-keys": _vm.checkedKeys2,
                      "default-expanded-keys": _vm.expandedClassifyKeys,
                      "show-checkbox": ""
                    },
                    on: { check: _vm.handleTreeChange }
                  })
                ],
                1
              )
            : _vm._e(),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  staticStyle: { width: "20%" },
                  on: {
                    click: function($event) {
                      return _vm.handleCloseVar()
                    }
                  }
                },
                [_vm._v(_vm._s(_vm.$i18n.t("common.cancel")))]
              ),
              _c(
                "el-button",
                {
                  staticStyle: { width: "20%" },
                  attrs: { type: "primary" },
                  on: {
                    click: function($event) {
                      return _vm.handleChangeVar()
                    }
                  }
                },
                [_vm._v(_vm._s(_vm.$i18n.t("common.confirm")))]
              )
            ],
            1
          )
        ]
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.$i18n.t("common.Selectproduct"),
            visible: _vm.goodsVisible,
            "append-to-body": ""
          },
          on: {
            "update:visible": function($event) {
              _vm.goodsVisible = $event
            }
          }
        },
        [
          _vm.goodsVisible
            ? _c(
                "div",
                [
                  _c("FormTableDialog", {
                    attrs: {
                      argFrom: _vm.argFrom,
                      dictionary: _vm.dictionary,
                      pageDataSelectList: _vm.pageDataSelectChangeList,
                      placeholderFrom: _vm.placeholderFrom
                    },
                    on: {
                      handleSearchList: _vm.handleSearchList,
                      handleResetSearch: _vm.handleResetSearch,
                      handleSelected: _vm.handleSelected
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "tableSlot",
                          fn: function() {
                            return [
                              _c(
                                "el-table",
                                {
                                  ref: "tableData",
                                  staticStyle: { width: "100%" },
                                  attrs: {
                                    "default-expand-all": "",
                                    data: _vm.pageData2.list,
                                    "tree-props": {
                                      children: "packageQtyList",
                                      hasChildren: "hasChildren"
                                    },
                                    border: "",
                                    id: "goodsTable",
                                    "row-key": function(row) {
                                      return row.goodsId
                                    },
                                    "header-cell-style": {
                                      background: "#EAEEF9",
                                      color: "#32353a"
                                    }
                                  },
                                  on: {
                                    "selection-change":
                                      _vm.handleSelectionChange,
                                    select: _vm.selectOfSelectable,
                                    "select-all": _vm.selectAllOfSelectable
                                  }
                                },
                                [
                                  _c("el-table-column", {
                                    attrs: {
                                      type: "selection",
                                      "reserve-selection": true,
                                      align: "center",
                                      width: "55",
                                      selectable: function(row) {
                                        return row.checkboxStatus
                                      }
                                    }
                                  }),
                                  _c("el-table-column", {
                                    attrs: {
                                      prop: "quantityType",
                                      label: "",
                                      width: "50px",
                                      align: "center"
                                    }
                                  }),
                                  _c("el-table-column", {
                                    attrs: {
                                      prop: "goodsId",
                                      label: _vm.$i18n.t("common.ProductID"),
                                      align: "center",
                                      "show-overflow-tooltip": ""
                                    }
                                  }),
                                  _c("el-table-column", {
                                    attrs: {
                                      prop: "goodsCode",
                                      label:
                                        "SKU/EAN/" +
                                        _vm.$i18n.t("common.coding"),
                                      align: "center",
                                      "show-overflow-tooltip": ""
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function(scope) {
                                            return [
                                              _c("span", [
                                                _vm._v(
                                                  "SKU:" + _vm._s(scope.row.sku)
                                                )
                                              ]),
                                              _c("br"),
                                              _c("span", [
                                                _vm._v(
                                                  "EAN:" +
                                                    _vm._s(scope.row.barCode)
                                                )
                                              ]),
                                              _c("br"),
                                              _c("span", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$i18n.t("common.coding")
                                                  ) +
                                                    ":" +
                                                    _vm._s(scope.row.goodsCode)
                                                )
                                              ])
                                            ]
                                          }
                                        }
                                      ],
                                      null,
                                      false,
                                      3867731264
                                    )
                                  }),
                                  _c("el-table-column", {
                                    attrs: {
                                      prop: "imageUrl",
                                      label: _vm.$i18n.t(
                                        "common.Productimage1"
                                      ),
                                      align: "center",
                                      "show-overflow-tooltip": ""
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function(scope) {
                                            return [
                                              scope.row.imageUrl
                                                ? _c("el-image", {
                                                    staticStyle: {
                                                      height: "100px"
                                                    },
                                                    attrs: {
                                                      src:
                                                        _vm.ossHost +
                                                        scope.row.imageUrl,
                                                      fit: "fill"
                                                    }
                                                  })
                                                : _vm._e()
                                            ]
                                          }
                                        }
                                      ],
                                      null,
                                      false,
                                      183490098
                                    )
                                  }),
                                  _c("el-table-column", {
                                    attrs: {
                                      prop: "packageQuantity",
                                      label: _vm.$i18n.t(
                                        "common.PackageQuantity"
                                      ),
                                      align: "center",
                                      "show-overflow-tooltip": ""
                                    }
                                  }),
                                  _c("el-table-column", {
                                    attrs: {
                                      prop: "treeList",
                                      label: _vm.$i18n.t("common.Category"),
                                      align: "center",
                                      "show-overflow-tooltip": ""
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function(scope) {
                                            return _vm._l(
                                              scope.row.treeList,
                                              function(item, index) {
                                                return _c(
                                                  "div",
                                                  { key: index },
                                                  [
                                                    _vm._v(_vm._s(item.title)),
                                                    _c("br")
                                                  ]
                                                )
                                              }
                                            )
                                          }
                                        }
                                      ],
                                      null,
                                      false,
                                      4054748647
                                    )
                                  }),
                                  _c("el-table-column", {
                                    attrs: {
                                      prop: "title",
                                      label: _vm.$i18n.t("common.ProductName"),
                                      align: "center",
                                      "show-overflow-tooltip": ""
                                    }
                                  }),
                                  _c("el-table-column", {
                                    attrs: {
                                      prop: "goodsSpecials",
                                      label: _vm.$i18n.t(
                                        "common.Countrieslisted"
                                      ),
                                      align: "center",
                                      "show-overflow-tooltip": ""
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function(scope) {
                                            return _vm._l(
                                              scope.row.goodsSpecials,
                                              function(s, index) {
                                                return _c(
                                                  "span",
                                                  { key: index },
                                                  [
                                                    s.shelf == true
                                                      ? _c("span", [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.$dict.getDictValue(
                                                                "countrys",
                                                                s.countryCode
                                                              )
                                                            )
                                                          ),
                                                          _c("br")
                                                        ])
                                                      : _vm._e()
                                                  ]
                                                )
                                              }
                                            )
                                          }
                                        }
                                      ],
                                      null,
                                      false,
                                      396586729
                                    )
                                  }),
                                  _c("el-table-column", {
                                    attrs: {
                                      prop: "shelf",
                                      label: _vm.$i18n.t("common.state"),
                                      align: "center",
                                      "show-overflow-tooltip": ""
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function(scope) {
                                            return [
                                              scope.row.shelf == true
                                                ? _c(
                                                    "span",
                                                    {
                                                      staticStyle: {
                                                        color: "#46a8a0"
                                                      }
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.$i18n.t(
                                                            "common.Listed"
                                                          )
                                                        )
                                                      )
                                                    ]
                                                  )
                                                : _c("span", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$i18n.t(
                                                          "common.Notlisted"
                                                        )
                                                      )
                                                    )
                                                  ])
                                            ]
                                          }
                                        }
                                      ],
                                      null,
                                      false,
                                      1526612380
                                    )
                                  })
                                ],
                                1
                              ),
                              _c(
                                "div",
                                { staticClass: "block" },
                                [
                                  _c("el-pagination", {
                                    attrs: {
                                      "current-page": _vm.listQuery.page,
                                      "page-size": _vm.size,
                                      layout:
                                        "total, prev, pager, next, jumper",
                                      total: _vm.pageData2.total
                                    },
                                    on: {
                                      "current-change": _vm.handleCurrentChange
                                    }
                                  })
                                ],
                                1
                              )
                            ]
                          },
                          proxy: true
                        }
                      ],
                      null,
                      false,
                      3361481839
                    )
                  })
                ],
                1
              )
            : _vm._e()
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }