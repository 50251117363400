<template> 
    <div>
        
        <el-form :model="formData"  ref="formData">     
        
            <div class="from-item">
                <el-checkbox-group v-model="formData.countryCodeList">
                    <el-checkbox v-for="(item, index) in dictionary.country" :key="item.code" :label="item.code">{{
                        item.name }}</el-checkbox>
                </el-checkbox-group>
            </div>

        </el-form>

        <span slot="footer" class="dialog-footer">
			<el-button style="width: 20%;" @click="closePreSaleCountryDialog">{{$t('common.cancel')}}</el-button>
			<el-button style="width: 20%;" type="primary" :loading="loading"  @click="onSubmit">{{$t('common.confirm1')}}</el-button>
		</span>
 
    </div>
</template>


<script>
 
 
export default {
    components: {  
    },
    props: {
        //字典
        dictionary: {
            type: Object,
            default: () => {
            return {}
            }
        },
        rowData: {
            type: Object,
            default: () => {
                return {}
            }
        }
    },
    data() {
        return {
            loading: false,
            formData: {
                goodsId: '',
                countryCodeList: []
            },
        };
    },
    mounted() {
        this.initData()  
    },
    methods: {
        initData() {    
            let goodsId = this.rowData.goodsId
            this.formData.goodsId = goodsId
            this.$store.dispatch('getPresalesCountry', {"goodsId":goodsId}).then((res) => {      
                this.formData.countryCodeList = res.countryCodeList   
            });  
        },
        closePreSaleCountryDialog(){
            this.$emit('closePreSaleCountryDialog');
        },
        onSubmit(){
            let countryCodes = new Set(this.formData.countryCodeList)
            let data = {
                goodsId: this.rowData.goodsId,
                countryCodeList: countryCodes
            }

            let pallet = false;
            let promotion = false;
            let distribution = false;
            let fullDiscount = false;
            let preSaleCountry = false;
            let countryList = [];

            this.$store.dispatch('checkPresalesCountry', data).then((res) => {
                if (res && res.length > 0) {
                    res.forEach((item, index) => {
                        if (item.checkType == 'Pallet') {
                            pallet = true;
                        } else if (item.checkType == 'Promotion') {
                            promotion = true;
                        } else if (item.checkType == 'Distribution') {
                            distribution = true;
                        } else if (item.checkType == 'FullDiscount') {
                            fullDiscount = true;
                        } else if (item.checkType == 'PreSaleCountry'){
                            preSaleCountry = true;
                            // console.log(item.extend)
                            if(item.extend && item.extend.countryList){
                                countryList = item.extend.countryList;
                            }
                        }
                    });
                }

                setTimeout(() => {
                    if (pallet) {
                            this.$confirm(this.$i18n.t('common.CombinationItemsAreNotAvailableForPresalePleaseRemoveTheCombinationFirst'), {
                                confirmButtonText: this.$i18n.t('common.confirm1'),
                                cancelButtonText: this.$i18n.t('common.cancel'),
                                type: 'warning'
                            }).then(() => {});
                            return;
                    }
                    if (promotion) {
                        this.$confirm(this.$i18n.t('common.PromotionalItemsAreNotAvailableForPresalePleaseCancelThePromotionFirst'), {
                            confirmButtonText: this.$i18n.t('common.confirm1'),
                            cancelButtonText: this.$i18n.t('common.cancel'),
                            type: 'warning'
                        }).then(() => {});
                        return;
                    }
                    if (distribution) {
                        this.$confirm(
                            this.$i18n.t('common.DistributedProductsAreNotAvailableForPresalePleaseCancelTheDistributionFirst'),
                            {
                                confirmButtonText: this.$i18n.t('common.confirm1'),
                                cancelButtonText: this.$i18n.t('common.cancel'),
                                type: 'warning'
                            }
                        ).then(() => {});
                        return;
                    }
                    if (fullDiscount) {
                        this.$confirm(this.$i18n.t('common.ThisProductAlreadyHasAFullReductionActivityAndCannotBeAdded'), {
                            confirmButtonText: this.$i18n.t('common.confirm1'),
                            cancelButtonText: this.$i18n.t('common.cancel'),
                            type: 'warning'
                        }).then(() => {});
                        return;
                    }
                    if (preSaleCountry) {
                        let countryStr = '';
                        const countryMap = this.dictionary.country.reduce((acc, item) => {
                            acc.set(item.code, item);
                            return acc;
                        }, new Map());

                        countryList.forEach((item) => {
                            let dictionaryCountry =  countryMap.get(item);
                            if(dictionaryCountry){
                                countryStr = countryStr + dictionaryCountry.name + ',';
                            }else{
                                countryStr = countryStr + item + ',';
                            }
                            
                        });
                        countryStr = countryStr.substring(0, countryStr.length - 1);
                        // console.log('countryStr',countryStr,countryList)

                        this.$confirm(this.$i18n.t('common.PleaseSetUpThePreSaleCampaignBeforeSettingUpTheCountryWhereThePreSaleItemsWillBeDisplayed', { Any: countryStr }), {
                            confirmButtonText: this.$i18n.t('common.confirm1'),
                            cancelButtonText: this.$i18n.t('common.cancel'),
                            type: 'warning'
                        }).then(() => {});
                        return;
                    }

                    this.$confirm(this.$i18n.t("common.Whethertosubmitdata"), this.$i18n.t("common.prompt"), {
                        confirmButtonText: this.$i18n.t("common.confirm"),
                        cancelButtonText: this.$i18n.t("common.cancel"),
                        type: 'warning'
                    }).then(() => {  

                        this.loading=true
                    
                        this.$store.dispatch('updatePresalesCountry', data).then(response => {
                            this.$message({
                                message: this.$i18n.t("common.Successfullysaved"),
                                type: 'success',
                                duration: 1000
                            });
                            this.closePreSaleCountryDialog();
                        }).finally(() => {
                            this.loading = false;
                        });

                    }); 

                });
            });

            
        }
    },
    watch: {     
    }
};
</script>
<style scoped>
.dialog-footer {
    margin-top: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
}
</style>