var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.collectionVisible
    ? _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.$i18n.t("common.Collectproducts"),
            visible: _vm.collectionVisible,
            width: "1200px",
            "close-on-click-modal": false
          },
          on: {
            "update:visible": function($event) {
              _vm.collectionVisible = $event
            }
          }
        },
        [
          _c(
            "el-select",
            {
              staticClass: "filter-item",
              staticStyle: { width: "180px", "margin-left": "10px" },
              attrs: {
                multiple: true,
                placeholder: _vm.$i18n.t("common.ShowCountry")
              },
              model: {
                value: _vm.collectionQuery.countryCodes,
                callback: function($$v) {
                  _vm.$set(_vm.collectionQuery, "countryCodes", $$v)
                },
                expression: "collectionQuery.countryCodes"
              }
            },
            _vm._l(_vm.countrysDicts, function(cc) {
              return _c("el-option", {
                key: cc.code,
                attrs: { label: cc.name, value: cc.code }
              })
            }),
            1
          ),
          _c(
            "el-select",
            {
              staticClass: "filter-item",
              staticStyle: { width: "180px", "margin-left": "10px" },
              attrs: {
                multiple: false,
                placeholder: _vm.$i18n.t("common.brand")
              },
              model: {
                value: _vm.collectionQuery.brandCode,
                callback: function($$v) {
                  _vm.$set(_vm.collectionQuery, "brandCode", $$v)
                },
                expression: "collectionQuery.brandCode"
              }
            },
            _vm._l(_vm.brandDicts, function(cc) {
              return _c("el-option", {
                key: cc.code,
                attrs: { label: cc.name, value: cc.code }
              })
            }),
            1
          ),
          _c(
            "el-button",
            {
              staticClass: "filter-item",
              staticStyle: { "margin-left": "10px" },
              attrs: { icon: "el-icon-search", type: "primary" },
              on: { click: _vm.handleCollectionQuery }
            },
            [
              _vm._v(
                "\n       " + _vm._s(_vm.$i18n.t("common.query")) + "\n    "
              )
            ]
          ),
          _c(
            "el-table",
            {
              staticStyle: { width: "100%", "margin-top": "20px" },
              attrs: {
                data: _vm.collection.list,
                border: "",
                stripe: "",
                size: "small"
              }
            },
            [
              _c("el-table-column", {
                attrs: {
                  prop: "countryCode",
                  label: _vm.$i18n.t("common.country"),
                  align: "center",
                  width: "50px"
                }
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "brandCode",
                  label: _vm.$i18n.t("common.brand"),
                  align: "center",
                  width: "150px"
                }
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "goodsId",
                  label: _vm.$i18n.t("common.ProductID1"),
                  width: "150px",
                  align: "center"
                }
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "title",
                  label: _vm.$i18n.t("common.Productname1"),
                  width: "350px",
                  align: "center"
                }
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "collectPrice",
                  label: _vm.$i18n.t("common.CollectionPrice"),
                  width: "150px",
                  align: "center"
                }
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "finallyPrice",
                  label: _vm.$i18n.t("common.Currentprice"),
                  width: "150px",
                  align: "center"
                }
              }),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$i18n.t("common.Collectiontime1"),
                  prop: "collectTime",
                  align: "center",
                  width: "159px",
                  formatter: _vm.formatDate
                }
              })
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "block" },
            [
              _c("el-pagination", {
                staticStyle: { "margin-top": "10px" },
                attrs: {
                  background: "",
                  "current-page": _vm.collectionQuery.page,
                  "page-size": _vm.collectionQuery.size,
                  layout: "total, prev, pager, next, jumper",
                  total: _vm.collection.total
                },
                on: { "current-change": _vm.handleAddressCurrentChange }
              })
            ],
            1
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }