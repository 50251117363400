var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticStyle: { height: "100%", "min-height": "350px" } },
    [
      _c("filter-container", {
        attrs: {
          arg: _vm.arg,
          defaultListQuery: _vm.defaultListQuery,
          dictionary: _vm.dictionary,
          placeholder: _vm.placeholder
        },
        on: {
          handleSearchList: _vm.handleSearchList,
          handleResetSearch: _vm.handleResetSearch
        }
      }),
      _c(
        "div",
        { staticStyle: { margin: "20px 0px 20px 20px" } },
        [
          _vm.$common.hasPermission("Create", _vm.module_code)
            ? _c(
                "el-button",
                {
                  attrs: { type: "success", size: "small" },
                  on: { click: _vm.add }
                },
                [_vm._v(_vm._s(_vm.$i18n.t("common.Add")))]
              )
            : _vm._e()
        ],
        1
      ),
      _c(
        "el-table",
        {
          ref: "pagedata",
          staticStyle: { width: "100%" },
          attrs: {
            data: _vm.pageData.list,
            border: "",
            height: "calc(100% - 190px)"
          }
        },
        [
          _c("el-table-column", {
            attrs: {
              prop: "goodsVariantId",
              label: "ID",
              align: "center",
              "show-overflow-tooltip": ""
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "title",
              label: _vm.$i18n.t("common.name"),
              align: "center",
              "show-overflow-tooltip": ""
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "count",
              label: _vm.$i18n.t("common.Productquantity"),
              align: "center",
              "show-overflow-tooltip": ""
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    scope.row.count
                      ? _c("span", [_vm._v(_vm._s(scope.row.count))])
                      : _vm._e()
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              prop: "createTime",
              label: _vm.$i18n.t("common.Creationtime"),
              align: "center",
              "show-overflow-tooltip": ""
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("span", [
                      _vm._v(_vm._s(_vm._f("formatDate")(scope.row.createTime)))
                    ])
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              prop: "updateTime",
              label: _vm.$i18n.t("common.Updatetime"),
              align: "center",
              "show-overflow-tooltip": ""
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    scope.row.updateTime
                      ? _c("span", [
                          _vm._v(
                            _vm._s(_vm._f("formatDate")(scope.row.updateTime))
                          )
                        ])
                      : _vm._e()
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              label: _vm.$i18n.t("common.operation"),
              align: "center",
              fixed: "right"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _vm.$common.hasPermission("Update", _vm.module_code)
                      ? _c(
                          "el-button",
                          {
                            attrs: { type: "text", size: "small" },
                            on: {
                              click: function($event) {
                                return _vm.edit(scope.row)
                              }
                            }
                          },
                          [_vm._v(_vm._s(_vm.$i18n.t("common.edit")))]
                        )
                      : _vm._e(),
                    _vm.$common.hasPermission("Delete", _vm.module_code)
                      ? _c(
                          "el-button",
                          {
                            attrs: { type: "text", size: "small" },
                            on: {
                              click: function($event) {
                                return _vm.del(scope.row)
                              }
                            }
                          },
                          [_vm._v(_vm._s(_vm.$i18n.t("common.delete")))]
                        )
                      : _vm._e()
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "block" },
        [
          _c("el-pagination", {
            attrs: {
              "current-page": _vm.listQuery.page,
              "page-size": _vm.listQuery.limit,
              layout: "total, prev, pager, next, jumper",
              total: _vm.pageData.total
            },
            on: { "current-change": _vm.handleCurrentChange }
          })
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.isEdit
              ? _vm.$i18n.t("common.edit")
              : _vm.$i18n.t("common.Add"),
            visible: _vm.dialogFormVisible,
            "before-close": _vm.closeDialog,
            "close-on-click-modal": false
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogFormVisible = $event
            }
          }
        },
        [
          _vm.dialogFormVisible
            ? _c(
                "div",
                [
                  _c("varoant-dialog", {
                    attrs: {
                      dictionary: _vm.dictionary,
                      isEdit: _vm.isEdit,
                      formListQuery: _vm.formListQuery
                    },
                    on: {
                      handleSearchList: _vm.handleSearchList,
                      closeDialog: _vm.closeDialog
                    }
                  })
                ],
                1
              )
            : _vm._e()
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }