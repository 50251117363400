var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("el-form", { ref: "formData", attrs: { model: _vm.formData } }, [
        _c(
          "div",
          { staticClass: "from-item" },
          [
            _c(
              "el-checkbox-group",
              {
                model: {
                  value: _vm.formData.countryCodeList,
                  callback: function($$v) {
                    _vm.$set(_vm.formData, "countryCodeList", $$v)
                  },
                  expression: "formData.countryCodeList"
                }
              },
              _vm._l(_vm.dictionary.country, function(item, index) {
                return _c(
                  "el-checkbox",
                  { key: item.code, attrs: { label: item.code } },
                  [_vm._v(_vm._s(item.name))]
                )
              }),
              1
            )
          ],
          1
        )
      ]),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer"
        },
        [
          _c(
            "el-button",
            {
              staticStyle: { width: "20%" },
              on: { click: _vm.closePreSaleCountryDialog }
            },
            [_vm._v(_vm._s(_vm.$t("common.cancel")))]
          ),
          _c(
            "el-button",
            {
              staticStyle: { width: "20%" },
              attrs: { type: "primary", loading: _vm.loading },
              on: { click: _vm.onSubmit }
            },
            [_vm._v(_vm._s(_vm.$t("common.confirm1")))]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }