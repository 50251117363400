var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.freightVisible
        ? _c(
            "el-dialog",
            {
              attrs: {
                title: _vm.$i18n.t("common.Freightstandard"),
                visible: _vm.freightVisible,
                width: "780px",
                "close-on-click-modal": false
              },
              on: {
                "update:visible": function($event) {
                  _vm.freightVisible = $event
                }
              }
            },
            [
              _c("div", [
                _vm._v(
                  "\n            " +
                    _vm._s(_vm.$i18n.t("common.country")) +
                    "：\n            "
                ),
                _c("span", [_vm._v(_vm._s(_vm.countryCode))])
              ]),
              _c(
                "el-button",
                {
                  staticStyle: { "margin-top": "10px" },
                  attrs: { type: "primary" },
                  on: { click: _vm.addFreight }
                },
                [_vm._v(_vm._s(_vm.$i18n.t("common.Add1")))]
              ),
              _c(
                "el-table",
                {
                  staticStyle: {
                    width: "750px",
                    "margin-top": "20px",
                    "margin-bottom": "20px"
                  },
                  attrs: { data: _vm.list }
                },
                [
                  _c("el-table-column", {
                    attrs: {
                      prop: "freightFeeStandardId",
                      label: "ID",
                      width: "180px"
                    }
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "minWeight",
                      label: _vm.$i18n.t("common.Weightrange_start_kg"),
                      width: "130px"
                    }
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "maxWeight",
                      label: _vm.$i18n.t("common.Weightrange_end_kg"),
                      width: "130px"
                    }
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "freightFee",
                      label: _vm.$i18n.t("common.freight"),
                      width: "130px"
                    }
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: _vm.$i18n.t("common.operation"),
                      align: "center",
                      width: "150px"
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function(scope) {
                            return [
                              _c(
                                "el-button",
                                {
                                  staticClass: "table-inner-button",
                                  attrs: { type: "primary", size: "mini" },
                                  on: {
                                    click: function($event) {
                                      return _vm.updateFreight(
                                        scope.$index,
                                        scope.row
                                      )
                                    }
                                  }
                                },
                                [_vm._v(_vm._s(_vm.$i18n.t("common.edit")))]
                              ),
                              _c(
                                "el-button",
                                {
                                  staticClass: "table-inner-button",
                                  attrs: { type: "primary", size: "mini" },
                                  on: {
                                    click: function($event) {
                                      return _vm.deleteFreight(
                                        scope.$index,
                                        scope.row
                                      )
                                    }
                                  }
                                },
                                [_vm._v(_vm._s(_vm.$i18n.t("common.delete")))]
                              )
                            ]
                          }
                        }
                      ],
                      null,
                      false,
                      4036265785
                    )
                  })
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _vm.formVisible
        ? _c(
            "el-dialog",
            {
              attrs: {
                title: _vm.freightTitle,
                visible: _vm.formVisible,
                width: "700px"
              },
              on: {
                "update:visible": function($event) {
                  _vm.formVisible = $event
                }
              }
            },
            [
              _c(
                "el-form",
                {
                  ref: "dataForm",
                  staticStyle: { "margin-left": "50px" },
                  attrs: {
                    rules: _vm.formRules,
                    model: _vm.form,
                    "label-position": "left",
                    size: "small",
                    "label-width": "180px"
                  }
                },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: _vm.$i18n.t("common.Weightrange_start_kg"),
                        prop: "minWeight"
                      }
                    },
                    [
                      _c(
                        "el-input",
                        {
                          staticStyle: { width: "250px" },
                          model: {
                            value: _vm.form.minWeight,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "minWeight", $$v)
                            },
                            expression: "form.minWeight"
                          }
                        },
                        [_c("template", { slot: "append" }, [_vm._v(" kg ")])],
                        2
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: _vm.$i18n.t("common.Weightrange_end_kg"),
                        prop: "maxWeight"
                      }
                    },
                    [
                      _c(
                        "el-input",
                        {
                          staticStyle: { width: "250px" },
                          model: {
                            value: _vm.form.maxWeight,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "maxWeight", $$v)
                            },
                            expression: "form.maxWeight"
                          }
                        },
                        [_c("template", { slot: "append" }, [_vm._v(" kg ")])],
                        2
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: _vm.$i18n.t("common.freight"),
                        prop: "freightFee"
                      }
                    },
                    [
                      _c("el-input", {
                        staticStyle: { width: "250px" },
                        model: {
                          value: _vm.form.freightFee,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "freightFee", $$v)
                          },
                          expression: "form.freightFee"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "div",
                {
                  staticClass: "dialog-footer",
                  attrs: { slot: "footer" },
                  slot: "footer"
                },
                [
                  _c(
                    "el-button",
                    {
                      on: {
                        click: function($event) {
                          _vm.formVisible = false
                        }
                      }
                    },
                    [_vm._v("  " + _vm._s(_vm.$i18n.t("common.cancel")) + " ")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: {
                        click: function($event) {
                          return _vm.doUpdate()
                        }
                      }
                    },
                    [_vm._v("  " + _vm._s(_vm.$i18n.t("common.confirm")) + " ")]
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }