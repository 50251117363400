var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "app-container",
      staticStyle: { height: "100%", "min-height": "350px" }
    },
    [
      _c(
        "div",
        { staticClass: "filter-container" },
        [
          _c(
            "el-form",
            {
              attrs: {
                model: _vm.query,
                inline: true,
                size: "small",
                "label-width": "140px"
              }
            },
            [
              _c(
                "el-form-item",
                [
                  _c("el-input", {
                    staticClass: "filter-item",
                    staticStyle: { width: "180px" },
                    attrs: {
                      clearable: "",
                      placeholder: _vm.$i18n.t(
                        "common.Ordernumber_UserID_Taxnumber"
                      )
                    },
                    nativeOn: {
                      keyup: function($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        ) {
                          return null
                        }
                        return _vm.handleQuery.apply(null, arguments)
                      }
                    },
                    model: {
                      value: _vm.query.orderIdOrUserIdOrTaxNumber,
                      callback: function($$v) {
                        _vm.$set(_vm.query, "orderIdOrUserIdOrTaxNumber", $$v)
                      },
                      expression: "query.orderIdOrUserIdOrTaxNumber"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-select",
                    {
                      staticClass: "filter-item",
                      staticStyle: { width: "180px", "margin-left": "10px" },
                      attrs: {
                        multiple: true,
                        placeholder: _vm.$i18n.t("common.country")
                      },
                      model: {
                        value: _vm.query.countryCode,
                        callback: function($$v) {
                          _vm.$set(_vm.query, "countryCode", $$v)
                        },
                        expression: "query.countryCode"
                      }
                    },
                    _vm._l(_vm.countrysDicts, function(cc) {
                      return _c("el-option", {
                        key: cc.code,
                        attrs: {
                          label: cc.name + "(" + cc.code + ")",
                          value: cc.code
                        }
                      })
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-select",
                    {
                      staticClass: "filter-item",
                      staticStyle: { width: "180px", "margin-left": "10px" },
                      attrs: {
                        placeholder: _vm.$i18n.t("common.Auditstatus"),
                        clearable: ""
                      },
                      model: {
                        value: _vm.query.examineStatus,
                        callback: function($$v) {
                          _vm.$set(_vm.query, "examineStatus", $$v)
                        },
                        expression: "query.examineStatus"
                      }
                    },
                    [
                      _c("el-option", {
                        attrs: {
                          label: _vm.$i18n.t("common.Unaudited"),
                          value: "0"
                        }
                      }),
                      _c("el-option", {
                        attrs: {
                          label: _vm.$i18n.t("common.Passed"),
                          value: "1"
                        }
                      }),
                      _c("el-option", {
                        attrs: {
                          label: _vm.$i18n.t("common.Failed"),
                          value: "2"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-select",
                    {
                      staticClass: "filter-item",
                      staticStyle: { width: "180px", "margin-left": "10px" },
                      attrs: {
                        placeholder: _vm.$i18n.t("common.Reviewedby"),
                        clearable: ""
                      },
                      model: {
                        value: _vm.query.examineAdmin,
                        callback: function($$v) {
                          _vm.$set(_vm.query, "examineAdmin", $$v)
                        },
                        expression: "query.examineAdmin"
                      }
                    },
                    [
                      _c("el-option", {
                        attrs: { label: "bobo", value: "bobo" }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-select",
                    {
                      staticClass: "filter-item",
                      staticStyle: { width: "180px", "margin-left": "10px" },
                      attrs: {
                        placeholder: _vm.$i18n.t("common.InvoiceStatus"),
                        clearable: ""
                      },
                      model: {
                        value: _vm.query.invoiceStatus,
                        callback: function($$v) {
                          _vm.$set(_vm.query, "invoiceStatus", $$v)
                        },
                        expression: "query.invoiceStatus"
                      }
                    },
                    [
                      _c("el-option", {
                        attrs: {
                          label: _vm.$i18n.t("common.Notuploaded"),
                          value: "2"
                        }
                      }),
                      _c("el-option", {
                        attrs: {
                          label: _vm.$i18n.t("common.Uploaded"),
                          value: "1"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c("el-date-picker", {
                    staticStyle: { "margin-left": "10px" },
                    attrs: {
                      type: "daterange",
                      "range-separator": _vm.$i18n.t("common.to"),
                      "start-placeholder": _vm.$i18n.t(
                        "common.Applicationtime"
                      ),
                      "end-placeholder": _vm.$i18n.t("common.Applicationtime")
                    },
                    model: {
                      value: _vm.valuePicker,
                      callback: function($$v) {
                        _vm.valuePicker = $$v
                      },
                      expression: "valuePicker"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      staticClass: "filter-item",
                      staticStyle: { "margin-left": "10px" },
                      attrs: { icon: "el-icon-search", type: "primary" },
                      on: { click: _vm.handleQuery }
                    },
                    [_vm._v("  " + _vm._s(_vm.$i18n.t("common.query")) + "  ")]
                  ),
                  _c(
                    "el-button",
                    {
                      staticClass: "filter-item",
                      staticStyle: { "margin-left": "10px" },
                      attrs: {
                        icon: "el-icon-search",
                        type: "primary",
                        size: "small"
                      },
                      on: { click: _vm.cleanQuery }
                    },
                    [
                      _vm._v(
                        "\n                            " +
                          _vm._s(_vm.$i18n.t("common.Reset")) +
                          "\n                        "
                      )
                    ]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading"
            }
          ],
          staticStyle: { width: "100%", "margin-top": "20px" },
          attrs: {
            data: _vm.list.list,
            border: "",
            stripe: "",
            size: "medium",
            height: "calc(100% - 140px)"
          }
        },
        [
          _c("el-table-column", {
            attrs: {
              label: "ID",
              prop: "invoiceId",
              align: "center",
              "show-overflow-tooltip": ""
            }
          }),
          _c("el-table-column", {
            attrs: {
              label: _vm.$i18n.t("common.Ordernumber"),
              prop: "orderNo",
              align: "center",
              width: "150px"
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "countryCode",
              label: _vm.$i18n.t("common.country"),
              align: "center",
              width: "50px"
            }
          }),
          _c("el-table-column", {
            attrs: {
              label: _vm.$i18n.t("common.UserID"),
              prop: "userId",
              align: "center",
              "show-overflow-tooltip": ""
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "orderPrice",
              label: _vm.$i18n.t("common.money"),
              "show-overflow-tooltip": "",
              align: "center"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [_c("span", [_vm._v("€" + _vm._s(row.orderPrice))])]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              prop: "taxNumber",
              label: _vm.$i18n.t("common.TaxID"),
              "show-overflow-tooltip": "",
              align: "center"
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "invoiceAddress",
              label: _vm.$i18n.t("common.Invoiceaddress"),
              width: "200px",
              align: "center"
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "applyTime",
              label: _vm.$i18n.t("common.Applicationtime"),
              width: "200px",
              align: "center",
              formatter: _vm.formatDate
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "examineTime",
              label: _vm.$i18n.t("common.Audittime"),
              width: "200px",
              align: "center"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    row.examineStatus == 1 || row.examineStatus == 2
                      ? _c("span", [
                          _vm._v(_vm._s(_vm.formatTime(row.examineTime)))
                        ])
                      : _vm._e()
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              prop: "examineStatus",
              label: _vm.$i18n.t("common.Auditstatus"),
              "show-overflow-tooltip": "",
              align: "center"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    row.examineStatus == 0
                      ? _c("span", { staticStyle: { color: "red" } }, [
                          _vm._v(_vm._s(_vm.$i18n.t("common.Unaudited")))
                        ])
                      : _vm._e(),
                    row.examineStatus == 1
                      ? _c("span", { staticStyle: { color: "green" } }, [
                          _vm._v(_vm._s(_vm.$i18n.t("common.Passed")))
                        ])
                      : _vm._e(),
                    row.examineStatus == 2
                      ? _c("span", { staticStyle: { color: "grey" } }, [
                          _vm._v(_vm._s(_vm.$i18n.t("common.Failed")))
                        ])
                      : _vm._e()
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              prop: "examineContent",
              label: _vm.$i18n.t("common.AuditDescription"),
              "show-overflow-tooltip": "",
              align: "center"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    _vm._v(
                      "\n                    " +
                        _vm._s(row.examineContent.slice(0, 200)) +
                        "\n                "
                    )
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              prop: "examineAdmin",
              label: _vm.$i18n.t("common.Reviewedby"),
              "show-overflow-tooltip": "",
              align: "center"
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "invoiceStatus",
              label: _vm.$i18n.t("common.InvoiceStatus"),
              "show-overflow-tooltip": "",
              align: "center"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    row.invoiceStatus == 0
                      ? _c("span", { staticStyle: { color: "red" } }, [
                          _vm._v(_vm._s(""))
                        ])
                      : _vm._e(),
                    row.invoiceStatus == 1
                      ? _c("span", { staticStyle: { color: "green" } }, [
                          _vm._v(_vm._s(_vm.$i18n.t("common.Uploaded")))
                        ])
                      : _vm._e(),
                    row.invoiceStatus == 2
                      ? _c("span", { staticStyle: { color: "red" } }, [
                          _vm._v(_vm._s(_vm.$i18n.t("common.Notuploaded")))
                        ])
                      : _vm._e()
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              label: _vm.$i18n.t("common.operation"),
              align: "center",
              width: "230"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    row.examineStatus == 0
                      ? _c(
                          "el-button",
                          {
                            staticClass: "table-inner-button",
                            attrs: { type: "primary", size: "mini" },
                            on: {
                              click: function($event) {
                                return _vm.examineHandle(row)
                              }
                            }
                          },
                          [_vm._v(_vm._s(_vm.$i18n.t("common.Auditing")))]
                        )
                      : _vm._e(),
                    row.examineStatus == 1 && row.invoiceStatus == 1
                      ? _c(
                          "el-button",
                          {
                            staticClass: "table-inner-button",
                            attrs: { type: "primary", size: "mini" },
                            on: {
                              click: function($event) {
                                return _vm.showInvoice(row)
                              }
                            }
                          },
                          [_vm._v(_vm._s(_vm.$i18n.t("common.check")))]
                        )
                      : _vm._e(),
                    row.examineStatus == 1 && row.invoiceStatus == 1
                      ? _c(
                          "el-button",
                          {
                            staticClass: "table-inner-button",
                            attrs: { type: "primary", size: "mini" },
                            on: {
                              click: function($event) {
                                return _vm.downloadHandle(row)
                              }
                            }
                          },
                          [_vm._v(_vm._s(_vm.$i18n.t("common.download")))]
                        )
                      : _vm._e()
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "block" },
        [
          _c("el-pagination", {
            staticStyle: { "margin-top": "10px" },
            attrs: {
              background: "",
              "current-page": _vm.list.page,
              "page-size": _vm.list.size,
              layout: "total, prev, pager, next, jumper",
              total: _vm.list.total
            },
            on: { "current-change": _vm.handleCurrentChange }
          })
        ],
        1
      ),
      _vm.invoiceVisible
        ? _c(
            "el-dialog",
            {
              attrs: {
                title: _vm.$i18n.t("common.invoice"),
                visible: _vm.invoiceVisible
              },
              on: {
                "update:visible": function($event) {
                  _vm.invoiceVisible = $event
                }
              }
            },
            [
              _c(
                "div",
                [
                  _vm.invoiceUrl != null
                    ? _c("pdf", { ref: "pdf", attrs: { src: _vm.invoiceUrl } })
                    : _vm._e()
                ],
                1
              )
            ]
          )
        : _vm._e(),
      _vm.diologVisible
        ? _c(
            "el-dialog",
            {
              attrs: {
                title: _vm.diologTitle,
                visible: _vm.diologVisible,
                width: "1000px",
                "close-on-click-modal": false
              },
              on: {
                "update:visible": function($event) {
                  _vm.diologVisible = $event
                }
              }
            },
            [
              _c(
                "div",
                [
                  _c("span", { staticStyle: { "font-size": "125%" } }, [
                    _vm._v(_vm._s(_vm.$i18n.t("common.OrderInformation")))
                  ]),
                  _c(
                    "el-table",
                    {
                      staticStyle: { "margin-top": "10px" },
                      attrs: {
                        data: _vm.examineList[0].orderDetails,
                        size: "medium"
                      }
                    },
                    [
                      _c("el-table-column", {
                        attrs: {
                          label: _vm.$i18n.t("common.Ordernumber"),
                          width: "100px",
                          align: "center",
                          prop: "orderNo"
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function(ref) {
                                var row = ref.row
                                return [
                                  _c(
                                    "el-link",
                                    {
                                      attrs: { type: "primary" },
                                      on: {
                                        click: function($event) {
                                          return _vm.orderHandle(row.orderId)
                                        }
                                      }
                                    },
                                    [_vm._v(_vm._s(row.orderNo))]
                                  )
                                ]
                              }
                            }
                          ],
                          null,
                          false,
                          2347927803
                        )
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: _vm.$i18n.t("common.country"),
                          "show-overflow-tooltip": "",
                          align: "center",
                          prop: "countryCode"
                        }
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: _vm.$i18n.t("common.UserID"),
                          width: "150px",
                          align: "center",
                          prop: "userId"
                        }
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: _vm.$i18n.t("common.Numberofproductmodels"),
                          "show-overflow-tooltip": "",
                          align: "center",
                          prop: "orderItemList.length"
                        }
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: _vm.$i18n.t("common.source"),
                          "show-overflow-tooltip": "",
                          align: "center",
                          prop: "source"
                        }
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: _vm.$i18n.t("common.money"),
                          "show-overflow-tooltip": "",
                          align: "center",
                          prop: "totalAmt"
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function(ref) {
                                var row = ref.row
                                return [
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$dict.getDictValue(
                                          "country_currency",
                                          row.countryCode
                                        )
                                      ) + _vm._s(row.totalAmt)
                                    )
                                  ])
                                ]
                              }
                            }
                          ],
                          null,
                          false,
                          2886052851
                        )
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: _vm.$i18n.t("common.OrderStatus"),
                          "show-overflow-tooltip": "",
                          align: "center",
                          prop: "orderStatus"
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function(ref) {
                                var row = ref.row
                                return [
                                  _vm._v(
                                    "\n                            " +
                                      _vm._s(
                                        _vm.$dict.getDictValue(
                                          "order_status",
                                          row.orderStatus
                                        )
                                      ) +
                                      "\n                        "
                                  )
                                ]
                              }
                            }
                          ],
                          null,
                          false,
                          2423993466
                        )
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: _vm.$i18n.t("common.Creationtime"),
                          width: "150px",
                          align: "center",
                          formatter: _vm.formatDate,
                          prop: "createTime"
                        }
                      }),
                      _c(
                        "el-table-column",
                        {
                          attrs: {
                            label: _vm.$i18n.t("common.Paymentmethod"),
                            "show-overflow-tooltip": "",
                            align: "center"
                          }
                        },
                        [
                          _vm._v(
                            "\n                        " +
                              _vm._s(_vm.examineList[0].payMethodName) +
                              "\n                    "
                          )
                        ]
                      ),
                      _c("el-table-column", {
                        attrs: {
                          label: _vm.$i18n.t("common.Paymenttime"),
                          width: "150px",
                          align: "center",
                          formatter: _vm.formatDate,
                          prop: "paymentTime"
                        }
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: _vm.$i18n.t("common.Shipmentstatus"),
                          "show-overflow-tooltip": "",
                          align: "center",
                          prop: "deliveryStatus"
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function(ref) {
                                var row = ref.row
                                return [
                                  _vm._v(
                                    "\n                            " +
                                      _vm._s(
                                        _vm.$dict.getDictValue(
                                          "delivery_status",
                                          row.deliveryStatus
                                        )
                                      ) +
                                      "\n                        "
                                  )
                                ]
                              }
                            }
                          ],
                          null,
                          false,
                          3050521530
                        )
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: _vm.$i18n.t("common.Deliverytime"),
                          width: "150px",
                          align: "center",
                          formatter: _vm.formatDate,
                          prop: "deliverTime"
                        }
                      }),
                      _c(
                        "el-table-column",
                        {
                          attrs: {
                            label: _vm.$i18n.t("common.InvoiceStatus"),
                            "show-overflow-tooltip": "",
                            align: "center"
                          }
                        },
                        [
                          [
                            _vm.examineList[0].examineStatus == 0 ||
                            _vm.examineList[0].invoiceStatus == 2
                              ? _c("span", [
                                  _vm._v(_vm._s(_vm.$i18n.t("common.Applying")))
                                ])
                              : _vm._e(),
                            _vm.examineList[0].invoiceStatus == 1
                              ? _c("span", { staticStyle: { color: "grey" } }, [
                                  _vm._v(_vm._s(_vm.$i18n.t("common.Uploaded")))
                                ])
                              : _vm._e()
                          ]
                        ],
                        2
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.loading,
                      expression: "loading"
                    }
                  ],
                  staticStyle: { "margin-top": "30px" }
                },
                [
                  _c("span", { staticStyle: { "font-size": "125%" } }, [
                    _vm._v(_vm._s(_vm.$i18n.t("common.Invoiceaddress")))
                  ]),
                  _c("div", { staticStyle: { "margin-top": "20px" } }, [
                    _c("span", [
                      _vm._v(
                        _vm._s(_vm.$i18n.t("common.TaxID")) +
                          ":" +
                          _vm._s(_vm.examineList[0].taxNumber)
                      )
                    ])
                  ]),
                  _c(
                    "el-table",
                    {
                      staticStyle: { "margin-top": "20px" },
                      attrs: { data: _vm.examineList, size: "medium" }
                    },
                    [
                      _c(
                        "el-table-column",
                        {
                          attrs: {
                            label: _vm.$i18n.t("common.name1"),
                            align: "center",
                            "show-overflow-tooltip": "",
                            prop: "firstName"
                          }
                        },
                        [
                          [
                            _c("span", [
                              _vm._v(
                                _vm._s(_vm.examineList[0].firstName) +
                                  " " +
                                  _vm._s(_vm.examineList[0].lastName)
                              )
                            ])
                          ]
                        ],
                        2
                      ),
                      _c("el-table-column", {
                        attrs: {
                          label: _vm.$i18n.t("common.Mobilephonenumber"),
                          align: "center",
                          prop: "phoneNumber",
                          width: "150px"
                        }
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: _vm.$i18n.t("common.country"),
                          "show-overflow-tooltip": "",
                          align: "center",
                          prop: "country"
                        }
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: _vm.$i18n.t("common.province"),
                          "show-overflow-tooltip": "",
                          align: "center",
                          prop: "province"
                        }
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: _vm.$i18n.t("common.city"),
                          "show-overflow-tooltip": "",
                          align: "center",
                          prop: "city"
                        }
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: _vm.$i18n.t("common.PostalCode"),
                          "show-overflow-tooltip": "",
                          align: "center",
                          prop: "postalCode"
                        }
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: _vm.$i18n.t("common.Detailedaddress"),
                          align: "center",
                          prop: "addressContent",
                          width: "200px"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "div",
                { staticStyle: { "margin-top": "20px" } },
                [
                  _c("span", { staticStyle: { "font-size": "125%" } }, [
                    _vm._v(_vm._s(_vm.$i18n.t("common.Auditing")) + ":")
                  ]),
                  _c(
                    "el-form",
                    {
                      staticStyle: { "margin-top": "20px" },
                      attrs: { model: _vm.examineRequest }
                    },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: _vm.$i18n.t("common.result") } },
                        [
                          _c(
                            "el-radio-group",
                            {
                              model: {
                                value: _vm.examineRequest.examineStatus,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.examineRequest,
                                    "examineStatus",
                                    $$v
                                  )
                                },
                                expression: "examineRequest.examineStatus"
                              }
                            },
                            [
                              _c("el-radio", { attrs: { label: "1" } }, [
                                _vm._v(_vm._s(_vm.$i18n.t("common.adopt")))
                              ]),
                              _c("el-radio", { attrs: { label: "2" } }, [
                                _vm._v(_vm._s(_vm.$i18n.t("common.Notpassed")))
                              ])
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _vm.examineRequest.examineStatus == 1 &&
                      _vm.scene.isNonSelf
                        ? _c(
                            "el-form-item",
                            { attrs: { label: "" } },
                            [
                              _c("fileUpload", {
                                ref: "fileUpload",
                                on: { event: _vm.changeUrl }
                              })
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "el-form-item",
                        { attrs: { label: _vm.$i18n.t("common.reason") } },
                        [
                          _c("el-input", {
                            attrs: {
                              type: "textarea",
                              autosize: { minRows: 4, maxRows: 10 },
                              placeholder: _vm.$i18n.t("common.Enterthereason")
                            },
                            model: {
                              value: _vm.examineRequest.examineContent,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.examineRequest,
                                  "examineContent",
                                  $$v
                                )
                              },
                              expression: "examineRequest.examineContent"
                            }
                          })
                        ],
                        1
                      ),
                      _c("el-form-item", { attrs: { label: "" } }, [
                        _c("span", { staticStyle: { color: "red" } }, [
                          _vm._v("*")
                        ]),
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              _vm.$i18n.t(
                                "common.Thereasonfornotpassingwillbedisplayedtotheuser"
                              )
                            )
                          )
                        ])
                      ]),
                      _c(
                        "el-form-item",
                        { attrs: { label: "" } },
                        [
                          _c(
                            "el-button",
                            {
                              staticClass: "table-inner-button",
                              attrs: { type: "primary", size: "medium" },
                              on: {
                                click: function($event) {
                                  return _vm.examine(
                                    _vm.examineList[0].invoiceId
                                  )
                                }
                              }
                            },
                            [_vm._v(_vm._s(_vm.$i18n.t("common.Auditing")))]
                          ),
                          _c(
                            "el-button",
                            {
                              attrs: { size: "medium" },
                              on: {
                                click: function($event) {
                                  _vm.diologVisible = false
                                }
                              }
                            },
                            [_vm._v(_vm._s(_vm.$i18n.t("common.cancel")))]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ]
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }