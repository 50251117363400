<template>
    <div>
        <el-dialog :title='$i18n.t("common.PricingSettings")' :visible.sync="priceSettingVisible" width="600px" v-if="priceSettingVisible" :close-on-click-modal='false'>
            <el-form
                ref="dataForm"
                :rules="formRules"
                :model="form"
                label-position="left"
                size="small"
                label-width="150px"
                style="width: 600px; margin-left: 50px"
            >
                <el-form-item :label='$i18n.t("common.country")' prop="countryCode">
                    <dataSelect
                        style="width: 200px"
                        class="filter-item"
                        v-model="form.countryCode"
                        clearable
                        :codeType="'countrys'"
                        :placeholder='$i18n.t("common.country")'
                        disabled
                    ></dataSelect>
                </el-form-item>
                <el-form-item :label='$i18n.t("common.Costprice")' prop="priceType">
                    <dataSelect
                        style="width: 200px"
                        class="filter-item"
                        v-model="form.priceType"
                        clearable
                        :codeType="'price_type'"
                        :placeholder='$i18n.t("common.Costprice")'
                    ></dataSelect>
                </el-form-item>
                <el-form-item :label='$i18n.t("common.taxrate")' prop="taxRate">
                    <el-input v-model="form.taxRate" style="width: 200px">
                        <template slot="append"> % </template>
                    </el-input>
                </el-form-item>
                <el-form-item :label='$i18n.t("common.commission")' prop="commission">
                    <el-input v-model="form.commission" style="width: 200px">
                        <template slot="append"> % </template>
                    </el-input>
                </el-form-item>
                <el-form-item :label='$i18n.t("common.Grossmargin")' prop="profit">
                    <el-input v-model="form.profit" style="width: 200px">
                        <template slot="append"> % </template>
                    </el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="priceSettingVisible = false">  {{ $i18n.t("common.cancel") }} </el-button>
                <el-button type="primary" @click="doUpdate()">  {{ $i18n.t("common.confirm") }} </el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import dataSelect from '@/components/common/DataSelect.vue';
import dataText from '@/components/common/DataText.vue';
import imgUpload from '@/views/country/imgUpload';

function initFormBean() {
    const formBean = {
        logisticsPricingId: null,
        pricingSettingId: null,
        countryCode: null,
        priceType: '',
        taxRate: 0,
        commission: 0,
        profit: 0
    };
    return formBean;
}

export default {
    name: 'PriceSetting',
    components: {
        dataSelect,
        dataText,
        imgUpload
    },
    data() {
        var isFreightFee = (rule, value, callback) => {
            var pattern = /^(([0])|([1-9][0-9]*)|(([0]\.\d{1,2}|[1-9][0-9]*\.\d{1,2})))$/;
            if (value > 99.99) {
                return callback(new Error(this.$i18n.t("common.Fillinanumberthatistoolarge")));
            } else if (value > 0 && !pattern.test(value)) {
                return callback(new Error(this.$i18n.t("common.AtmosttwodigitscanbeenteredaftertheDecimalseparator")));
            } else if (!pattern.test(value)) {
                return callback(new Error(this.$i18n.t("common.Pleaseinputthenumbercorrectly")));
            } else {
                return callback();
            }
        };
        return {
            old: initFormBean(),
            form: initFormBean(),
            priceSettingVisible: false,
            formRules: {
                countryCode: [{ required: true, message: this.$i18n.t("common.Pleaseenterthecountry"), trigger: 'blur' }],
                priceType: [{ required: true, message: this.$i18n.t("common.Pleaseselectapricetype"), trigger: 'blur' }],
                taxRate: [
                    { required: true, message: this.$i18n.t("common.Pleasecompletethetaxrateconfiguration"), trigger: 'blur' },
                    { validator: isFreightFee, trigger: 'blur' }
                ],
                commission: [
                    { required: true, message: this.$i18n.t("common.Pleasecompletethecommissionconfiguration"), trigger: 'blur' },
                    { validator: isFreightFee, trigger: 'blur' }
                ],
                profit: [
                    { required: true, message: this.$i18n.t("common.PleasecompleteGrossmarginconfiguration"), trigger: 'blur' },
                    { validator: isFreightFee, trigger: 'blur' }
                ]
            }
        };
    },
    methods: {
        /**
         * 重置表单
         */
        resetForm() {
            for (const key in initFormBean()) {
                this.form[key] = this.old[key];
            }
        },
        /**
         * 打开编辑表单
         */
        handleUpdate(row) {
            this.resetForm();
            this.$store.dispatch('getPriceSetting', row.logisticsPricingId).then((res) => {
                this.form.pricingSettingId = res.pricingSettingId;
                this.form.logisticsPricingId = res.logisticsPricingId;
                this.form.countryCode = res.countryCode;
                this.form.priceType = res.priceType;
                this.form.taxRate = res.taxRate;
                this.form.commission = res.commission;
                this.form.profit = res.profit;
                console.log(this.from);
            });
            this.priceSettingVisible = true;
        },
        /**
         * 执行修改操作
         */
        doUpdate() {
            this.$refs['dataForm'].validate((valid) => {
                if (valid) {
                    this.$store.dispatch('updatePriceSetting', this.form).then((data) => {
                        this.$parent.doQueryList({ page: 1 });
                        this.$message({
                            message:this.$i18n.t("common.Successfullyedited"),
                            type: 'success'
                        });
                        this.priceSettingVisible = false;
                    });
                } else {
                    this.$message({
                        message:this.$i18n.t("common.Editingfailed"),
                        type: 'error'
                    });

                    return false;
                }
            });
        }
    }
};
</script>
