<template>
    <div style="height: 100%;min-height:350px;" class="app-container">
        <div class="filter-container">
            <dataSelect
                style="width: 200px"
                class="filter-item"
                v-model="query.countryCode"
                clearable
                :codeType="'countrys'"
                :placeholder='$i18n.t("common.country")'
            ></dataSelect>


            <el-button class="filter-item" icon="el-icon-search" type="primary" @click="handleQuery">  {{ $i18n.t("common.search") }}  </el-button>
            <div style="margin: 10px 0px 10px 0px;">
                <el-button class="filter-item" style="margin-left: 0px" type="success" icon="el-icon-edit" @click="handleCreate">
                    {{ $i18n.t("common.Add1") }}

                </el-button>
            </div>
        </div>

        <el-table
            v-loading="listLoading"
            :data="list"
            height="calc(100% - 130px)"
            @selection-change="selectionChange"
            border
            stripe
            style="width: 100%; margin-top: 20px"
        >
            <el-table-column label="ID" prop="countryid" align="center" width="200px">
                <template slot-scope="{ row }">
                    <span>{{ row.countryId }}</span>
                </template>
            </el-table-column>
            <el-table-column  :label='$i18n.t("common.country")' prop="countrycode" align="center">
                <template slot-scope="{ row }">
                    {{ $dict.getDictValue('countrys', row.countryCode) }}
                </template>
            </el-table-column>
            <el-table-column :label='$i18n.t("common.icon")' prop="icon" align="center">
                <template slot-scope="scope">
                    <el-image style="width: 100px; height: 100px" :src="ossHost+ scope.row.icon" fit="fit"></el-image>
                </template>
            </el-table-column>
            <el-table-column :label='$i18n.t("common.language")' prop="lang" align="center">
                <template slot-scope="{ row }">
                    {{ $dict.getDictValue('lang', row.lang) }}
                </template>
            </el-table-column>
            <el-table-column :label='$i18n.t("common.currency")' prop="currency" align="center">
                <template slot-scope="{ row }">
                    <span>{{ row.currency }}</span>
                </template>
            </el-table-column>

            <!--
            <el-table-column :label='$i18n.t("common.brand")' prop="description" align="center" width="250px">
                <template slot-scope="{ row }">
                    <el-tag v-for="item in row.countryParameterList" :key="item.countryParameterId" style="margin-left: 10px">
                        {{ item.brand }}
                    </el-tag>
                </template>
            </el-table-column>
            -->
            <el-table-column :label='$i18n.t("common.Creationtime")' prop="createtime" align="center">

                <template slot-scope="{ row }">
                    <span>{{ new Date(row.createTime).toLocaleString() }}</span>
                </template>
            </el-table-column>
            <el-table-column  :label='$i18n.t("common.Modificationtime")' prop="updatetime" align="center">
                <template slot-scope="{ row }">
                    <span>{{ row.updateTime == null ? '' : new Date(row.updateTime).toLocaleString() }}</span>
                </template>
            </el-table-column>
            <el-table-column :label='$i18n.t("common.state")' prop="status" align="center">
                <template slot-scope="{ row }">
                    <span>
                        <el-switch @change="changeStatus(row)" v-model="row.status" active-color="#13ce66"> </el-switch>
                    </span>
                </template>
            </el-table-column>
            <el-table-column :label='$i18n.t("common.operation")'  align="center" width="230">
                <template slot-scope="{ row }">
                    <el-button size="mini" @click="handleConfig(row.countryId)" class="table-inner-button">{{ $i18n.t("common.allocation") }}</el-button>
                    <el-button type="primary" size="mini" @click="handleUpdate(row)" class="table-inner-button">{{ $i18n.t("common.edit") }}</el-button>
                    <el-button type="danger" size="mini" @click="handleDeleteSingle(row)" class="table-inner-button">{{ $i18n.t("common.delete") }}</el-button>
                </template>
            </el-table-column>
        </el-table>
        <!-- 新建表单 -->
        <country-add ref="countryAdd" @created="doQueryList({ pageNo: 1 })" />

        <countryConfig ref="countryConfig" @restOpen="restOpendialog" />
    </div>
</template>

<script>
import countryAdd from './saveOrUpdate';
import DataSelect from '@/components/common/DataSelect';
import countryConfig from '@/views/country/countryConfig';
export default {
    name: 'CountryTable',
    components: {
        countryAdd,
        DataSelect,
        countryConfig
    },
    data() {
        return {
            ossHost: process.env.VUE_APP_OSS_Head,
            list: [],
            listLoading: true,
            query: {
                sydelete: null,
                countryCode: null,
                icon: null,
                lang: null,
                currency: null,
                description: null,
                createtimeStart: '',
                createtimeEnd: '',
                updatetimeStart: '',
                updatetimeEnd: '',
                status: null,
                updatebyStart: '',
                updatebyEnd: '',
                systampStart: '',
                systampEnd: '',
                tenantid: undefined
            },
            selectItems: []
        };
    },
    mounted() {
        this.doQueryList({ pageNo: 1 });
    },
    methods: {
        restOpendialog(data) {
            this.handleConfig(data);
        },
        handleConfig(countryId) {
            this.$store.dispatch('getCountryInfo', countryId).then((res) => {
                console.log(res);
                this.$refs.countryConfig.countryParameterList = res.countryParameterList;

                this.$refs.countryConfig.form = res.countryParameterList[0];
                this.$refs.countryConfig.countryId = countryId;
                this.$refs.countryConfig.activeName='0'
                this.$refs.countryConfig.payMethodList = res.countryParameterList[0].payMethodList;
                let arr = [];
                for (const key in res.countryParameterList) {
                    const element = res.countryParameterList[key];
                    arr.push({
                        title: element.brand,
                        name: key
                    });
                }

                this.$refs.countryConfig.editableTabs = arr;
                this.$refs.countryConfig.dialogFormVisible = true;
            });
        },
        changeStatus(row) {
            let param = {
                status: row.status,
                id: row.countryId
            };

            this.$store
                .dispatch('changeCountryStatus', param)
                .then((res) => {
                    this.$message.success($i18n.t("common.Statuseditedsuccessfully"));
                })
                .finally(() => {
                    this.listLoading = false;
                });
        },
        /**
         * 选择框变化
         */
        selectionChange(val) {
            this.selectItems = val;
        },
        /**
         * 触发搜索操作
         */
        handleQuery() {
            this.doQueryList({ pageNo: 1 });
        },
        /**
         * 执行列表查询
         */
        doQueryList({ pageNo, pageSize }) {
            this.listLoading = true;

            this.$store
                .dispatch('getcountryList', this.query)
                .then((res) => {
                    this.list = res;
                    console.log("====",this.list)
                })
                .finally(() => {
                    this.listLoading = false;
                });
        },
        /**
         * 删除单条记录
         */
        handleDeleteSingle(row) {
            return this.$confirm( this.$i18n.t("common.Areyousuretocontinuedeletingdata"),  this.$i18n.t("common.prompt"), {
                // confirmButtonText: '确定',
                // cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.$store.dispatch('deleteCountry', row.countryId).then((res) => {
                    this.$message({
                        type: 'success',
                        message: this.$i18n.t("common.Successfullydeleted")
                    });
                    this.doQueryList({ pageNo: 1 });
                });
            });
        },
        /**
         * 打开新建表单
         */
        handleCreate() {
            this.$refs.countryAdd.handleCreate();
        },
        /**
         * 打开编辑表单
         */
        handleUpdate(row) {
            this.$refs.countryAdd.handleEdit();
            this.$refs.countryAdd.form.countryId = row.countryId;
            this.$refs.countryAdd.form.countryCode = row.countryCode;
            this.$refs.countryAdd.countryWatch = row.countryCode;
            this.$refs.countryAdd.form.icon = row.icon;
            this.$refs.countryAdd.changeCountry(row.countryCode);
            let arr = [];
            if (row.countryParameterList != null && row.countryParameterList.length != 0) {
                row.countryParameterList.forEach((element) => {
                    arr.push(element.brand);
                });
            }
            this.$refs.countryAdd.imageUrl = row.icon;
            this.$refs.countryAdd.reloadimageUrl=row.icon
            this.$refs.countryAdd.checkedBrand = arr;
            this.$refs.countryAdd.form.status = row.status;
        }
    }
};
</script>
<style scoped>
.filter-container {
    padding: auto;
    margin-left: 20px;
    padding-top: 20px;
}
.filter-item {
    margin-right: 10px;
}
.table-inner-button{
    margin-bottom: 10px;
}
</style>
