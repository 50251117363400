<template>
    <div>
        <el-dialog :title="dialogTitle" :visible.sync="logisticsVisible" width="900px" v-if="logisticsVisible" :close-on-click-modal='false'>
            <el-form
                ref="dataForm"
                :rules="formRules"
                :model="form"
                label-position="left"
                size="small"
                label-width="120px"
                style="width: 750px; margin-left: 50px"
            >
                <el-form-item  :label='$i18n.t("common.country")' prop="countryCode">
                    <dataSelect
                        style="width: 180px;"
                        class="filter-item"
                        v-model="form.countryCode"
                        clearable
                        :codeType="'countrys'"
                        :placeholder='$i18n.t("common.country")'
                    ></dataSelect>
                </el-form-item>
                <div>
                    <el-divider content-position="left">{{ $i18n.t("common.Freightallocation") }}</el-divider>
                </div>
                <el-form-item :label='$i18n.t("common.FreightType")' prop="isFreeFreightFee">
                    <el-radio-group v-model="form.isFreeFreightFee">
                        <el-radio :label="true">{{ $i18n.t("common.Freeshipping") }}</el-radio>
                        <el-radio :label="false">{{ $i18n.t("common.Configureshippingcosts") }}</el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item :label='$i18n.t("common.Basicfreight")' prop="freightFee" v-if="!form.isFreeFreightFee">
                    <el-input v-model="form.freightFee" style="width: 180px;"/>
                </el-form-item>
                <el-form-item :label='$i18n.t("common.Fullreductionoffreight")' prop="freeFreightFeePrice" v-if="!form.isFreeFreightFee">
                    <el-input v-model="form.freeFreightFeePrice" style="width: 300px;">
                        <template slot="prepend"> {{ $i18n.t("common.Orderamountfull") }} </template>
                        <template slot="append">  {{ $i18n.t("common.Freeshipping") }} </template>
                    </el-input>
                </el-form-item>
                <div>
                    <el-divider content-position="left">{{ $i18n.t("common.Receivingandshippingreminder") }}</el-divider>
                </div>
                <el-form-item :label='$i18n.t("common.Deliverytimeprompt")' prop="deliverDescribe">
                    <el-input v-model="form.deliverDescribe"  style="width: 500px;"/>
                </el-form-item>
                <el-form-item :label='$i18n.t("common.Receivingtimeprompt")' prop="receivingDescribe">
                    <el-input v-model="form.receivingDescribe" style="width: 500px;" />
                </el-form-item>
                <div>
                    <el-divider content-position="left">{{ $i18n.t("common.logisticscompany") }}</el-divider>
                    <el-button type="primary" @click="addCompany()"> {{ $i18n.t("common.Add1") }}</el-button>
                </div>
                <el-table :data="logisticsCompany" style="width: 850px; margin-top: 20px; margin-bottom: 20px">
                    <el-table-column :label='$i18n.t("common.Serialnumber")' width="80px" type="index"> </el-table-column>
                    <el-table-column prop="title" :label='$i18n.t("common.name")' width="150px"> </el-table-column>
                    <el-table-column prop="logo" :label='$i18n.t("common.icon")' width="150px">
                        <template slot-scope="scope">
                            <el-image
                                style="width: 100px; height: 100px"
                                v-if="scope.row.logo"
                                :src="ossHost +scope.row.logo"
                                fit="fill"
                            ></el-image>
                        </template>
                    </el-table-column>
                    <el-table-column prop="link" :label='$i18n.t("common.link")' width="180px"> </el-table-column>
                    <el-table-column prop="sort":label='$i18n.t("common.sort")' width="50px">
                        <template slot-scope="scope">
                            <el-button
                                v-if="scope.$index != 0"
                                type="text"
                                size="medium"
                                @click="moveUp(scope.$index, scope.row)"
                                class="table-inner-button"
                                >⬆</el-button
                            >
                            <el-button
                                v-if="scope.$index != logisticsCompany.length - 1"
                                type="text"
                                size="medium"
                                @click="moveDown(scope.$index, scope.row)"
                                class="table-inner-button"
                                >⬇</el-button
                            >
                        </template>
                    </el-table-column>
                    <el-table-column :label='$i18n.t("common.operation")' align="center" width="150px">
                        <template slot-scope="scope">
                            <el-button type="primary" size="mini" @click="updateCompany(scope.$index, scope.row)" class="table-inner-button"
                                >{{ $i18n.t("common.edit") }}</el-button
                            >
                            <el-button type="primary" size="mini" @click="deleteCompany(scope.$index, scope.row)" class="table-inner-button"
                                >{{ $i18n.t("common.delete") }}</el-button
                            >
                        </template>
                    </el-table-column>
                </el-table>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="logisticsVisible = false">  {{ $i18n.t("common.cancel") }} </el-button>
                <el-button type="primary" @click="doUpdate()">  {{ $i18n.t("common.confirm") }} </el-button>
            </div>
        </el-dialog>
        <el-dialog :title="companyTitle" :visible.sync="companyVisible" v-if="companyVisible">
            <el-form
                ref="companyForm"
                :rules="companyFormRules"
                :model="logisticsCompanyAdd"
                label-position="left"
                size="small"
                label-width="100px"
                style="width: 400px; margin-left: 50px"
            >
                <el-form-item  :label='$i18n.t("common.name")' prop="title">
                    <el-input v-model="logisticsCompanyAdd.title" />
                </el-form-item>
                <el-form-item :label='$i18n.t("common.link")' prop="link">
                    <el-input v-model="logisticsCompanyAdd.link" />
                </el-form-item>
                <el-form-item :label='$i18n.t("common.icon")' prop="logo">
                    <el-col :span="14">
                        <imgUpload ref="imageUpload" :img="logo" @event="changeUrl"> </imgUpload>
                    </el-col>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="companyVisible = false">  {{ $i18n.t("common.cancel") }} </el-button>
                <el-button type="primary" @click="doAddCompany()">  {{ $i18n.t("common.confirm") }} </el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import dataSelect from '@/components/common/DataSelect.vue';
import dataText from '@/components/common/DataText.vue';
import imgUpload from '@/views/country/imgUpload';

function initFormBean() {
    const formBean = {
        logisticsPricingId:null,
        countryCode: null,
        isFreeFreightFee: false,
        freightFee: 0,
        freeFreightFeePrice: 0,
        deliverDescribe: null,
        receivingDescribe: null,
        logisticsCompanyReq: []
    };
    return formBean;
}

export default {
    name: 'LogisticsEdit',
    components: {
        dataSelect,
        dataText,
        imgUpload
    },
    data() {
        var isfreightFee = (rule, value, callback) => {
            console.log(value);
            var pattern = /^(([1-9][0-9]*)|(([0]\.\d{1,2}|[1-9][0-9]*\.\d{1,2})))$/;
            // if (value > 99.99) {
            //     return callback(new Error('填写金额过大'));
            // }
            if (value == 0) {
                return callback(new Error(this.$i18n.t("common.Cannotbe0")));
            } else if (value > 0 && value < 1 && !pattern.test(value)) {
                return callback(new Error(this.$i18n.t("common.AtmosttwodigitscanbeenteredaftertheDecimalseparator")));
            } else if (!pattern.test(value)) {
                return callback(new Error(this.$i18n.t("common.Pleaseinputthenumbercorrectly")));
            } else {
                return callback();
            }
        };
        return {
            old: initFormBean(),
            form: initFormBean(),
            logisticsVisible: false,
            companyVisible: false,
            dialogTitle: '',
            companyTitle: '',
            //true为添加，false为编辑
            dialogType: null,
            updateIndex: null,
            logisticsCompanyAdd: {
                title: '',
                link: '',
                logo: '',
                sort: null
            },
            logisticsCompany: [],
            logo:'',
            ossHost: process.env.VUE_APP_OSS_Head,
            formRules: {
                countryCode: [{ required: true, message: this.$i18n.t("common.Pleaseenterthecountry"), trigger: 'blur' }],
                isFreeFreightFee: [{ required: true, message: this.$i18n.t("common.Pleaseselectshippingcost"), trigger: 'blur' }],
                freightFee: [
                    { required: true, message: this.$i18n.t("common.Pleasecompletetheshippingcostconfiguration"), trigger: 'blur' },
                    { validator: isfreightFee, trigger: 'blur' }
                ],
                freeFreightFeePrice: [
                    { required: true, message: this.$i18n.t("common.Pleasecompletetheshippingcostconfiguration"), trigger: 'blur' },
                    { validator: isfreightFee, trigger: 'blur' }
                ],
                deliverDescribe: [{ required: true, message: this.$i18n.t("common.Pleaseenteradeliveryprompt"), trigger: 'change' }],
                receivingDescribe: [{ required: true, message: this.$i18n.t("common.Pleaseenteradeliveryprompt"), trigger: 'change' }]
            },
            companyFormRules: {
                title: [{ required: true, message: this.$i18n.t("common.Pleaseenteraname"), trigger: 'blur' }],
                link: [{ required: true, message: this.$i18n.t("common.Pleaseenteralink"), trigger: 'blur' }]
            }
        };
    },
    methods: {
        /**
         * 重置表单
         */
        resetForm() {
            for (const key in initFormBean()) {
                this.form[key] = this.old[key];
            }
            this.logisticsCompany = [];
        },
        /**
         * 打开添加菜单
         */
        handleAdd() {
            this.resetForm();
            this.dialogTitle =  this.$i18n.t("common.Addlogisticsconfiguration");
            this.logisticsVisible = true;
            this.dialogType = true;
        },
        /**
         * 打开编辑表单
         */
        handleUpdate(row) {
            this.resetForm();
            this.form.logisticsPricingId = row.logisticsPricingId
            this.form.countryCode = row.countryCode;
            this.form.isFreeFreightFee = row.isFreeFreightFee;
            this.form.freightFee = row.freightFee;
            this.form.freeFreightFeePrice = row.freeFreightFeePrice;
            this.form.deliverDescribe = row.deliverDescribe;
            this.form.receivingDescribe = row.receivingDescribe;
            if (row.logisticsCompany != null) {
                for (var item of row.logisticsCompany) {
                    this.logisticsCompany.push(item);
                }
            }
            this.dialogTitle = this.$i18n.t("common.Editlogisticsconfiguration");
            this.dialogType = false;
            console.log(this.form);
            console.log(this.logisticsCompany);
            this.logisticsVisible = true;
        },
        /**
         * 执行修改操作
         */
        doUpdate() {
            if (this.logisticsCompany.length == 0) {
                this.$message({
                    message:  this.$i18n.t("common.Pleaseenterthelogisticscompany"),
                    type: 'warning'
                });
                return;
            }
            if (this.form.isFreeFreightFee) {
                this.form.freightFee = 0;
                this.form.freeFreightFeePrice = 0;
            }
            //公司赋值
            this.form.logisticsCompanyReq = [];

            var i = 1;
            console.log(this.logisticsCompany);
            for (const key in this.logisticsCompany) {
                var logisticsCompanyItem = {
                    title: '',
                    link: '',
                    logo: '',
                    sort: null
                };
                logisticsCompanyItem.title = this.logisticsCompany[key].title;
                logisticsCompanyItem.link = this.logisticsCompany[key].link;
                logisticsCompanyItem.logo = this.logisticsCompany[key].logo;
                logisticsCompanyItem.sort = i++;
                this.form.logisticsCompanyReq.push(logisticsCompanyItem);
            }
            if(this.form.logisticsPricingId == null){
                this.$refs['dataForm'].validate((valid) => {
                    if (valid) {
                        this.$store.dispatch('addLogistics', this.form).then((data) => {
                            this.$parent.doQueryList({ page: 1 });
                            this.$message({
                                message: this.$i18n.t("common.Successfullysaved"),
                                type: 'success'
                            });
                            this.logisticsVisible = false;
                        });
                    } else {
                        this.$message({
                            message: this.$i18n.t("common.Savefailed"),
                            type: 'error'
                        });

                        return false;
                    }
                });
            }else{
                this.$refs['dataForm'].validate((valid) => {
                    if (valid) {
                        this.$store.dispatch('updateLogistics', this.form).then((data) => {
                            this.$parent.doQueryList({ page: 1 });
                            this.$message({
                                message: this.$i18n.t("common.Successfullyedited"),
                                type: 'success'
                            });
                            this.logisticsVisible = false;
                        });
                    } else {
                        this.$message({
                            message: this.$i18n.t("common.Editingfailed"),
                            type: 'error'
                        });

                        return false;
                    }
                });
            }

            
            
        },
        addCompany() {
            this.logisticsCompanyAdd.title = '';
            this.logisticsCompanyAdd.link = '';
            this.logisticsCompanyAdd.logo = '';
            this.logisticsCompanyAdd.sort = null;
            this.logo = '';
            this.companyTitle = this.$i18n.t("common.Addlogisticscompany")
            this.companyVisible = true;
        },
        doAddCompany() {
            if (this.logisticsCompanyAdd.logo == '') {
                this.$message({
                    message:this.$i18n.t("common.Pleaseuploadtheicon"),
                    type: 'warning'
                });
                return;
            }
            //链接校验
            var urlTest = /^((https?|ftp):\/\/)?([a-zA-Z0-9.-]+(:[a-zA-Z0-9.&%$-]+)*@)*((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9][0-9]?)(\.(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9]?[0-9])){3}|([a-zA-Z0-9-]+\.)*[a-zA-Z0-9-]+\.(com|edu|gov|int|mil|net|org|biz|arpa|info|name|pro|aero|coop|museum|[a-zA-Z]{2}))(:[0-9]+)*(\/($|[a-zA-Z0-9.,?'\\+&%$#=~_-]+))*$/;
            if(!urlTest.test(this.logisticsCompanyAdd.link)){
                this.$message({
                    message:this.$i18n.t("common.Pleaseenterthelinkcorrectly"),
                    type: 'warning'
                });
                return;
            }else{
                //http协议拼接
                if(!this.logisticsCompanyAdd.link.startsWith('http')){
                    var text1 = 'https://';
                    this.logisticsCompanyAdd.link = text1.concat('',this.logisticsCompanyAdd.link);
                }
            }
            //添加序号
            this.logisticsCompanyAdd.sort = this.logisticsCompany.length + 1;
            if (this.companyTitle == this.$i18n.t("common.Addlogisticscompany")) {
                this.$refs['companyForm'].validate().then(() => {
                    this.$message({
                        message:  this.$i18n.t("common.Successfullyadded"),
                        type: 'success'
                    });
                    var logisticsCompanyAdd = {
                        title: this.logisticsCompanyAdd.title,
                        link: this.logisticsCompanyAdd.link,
                        logo: this.logisticsCompanyAdd.logo,
                        sort: this.logisticsCompanyAdd.sort
                    };
                    this.logisticsCompany.push(logisticsCompanyAdd);
                    this.companyVisible = false;
                });
            }
            if (this.companyTitle == this.$i18n.t("common.Editlogisticscompany")) {
                this.$refs['companyForm'].validate().then(() => {
                    this.$message({
                        message: this.$i18n.t("common.Successfullyedited"),
                        type: 'success'
                    });
                    var logisticsCompanyAdd = {
                        title: this.logisticsCompanyAdd.title,
                        link: this.logisticsCompanyAdd.link,
                        logo: this.logisticsCompanyAdd.logo,
                        sort: this.logisticsCompanyAdd.sort
                    };
                    this.logisticsCompany.splice(this.updateIndex, 1);
                    this.logisticsCompany.splice(this.updateIndex, 0, logisticsCompanyAdd);
                    this.companyVisible = false;
                });
            }

            console.log(this.logisticsCompanyAdd);
        },
        changeUrl(data) {
            this.logisticsCompanyAdd.logo = data;
        },
        moveUp(index, row) {
            console.log(index);
            if (index > 0) {
                let old = this.logisticsCompany[index - 1];
                this.logisticsCompany.splice(index - 1, 1);
                this.logisticsCompany.splice(index, 0, old);
            } else {
                this.$message({
                    message: this.$i18n.t("common.Thisisalreadythefirstitemmoveupfailed"),
                    type: 'warning'
                });
            }
            console.log(this.logisticsCompany);
        },
        moveDown(index, row) {
            if (index + 1 == this.logisticsCompany.length) {
                this.$message({
                    message: this.$i18n.t("common.Thisisalreadythelastitemmovedownfailed"),
                    type: 'warning'
                });
            } else {
                let old = this.logisticsCompany[index + 1];
                this.logisticsCompany.splice(index + 1, 1);
                this.logisticsCompany.splice(index, 0, old);
            }
        },
        deleteCompany(index, row) {
            this.$confirm( this.$i18n.t("common.confirmdeletion"),   this.$i18n.t("common.prompt"), {
                // confirmButtonText: '确定',
                // cancelButtonText: '取消',
                type: 'warning'
            })
                .then(() => {
                    this.logisticsCompany.splice(index, 1);
                    this.$message({
                        message:this.$i18n.t("common.Successfullydeleted"),
                        type: 'success'
                    });
                })
                .catch(() => {});
        },
        updateCompany(index, row) {
            this.logisticsCompanyAdd.title = row.title;
            this.logisticsCompanyAdd.link = row.link;
            this.logisticsCompanyAdd.logo = row.logo;
            this.logo = row.logo;
            this.logisticsCompanyAdd.sort = row.sort;
            this.companyTitle = this.$i18n.t("common.Editlogisticscompany");
            this.updateIndex = index;
            this.companyVisible = true;
        }
    }
};
</script>
