<template> 
    <div v-if="isShow">
        <div>
            {{ $i18n.t('common.Countrieslisted') }}:
            <span v-for="(item,index) in formData.goodsSpecials" :key="index">
				<span v-if="item.shelf">{{ $dict.getDictValue('countrys', item.countryCode) }}</span>
			</span>
        </div>
        <el-tabs v-model="activeName" @tab-click="handleClick">
            <el-tab-pane :label="$i18n.t('common.title')" name="1">
                <el-form ref="titleFrom" label-width="100px">
                    <el-form-item v-for="(item,index) in languageList" :key="item.id" :label="item.name">
                        <el-input v-model="item.title" @input="forceUpdate($event)"
                            :placeholder="$i18n.t('common.Pleaseentertheproducttitle')"></el-input>
                    </el-form-item>
                </el-form>
            </el-tab-pane>
            <el-tab-pane :label="$i18n.t('common.Largeimage')" name="2">
                <el-tabs v-model="activeName2" @tab-click="handleChildClick">
                    <el-tab-pane v-for="(item,index2) in languageList" :key="item.id" :label="item.name"
                                 :name="item.id">
                        <div style="display: flex;justify-content:flex-start;margin-bottom:20px;flex-wrap: wrap;">
<!--                                <oss-upload itT='itT' :imgInfo.sync="imgInfo" style="margin-left:10px">-->
<!--                                    <el-button @click="handleIsRep" type="success" size="small">{{ $i18n.t('common.Uploadimages') }}</el-button>-->
<!--                                </oss-upload>-->
                                <el-button type="success" size="small" @click="uploadImage">{{ $i18n.t('common.Uploadimages') }}</el-button>
                                <oss-upload itT='itV' :butName="$i18n.t('common.UploadVideo')" accept=".mp4" :imgInfo.sync="imgInfo" :percent.sync="percent" style="margin-left:10px">
                                    <el-button v-if='percent>0'  @click="handleIsRep" type="primary" size="small" disabled>{{$t('common.UploadingVideo')}}...{{percent}}%</el-button>
                                    <el-button v-else  @click="handleIsRep" type="success" size="small">{{ $i18n.t('common.UploadVideo') }}</el-button>
                                </oss-upload>
                                <el-button @click="handleIsShow(1)" style="margin-left:10px" type="success" size="small">{{ $i18n.t('common.Batchdisplay') }}</el-button>
                                <el-button @click="handleIsShow(2)" type="success" size="small">{{ $i18n.t('common.BatchCloseDisplay') }}</el-button>
                                <el-button @click="handleDelete" type="success" size="small">{{ $i18n.t('common.Batchdeletion') }}</el-button>
                                <el-button type="success" size="small" @click="openSynchroLang">{{ $i18n.t('common.Synchronizetootherlanguages') }}</el-button>
                                <el-button type="success" size="small" @click="openGallery">{{ $i18n.t('common.Selectfrompicturelibrary') }}</el-button>


                        </div>
                        <div v-if="bigImgList.length>0">
                            <el-table ref="bigImgTable" :data="bigImgList" style="width: 100%;margin-top: 20px;" border
                                      :header-cell-style="{ background: '#EAEEF9', color: '#32353a' }"
                                      @selection-change="handleSelectionChange">
                                <el-table-column type="selection" width="55"/>

                                <el-table-column prop="type" :label="$i18n.t('common.type')" align="center" show-overflow-tooltip>
                                </el-table-column>

                                <el-table-column prop="brand" :label="$i18n.t('common.Pictures_Videos')" width="120"  align="center">

                                    <template slot-scope="scope">
                                        <div v-if="scope.row.type=='图片'">
                                            <el-image style="width: 100px; height: 100px"
                                                      v-if="scope.row.imageUrl"
                                                      :src="ossHost+scope.row.imageUrl"
                                                      fit="fill">
                                            </el-image>
                                        </div>
                                        <div v-else-if="scope.row.type=='视频'">
                                            <video style="width: 100px; height: 100px" :src="ossHost+scope.row.imageUrl"
                                                   controls="controls">
                                                   {{$t('common.YourBrowserDoesNotSupportVideoPlayback')}}
                                            </video>
                                        </div>
                                    </template>
                                </el-table-column>
                                <el-table-column prop="mainImg" :label="$i18n.t('common.size')" align="center" show-overflow-tooltip>
                                    <template slot-scope="scope">
                                        <div v-if="scope.row.fileWidth && scope.row.fileHeight">
                                            {{ scope.row.fileWidth }}*{{ scope.row.fileHeight }}
                                        </div>
                                    </template>
                                </el-table-column>
                                <el-table-column prop="mainImg" :label="$i18n.t('common.Isitthemainimage')" align="center" show-overflow-tooltip>
                                    <template slot-scope="scope">
                                        <el-switch
                                                :disabled="scope.row.imageTypeCode=='Video'"
                                                :value="scope.row.mainImg"
                                                @change="putGoodsbjMainimage(scope.row,scope.$index)"
                                                active-color="#13ce66">
                                        </el-switch>
                                    </template>
                                </el-table-column>
                                <el-table-column prop="lang" :label="$i18n.t('common.language')" align="center" show-overflow-tooltip>
                                    <template slot-scope="scope">
                                        <div>{{ $dict.getDictValue('lang', scope.row.lang) }}</div>
                                    </template>
                                </el-table-column>
                                <!-- <el-table-column prop="size" label="规格" align="center" show-overflow-tooltip>
                                </el-table-column> -->
                                <el-table-column prop="sort" :label="$i18n.t('common.sort')" align="center" show-overflow-tooltip>
                                    <template slot-scope="scope">
                                        <el-input v-model="scope.row.sort"
                                                  oninput="value=value.replace(/[^0-9]/g,'');if(value.length>8)value=value.slice(0,8)"
                                                  @keyup.enter.native="putGoodsbjSort(scope.row)"
                                                  @blur="putGoodsbjSort(scope.row)"
                                                  :placeholder="$i18n.t('common.Pleaseentersorting')"></el-input>
                                    </template>
                                </el-table-column>
                                <el-table-column prop="updataTime" :label="$i18n.t('common.Updatetime')" align="center"
                                                 show-overflow-tooltip>
                                    <template slot-scope="scope">
										<span v-if="scope.row.updateTime">
            								{{ scope.row.updateTime | formatDate }}<br/>
            							</span>
                                    </template>
                                </el-table-column>
                                <el-table-column prop="showed" :label="$i18n.t('common.Displayornot')" align="center" show-overflow-tooltip>
                                    <template slot-scope="scope" v-if="!scope.row.mainImg">
                                        <el-switch  :value="scope.row.showed"
                                                   @change="putGoodsbjshow(scope.row,scope.$index)"
                                                   active-color="#13ce66">
                                        </el-switch>
                                    </template>
                                </el-table-column>
                                <el-table-column :label="$i18n.t('common.operation')" align="center" fixed="right">
                                    <template slot-scope="scope">
                                        <!-- <oss-upload ref="ossImg" :imgInfo.sync="imgInfo">
                                            <el-button @click="updateImg(scope.$index)" type="text" size="small">更新图片
                                            </el-button>
                                        </oss-upload> -->
                                        <el-button @click="deleteGoodsPage(scope.row,scope.$index)" type="text"
                                                   size="small">{{ $i18n.t('common.delete') }}
                                        </el-button>
                                    </template>
                                </el-table-column>
                            </el-table>
                        </div>
                    </el-tab-pane>
                </el-tabs>
            </el-tab-pane>
            <el-tab-pane :label="$i18n.t('common.GraphicDetails')" name="3">
                <el-tabs v-model="activeName3" @tab-click="handleChildClick" v-if="wangEditerContent && wangEditerContent.length>0">
                        <el-tab-pane v-for="(item,index) in wangEditerContent" :key="index" :label="item.name" :name="item.id">
                                <div class="container">
                                    <wangEditer ref="editer1" 
                                    :content="item.content"
                                    :lang="item.lang"
                                    editorId="editor1"         
                                    style="margin-top:10px"/>
                                </div>                    
                        </el-tab-pane>
                </el-tabs>
            </el-tab-pane>
            <el-tab-pane label="SEO" name="4">
                <el-tabs v-model="activeName4">
                    <el-tab-pane v-for='(item,index) in seoFromList' :key="item.code" :name="item.id" :label="item.name">
                        <el-form  ref="variantFrom">
                                <el-form-item label="URL" prop="head"
                                              :label-width="formLabelWidth">
                                    <el-input type="textarea" show-word-limit maxlength="500" :autosize="{ minRows: 4}"
                                              @input="forceUpdate($event)" :placeholder="$i18n.t('common.Pleaseentercontent')"
                                              v-model="item.seoUrlParameter"></el-input>
                                </el-form-item>
                                <el-form-item :label="$i18n.t('common.PageTitle')" prop="head"
                                              :label-width="formLabelWidth">
                                    <el-input type="textarea" show-word-limit maxlength="500" :autosize="{ minRows: 4}"
                                              @input="forceUpdate($event)" :placeholder="$i18n.t('common.Pleaseentercontent')"
                                              v-model="item.seoTitle"></el-input>
                                </el-form-item>
                                <el-form-item :label="$i18n.t('common.Pagekeywords')" prop="head"
                                              :label-width="formLabelWidth">
                                    <el-input type="textarea" show-word-limit maxlength="500" :autosize="{ minRows: 4}"
                                              @input="forceUpdate($event)" :placeholder="$i18n.t('common.Pleaseentercontent')"
                                              v-model="item.seoKeyword"></el-input>
                                </el-form-item>
                                <el-form-item :label="$i18n.t('common.PageDescription')" prop="head"
                                              :label-width="formLabelWidth">
                                    <el-input type="textarea" show-word-limit maxlength="1000" :autosize="{ minRows: 4}"
                                              @input="forceUpdate($event)" :placeholder="$i18n.t('common.Pleaseentercontent')"
                                              v-model="item.seoDescribe"></el-input>
                                </el-form-item>
                        </el-form>
                    </el-tab-pane>
                </el-tabs>
            </el-tab-pane>
            <el-tab-pane :label="$i18n.t('common.parameter')" name="5" v-if="!scene.isNonSelf">
                <div>
                    <el-input style="width: 20%;" v-model="parameterSearchVal" :placeholder="$i18n.t('common.Pleaseenteraparametername')"
                              autocomplete="off">
                    </el-input>
                    <el-button style="margin: 20px;" type="primary" @click="postGoodsParameter" size="small">
                        {{ $i18n.t('common.query') }}
                    </el-button>
                    <el-checkbox v-model="checkbox" @change="hideNull">{{ $i18n.t('common.Hideemptyparameters') }}
                    </el-checkbox>
                </div>
                <el-row :gutter="20">
                    <el-col :span="5">
                        <div class="tree-container" shadow="never">
                            <el-tree ref="tree" :props="defaultProps" :data="groupleft" node-key="parameterGroupId"
                                     @node-click="handleGroupLeft">
                            </el-tree>
                        </div>
                    </el-col>
                    <el-col :span="19">
                        <el-table ref="pagedata" :data="rightParamterList" style="width: 100%;" border
                                  :header-cell-style="{ background: '#EAEEF9', color: '#32353a' }">
                            <el-table-column prop="parameterItemName" :label="$i18n.t('common.parameter')" align="center" show-overflow-tooltip>
                                <template slot-scope="scope">
                                    <span>{{ scope.row.parameterItemName }}</span>
                                </template>
                            </el-table-column>
                            <el-table-column prop="parameterValue" :label="$i18n.t('common.Parametervalue')" align="center" show-overflow-tooltip>
                            </el-table-column>
                            <el-table-column prop="parameterType" :label="$i18n.t('common.ParameterProperties')" align="center" show-overflow-tooltip>
                                <template slot-scope="scope">
                                    <span v-if="scope.row.parameterType==null"></span>
                                    <span v-else-if="scope.row.parameterType==0">{{$t('common.SearchParameters')}}</span>
                                    <span v-else-if="scope.row.parameterType==1">{{$t('common.ImportantParameters')}}</span>
                                    <span v-else-if="scope.row.parameterType==2">{{$t('common.SearchParameters')}}，{{$t('common.ImportantParameters')}}</span>
                                </template>
                            </el-table-column>
                        </el-table>
                    </el-col>
                </el-row>
            </el-tab-pane>
        </el-tabs>
        <span slot="footer" class="dialog-footer">
			<el-button style="width: 20%;" @click="closeDetailsDialog">{{ $i18n.t('common.cancel') }}</el-button>
			<el-button v-if="this.activeName != 5" style="width: 20%;" type="primary"
                       @click="onSubmit">{{ $i18n.t('common.confirm1') }}</el-button>
		</span>
        <el-dialog :title="$i18n.t('common.Synchronizetootherlanguages')" :visible.sync="synVisible" @close="closeSynVisible" width="35%" append-to-body>
			<div v-if="synVisible">
				<div v-if="checkedAllLang.length>0">
					<el-checkbox :indeterminate="isIndeterminate" v-model="checkAll" @change="handleCheckAllChange">{{ $i18n.t('common.SelectAll') }}
					</el-checkbox>
					<div style="margin: 15px 0;"></div>
					<el-checkbox-group v-model="checkedLang" @change="handleCheckedCitiesChange">
						<el-checkbox v-for="item in checkedAllLang" :key="item.code" :name="item.id"
                                 :label="item.name">{{item.name}}</el-checkbox>
					</el-checkbox-group>
				</div>
				<span slot="footer" class="dialog-footer">
					<el-button style="width: 20%;" @click="closeSynVisible">{{ $i18n.t('common.cancel') }}</el-button>
					<el-button style="width: 20%;" type="primary" @click="synchroLang">{{ $i18n.t('common.confirm1') }}</el-button>
				</span>
			</div>           
        </el-dialog>
        <el-dialog :title="$i18n.t('common.Selectfrompicturelibrary')" :visible.sync="galleryVisible" @close="closeDialogGallery" width="35%" append-to-body>
			<div v-if="galleryVisible">
                <div style="display: flex;justify-content: space-between;align-items: center;">
                    <el-select v-model="galleryLang" @change="getGalleryList" clearable class="input-width" :placeholder="$i18n.t('common.PictureLanguage')">
                        <el-option v-for="item in languageList" :key="item.code" :label="item.name"  
                            :value="item.code">
                        </el-option>
                    </el-select>
                    <el-button type="success" size="small" :loading="handleGalleryLoading" @click="handleGallery">{{$t('common.Select')}}</el-button>
                </div>
				<el-table v-loading="galleryLoading" ref="galleryTable" row-key="goodsImageId" :data="galleryList" style="width: 100%;margin-top: 10px;" height="400" border
                                  :header-cell-style="{ background: '#EAEEF9', color: '#32353a' }">
                    <el-table-column
                        fixed
                        type="selection"
                        width="55">
                    </el-table-column>              
                    <el-table-column prop="parameterItemName" :label="$i18n.t('common.Pictures_Videos')" align="center" show-overflow-tooltip>
                        <template slot-scope="scope">
<!--                            <el-image style="width: 100px; height: 100px" v-if="scope.row.imageTypeCode=='BigImg' && scope.row.imageUrl"-->
<!--                                :src="ossHost+scope.row.imageUrl" fit="fill"></el-image>-->
                            <listImage v-if="scope.row.imageTypeCode=='BigImg'" :imgUrl="scope.row.imageUrl"></listImage>

                            <video class='float-l' style="width: 100px; height: 100px" v-else-if="scope.row.imageTypeCode=='Video' && scope.row.imageUrl"
                            :src='ossHost+scope.row.imageUrl'
                                controls='controls'>
                                {{$t('common.YourBrowserDoesNotSupportVideoPlayback')}}
                            </video>	
                        </template>
                    </el-table-column>
                    <el-table-column prop="goodsImageId" :label="$i18n.t('common.ImageID')" align="center" show-overflow-tooltip>
                    </el-table-column>
                    <el-table-column prop="lang" :label="$i18n.t('common.language')" align="center" show-overflow-tooltip>
                    </el-table-column>
                    <el-table-column prop="parameterValue" :label="$i18n.t('common.size')" align="center" show-overflow-tooltip>
                        <template slot-scope="scope">
                            <div v-if="scope.row.fileWidth && scope.row.fileHeight">
                                <span>{{scope.row.fileWidth}}</span>*<span>{{scope.row.fileHeight}}</span>	
                            </div>                   
                        </template>
                    </el-table-column>        
                </el-table>		
			</div>           
        </el-dialog>

        <el-dialog :title="$i18n.t('common.Uploadimages')" :visible.sync="uploadImgVisible" v-if="uploadImgVisible" width="35%" append-to-body>
            <multiple-oss-upload ref="multipleOssUpload" :limit="20" @uploadAllSuccess="uploadAllSuccess"></multiple-oss-upload>
        </el-dialog>

    </div>
</template>
<script>
import wangEditer from '@/components/common/WangEditer/index';
import OssUpload from '@/components/common/OssUpload.vue';
import scene from "@/utils/scene";
import listImage from '@/components/common/ListImage.vue';
import dictionaryUtil from '@/utils/dictionaryUtil';
import multipleOssUpload from '@/components/common/MultipleOssUpload.vue';

export default {
    components: {
        OssUpload,
        wangEditer,
        listImage,
        multipleOssUpload
    },
    props: {
        formData: {
            type: Object,
            default: () => {
                return {}
            }
        },
        bigImg: {
            //大图表格数据
            type: Array,
            default: () => {
                return []
            }
        },
        languageList: {
            type: Array,
            default: () => {
                return []
            }
        },
        countryList: {
            type: Array,
            default: () => {
                return []
            }
        },
    },
    data() {
        return {
            handleGalleryLoading:false,
            galleryLoading:false,
            // 图片库列表
            galleryList:[],
            // 图片库选中的语言
            galleryLang:'',
            percent:0,
            checkbox: true,
            bigImgList: this.bigImg,
            currCountryCode: 'zh',
            defaultProps: {
                label: function (data, node) {
                    return data.title1
                },
                id: function (data, node) {
                    return data.parameterGroupId
                },
            },
            ossHost: process.env.VUE_APP_OSS_Head,
            //树选中的
            checkedKeys: [],
            wangEditerContent: [],
            currLangName: "",
            parameterSearchVal: "",
            allImgList: [],
            newImgList: [],
            rowIndex: null,
            isShow: false,
            //是否是替换图片
            isReplace: false,
            //阿里云图片信息
            imgInfo: {},
            //阿里云视频信息
            vInfo: {},
            currGroupLeft: {},
            //seoList
            //选种列表
            selectionList: [],
            goodsSeoList: [],
            groupleft: [],
            groupright: [],
            grouprightItem: [],
            grouprightItemCopy: [],
            formLabelWidth: '120px',
            activeName: '1',
            mitName: "1",
            submitName: '0',
            activeName2: "0",
            activeName3: "0",
            activeName4: "0",
            synVisible: false,
            galleryVisible:false,
            checkAll: true,
            content: '',
            editorOption: {
                placeholder: ''
            },
			checkedAllLang:[],//默认选择的所有的语言
            checkedLang: [],//选择的语言
            isIndeterminate: false,
            uploadImgVisible: false,
            seoFromList: [],
            //富文本
            wangEditerInfo: {
                goodsId: this.formData.goodsId,
                content: "",
                useContent: "",
                lang: ""
            },
            rightParamterList: [],
            wangEditerList: [],
            rules: {
                name: [{
                    required: true,
                    message: this.$i18n.t('common.Pleaseentertheproducttitle'),
                    trigger: 'blur'
                }]
            }
        };
    },
    watch: {
        imgInfo: {
            handler(newVal) {
                if (newVal) {
                    // let lang = ""
                    // let zi = 'com'
                    // if (newVal && newVal.imageUrl.indexOf(zi) !== -1) {
                    //     newVal.imageUrl = newVal.imageUrl.substr(newVal.imageUrl.lastIndexOf(zi) + zi.length)
                    // }
                    // let rowIndex = this.rowIndex
                    // this.languageList.forEach((item, index) => {
                    //     if (this.activeName2 == item.id) {
                    //         lang = item.code
                    //     }
                    // })
                    // newVal.lang = lang
                    // newVal.goodsId = this.formData.goodsId
                    // newVal.isNew = true
                    // newVal.mainImg = false
                    // let imglist = ['png', 'jpg', 'jpeg', 'bmp', 'gif'];
                    // let videolist = ['mp4', 'm2v', 'mkv'];
                    // let index1 = newVal.imageUrl.lastIndexOf(".");
                    // let str = newVal.imageUrl.substring(index1 + 1, newVal.imageUrl.length);
                    // let type = "图片"
                    // let imageTypeCode = ""
                    // if (imglist.indexOf(str) !== -1) {
                    //     type = "图片"
                    //     newVal.imageTypeCode = "BigImg"
                    // } else if (videolist.indexOf(str) !== -1) {
                    //     type = "视频"
                    //     newVal.imageTypeCode = "Video"
                    // }
                    // if (this.isReplace == false) {
                    //     this.bigImgList.push(newVal)
                    //     this.allImgList.push(newVal)
                    //     this.newImgList.push(newVal)
                    // } else {
                    //     this.bigImgList[rowIndex].imageUrl = newVal.imageUrl
                    //     this.allImgList.forEach((item, index) => {
                    //         if (item.imageMD5 == newVal.imageMD5) {
                    //             this.allImgList[index].imageUrl = newVal.imageUrl
                    //         }
                    //     })
                    // }
                    //上面的代码，提取成一个方法
                    this.handleFileData(newVal);
                }
            }
        },
        deep: true
    },
    computed: {
        scene() {
            return scene
        }
    },
    methods: {
        initData() {
            this.isShow = false
            this.languageList.forEach((item, index) => {
                item.id = index.toString()
            })

            this.currLangName = this.languageList[0].name
            let p1=this.getGoodsSeo(0)
            let p2=this.getGoodsbjimage(0)
            let p3=this.getGoodWangEditer()
            let p4=this.getVarAdGroupRight()
            Promise.all([p1,p2,p3,p4]).then((result) => {
                console.log(result)
                this.isShow = true               //['成功了', 'success']
            }).catch((error) => {
                console.log(error)
            })
        },
        uploadAllSuccess(fileDataList) {

            fileDataList.forEach(f => {

                this.handleFileData(f);
            })

            this.uploadImgVisible = false;
        },
        handleFileData(newVal) {
            let lang = ""
            let zi = 'com'
            if (newVal && newVal.imageUrl.indexOf(zi) !== -1) {
                newVal.imageUrl = newVal.imageUrl.substr(newVal.imageUrl.lastIndexOf(zi) + zi.length)
            }
            let rowIndex = this.rowIndex
            this.languageList.forEach((item, index) => {
                if (this.activeName2 == item.id) {
                    lang = item.code
                }
            })
            newVal.lang = lang
            newVal.goodsId = this.formData.goodsId
            newVal.isNew = true
            newVal.mainImg = false
            let imglist = ['png', 'jpg', 'jpeg', 'bmp', 'gif'];
            let videolist = ['mp4', 'm2v', 'mkv'];
            let index1 = newVal.imageUrl.lastIndexOf(".");
            let str = newVal.imageUrl.substring(index1 + 1, newVal.imageUrl.length);
            let type = "图片"
            let imageTypeCode = ""
            if (imglist.indexOf(str) !== -1) {
                type = "图片"
                newVal.imageTypeCode = "BigImg"
            } else if (videolist.indexOf(str) !== -1) {
                type = "视频"
                newVal.imageTypeCode = "Video"
            }
            if (this.isReplace == false) {
                this.bigImgList.push(newVal)
                this.allImgList.push(newVal)
                this.newImgList.push(newVal)
            } else {
                this.bigImgList[rowIndex].imageUrl = newVal.imageUrl
                this.allImgList.forEach((item, index) => {
                    if (item.imageMD5 == newVal.imageMD5) {
                        this.allImgList[index].imageUrl = newVal.imageUrl
                    }
                })
            }
        },
        uploadImage() {

            this.isReplace = false;
            this.uploadImgVisible = true;
        },
        putGoodsbjSort(row) {
            if (!row.isNew) {
                let imglist = ['png', 'jpg', 'jpeg', 'bmp', 'gif', 'PNG', 'JPG', 'JPEG', 'BMP', 'GIF'];
                let videolist = ['mp4', 'm2v', 'mkv', 'MP4', 'M2V', 'MKV'];
                let index1 = row.imageUrl.lastIndexOf(".");
                let str = row.imageUrl.substring(index1 + 1, row.imageUrl.length);
                let type = "图片"
                let imageTypeCode = ""
                if (row.imageTypeCode) {
                    // 因图片后缀导致接口报错 云测bug：商城-0927 imglist 之前都是小写，如果后缀大写就不匹配 ，还有本接口应该是修改排序，理论上不应该修改imageTypeCode类型
                    imageTypeCode = row.imageTypeCode;
                } else {
                    if (imglist.indexOf(str) !== -1) {
                        type = "图片"
                        imageTypeCode = "BigImg"
                    } else if (videolist.indexOf(str) !== -1) {
                        type = "视频"
                        imageTypeCode = "Video"
                    }
                }
                this.$store
                        .dispatch("putGoodsbjSort", {
                            goodsImageId: row.goodsImageId,
                            goodsImagePrivatelyId: row.goodsImagePrivatelyId,
                            relaType: row.goodsImagePrivatelyId,
                            goodsId: this.formData.goodsId,
                            sort: row.sort,
                            md5: row.imageMD5,
                            langCode: this.languageList[this.activeName2].code,
                            imageTypeCode: imageTypeCode
                        })
                        .then((res) => {
                            this.getGoodsbjimage(this.submitName)
                        });
            }

        },
        hideNull(val) {
            this.checkbox = val
            this.handleGroupLeft(this.currGroupLeft)
            this.grouprightItemCopy = JSON.parse(JSON.stringify(this.grouprightItem))
            if (val == true) {
                if (this.grouprightItem && this.grouprightItem.length > 0) {
                    this.grouprightItem = this.grouprightItem.filter((item) => {
                        if (item.parameterValue != null && item.parameterValue) {
                            return item
                        }
                    })
                }
            } else {
                this.grouprightItem = this.grouprightItemCopy
            }
        },
        //强行刷新
        forceUpdate(e) {
            this.$forceUpdate()
        },
        //更新主图
        putGoodsbjMainimage(row, index) {
            let tips = row.mainImg ? this.$i18n.t("common.CancelSettingAsTheMainImage"):this.$i18n.t("common.SetAsTheMainImage")
            let imglist = ['png', 'jpg', 'jpeg', 'bmp', 'gif'];
            let videolist = ['mp4', 'm2v', 'mkv'];
            let index1 = row.imageUrl.lastIndexOf(".");
            let str = row.imageUrl.substring(index1 + 1, row.imageUrl.length);
            let type = "图片"
            let imageTypeCode = ""
            if (imglist.indexOf(str) !== -1) {
                type = "图片"
                imageTypeCode = "BigImg"
            } else if (videolist.indexOf(str) !== -1) {
                type = "视频"
                imageTypeCode = "Video"
            }
            // 因为设置主图不是 使用v-model  所以入参开关值不会变，   row.mainImg 还是等于false
            // if(row.isNew && row.mainImg){
            if(row.isNew && !row.mainImg){
               this.allImgList.forEach((item, index) => {
                    if(row.imageMD5 == item.imageMD5){
                        item.showed=true;
                    }
                })
            }
            const data = {
                goodsImageId: row.goodsImageId,
                goodsImagePrivatelyId: row.goodsImagePrivatelyId,
                relaType: row.goodsImagePrivatelyId,
                goodsId: this.formData.goodsId,
                main: !row.mainImg,
                imageMD5: row.imageMD5,
                lang: this.languageList[this.activeName2].code,
                imageTypeCode: row.isNew ? imageTypeCode : row.imageTypeCode
            }

            if (!row.isNew) {
                this.$confirm(tips, {
                    confirmButtonText: this.$i18n.t("common.confirm"),
                    cancelButtonText: this.$i18n.t("common.cancel"),
                    type: 'warning'
                }).then(() => {
                    this.$store
                            .dispatch("putGoodsbjMainimage", data).then(res => {
                            this.$message({
                                type: 'success',
                                message: this.$i18n.t("common.SetSuccessfully")
                            });
                            if (!row.mainImg) {
                                this.putGoodsbjshow(row, index, row.mainImg)
                            } else {
                                this.getGoodsbjimage(this.submitName)
                            }
                    });
                }).catch(() => {
                });
            } else {
                this.bigImgList[index].mainImg = !row.mainImg
                this.bigImgList[index].goodsImageId = 0
                this.bigImgList[index].goodsImagePrivatelyId = 0
                this.bigImgList[index].relaType = 0
                // this.bigImgList[index].mainImg=!row.mainImg
                this.$set(this.bigImgList, index, this.bigImgList[index])
                this.$forceUpdate()
            }
        },
        // 表格多选框 选中数据
        handleSelectionChange(selection) {
            this.selectionList = selection;
        },
		openSynchroLang(){
			let arr=[]
			this.checkedLang=[]
			this.$nextTick(() => {
				this.languageList.forEach((item, index) => {
					if(this.currLangName!=item.name){
						arr.push(item)
						this.checkedLang.push(item.name)
					}
				})
				this.checkedAllLang=JSON.parse(JSON.stringify(arr))
				this.synVisible=true;
			})
		},
        //打开图片库存
        openGallery(){
            this.galleryVisible=true;
            this.getGalleryList()
        },
        closeDialogGallery(){
            this.galleryVisible=false;
            this.galleryList=[]
            this.galleryLang=''
            this.galleryLoading=false
            this.handleGalleryLoading=false
        },
        //获取图片库存列表
        getGalleryList(val){
            // console.log('getGalleryList',val)
            if(val == ''){
                val = null
            }
            this.galleryLoading=true
            this.$store
                .dispatch("getGalleryList", {'goodsId':this.formData.goodsId,'lang':val, 'sku':this.formData.sku}).then(res => {
                    this.galleryList=res
                    setTimeout(() => {
                        this.galleryLoading=false
                    }, 300);
                }).catch((err)=>{
                    this.galleryLoading=false;
                });
        },
        //选中图片库存图片
        handleGallery(){
            if(this.$refs.galleryTable && this.$refs.galleryTable.selection.length>0){
                let result = this.$refs.galleryTable.selection.filter(item => this.bigImgList.some(ele=> ele.imageMD5 == item.imageMD5))
                if(result.length>0){
                    this.$message({
                        type: 'error',
                        message: this.$i18n.t("common.CannotSubmitImagesOrVideosThatAlreadyExist")
                    });
                    return;
                }
                this.handleGalleryLoading=true
                let query={
                    'imageIds':this.$refs.galleryTable.selection.map((item)=>{
                        return item.goodsImageId
                    }),
                    'lang':this.currCountryCode,
                    'goodsId': this.formData.goodsId
                }
                this.$store
                .dispatch("postImagecheck",query).then(res => {
                    setTimeout(() => {
                        this.closeDialogGallery()
                        this.getGoodsbjimage(this.submitName)
                        this.handleGalleryLoading=false
                        this.$message({
                            message: this.$i18n.t("common.Selectionsuccessful"),
                            type: 'success',
                            duration: 1000
                        });
                    }, 300);
                }).catch((err)=>{
                    this.closeDialogGallery()
                    this.handleGalleryLoading=false;
                });
            }else{
                this.$message({
                        type: 'error',
                        message: this.$i18n.t("common.Selectapictureorvideo")
                });
                return;
            }
        },
        //同步到其他语言
        synchroLang() {
            this.$confirm(this.$i18n.t("common.SynchronizeAnyImagesToOtherLanguages", {Any: this.currLangName}), this.$i18n.t("common.prompt"), {
                confirmButtonText: this.$i18n.t("common.confirm"),
                cancelButtonText: this.$i18n.t("common.cancel"),
                type: 'warning'
            }).then(() => {
                if(!this.selectionList || this.selectionList.length <= 0){
                    this.$message({
                        type: 'error',
                        message: this.$i18n.t("common.PleaseSelectTheProductToBeSynchronizedFirst")
                    });
                    return;
                }
				let langs = []
                this.selectionList.forEach((item, index) => {
                    if (item.isNew) {
                        let imageTypeCode = ''
                        if (item.type == '图片') {
                            imageTypeCode = "BigImg"
                        } else if (item.type == '视频') {
                            imageTypeCode = "Video"
                        }
                        this.selectionList[index].imageTypeCode = imageTypeCode
                    }
                })
                let arr = this.selectionList.filter((item, index) => {
                    if (item.lang == this.currCountryCode) {
                        return item
                    }
                })
				this.checkedAllLang.forEach((item)=>{
					if(this.checkedLang.indexOf(item.name) != -1) {
						langs.push(item.code)
					}
				})
				const data={
					images: arr,
					langs
				}
                this.$store
                    .dispatch("synchroLang", data).then(res => {
                    this.$message({
                        message: this.$i18n.t("common.Successfullysubmitted"),
                        type: 'success',
                        duration: 1000
                    });
					this.synVisible=false;
                    this.handleSearchList()
                    this.getGoodsbjimage(this.submitName)
                });
            });
        },
        //批量显示
        handleIsShow(type) {
            let tips = type == 1 ? this.$i18n.t("common.Whethertodisplayall")+`?` : this.$i18n.t("common.Areallclosed")+`?`
            if (this.selectionList.length <= 0) {
                this.$message({
                    message: this.$i18n.t("common.PleaseSelectTheProductFirst"),
                    type: 'error',
                    duration: 1000
                });
                return
            }
            this.$confirm(tips, this.$i18n.t("common.Prompt"), {
                confirmButtonText: this.$i18n.t("common.confirm"),
                cancelButtonText: this.$i18n.t("common.cancel"),
                type: 'warning'
            }).then(() => {
                this.selectionList.forEach((item, index) => {
                    this.selectionList[index].showed = type == 1 ? true : false
                    if (item.isNew) {
                        let imageTypeCode = ''
                        if (item.type == '图片') {
                            imageTypeCode = "BigImg"
                        } else if (item.type == '视频') {
                            imageTypeCode = "Video"
                        }
                        this.selectionList[index].imageTypeCode = imageTypeCode
                    }
                })
                this.$store
                        .dispatch("putGoodsbjAdimage", this.selectionList).then(res => {
                    this.$message({
                        message: this.$i18n.t("common.Successfullysubmitted"),
                        type: 'success',
                        duration: 1000
                    });
                    this.handleRemoveNewImgList(this.selectionList)
                    this.getGoodsbjimage(this.submitName)
                    this.handleSearchList()
                });
            });
        },
        handleRemoveNewImgList(selectionList){
            // console.log('handleRemoveNewImgList',selectionList)
            selectionList.forEach(item=>{
                if(item.isNew){
                    let index = -1;
                    this.newImgList.forEach((e,i)=>{
                        if(item.updateTime == e.updateTime && item.imageMD5 == e.imageMD5){
                            index = i;
                        } 
                    })
                    // console.log('handleRemoveNewImgList',index)
                    if(index>=0){
                        this.newImgList.splice(index,1)
                    }    
                }   
            })
        },
        //批量删除
        handleDelete(){
            if (this.selectionList.length <= 0) {
                this.$message({
                    message: this.$i18n.t("common.PleaseSelectTheProductFirst"),
                    type: 'error',
                    duration: 1000
                });
                return
            }
            this.$confirm(this.$i18n.t("common.Confirmdeletionoftheselectedpicture_video")+'?',this.$i18n.t("common.prompt"), {
                confirmButtonText: this.$i18n.t("common.confirm"),
                cancelButtonText: this.$i18n.t("common.cancel"),
                type: 'warning'
            }).then(() => {

                // 过滤新图
                var removeArr = [];
                var removeNewArr = [];
                this.selectionList.forEach(item=>{

                    if(item.isNew){
                        removeNewArr.push(item)
                    }else{
                        removeArr.push(item)
                    }
                })

                if(removeNewArr.length > 0){
                    removeNewArr.forEach(item=>{
                        if(item.isNew){
                            let index = -1;
                            this.newImgList.forEach((e,i)=>{
                                if(item.updateTime == e.updateTime && item.imageMD5 == e.imageMD5){
                                    index = i;
                                } 
                            })
                            if(index>=0){
                                this.newImgList.splice(index,1)
                            }
                            
                            let index2 = -1;
                            this.bigImgList.forEach((e,i)=>{
                                if(item.updateTime == e.updateTime && item.imageMD5 == e.imageMD5){
                                    index2 = i;
                                } 
                            })
                            if(index2>=0){
                                this.bigImgList.splice(index2,1)
                            }

                            let index3 = -1;
                            this.allImgList.forEach((e,i)=>{
                                if(item.updateTime == e.updateTime && item.imageMD5 == e.imageMD5){
                                    index3 = i;
                                } 
                            })
                            if(index3>=0){
                                this.allImgList.splice(index3,1)
                            }
                        }   
                    })
                    
                    if(removeArr.length <= 0){
                        this.$message({
                            message: this.$i18n.t("common.Successfullydeleted"),
                            type: 'success',
                            duration: 1000
                        });
                    }
                }

                if(removeArr.length>0){    
                    this.$store
                        .dispatch("putImageDel",{'goodsId':this.formData.goodsId,'list':removeArr}).then(res => {
                        this.$message({
                            message: this.$i18n.t("common.Successfullydeleted"),
                            type: 'success',
                            duration: 1000
                        });
                        this.getGoodsbjimage(this.submitName)
                    });
                }
            });
        },
        //是否显示
        putGoodsbjshow(row, index, mainImg = true) {
            if (row.mainImg == true) {
                this.$message({
                    type: 'error',
                    message: this.$i18n.t("common.TheMainPictureCannotBeTurnedOff")
                });
                return
            }
            let tips = row.showed ? this.$i18n.t("common.CancelDisplay") : this.$i18n.t("common.Displayornot")+`?`
            let imglist = ['png', 'jpg', 'jpeg', 'bmp', 'gif'];
            let videolist = ['mp4', 'm2v', 'mkv'];
            let index1 = row.imageUrl.lastIndexOf(".");
            let str = row.imageUrl.substring(index1 + 1, row.imageUrl.length);
            let type = "图片"
            let imageTypeCode = ""
            if (imglist.indexOf(str) !== -1) {
                type = "图片"
                imageTypeCode = "BigImg"
            } else if (videolist.indexOf(str) !== -1) {
                type = "视频"
                imageTypeCode = "Video"
            }
            if (!row.isNew) {
                if (mainImg == true) {
                    this.$confirm(tips, {
                        confirmButtonText: this.$i18n.t("common.confirm"),
                        cancelButtonText: this.$i18n.t("common.cancel"),
                        type: 'warning'
                    }).then(() => {
                        this.$store
                                .dispatch("putGoodsbjshow", {
                                    goodsImageId: row.goodsImageId,
                                    goodsImagePrivatelyId: row.goodsImagePrivatelyId,
                                    relaType: row.goodsImagePrivatelyId,
                                    goodsId: this.formData.goodsId,
                                    showed: !row.showed,
                                    imageMD5: row.imageMD5,
                                    lang: this.languageList[this.activeName2].code,
                                    imageTypeCode: row.isNew ? imageTypeCode : row.imageTypeCode
                                }).then(res => {
                            this.$message({
                                type: 'success',
                                message: this.$i18n.t("common.SetSuccessfully")
                            });
                            this.getGoodsbjimage(this.submitName)
                        });
                    }).catch(() => {
                    });
                } else {
                    this.$store
                            .dispatch("putGoodsbjshow", {
                                goodsImageId: row.goodsImageId,
                                goodsImagePrivatelyId: row.goodsImagePrivatelyId,
                                relaType: row.goodsImagePrivatelyId,
                                goodsId: this.formData.goodsId,
                                showed: true,
                                imageMD5: row.imageMD5,
                                lang: this.languageList[this.activeName2].code,
                                imageTypeCode: row.isNew ? imageTypeCode : row.imageTypeCode
                            }).then(res => {
                        this.getGoodsbjimage(this.submitName)
                    }).catch(() => {
                    });
                }
            } else {
                this.bigImgList[index].goodsImageId = 0
                this.bigImgList[index].goodsImagePrivatelyId = 0
                this.bigImgList[index].relaType = 0
                this.bigImgList[index].showed = !row.showed
                this.$set(this.bigImgList, index, this.bigImgList[index])
                this.$forceUpdate()
            }
        },
        //参数组左
        getVarAdGroupleft() {
            this.$store
                    .dispatch('getVarAdGroupleft', {})
                    .then(res => {
                        this.groupleft = res
                        this.groupleft.forEach((item, index) => {
                            this.groupleft[index].title1 = this.groupleft[index].title + '(' + this.groupright.filter((item2) => {
                                return item.parameterGroupId == item2.parameterGroupId;
                            }).length + ')'
                        });
                    });
        },
        //参数右边
        getVarAdGroupRight() {
            let success = new Promise((resolve, reject) => {
            let arr = [this.formData.goodsId]
            this.$store
                    .dispatch('getVarAdGroupRight', arr)
                    .then(res => {
                        this.groupright = res
                        this.getVarAdGroupleft()
                    });
                resolve('success')
            })
            return success
        },
        deleteGoodsPage(row, index) {
            this.$confirm(this.$i18n.t("common.ConfirmWhetherToDelete"), this.$i18n.t("common.prompt"), {
                confirmButtonText: this.$i18n.t("common.confirm"),
                cancelButtonText: this.$i18n.t("common.cancel"),
                type: 'warning'
            })
                    .then(() => {
                        let imglist = ['png', 'jpg', 'jpeg', 'bmp', 'gif'];
                        let videolist = ['mp4', 'm2v', 'mkv'];
                        let index1 = row.imageUrl.lastIndexOf(".");
                        let str = row.imageUrl.substring(index1 + 1, row.imageUrl.length);
                        let type = "图片"
                        let imageTypeCode = ""
                        if (!row.isNew) {
                            if (imglist.indexOf(str) !== -1) {
                                type = "图片"
                                imageTypeCode = "BigImg"
                            } else if (videolist.indexOf(str) !== -1) {
                                type = "视频"
                                imageTypeCode = "Video"
                            }
                        }
                        if (row.isNew) {
                            this.allImgList.forEach((item, index) => {
                                if (row.updateTime == item.updateTime && row.imageMD5 == item.imageMD5) {
                                    this.allImgList.splice(index, 1)
                                }
                            })
                            this.bigImgList.splice(index, 1)

                            this.newImgList.forEach((e,i)=>{
                                if(row.updateTime == e.updateTime && row.imageMD5 == e.imageMD5){                                 
                                    this.newImgList.splice(i, 1)
                                } 
                            })

                        } else {
                            this.$store
                                    .dispatch('deleteGoodsPage', {
                                        goodsImageId: row.goodsImageId,
                                        goodsImagePrivatelyId: row.goodsImagePrivatelyId,
                                        relaType: row.goodsImagePrivatelyId,
                                        goodsId: row.goodsId,
                                        imageMD5: row.imageMD5,
                                        lang: row.lang,
                                        imageTypeCode: row.isNew ? imageTypeCode : row.imageTypeCode
                                    })
                                    .then(res => {
                                        this.getGoodsbjimage(this.submitName)
                                    });
                        }
                    })
                    .catch(() => {
                    });

        },

        handleGroupLeft(data) {
            //当前选中的树
            this.currGroupLeft = data
            this.parameterGroupId = data.parameterGroupId
            this.grouprightItem = []
            this.groupright.forEach((item, index) => {
                if (item.parameterGroupId == data.parameterGroupId) {
                    this.grouprightItem.push(item)
                }
            })

            this.rightParamterList = [];
            if(data.parameterItems && data.parameterItems.length>0){
                data.parameterItems.forEach((item2, index2) => {
                    var isFind = false;
                    this.grouprightItem.forEach((item, index) => {
                        if (item.parameterId == item2.parameterItemId) {
                            var temp = {
                                parameterItemName: item2.title + ' ' + item2.source,
                                parameterValue: item.parameterValue,
                                parameterType: item.parameterType
                            };
                            this.rightParamterList.push(temp)
                            isFind = true;
                        }
                    })
                    if (!isFind && !this.checkbox) {
                        var temp = {
                            parameterItemName: item2.title + ' ' + item2.source,
                            parameterValue: '',
                            parameterType: ''
                        };
                        this.rightParamterList.push(temp)
                    }
                });
            }
        },
        getGoodsSeo(type) {
            let success = new Promise((resolve, reject) => {
                resolve('成功了')
                this.$store
                    .dispatch("getGoodsSeo", {
                        goodsId: this.formData.goodsId
                    }).then(res => {
                    //赋值多语言
                    res.forEach(e => {

                        this.seoFromList.forEach(lang => {
                            if (e.lang == lang.lang) {
                                this.$set(lang, 'seoTitle', e.seoTitle);
                                this.$set(lang, 'seoKeyword', e.seoKeyword);
                                this.$set(lang, 'seoDescribe', e.seoDescribe);
                                this.$set(lang, 'seoUrlParameter', e.seoUrlParameter);
                            }
                        })
                    })
                });
            })
            return success
        },
        getGoodsbjimage(type) {
            let success = new Promise((resolve, reject) => {
            this.$store
                    .dispatch("getGoodsbjimage", {
                        goodsId: this.formData.goodsId
                    }).then(res => {
                let arr = []
                let typeIndex = Number(type)
                let imglist = ['png', 'jpg', 'jpeg', 'bmp', 'gif'];
                let videolist = ['mp4', 'm2v', 'mkv'];
                res.forEach((item, index) => {
                    if (item.imageUrl) {
                        let index1 = item.imageUrl.lastIndexOf(".");
                        let str = item.imageUrl.substring(index1 + 1, item.imageUrl.length);
                        let type = "图片"
                        let imageTypeCode = ""
                        if (imglist.indexOf(str) !== -1) {
                            type = "图片"
                            imageTypeCode = "BigImg"
                        } else if (videolist.indexOf(str) !== -1) {
                            type = "视频"
                            imageTypeCode = "Video"
                        }
                        item.type = type
                        if (item.isNew) {
                            item.imageTypeCode = imageTypeCode
                        }
                    }
                    if (item.lang) {
                        if (item.lang == this.languageList[typeIndex].code) {
                            arr.push(item)
                        }
                    }

                })
                arr = arr.sort((a, b) => {
                    return b.sort - a.sort
                })
                this.bigImgList = arr

                this.allImgList = res
                //切换标签保存新数据
                if (this.newImgList.length > 0) {
                    this.newImgList.forEach((item, index) => {
                        this.allImgList.push(this.newImgList[index])
                        if (item.lang == this.languageList[typeIndex].code) {
                            this.bigImgList.push(this.newImgList[index])
                        }
                    })
                }
            });
            resolve('success')
            })
            return success
        },
        getGoodWangEditer() {
            let success = new Promise((resolve, reject) => {
            this.$store
                    .dispatch("getGoodWangEditer", {
                        goodsId: this.formData.goodsId
                    }).then(res => {
                //y代表双引号
                //尾号2的代表替换连接不要/
                let strB2b = "src='b2b";
                let strB2b2 = "src='/b2b";
                let strB2B = "src='B2B";
                let strB2B2 = "src='/B2B";
                let strB2by = `src="b2b`;
                let strB2by2 = `src="/b2b`;
                let strB2bY = `src="B2B`;
                let strB2bY2 = `src="/B2B`;

                let strB2c = "src='b2c";
                let strB2c2 = "src='/b2c";
                let strB2C = "src='B2C";
                let strB2C2 = "src='/B2C";
                let strB2cy = `src="b2c`;
                let strB2cy2 = `src="/b2c`;
                let strB2cY = `src="B2C`;
                let strB2cY2 = `src="/B2C`;

                let strArc = "src='arc";
                let strArc2 = "src='/arc";
                let strARC = "src='ARC";
                let strARC2 = "src='/ARC";
                let strArcy = `src="arc`;
                let strArcy2 = `src="/arc`;
                let strArcY = `src="ARC`;
                let strArcY2 = `src="/ARC`;


                let strArc_data = "src='arc_data_center";
                let strArc_data2 = "src='/arc_data_center";
                let strArc_datay = `src="arc_data_center`;
                let strArc_datay2 = `src="/arc_data_center`;

                //过滤租户对应的语言
                let langs = dictionaryUtil.getDict('lang');
                res = res.filter(item => {
                    for (let i = 0; i < langs.length; i++) {
                        if (item.lang == langs[i].code) {
                            item.sort = i;
                            return true;
                        }
                    }
                    return false;
                }).sort((a, b) => a.sort - b.sort);

                res.forEach((item, index) => {
                    if (item && item.content) {
                        if (item.content.indexOf(strB2b) != -1) {
                            item.content = item.content.replace(RegExp(strB2b, "g"), `src='${this.ossHost}/b2b`)
                        }
                        if (item.content.indexOf(strB2b2) != -1) {
                            item.content = item.content.replace(RegExp(strB2b2, "g"), `src='${this.ossHost}b2b`)
                        }
                        if (item.content.indexOf(strB2B) != -1) {
                            item.content = item.content.replace(RegExp(strB2B, "g"), `src='${this.ossHost}/B2B`)
                        }
                        if (item.content.indexOf(strB2B2) != -1) {
                            item.content = item.content.replace(RegExp(strB2B2, "g"), `src='${this.ossHost}B2B`)
                        }
                        if (item.content.indexOf(strB2by) != -1) {
                            item.content = item.content.replace(RegExp(strB2by, "g"), `src="${this.ossHost}/b2b`)
                        }
                        if (item.content.indexOf(strB2by2) != -1) {
                            item.content = item.content.replace(RegExp(strB2by2, "g"), `src="${this.ossHost}b2b`)
                        }
                        if (item.content.indexOf(strB2bY) != -1) {
                            item.content = item.content.replace(RegExp(strB2bY, "g"), `src="${this.ossHost}/B2B`)
                        }
                        if (item.content.indexOf(strB2bY2) != -1) {
                            item.content = item.content.replace(RegExp(strB2bY2, "g"), `src="${this.ossHost}B2B`)
                        }


                        if (item.content.indexOf(strB2c) != -1) {
                            item.content = item.content.replace(RegExp(strB2c, "g"), `src='${this.ossHost}/b2c`)
                        }
                        if (item.content.indexOf(strB2c2) != -1) {
                            item.content = item.content.replace(RegExp(strB2c2, "g"), `src='${this.ossHost}b2c`)
                        }
                        if (item.content.indexOf(strB2C) != -1) {
                            item.content = item.content.replace(RegExp(strB2C, "g"), `src='${this.ossHost}/B2C`)
                        }
                        if (item.content.indexOf(strB2C2) != -1) {
                            item.content = item.content.replace(RegExp(strB2C2, "g"), `src='${this.ossHost}B2C`)
                        }
                        if (item.content.indexOf(strB2cy) != -1) {
                            item.content = item.content.replace(RegExp(strB2cy, "g"), `src="${this.ossHost}/b2c`)
                        }
                        if (item.content.indexOf(strB2cy2) != -1) {
                            item.content = item.content.replace(RegExp(strB2cy2, "g"), `src="${this.ossHost}b2c`)
                        }
                        if (item.content.indexOf(strB2cY) != -1) {
                            item.content = item.content.replace(RegExp(strB2cY, "g"), `src="${this.ossHost}/B2C`)
                        }
                        if (item.content.indexOf(strB2cY2) != -1) {
                            item.content = item.content.replace(RegExp(strB2cY2, "g"), `src="${this.ossHost}B2C`)
                        }

                        if (item.content.indexOf(strArc) != -1) {
                            item.content = item.content.replace(RegExp(strArc, "g"), `src='${this.ossHost}/arc`)
                        }
                        if (item.content.indexOf(strArc2) != -1) {
                            item.content = item.content.replace(RegExp(strArc2, "g"), `src='${this.ossHost}arc`)
                        }
                        if (item.content.indexOf(strARC) != -1) {
                            item.content = item.content.replace(RegExp(strARC, "g"), `src='${this.ossHost}/ARC`)
                        }
                        if (item.content.indexOf(strARC2) != -1) {
                            item.content = item.content.replace(RegExp(strARC2, "g"), `src='${this.ossHost}ARC`)
                        }
                        if (item.content.indexOf(strArcy) != -1) {
                            item.content = item.content.replace(RegExp(strArcy, "g"), `src="${this.ossHost}/arc`)
                        }
                        if (item.content.indexOf(strArcy2) != -1) {
                            item.content = item.content.replace(RegExp(strArcy2, "g"), `src="${this.ossHost}arc`)
                        }
                        if (item.content.indexOf(strArcY) != -1) {
                            item.content = item.content.replace(RegExp(strArcY, "g"), `src="${this.ossHost}/ARC`)
                        }
                        if (item.content.indexOf(strArcY2) != -1) {
                            item.content = item.content.replace(RegExp(strArcY2, "g"), `src="${this.ossHost}ARC`)
                        }


                        if (item.content.indexOf(strArc_data) != -1) {
                            item.content = item.content.replace(RegExp(strArc_data, "g"), `src='${this.ossHost}/arc_data_center`)
                        }
                        if (item.content.indexOf(strArc_data2) != -1) {
                            item.content = item.content.replace(RegExp(strArc_data2, "g"), `src='${this.ossHost}arc_data_center`)
                        }
                        if (item.content.indexOf(strArc_datay) != -1) {
                            item.content = item.content.replace(RegExp(strArc_datay, "g"), `src='${this.ossHost}/arc_data_center`)
                        }
                        if (item.content.indexOf(strArc_datay2) != -1) {
                            item.content = item.content.replace(RegExp(strArc_datay2, "g"), `src='${this.ossHost}arc_data_center`)
                        }
                    }
                })
                let keys=this.languageList.map((item)=>{
                    return item.code
                })
                res.forEach((item,index)=>{
                    if(keys.indexOf(item.lang) == -1){
                        res.splice(index,1)
                    }
                })
                res.forEach((item)=>{
                    this.languageList.forEach((item2)=>{
                        if(item.lang==item2.code){
                            item.name=item2.name
                        }
                    })
                })

                this.wangEditerContent=res

                this.$forceUpdate()            
            });
            resolve('success')
            })
            return success
        },
        openMainImgVisible() {
            this.$confirm(this.$i18n.t("common.ConfirmSettingThisImageAsTheMainImage")+'?', this.$i18n.t("common.prompt"), {
                confirmButtonText: this.$i18n.t('common.confirm1'),
				cancelButtonText: this.$i18n.t('common.cancel'),
                type: 'warning'
            }).then(() => {
                this.$message({
                    type: 'success',
                    message: this.$i18n.t('common.Successfullydeleted')
                });
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: this.$i18n.t('common.Cancelleddeletion')
                });
            });
        },
        openShowVisible() {
            this.$confirm(this.$i18n.t('common.ConfirmDisplayDoNotDisplay')+'?', this.$i18n.t("common.prompt"), {
                confirmButtonText: this.$i18n.t('common.confirm1'),
				cancelButtonText: this.$i18n.t('common.cancel'),
                type: 'warning'
            }).then(() => {
                this.$message({
                    type: 'success',
                    message: this.$i18n.t('common.Successfullydeleted')
                });
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: this.$i18n.t('common.Cancelleddeletion')
                });
            });
        },
        //同步其他语言
        openSynVisible() {
            this.synVisible = true
        },
        //富文本
        onEditorChange({
                           editor,
                           html,
                           text
                       }) {
            this.content = html;
        },
        handleRemove(file, fileList) {
            console.log(file, fileList);
        },
        handlePreview(file) {
            console.log(file);
        },
        handleExceed(files, fileList) {
            let total = files.length + fileList.length
            this.$message.warning(this.$i18n.t('common.TheCurrentLimitIsToSelectNum1FilesThisTimeYouHaveSelectedNum2FilesATotalOfNum3FilesHaveBeenSelected',{num1:3,num2:files.length,num3:total}));
        },
        beforeRemove(file, fileList) {
            return this.$confirm(this.$i18n.t('common.ConfirmToRemoveAny',{Any:file.name}));
        },
        handleClick(tab, event) {
            this.mitName = tab.name.toString()
        },
        handleChildClick(tab, event) {
            this.submitName = tab.index
            let $index = tab.index - 0
            this.currCountryCode = this.languageList[$index].code
            this.currLangName = this.languageList[$index].name
            if (this.mitName == 2) {
                this.getGoodsbjimage(tab.name)
            } else if (this.mitName == 4) {
                this.getGoodsSeo(tab.name)
            }
        },
		//全选
		handleCheckAllChange(val) {
			let arr=this.checkedAllLang.map((item)=>{
				return item.name
			})
			this.checkedLang = val ? arr : [];
			this.isIndeterminate = false;
		},

        handleCheckedCitiesChange(value) {
            let checkedCount = value.length;
            this.checkAll = checkedCount === this.checkedAllLang.length;
            this.isIndeterminate = checkedCount > 0 && checkedCount < this.checkedAllLang.length;
        },
        closeSynVisible(){
            this.isIndeterminate=false;
            this.checkAll=true;
            this.synVisible = false
        },
        handleUploadError(error) {
            this.$message({
                message: this.$i18n.t('common.TheTestEnvironmentCannotUploadFiles'),
                type: 'error',
                duration: 1000
            });
            // eslint-disable-next-line no-console
            console.log('error', error)
        },
        handleResponse({
                           id
                       }) {
            return 'https://s3.pstatp.com/aweme/resource/web/static/image/index/tvc-v2_30097df.mp4?id=' + id
        },
        handleAvatarSuccess(res, file) {
            this.imageUrl = URL.createObjectURL(file.raw);
        },
        updateImg(index) {
            this.rowIndex = index
            this.isReplace = true
        },
        handleIsRep() {
            this.isReplace = false
        },
        //关闭窗口
        closeDetailsDialog() {

            this.$emit("closeDetailsDialog")
        },
        handleSearchList() {
            this.$emit("handleSearchList")
        },
        //参数搜索
        postGoodsParameter() {
            if (this.parameterSearchVal) {
                this.groupleft = this.groupleft.filter((item) => {
                    if (item.title == this.parameterSearchVal) {
                        return item
                    }
                })
            } else {
                this.getVarAdGroupleft()
            }
        },
        onSubmit() {
            if (this.activeName == 1) {
                let valid = true
                let validFlag = false
                this.languageList.forEach((item, index) => {
                    this.languageList[index].goodsId = this.formData.goodsId
                    this.languageList[index].lang = this.languageList[index].code
                })
                this.languageList.forEach((item, index) => {
                    if (item.lang == 'en') {
                        if (item.title) {
                            validFlag = true
                        }
                    }
                })
                if (validFlag == false) {
                    console.log('validFlag')
                    this.$message.error(this.$i18n.t('common.PleaseEnterTheEnglishTitle'))
                    return
                }
                if (valid) {
                    this.$confirm(this.$i18n.t("common.Whethertosubmitdata"), this.$i18n.t("common.prompt"), {
                        confirmButtonText: this.$i18n.t("common.confirm"),
                        cancelButtonText: this.$i18n.t("common.cancel"),
                        type: 'warning'
                    }).then(() => {
                        this.$store
                                .dispatch("putGoodsTranslate", this.languageList).then(res => {
                            this.$message({
                                type: 'success',
                                message: this.$i18n.t('common.SubmittedSuccessfully')
                            });
                            this.closeDetailsDialog()
                            this.handleSearchList()
                            this.$emit('handleResetSearch2')
                        });
                    });
                }
            } else if (this.activeName == 2) {
                let valid = false
                let arrValid = ['en']
                if (this.allImgList.length > 0) {
                    this.allImgList.forEach((item, index) => {
                        arrValid.forEach((item2, index2) => {
                            if (item.lang == item2) {
                                valid = true
                            }
                        })
                    })
                } else {
                    this.$message({
                        message: this.$i18n.t('common.PleaseUploadTheEnglishImage'),
                        type: 'error',
                        duration: 1000
                    });
                    return
                }
                if (valid) {
                    this.$confirm(this.$i18n.t("common.Whethertosubmitdata"), this.$i18n.t("common.prompt"), {
                        confirmButtonText: this.$i18n.t("common.confirm"),
                        cancelButtonText: this.$i18n.t("common.cancel"),
                        type: 'warning'
                    }).then(() => {
                        this.allImgList.forEach((item, index) => {
                            if (item.isNew) {
                                let imageTypeCode = ''
                                if (item.type == '图片') {
                                    imageTypeCode = "BigImg"
                                } else if (item.type == '视频') {
                                    imageTypeCode = "Video"
                                }
                                this.allImgList[index].imageTypeCode = imageTypeCode
                            }
                        })
                        this.$store
                                .dispatch("putGoodsbjAdimage", this.allImgList).then(res => {
                            this.$message({
                                message: this.$i18n.t("common.Successfullysubmitted"),
                                type: 'success',
                                duration: 1000
                            });
                            this.closeDetailsDialog()
                            this.handleSearchList()
                            this.$emit('handleResetSearch2')
                        });
                    });
                } else {
                    this.$message({
                        message: this.$i18n.t('common.PleaseUploadTheEnglishImage'),
                        type: 'error',
                        duration: 1000
                    });
                    return false;
                }
            } else if (this.activeName == 3) {
                let valid = true
                if (valid) {
                    this.$confirm(this.$i18n.t("common.Whethertosubmitdata"), this.$i18n.t("common.prompt"), {
                        confirmButtonText: this.$i18n.t("common.confirm"),
                        cancelButtonText: this.$i18n.t("common.cancel"),
                        type: 'warning'
                    }).then(() => {
                        let arr = []
                        let validFlag = false
                        this.$refs['editer1'].forEach((item, index) => {
                            let wangEditerText = item.getHtml()
                            let data = {
                                goodsId: this.formData.goodsId,
                                content: wangEditerText ? wangEditerText : "",
                                lang: item.lang
                            }
                            arr.push(data)
                        })
                        console.log('arr',arr)
                        if (arr && arr.length > 0) { 
                             arr.forEach(item => {
                                if (item.lang == 'en') {
                                    if (item.content) {
                                        validFlag=true;
                                    }else{
                                        validFlag=false;
                                    }
                                }                             
                            });
                            if (validFlag == false) {
                                this.$message.error(this.$i18n.t("common.Detailedcontentofgraphicsandtext"))
                                return
                            }
                            // arr.forEach((item, index) => {
                            //     if (item.lang == 'en') {
                            //         console.log('item.lang', item)
                            //         if (item.content && item.content != '') {
                            //             validFlag = true
                            //         }
                            //     }
                            // })
                            
                        } else {
                            this.$message.error(this.$i18n.t("common.Detailedcontentofgraphicsandtext"));
                            return
                        }
                        this.$store
                                .dispatch("putGoodWangEditer", arr).then(res => {
                            this.$message({
                                message: this.$i18n.t("common.Successfullysubmitted"),
                                type: 'success',
                                duration: 1000
                            });
                            this.closeDetailsDialog()
                            this.handleSearchList()
                            this.$emit('handleResetSearch2')
                        });
                    });
                } else {
                    this.$message({
                        message: this.$i18n.t("common.Detailedcontentofgraphicsandtext"),
                        type: 'error',
                        duration: 1000
                    });
                    return false;
                }
            } else if (this.activeName == 4) {
                let valid = true
                if (valid) {
                    this.$confirm(this.$i18n.t("common.Whethertosubmitdata"), this.$i18n.t("common.prompt"), {
                        confirmButtonText: this.$i18n.t("common.confirm"),
                        cancelButtonText: this.$i18n.t("common.cancel"),
                        type: 'warning'
                    }).then(() => {
                        let arr = []
                        let index = Number(this.activeName4)
                        console.log('this.seoFromList', this.seoFromList)
                        this.$store
                                .dispatch("putGoodsSeo", this.seoFromList).then(res => {
                            this.$message({
                                message: this.$i18n.t("common.Successfullysubmitted"),
                                type: 'success',
                                duration: 1000
                            });
                            this.closeDetailsDialog()
                            this.handleSearchList()
                            this.$emit('handleResetSearch2')
                        });
                    });
                } else {
                    this.$message({
                        message: this.$i18n.t('common.FailedPleaseEnterTheProductTitleForAllSalesCountries'),
                        type: 'error',
                        duration: 1000
                    });
                    return false;
                }
            }
        }
    },
    mounted() {

        this.languageList.forEach((item, index) => {

            this.seoFromList.push({
                goodsId: this.formData.goodsId,
                seoTitle: "",
                seoKeyword: "",
                seoDescribe: "",
                seoUrlParameter: "",
                lang: item.code,
                name: item.name,
                id: item.id
            })
        })
        this.$nextTick(()=>{
            this.initData()
        })      
    }
};
</script>
<style scoped>
.input-width {
    width: 70%;
}

.dialog-footer {
    margin-top: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.tree-container {
    border: 1px solid #EBEEF5;
    padding: 20px 10px;
    min-height: 400px;
    overflow-y: scroll;
}

</style>