var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-form",
        { ref: "variantFrom", attrs: { model: _vm.formData } },
        [
          _c(
            "el-form-item",
            { attrs: { label: "", "label-width": _vm.formLabelWidth } },
            [
              _c("div", [
                _vm._v(
                  _vm._s(_vm.$i18n.t("common.CommenOnPicturesVideos")) +
                    "（" +
                    _vm._s(_vm.$i18n.t("common.PicturesVideos")) +
                    "）"
                )
              ]),
              _c("div", { staticClass: "avatar-uploader" }, [
                _c(
                  "ul",
                  {
                    staticClass: "el-upload-list el-upload-list--picture-card"
                  },
                  [
                    _vm._l(_vm.imageList, function(item, idx) {
                      return _c(
                        "li",
                        {
                          key: idx,
                          staticClass: "el-upload-list__item is-success",
                          attrs: { tabindex: "0" }
                        },
                        [
                          _c("div", [
                            item.fileType == "Video" && item.fileUrl
                              ? _c(
                                  "video",
                                  {
                                    staticClass: "float-l",
                                    staticStyle: {
                                      width: "178px",
                                      height: "178px"
                                    },
                                    attrs: {
                                      src: item.fileUrl,
                                      controls: "controls"
                                    }
                                  },
                                  [
                                    _vm._v(
                                      "\n                                    " +
                                        _vm._s(
                                          _vm.$i18n.t(
                                            "common.YourBrowserDoesNotSupportVideoPlayback"
                                          )
                                        ) +
                                        "\n                                "
                                    )
                                  ]
                                )
                              : _c("img", {
                                  staticClass:
                                    "el-upload-list__item-thumbnail img-center",
                                  attrs: {
                                    src:
                                      item.fileUrl +
                                      "?x-oss-process=image/auto-orient,1/resize,m_fixed,h_200,w_200/format,webp",
                                    alt: ""
                                  }
                                }),
                            _c(
                              "span",
                              { staticClass: "el-upload-list__item-actions" },
                              [
                                _c(
                                  "span",
                                  {
                                    staticClass: "el-upload-list__item-preview",
                                    on: {
                                      click: function($event) {
                                        return _vm.handlePictureCardPreview(
                                          item,
                                          idx
                                        )
                                      }
                                    }
                                  },
                                  [_c("i", { staticClass: "el-icon-zoom-in" })]
                                ),
                                _c(
                                  "span",
                                  {
                                    staticClass: "el-upload-list__item-delete",
                                    on: {
                                      click: function($event) {
                                        return _vm.handleRemove(item, idx)
                                      }
                                    }
                                  },
                                  [_c("i", { staticClass: "el-icon-delete" })]
                                )
                              ]
                            )
                          ])
                        ]
                      )
                    }),
                    _vm.imageList.length < 10
                      ? _c(
                          "li",
                          { staticClass: "el-upload-list__item is-success" },
                          [
                            _c("fileUpload", {
                              ref: "fileUpload",
                              on: { uploadSuccess: _vm.uploadSuccess }
                            })
                          ],
                          1
                        )
                      : _vm._e()
                  ],
                  2
                )
              ])
            ]
          )
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer"
        },
        [
          _c(
            "el-button",
            {
              staticStyle: { width: "20%" },
              on: {
                click: function($event) {
                  return _vm.closeDialog(false)
                }
              }
            },
            [_vm._v(" " + _vm._s(_vm.$i18n.t("common.cancel")))]
          ),
          _c(
            "el-button",
            {
              staticStyle: { width: "20%" },
              attrs: { type: "primary" },
              on: {
                click: function($event) {
                  return _vm.onSubmit()
                }
              }
            },
            [_vm._v(" " + _vm._s(_vm.$i18n.t("common.confirm")))]
          )
        ],
        1
      ),
      _vm.dialogVisible
        ? _c(
            "el-dialog",
            {
              attrs: {
                "append-to-body": true,
                width: "700px",
                visible: _vm.dialogVisible
              },
              on: {
                "update:visible": function($event) {
                  _vm.dialogVisible = $event
                }
              }
            },
            [_c("img", { attrs: { width: "100%", src: _vm.dialogImageUrl } })]
          )
        : _vm._e(),
      _vm.dialogVideoVisible
        ? _c(
            "el-dialog",
            {
              staticStyle: { height: "700px" },
              attrs: {
                "append-to-body": true,
                width: "700px",
                visible: _vm.dialogVideoVisible
              },
              on: {
                "update:visible": function($event) {
                  _vm.dialogVideoVisible = $event
                }
              }
            },
            [
              _c(
                "video",
                {
                  staticStyle: { width: "90%" },
                  attrs: {
                    src: _vm.dialogImageUrl,
                    autoplay: "true",
                    controls: "controls"
                  }
                },
                [
                  _vm._v(
                    "\n                " +
                      _vm._s(
                        _vm.$i18n.t(
                          "common.YourBrowserDoesNotSupportVideoPlayback"
                        )
                      ) +
                      "\n            "
                  )
                ]
              )
            ]
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }