var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "app-container",
      staticStyle: { height: "100%", "min-height": "350px" }
    },
    [
      _c(
        "div",
        { staticClass: "filter-container" },
        [
          _c(
            "el-form",
            {
              attrs: {
                model: _vm.query,
                inline: true,
                size: "small",
                "label-width": "140px"
              }
            },
            [
              _c(
                "el-form-item",
                [
                  _c("el-input", {
                    staticClass: "filter-item",
                    staticStyle: { width: "180px" },
                    attrs: {
                      clearable: "",
                      placeholder: _vm.$i18n.t("common.OrderNumber_UserID")
                    },
                    nativeOn: {
                      keyup: function($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        ) {
                          return null
                        }
                        return _vm.handleQuery.apply(null, arguments)
                      }
                    },
                    model: {
                      value: _vm.query.orderIdOrUserId,
                      callback: function($$v) {
                        _vm.$set(_vm.query, "orderIdOrUserId", $$v)
                      },
                      expression: "query.orderIdOrUserId"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-select",
                    {
                      staticClass: "filter-item",
                      staticStyle: { width: "180px", "margin-left": "10px" },
                      attrs: {
                        multiple: true,
                        placeholder: _vm.$i18n.t("common.country")
                      },
                      model: {
                        value: _vm.query.countryCode,
                        callback: function($$v) {
                          _vm.$set(_vm.query, "countryCode", $$v)
                        },
                        expression: "query.countryCode"
                      }
                    },
                    _vm._l(_vm.countrysDicts, function(cc) {
                      return _c("el-option", {
                        key: cc.code,
                        attrs: {
                          label: cc.name + "(" + cc.code + ")",
                          value: cc.code
                        }
                      })
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-select",
                    {
                      staticClass: "filter-item",
                      staticStyle: { width: "180px", "margin-left": "10px" },
                      attrs: {
                        placeholder: _vm.$i18n.t("common.ReplyStatus"),
                        clearable: ""
                      },
                      model: {
                        value: _vm.query.status,
                        callback: function($$v) {
                          _vm.$set(_vm.query, "status", $$v)
                        },
                        expression: "query.status"
                      }
                    },
                    [
                      _c("el-option", {
                        attrs: {
                          label: _vm.$i18n.t("common.Replied"),
                          value: "1"
                        }
                      }),
                      _c("el-option", {
                        attrs: {
                          label: _vm.$i18n.t("common.Noresponse"),
                          value: "0"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c("dataSelect", {
                    staticClass: "filter-item",
                    staticStyle: { width: "180px", "margin-left": "10px" },
                    attrs: {
                      clearable: "",
                      codeType: "order_status",
                      placeholder: _vm.$i18n.t("common.OrderStatus")
                    },
                    model: {
                      value: _vm.query.orderStatus,
                      callback: function($$v) {
                        _vm.$set(_vm.query, "orderStatus", $$v)
                      },
                      expression: "query.orderStatus"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c("dataSelect", {
                    staticClass: "filter-item",
                    staticStyle: { width: "180px", "margin-left": "10px" },
                    attrs: {
                      clearable: "",
                      codeType: "consultationTitle",
                      placeholder: _vm.$i18n.t("common.content")
                    },
                    model: {
                      value: _vm.query.title,
                      callback: function($$v) {
                        _vm.$set(_vm.query, "title", $$v)
                      },
                      expression: "query.title"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-select",
                    {
                      staticClass: "filter-item",
                      staticStyle: { width: "180px", "margin-left": "10px" },
                      attrs: {
                        placeholder: _vm.$i18n.t("common.Respondent"),
                        clearable: ""
                      },
                      model: {
                        value: _vm.query.replyAdmin,
                        callback: function($$v) {
                          _vm.$set(_vm.query, "replyAdmin", $$v)
                        },
                        expression: "query.replyAdmin"
                      }
                    },
                    [
                      _c("el-option", {
                        attrs: { label: "111", value: "111" }
                      }),
                      _c("el-option", {
                        attrs: { label: "222", value: "222" }
                      }),
                      _c("el-option", { attrs: { label: "333", value: "333" } })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "div",
                { staticStyle: { padding: "auto" } },
                [
                  _c(
                    "el-form-item",
                    [
                      _c("el-date-picker", {
                        attrs: {
                          type: "daterange",
                          "range-separator": _vm.$i18n.t("common.to"),
                          "start-placeholder": _vm.$i18n.t(
                            "common.Consultationdate"
                          ),
                          "end-placeholder": _vm.$i18n.t(
                            "common.Consultationdate"
                          ),
                          "picker-options": _vm.pickerOptions
                        },
                        model: {
                          value: _vm.valuePicker,
                          callback: function($$v) {
                            _vm.valuePicker = $$v
                          },
                          expression: "valuePicker"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    [
                      _c(
                        "el-button",
                        {
                          staticClass: "filter-item",
                          staticStyle: { "margin-left": "10px" },
                          attrs: { icon: "el-icon-search", type: "primary" },
                          on: { click: _vm.handleQuery }
                        },
                        [
                          _vm._v(
                            "\n                       " +
                              _vm._s(_vm.$i18n.t("common.query")) +
                              "\n                    "
                          )
                        ]
                      ),
                      _c(
                        "el-button",
                        {
                          staticClass: "filter-item",
                          staticStyle: { "margin-left": "10px" },
                          attrs: {
                            icon: "el-icon-search",
                            type: "primary",
                            size: "small"
                          },
                          on: { click: _vm.cleanQuery }
                        },
                        [
                          _vm._v(
                            "\n                    " +
                              _vm._s(_vm.$i18n.t("common.Reset")) +
                              "\n                    "
                          )
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading"
            }
          ],
          staticStyle: { width: "100%", "margin-top": "20px" },
          attrs: { data: _vm.list.list, border: "", stripe: "", size: "medium" }
        },
        [
          _c("el-table-column", {
            attrs: {
              label: _vm.$i18n.t("common.UserID"),
              prop: "userId",
              align: "center",
              "show-overflow-tooltip": "",
              width: "160"
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "countryCode",
              label: _vm.$i18n.t("common.country"),
              align: "center",
              width: "50px"
            }
          }),
          _c("el-table-column", {
            attrs: {
              label: _vm.$i18n.t("common.Ordernumber"),
              prop: "orderNo",
              align: "center",
              width: "260px"
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "goodsCount",
              label: _vm.$i18n.t("common.Numberofproductmodels"),
              "show-overflow-tooltip": "",
              align: "center"
            }
          }),
          _c("el-table-column", {
            attrs: {
              label: _vm.$i18n.t("common.OrderStatus"),
              prop: "orderStatus",
              align: "center",
              "show-overflow-tooltip": ""
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    _vm._v(
                      "\n                " +
                        _vm._s(
                          _vm.$dict.getDictValue(
                            "order_status",
                            row.orderStatus
                          )
                        ) +
                        "\n            "
                    )
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              prop: "title",
              label: _vm.$i18n.t("common.content"),
              "show-overflow-tooltip": "",
              align: "center"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    _vm._v(
                      "\n                " +
                        _vm._s(_vm.showConsultationTitle(row.title)) +
                        "\n            "
                    )
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              prop: "content",
              label: _vm.$i18n.t("common.explain"),
              "show-overflow-tooltip": "",
              align: "center"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    _vm._v(
                      "\n                " +
                        _vm._s(row.content.slice(0, 200)) +
                        "\n            "
                    )
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              label: _vm.$i18n.t("common.Consultationtime"),
              prop: "consultTime",
              align: "center",
              "show-overflow-tooltip": "",
              formatter: _vm.formatDate
            }
          }),
          _c("el-table-column", {
            attrs: {
              label: _vm.$i18n.t("common.state"),
              prop: "status",
              align: "center",
              "show-overflow-tooltip": ""
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    row.status == 0
                      ? _c("span", { staticStyle: { color: "red" } }, [
                          _vm._v(_vm._s(_vm.$i18n.t("common.Noresponse")))
                        ])
                      : _vm._e(),
                    row.status == 1
                      ? _c("span", { staticStyle: { color: "grey" } }, [
                          _vm._v(_vm._s(_vm.$i18n.t("common.Replied")))
                        ])
                      : _vm._e()
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              label: _vm.$i18n.t("common.Replytime"),
              prop: "replyTime",
              align: "center",
              "show-overflow-tooltip": "",
              formatter: _vm.formatDate
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    row.status == 1
                      ? _c("span", [
                          _vm._v(_vm._s(_vm.formatTime(row.replyTime)))
                        ])
                      : _vm._e()
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              prop: "replyAdmin",
              label: _vm.$i18n.t("common.Respondent"),
              "show-overflow-tooltip": "",
              align: "center"
            }
          }),
          _c("el-table-column", {
            attrs: {
              label: _vm.$i18n.t("common.operation"),
              align: "center",
              width: "230"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    row.status
                      ? _c(
                          "el-button",
                          {
                            staticClass: "table-inner-button",
                            attrs: { type: "primary", size: "mini" },
                            on: {
                              click: function($event) {
                                return _vm.replyHandle(row)
                              }
                            }
                          },
                          [_vm._v(_vm._s(_vm.$i18n.t("common.operation")))]
                        )
                      : _c(
                          "el-button",
                          {
                            staticClass: "table-inner-button",
                            attrs: { type: "danger", size: "mini" },
                            on: {
                              click: function($event) {
                                return _vm.replyHandle(row)
                              }
                            }
                          },
                          [_vm._v(_vm._s(_vm.$i18n.t("common.reply")))]
                        )
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "block" },
        [
          _c("el-pagination", {
            staticStyle: { "margin-top": "10px" },
            attrs: {
              background: "",
              "current-page": _vm.query.page,
              "page-size": _vm.query.size,
              layout: "total, prev, pager, next, jumper",
              total: _vm.list.total
            },
            on: { "current-change": _vm.handleCurrentChange }
          })
        ],
        1
      ),
      _vm.diologVisible
        ? _c(
            "el-dialog",
            {
              attrs: {
                title: _vm.diologTitle,
                visible: _vm.diologVisible,
                width: "1000px",
                "close-on-click-modal": false,
                height: "calc(100% - 140px)"
              },
              on: {
                "update:visible": function($event) {
                  _vm.diologVisible = $event
                }
              }
            },
            [
              _c(
                "div",
                [
                  _c("span", { staticStyle: { "font-size": "125%" } }, [
                    _vm._v(_vm._s(_vm.$i18n.t("common.OrderInformation")))
                  ]),
                  _c(
                    "el-table",
                    {
                      staticStyle: { "margin-top": "10px" },
                      attrs: {
                        data: _vm.replyList.orderDetails,
                        size: "medium"
                      }
                    },
                    [
                      _c("el-table-column", {
                        attrs: {
                          label: _vm.$i18n.t("common.Ordernumber"),
                          width: "100px",
                          align: "center",
                          prop: "orderNo"
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function(ref) {
                                var row = ref.row
                                return [
                                  _c(
                                    "el-link",
                                    {
                                      attrs: { type: "primary" },
                                      on: {
                                        click: function($event) {
                                          return _vm.orderHandle(row.orderId)
                                        }
                                      }
                                    },
                                    [_vm._v(_vm._s(row.orderNo))]
                                  )
                                ]
                              }
                            }
                          ],
                          null,
                          false,
                          2347927803
                        )
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: _vm.$i18n.t("common.country"),
                          "show-overflow-tooltip": "",
                          align: "center",
                          prop: "countryCode"
                        }
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: _vm.$i18n.t("common.UserID"),
                          width: "150px",
                          align: "center",
                          prop: "userId"
                        }
                      }),
                      _c(
                        "el-table-column",
                        {
                          attrs: {
                            label: _vm.$i18n.t("common.Numberofproductmodels"),
                            "show-overflow-tooltip": "",
                            align: "center"
                          }
                        },
                        [
                          [
                            _c("span", [
                              _vm._v(_vm._s(_vm.replyList.goodsCount))
                            ])
                          ]
                        ],
                        2
                      ),
                      _c("el-table-column", {
                        attrs: {
                          label: _vm.$i18n.t("common.source"),
                          "show-overflow-tooltip": "",
                          align: "center",
                          prop: "source"
                        }
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: _vm.$i18n.t("common.money"),
                          "show-overflow-tooltip": "",
                          align: "center",
                          prop: "totalAmt"
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function(ref) {
                                var row = ref.row
                                return [
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$dict.getDictValue(
                                          "country_currency",
                                          row.countryCode
                                        )
                                      ) + _vm._s(row.totalAmt)
                                    )
                                  ])
                                ]
                              }
                            }
                          ],
                          null,
                          false,
                          2886052851
                        )
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: _vm.$i18n.t("common.OrderStatus"),
                          "show-overflow-tooltip": "",
                          align: "center",
                          prop: "orderStatus"
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function(ref) {
                                var row = ref.row
                                return [
                                  _vm._v(
                                    "\n                        " +
                                      _vm._s(
                                        _vm.$dict.getDictValue(
                                          "order_status",
                                          row.orderStatus
                                        )
                                      ) +
                                      "\n                    "
                                  )
                                ]
                              }
                            }
                          ],
                          null,
                          false,
                          3038123130
                        )
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: _vm.$i18n.t("common.Creationtime"),
                          width: "150px",
                          align: "center",
                          formatter: _vm.formatDate,
                          prop: "createTime"
                        }
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: _vm.$i18n.t("common.Paymentmethod"),
                          "show-overflow-tooltip": "",
                          align: "center",
                          prop: "payType"
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function(ref) {
                                var row = ref.row
                                return [
                                  row.orderStatus != "UnPaid"
                                    ? _c("span", [
                                        _vm._v(
                                          _vm._s(_vm.replyList.payMethodName)
                                        )
                                      ])
                                    : _vm._e()
                                ]
                              }
                            }
                          ],
                          null,
                          false,
                          2505391924
                        )
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: _vm.$i18n.t("common.Paymenttime"),
                          width: "150px",
                          align: "center",
                          formatter: _vm.formatDate,
                          prop: "paymentTime"
                        }
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: _vm.$i18n.t("common.Shipmentstatus"),
                          "show-overflow-tooltip": "",
                          align: "center",
                          prop: "deliveryStatus"
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function(ref) {
                                var row = ref.row
                                return [
                                  _vm._v(
                                    "\n                        " +
                                      _vm._s(
                                        _vm.$dict.getDictValue(
                                          "delivery_status",
                                          row.deliveryStatus
                                        )
                                      ) +
                                      "\n                    "
                                  )
                                ]
                              }
                            }
                          ],
                          null,
                          false,
                          2913272762
                        )
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: _vm.$i18n.t("common.Deliverytime"),
                          width: "150px",
                          align: "center",
                          formatter: _vm.formatDate,
                          prop: "deliverTime"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "div",
                [
                  _c(
                    "el-descriptions",
                    {
                      staticStyle: {
                        "margin-top": "30px",
                        "font-size": "125%"
                      },
                      attrs: { column: 1, size: "medium" }
                    },
                    [
                      _c(
                        "el-descriptions-item",
                        {
                          attrs: {
                            label: _vm.$i18n.t("common.Consultationcontent"),
                            contentStyle: { width: "800px" }
                          }
                        },
                        [
                          _vm._v(
                            _vm._s(
                              _vm.showConsultationTitle(_vm.replyList.title)
                            )
                          )
                        ]
                      ),
                      _c(
                        "el-descriptions-item",
                        {
                          attrs: {
                            label: _vm.$i18n.t(
                              "common.Consultationinstructions"
                            ),
                            contentStyle: { width: "800px" }
                          }
                        },
                        [
                          [
                            _c("span", [
                              _vm._v(
                                "\n                            " +
                                  _vm._s(_vm.replyList.content) +
                                  "\n                        "
                              )
                            ])
                          ]
                        ],
                        2
                      ),
                      !_vm.replyStatus
                        ? _c(
                            "el-descriptions-item",
                            {
                              attrs: {
                                label: _vm.$i18n.t("common.Replycontent"),
                                contentStyle: "color:red;width:800px"
                              }
                            },
                            [
                              _vm._l(_vm.contentHandler, function(item, index) {
                                return _c("span", { key: index }, [
                                  _vm._v(
                                    "\n                            " +
                                      _vm._s(item) +
                                      "\n                            "
                                  ),
                                  _vm.contentHandler.length != 1 &&
                                  index != _vm.contentHandler.length - 1
                                    ? _c("br")
                                    : _vm._e()
                                ])
                              })
                            ],
                            2
                          )
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              ),
              _vm.replyStatus
                ? _c(
                    "div",
                    [
                      _c("span", { staticStyle: { "font-size": "125%" } }, [
                        _vm._v(_vm._s(_vm.$i18n.t("common.reply")) + ":")
                      ]),
                      _c("el-input", {
                        staticStyle: { "margin-top": "10px" },
                        attrs: {
                          type: "textarea",
                          autosize: { minRows: 2, maxRows: 10 },
                          placeholder: _vm.$i18n.t("common.EnterReply")
                        },
                        model: {
                          value: _vm.replyContent,
                          callback: function($$v) {
                            _vm.replyContent = $$v
                          },
                          expression: "replyContent"
                        }
                      }),
                      _c(
                        "el-button",
                        {
                          staticClass: "table-inner-button",
                          staticStyle: { "margin-top": "30px" },
                          attrs: { type: "primary", size: "medium" },
                          on: {
                            click: function($event) {
                              return _vm.reply(_vm.replyList.consultationId)
                            }
                          }
                        },
                        [_vm._v(_vm._s(_vm.$i18n.t("common.reply")))]
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "div",
                { staticStyle: { "margin-top": "10px" } },
                [
                  _c("span", { staticStyle: { "font-size": "125%" } }, [
                    _vm._v(_vm._s(_vm.$i18n.t("common.Consultationrecords")))
                  ]),
                  _c(
                    "el-table",
                    {
                      staticStyle: { "margin-top": "10px" },
                      attrs: {
                        data: _vm.replyList.consultations,
                        size: "medium"
                      }
                    },
                    [
                      _c("el-table-column", {
                        attrs: {
                          label: _vm.$i18n.t("common.Serialnumber"),
                          type: "index",
                          width: "50",
                          "show-overflow-tooltip": "",
                          align: "center"
                        }
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: _vm.$i18n.t("common.content"),
                          align: "center",
                          prop: "title",
                          width: "100px"
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function(ref) {
                                var row = ref.row
                                return [
                                  _vm._v(
                                    "\n                        " +
                                      _vm._s(
                                        _vm.showConsultationTitle(row.title)
                                      ) +
                                      "\n                    "
                                  )
                                ]
                              }
                            }
                          ],
                          null,
                          false,
                          441328572
                        )
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: _vm.$i18n.t("common.explain"),
                          align: "center",
                          prop: "content",
                          width: "200px"
                        }
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: _vm.$i18n.t("common.Consultationtime"),
                          width: "150px",
                          align: "center",
                          formatter: _vm.formatDate,
                          prop: "consultTime"
                        }
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: _vm.$i18n.t("common.state"),
                          "show-overflow-tooltip": "",
                          align: "center",
                          prop: "status"
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function(ref) {
                                var row = ref.row
                                return [
                                  row.status == 0
                                    ? _c(
                                        "span",
                                        { staticStyle: { color: "red" } },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$i18n.t("common.Noresponse")
                                            )
                                          )
                                        ]
                                      )
                                    : _vm._e(),
                                  row.status == 1
                                    ? _c(
                                        "span",
                                        { staticStyle: { color: "grey" } },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$i18n.t("common.Replied")
                                            )
                                          )
                                        ]
                                      )
                                    : _vm._e()
                                ]
                              }
                            }
                          ],
                          null,
                          false,
                          1124481064
                        )
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: _vm.$i18n.t("common.Replycontent"),
                          width: "200px",
                          align: "center",
                          prop: "replyContent"
                        }
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: _vm.$i18n.t("common.Replytime"),
                          width: "150px",
                          align: "center",
                          formatter: _vm.formatDate,
                          prop: "replyTime"
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function(ref) {
                                var row = ref.row
                                return [
                                  row.status == 1
                                    ? _c("span", [
                                        _vm._v(
                                          _vm._s(_vm.formatTime(row.replyTime))
                                        )
                                      ])
                                    : _vm._e()
                                ]
                              }
                            }
                          ],
                          null,
                          false,
                          3630008970
                        )
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: _vm.$i18n.t("common.Respondent"),
                          "show-overflow-tooltip": "",
                          align: "center",
                          prop: "replyAdmin"
                        }
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: _vm.$i18n.t("common.operation"),
                          "show-overflow-tooltip": "",
                          align: "center"
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function(ref) {
                                var row = ref.row
                                return [
                                  row.status
                                    ? _c(
                                        "el-button",
                                        {
                                          staticClass: "table-inner-button",
                                          attrs: {
                                            type: "primary",
                                            size: "mini"
                                          },
                                          on: {
                                            click: function($event) {
                                              return _vm.replyHandle(row)
                                            }
                                          }
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$i18n.t("common.details")
                                            )
                                          )
                                        ]
                                      )
                                    : _c(
                                        "el-button",
                                        {
                                          staticClass: "table-inner-button",
                                          attrs: {
                                            type: "danger",
                                            size: "mini"
                                          },
                                          on: {
                                            click: function($event) {
                                              return _vm.replyHandle(row)
                                            }
                                          }
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(_vm.$i18n.t("common.reply"))
                                          )
                                        ]
                                      )
                                ]
                              }
                            }
                          ],
                          null,
                          false,
                          3470989293
                        )
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ]
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }