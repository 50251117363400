<template>
    <el-dialog :label='$i18n.t("common.informationservice")' :visible.sync="formVisible" width="1200px" :close-on-click-modal='false'>
        <el-form ref="dataForm" :rules="formRules" :model="form" label-position="right" size="small" label-width="120px">
            <el-form-item :label='$i18n.t("common.catalogue")'>
                <!-- <el-select v-model="form.parentId" placeholder="请选择栏目名称" @change="selectName">
                    <el-option label="一级目录" value="-1"></el-option>
                    <el-option
                        v-for="(item, index) in catalogueFilter"
                        :key="index"
                        :label="item.title"
                        :value="item.informationId"
                    ></el-option>
                </el-select> -->

                <el-cascader clearable v-model="form.parentId" :options="catalogueFilter" :props="defaultParams">
                    <template slot-scope="{ node, data }">
                        <span>{{ data.label }}</span>
                        <span v-if="!node.isLeaf"> ({{ data.children.length }}) </span>
                    </template>
                </el-cascader>
            </el-form-item>

            <el-form-item :label='$i18n.t("common.ShowCountry")'  prop="checkCountrys">
                <el-checkbox :indeterminate="isIndeterminate" v-model="checkAll" @change="handleCheckAllChange">{{ $i18n.t("common.SelectAll") }}</el-checkbox>
                <el-checkbox-group v-model="checkedCountrys" @change="handleCheckedCitiesChange">
                    <el-checkbox v-for="item in counrtys" :label="item.code" :key="item.code">{{ item.name }}</el-checkbox>
                </el-checkbox-group>
            </el-form-item>

            <!--
            <el-form-item :label='$i18n.t("common.brand")' prop="checkBrands">

                <el-checkbox-group v-model="checkedBrands">
                    <el-checkbox v-for="item in brands" :label="item.code" :key="item.code">{{ item.name }}</el-checkbox>
                </el-checkbox-group>
            </el-form-item>

            <el-form-item :label='$i18n.t("common.Isitdisplayedatthebottomofthewebsite")' prop="isshowbottom">

            -->


            <el-form-item :label='$i18n.t("common.Isitdisplayedatthebottomofthewebsite")'   prop="isshowbottom">

                <el-switch v-model="form.isShowBottom" active-color="#13ce66"> </el-switch>
            </el-form-item>
            <el-form-item  :label='$i18n.t("common.sort")'  prop="sort">
                <el-input-number :min="0" :max="9999" v-model="form.sort" style="width: 180px"></el-input-number>
            </el-form-item>

            <el-form-item v-for="(item, index) in titles" :key="index" prop="pageTitle" :label=" $i18n.t('common.title')+'(' + item.label + ')'">
                <el-input :placeholder="item.label + $i18n.t('common.TitleName')" :maxlength="200" v-model="item.title"></el-input>
            </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
            <el-button @click="formVisible = false">  {{ $i18n.t("common.cancel") }} </el-button>
            <el-button type="primary" @click="doCreate()"> {{ $i18n.t("common.confirm") }} </el-button>
        </div>
    </el-dialog>
</template>

<script>
function initFormBean() {
    const formBean = {
        name: null,
        isShowBottom: false,
        sort: null,
        parentId: null,
        informationId: null
    };
    return formBean;
}
import cache from '@/utils/cache';
export default {
    name: 'InformationAdd',
    data() {
        var validateCountry = (rule, value, callback) => {
            if (this.checkedCountrys.length == 0) {
                callback(new Error((this. $i18n.t("common.Pleaseselectacountry"))));
            } else {
                callback();
            }
        };

        // var validateBrand = (rule, value, callback) => {
        //     if (this.checkedBrands.length == 0) {
        //         callback(new Error(this. $i18n.t("common.Pleaseselectabrand")));
        //     } else {
        //         callback();
        //     }
        // };

        return {
            form: {},
            formVisible: false,
            formRules: {
                checkCountrys: [{ type: 'array', validator: validateCountry, required: true, trigger: 'change' }],

                // checkBrands: [{ type: 'array', validator: validateBrand, required: true, trigger: 'change' }],
                name: [{ max: 50, message: this. $i18n.t("common.Thelengthcannotexceed50characters"), trigger: 'blur' }],

                updatetime: [],
                // isshowbottom: [{ required: true, message: '请输入是否在网站底部显示', trigger: 'blur' }],
                sort: [{ required: true, message:  this. $i18n.t("common.Pleaseentersorting"), trigger: 'blur' }]
            },
            checkAll: false,
            catalogue: [], //目录列表
            catalogueFilter: [],

            checkedCountrys: [],
            checkedBrands: [],

            counrtys: [],
            brands: [],
            countryLang: [],

            titles: [],

            isIndeterminate: true,
            langs: [],
            checkIds: [],

            defaultParams: {
                label: 'label',
                value: 'id',
                children: 'children',
                checkStrictly: true,
                emitPath: false
            }
        };
    },
    mounted() {
        this.getCountryDictItems();
        this.getBrandDictItems();
        this.getLangDictItems();
        this.getCatalogue();
    },
    methods: {
        selectName(val) {
            if (val != -1) {
                let result = this.catalogueFilter.filter((item) => item.informationId == val);
                console.log(result[0]);
                this.form.name = result[0].name;

                let arr = [];
                for (const key in result[0].informationBrandList) {
                    const element = result[0].informationBrandList[key];
                    arr.push(element.brand);
                }
                let arr1 = [];
                for (const key in result[0].informationCountryList) {
                    const element = result[0].informationCountryList[key];
                    arr1.push(element.countryCode);
                }
                this.checkedCountrys = arr1;
                this.checkedBrands = arr;

                this.form.isShowBottom = result[0].isShowBottom;
                this.form.sort = result[0].sort;
            }
        },
        getCatalogue() {
            this.$store.dispatch('getCatalogue', {}).then((res) => {
                this.catalogue = res;
                this.catalogueFilter = res;
            });
        },
        handleCheckAllChange(val) {
            let arr = [];
            for (const key in this.counrtys) {
                const element = this.counrtys[key];

                arr.push(element.code);
            }
            this.checkedCountrys = val ? arr : [];
            this.isIndeterminate = false;
        },
        handleCheckedCitiesChange(value) {
            console.log(value);
            let checkedCount = value.length;

            this.checkAll = checkedCount === this.counrtys.length;
            this.isIndeterminate = checkedCount > 0 && checkedCount < this.counrtys.length;
        },
        getCountryDictItems() {
            var _this = this;
            this.$store.dispatch('getDicItems', { tag: 'countrys' }).then((res) => {
                if (res != null) {
                    _this.counrtys = res;
                }
            });
            this.$store.dispatch('getDicItems', { tag: 'countrylang' }).then((res) => {
                if (res != null) _this.countryLang = res;
            });
        },
        getBrandDictItems() {
            var _this = this;
            this.$store.dispatch('getDicItems', { tag: 'brand' }).then((res) => {
                _this.brands = res;
                console.log(_this.brands);
            });
        },
        getLangDictItems() {
            var _this = this;
            
            this.$store.dispatch('getDicItems', { tag: 'lang' }).then((res) => {
                _this.langs = res;

                res.forEach((element) => {
                    let node = {
                        title: '',
                        lang: element.code,
                        label: element.name
                    };
                    this.titles.push(node);
                });
            });
        },
        /**
         * 重置表单
         */
        resetForm() {
            this.form = initFormBean();
            this.titles = [];
            this.checkedCountrys = [];
            this.checkedBrands = [];
            this.getLangDictItems();
        },
        /**
         * 打开新建表单
         */
        handleCreate() {
            this.resetForm();
            this.formVisible = true;
            console.log(this.titles);
            // this.$nextTick(() => {
            //     this.$refs['dataForm'].resetFields();
            // });
        },
        setLangList(informationLangsArr) {
            for (const key in informationLangsArr) {
                const element = informationLangsArr[key];

                //赋值标题数组
                this.titleArr.push(element.title);
                this.isCategoryArr.push(element.isCategory);

                let arr = [];
                for (const key in element.informationLangCategoryList) {
                    const item = element.informationLangCategoryList[key];
                    arr.push(item.title);
                }
                this.$set(this.tagArr, key, arr);
            }
        },
        /**
         * 执行新建操作
         */
        doCreate() {
            //国家信息配置
            let arr = [];
            for (const key in this.checkedCountrys) {
                const element = this.checkedCountrys[key];
                arr.push({ countryCode: element });
            }
            this.form.informationCountryList = arr;

            //品牌信息配置
            let arr1 = [];
            this.checkedBrands = []
            this.checkedBrands.push(this.$tenantBrand.getBrand())
            for (const key in this.checkedBrands) {
                const element = this.checkedBrands[key];
                arr1.push({ brand: element });
            }
            this.form.informationBrandList = arr1;

            //多国家标题信息设置
            this.form.informationTitleList = this.titles;



            //校验
            var countryLangs = [];
            for (var item of this.form.informationCountryList) {
                for (var langItem of this.countryLang) {
                    if (item.countryCode == langItem.code) {
                        countryLangs.push(langItem.name);
                    }
                }
            }
            this.form.informationTitleList.filter((x) => x.lang);
            for (var item of this.form.informationTitleList) {
                if(item.lang == 'zh' && item.title == ''){
                    countryLangs = [];
                    this.$message({
                        showClose: false,
                        message:this. $i18n.t("common.Chinesetitleisrequired"),
                        type: 'warning'
                    });
                    return;
                }
                if (countryLangs.includes(item.lang) && item.title == '') {
                    countryLangs = [];
                    this.$message({
                        showClose: false,
                        message:this. $i18n.t("common.Selectedcountrytitleisrequired"),
                        type: 'warning'
                    });
                    return;
                }
            }

            this.$refs['dataForm'].validate().then(() => {
                if (this.form.informationId == null) {
                    this.$store.dispatch('createInformation', this.form).then((res) => {
                        console.log(res);
                        this.$message({
                            message: this. $i18n.t("common.Successfullyadded1"),
                            type: 'success'
                        });
                        this.formVisible = false;
                        this.$parent.handleQuery();
                        this.getCatalogue();
                        this.$parent.getTree();
                    });
                } else {
                    this.$store.dispatch('updateInformation', this.form).then((res) => {
                        console.log(res);
                        this.$message({
                            message: this. $i18n.t("common.Successfullyedited"),
                            type: 'success'
                        });
                        this.formVisible = false;
                        this.$parent.handleQuery();
                        this.getCatalogue();
                        this.$parent.getTree();
                    });
                }
            });
        }
    }
};
</script>
