<template>
    <div>
        <el-dialog :title='$i18n.t("common.BatchRecharge")'
                   :visible.sync="batchRechargeVisible"
                   @close="closeRechargeDialog()"
                   v-if="batchRechargeVisible"
                   :close-on-click-modal="false">
            <div>
                <el-button class="upload-btn" type="primary" icon='el-icon-upload2' :loading='loading'
                           style='margin-right: 10px;padding-left: 30px;padding-right: 30px'>{{ $i18n.t("common.Import") }}
                    <div id="clone_input">
                        <input class="upload-btn-file"
                               type="file"
                               ref="file"
                               accept='.xlsx'
                               @change="handleImport()" />
                    </div>
                </el-button>
                <!-- <el-link
                    icon="el-icon-download"
                    type="primary"
                    :href="tplDownloadUrl"
                    target="_blank"
                >{{ $i18n.t("common.DownloadImportTemplate") }}</el-link> -->
                <el-button  class="upload-btn"  icon="el-icon-download" type="primary" @click="handleDownloadTemplate"> {{ $i18n.t('common.DownloadImportTemplate') }} </el-button>

            </div>
            <el-divider></el-divider>
            <el-table  :data='batchRechargeList' height='320px'>
                <el-table-column :label='$i18n.t("common.Serialnumber")' align='center' property="seqNo"  >
                    <!--<template slot-scope="scope">{{ scope.$index + 1 }}</template>-->
                </el-table-column>
                <!--<el-table-column property="seqNo" label="序号" align="center" show-overflow-tooltip ></el-table-column>-->
                <el-table-column property="email" :label='$i18n.t("common.RechargeEmail")' align="center" show-overflow-tooltip></el-table-column>
                <el-table-column property="amount" :label='$i18n.t("common.RechargeAigoCoins")' align="center" show-overflow-tooltip></el-table-column>
                <el-table-column property="countryCode" :label='$i18n.t("common.RechargeCountry")' align="center" show-overflow-tooltip></el-table-column>
                <el-table-column property="validity" :label='$i18n.t("common.ValidityPeriod")' align="center" show-overflow-tooltip></el-table-column>
                <el-table-column property="unit" :label='$i18n.t("common.UnitOfValidity")' align="center" show-overflow-tooltip>
                    <template slot-scope="{ row }">
                        <dataText v-model="row.unit" :codeType="'validity'"></dataText>
                    </template>
                </el-table-column>
                <el-table-column property="remark" :label='$i18n.t("common.Remarks")' align="center" show-overflow-tooltip></el-table-column>
                <el-table-column property="operate" :label='$i18n.t("common.operation")' align="center" show-overflow-tooltip>
                    <template slot-scope="scope">
                        <el-button
                            type="text"
                            icon="el-icon-delete"
                            @click="handleDelete(scope.$index)"
                        >{{ $i18n.t("common.delete") }}</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <span slot="footer" class="dialog-footer" >
                <div style='text-align: center'>
                    <el-button style="width: 15%;" @click="closeRechargeDialog()"> {{ $i18n.t("common.Cancel") }} </el-button>
                    <el-button style="width: 15%;" type="primary" @click="handleSubmit()"> {{ $i18n.t("common.Submit") }} </el-button>
                </div>
            </span>
        </el-dialog>

        <el-dialog :title='$i18n.t("common.SubmittedSuccessfully")'
                   center
                   :visible.sync="successDialogVisible"
                   v-if="successDialogVisible"
                   width='20%'
                   :show-close='false'
                   :close-on-click-modal="false">
            <div>
                <p>{{ $i18n.t("common.RechargeSuccessfully") }}：<label v-text='res.successTotal'></label></p>
                <p>{{ $i18n.t("common.Rechargefailed") }}：<label v-text='res.failTotal'></label></p>
                <p>{{ $i18n.t("common.RechargeInformation") }}：
                    <el-link type='primary' @click="importInfoDownload"
                             v-loading.fullscreen.lock="downloadLoading"
                    >{{ $i18n.t("common.download") }}</el-link></p>
            </div>

            <span slot="footer" class="dialog-footer" >
                <el-button type="danger" v-if='!isDownload'>{{ $i18n.t("common.PleaseDownloadTheRechargeInformationFirst") }}</el-button>
                <el-button type="primary" @click="closeSuccessDialog" v-if='isDownload'>{{ $i18n.t("common.Confirm") }}</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>


import { read, utils } from 'xlsx'
import dataText from '@/components/common/DataText.vue';
export default {
    name: 'batchRechargeHandle',
    components: {
        dataText
    },
    data() {
        return {
            batchRechargeVisible: false,
            successDialogVisible: false,
            batchRechargeList:[],
            loading: false,
            downloadLoading: false,
            isDownload: false,
            res:{
                successTotal: 0,
                failTotal: 0,
                total: 0,
                batchId: 0
            },
            tplDownloadUrl: process.env.VUE_APP_OSS_Head + "b2c/tpl/aigo_coin_batch_recharge_tpl.xlsx",
        };
    },
    mounted() {
    },
    methods: {
        // 下载导入模板
        handleDownloadTemplate(){
            let fileName = 'aigo_coin_batch_recharge_tpl.xlsx';
            this.$store.dispatch('downloadBatchRechargeExcelTemplate', {  fileName: fileName }).then((res) => {
                this.$message({
                    type: 'success',
                    message: this.$i18n.t("common.Downloadsuccessful")
                });
            });
        },
		
        /**
         * 删除充值数据
         */
        handleDelete(index){
            // this.batchRechargeList[index].email = ""
            // this.batchRechargeList[index].amount = ""
            // this.batchRechargeList[index].countryCode = ""
            // this.batchRechargeList[index].remark = ""
            // this.batchRechargeList[index].noShow = true
            this.batchRechargeList.splice(index, 1)
            this.$forceUpdate();
        },
        /**
         * 导入
         */
        handleImport() {
            this.loading = true
            let file = this.$refs.file.files[0];
            let ext = this.getSuffix(file.name);
            let accept = ['.xlsx']
            if (accept.indexOf(ext) < 0) {
                let msg = this.$i18n.t("common.OnlySupportsFilesInFormat").replace("{xxx}", accept)
                this.$message.error(msg);
                return;
            }
            const fileReader = new FileReader();
            fileReader.onload = (ev) => {
                try {
                    const data = ev.target.result;
                    const workbook = read(data, {
                        type: 'binary'
                    });
                    const wsname = workbook.SheetNames[0];
                    // 导入模板写死header，模板变动需要修改
                    let ws = utils.sheet_to_json(workbook.Sheets[wsname]
                        , {'header': ['email', 'amount', 'countryCode', 'validity', 'unit', 'remark']});

                    if(ws.length > 1){
                        this.batchRechargeList = []
                        let set = new Set();
                        // v1.2.8 数据处理 (国家、邮箱、爱国币非法不显示)
                        // v1.3.2 若为填写或者有些期填写非数字，未选择单位，则自动过滤
                        for(let i = ws.length - 1; i > 0; i--){
                            let item = ws[i]
                            if(!item.countryCode || !item.email || (!item.amount || item.amount <= 0 ) || !item.unit || !item.validity || !Number.isInteger(item.validity)){
/*                                item.email = ""
                                item.amount = ""
                                item.countryCode = ""
                                item.remark = ""
                                item.noShow = true*/
                            }else{
                                // 相同邮箱+国家只能存在一条数据，以最后一条为准
                                item.countryCode = item.countryCode.toUpperCase()
                                const key = item.email + "_" + item.countryCode
                                if(!set.has(key)){
                                    set.add(key)
                                    item.seqNo = i
                                    item.amount = item.amount.toFixed(2)
                                    item.unit = this.handleUnit(item.unit)
                                    this.batchRechargeList.push(item)
                                }
                            }
                        }
                        this.batchRechargeList.reverse()

                        this.$refs.file.value = null;
                    }

                    this.loading = false
                } catch (e) {
                    this.$refs.file.value = null;
                    this.loading = false
                    return false;
                }
            };
            fileReader.readAsBinaryString(file);
        },
        handleUnit(str) {
            let lowerCase = str.toLowerCase();
            return lowerCase.slice(0, 1).toUpperCase() + lowerCase.slice(1);
        },
        /**
         * 提交数据
         */
        handleSubmit() {
            if(!this.batchRechargeList || this.batchRechargeList.length <= 0){
                this.$message({
                    message: this.$i18n.t("common.TheBatchRechargeDataOfTheAIGOCoinCannotBeEmpty"),
                    type: 'warning',
                    duration: 2000
                });
                return
            }

            if(this.batchRechargeList.length > 1000){
                this.$message({
                    message: this.$i18n.t("common.TheBatchRechargeDataCannotExceed1000Records"),
                    type: 'warning',
                    duration: 2000
                });
                return
            }

            this.$confirm(this.$i18n.t("common.ConfirmSubmission")+"?", this.$i18n.t("common.Prompt"), {
                confirmButtonText: this.$i18n.t("common.Confirm"),
                cancelButtonText: this.$i18n.t("common.Cancel"),
                type: 'warning'
            })
            .then(() => {
                this.$store.dispatch('batchRechargeCurrency', this.batchRechargeList).then((res) => {
                    this.res = res
                    console.info("批量充值爱国币res: " + res)
                });
                this.closeRechargeDialog()
                this.openSuccessDialog()
            })
            .catch(() => {
                this.$message({
                    type: 'info',
                    message: this.$i18n.t("common.Canceled")
                });
            });
        },
        /**
         * 充值信息导出下载
         */
        importInfoDownload(){
            this.isDownload = true
            this.downloadLoading = true
            let params = {}
            params.batchId = this.res.batchId
            params.fileName = this.$i18n.t("common.RechargeInformation")+"_" + params.batchId + ".xlsx"

            this.$store.dispatch('importInfoDownload', params).then((res) => {
                this.$message({
                    type: 'success',
                    message: this.$i18n.t("common.Exportsuccessful")
                });
                this.downloadLoading = false
            }).catch((res) =>{
                this.$message({
                    type: 'error',
                    message: this.$i18n.t("common.ExportFailed")
                });
                this.downloadLoading = false
            });
        },
        openRechargeDialog() {
            this.batchRechargeVisible = true;
        },
        openSuccessDialog() {
            this.successDialogVisible = true;
        },
        closeRechargeDialog() {
            this.batchRechargeVisible = false;
            this.batchRechargeList = []
        },
        closeSuccessDialog() {
            this.successDialogVisible = false;
            this.res={
                successTotal: 0,
                failTotal: 0,
                total: 0,
                batchId: 0
            }
            this.isDownload = false
        }

    }
};
</script>
