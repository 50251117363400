<template>
    <el-dialog :title='$i18n.t("common.SEOManagementImport")'  :visible.sync="importFormVisible" v-loading="importFormLoading" width="400px">
        <el-upload
            drag
            :action="importUrl"
            :on-success="onImportSuccess"
            :on-progress="onImportProgress"
            :on-error="onImportError"
            :show-file-list="false"
            :headers="headerData"
        >
            <i class="el-icon-upload"></i>
            <div class="el-upload__text">{{ $i18n.t("common.DragtheExcelfilehere_orclickonupload") }}</em></div>
        </el-upload>
    </el-dialog>
</template>
<script>
import cache from '@/utils/cache';
import Config from '@/utils/config';
import Scene from '@/utils/scene';
export default {
    name: 'SeoImport',
    data() {
        return {
            importFormLoading: false,
            importFormVisible: false,
            importUrl: process.env.VUE_APP_URL + '/b2c/v1.0/seo/import',
            headerData: {
                Authorization: cache.getStr(Config.accessToken),
                tenantid: Scene.getTenantId(),
            }
        };
    },
    created: function () {},
    methods: {
        onImportProgress(event, file, fileList) {
            this.importFormLoading = true;
        },
        onImportSuccess(response, file, fileList) {
            this.importFormVisible = false;
            this.importFormLoading = false;
            this.$message.success(this.$i18n.t("common.Fileimportedsuccessfully"));
            this.$parent.handleQuery();
        },
        onImportError(error, file, fileList) {
            console.log(error);
            this.$message({
                showClose: false,
                message: this.$i18n.t("common.Fileimportfailed_pleasecheckthefilecontent"),
                type: 'error'
            });
            this.importFormLoading = false;
            this.$common.showNotifyError(JSON.parse(error.message));
        },
        show() {
            this.importFormVisible = true;
        }
    }
};
</script>
