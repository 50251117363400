var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "app-container",
      staticStyle: { height: "100%", "min-height": "350px" }
    },
    [
      _c(
        "div",
        { staticClass: "filter-container" },
        [
          _c(
            "el-form",
            {
              attrs: {
                model: _vm.query,
                inline: true,
                size: "small",
                "label-width": "140px"
              }
            },
            [
              _c(
                "el-form-item",
                [
                  _c("el-input", {
                    staticClass: "filter-item",
                    staticStyle: { width: "210px" },
                    attrs: {
                      clearable: "",
                      placeholder: _vm.$i18n.t(
                        "common.UserID_loginemail_registrationinviterID_phonenumber"
                      )
                    },
                    nativeOn: {
                      keyup: function($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        ) {
                          return null
                        }
                        return _vm.handleQuery.apply(null, arguments)
                      }
                    },
                    model: {
                      value: _vm.query.userIdOrEmailOrInviterUserId,
                      callback: function($$v) {
                        _vm.$set(_vm.query, "userIdOrEmailOrInviterUserId", $$v)
                      },
                      expression: "query.userIdOrEmailOrInviterUserId"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-select",
                    {
                      staticClass: "filter-item",
                      staticStyle: { width: "180px", "margin-left": "10px" },
                      attrs: {
                        multiple: true,
                        placeholder: _vm.$i18n.t("common.country")
                      },
                      model: {
                        value: _vm.query.countryCode,
                        callback: function($$v) {
                          _vm.$set(_vm.query, "countryCode", $$v)
                        },
                        expression: "query.countryCode"
                      }
                    },
                    _vm._l(_vm.countrysDicts, function(cc) {
                      return _c("el-option", {
                        key: cc.code,
                        attrs: {
                          label: cc.name + "(" + cc.code + ")",
                          value: cc.code
                        }
                      })
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-select",
                    {
                      staticClass: "filter-item",
                      staticStyle: { width: "180px", "margin-left": "10px" },
                      attrs: {
                        placeholder: _vm.$i18n.t("common.Whethertosubscribe"),
                        clearable: ""
                      },
                      model: {
                        value: _vm.query.isSubscribe,
                        callback: function($$v) {
                          _vm.$set(_vm.query, "isSubscribe", $$v)
                        },
                        expression: "query.isSubscribe"
                      }
                    },
                    [
                      _c("el-option", {
                        attrs: { label: _vm.$i18n.t("common.yes"), value: "1" }
                      }),
                      _c("el-option", {
                        attrs: { label: _vm.$i18n.t("common.no"), value: "0" }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-select",
                    {
                      staticClass: "filter-item",
                      staticStyle: { width: "180px", "margin-left": "10px" },
                      attrs: {
                        placeholder: _vm.$i18n.t("common.Loginstatus"),
                        clearable: ""
                      },
                      model: {
                        value: _vm.query.status,
                        callback: function($$v) {
                          _vm.$set(_vm.query, "status", $$v)
                        },
                        expression: "query.status"
                      }
                    },
                    [
                      _c("el-option", {
                        attrs: {
                          label: _vm.$i18n.t("common.normal"),
                          value: "1"
                        }
                      }),
                      _c("el-option", {
                        attrs: {
                          label: _vm.$i18n.t("common.Disabled"),
                          value: "0"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-select",
                    {
                      staticClass: "filter-item",
                      staticStyle: { width: "180px", "margin-left": "10px" },
                      attrs: {
                        placeholder: _vm.$i18n.t("common.memberOrNot"),
                        clearable: ""
                      },
                      model: {
                        value: _vm.query.userMember,
                        callback: function($$v) {
                          _vm.$set(_vm.query, "userMember", $$v)
                        },
                        expression: "query.userMember"
                      }
                    },
                    [
                      _c("el-option", {
                        attrs: { label: _vm.$i18n.t("common.yes"), value: "1" }
                      }),
                      _c("el-option", {
                        attrs: { label: _vm.$i18n.t("common.no"), value: "0" }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c("dataSelect", {
                    staticClass: "filter-item",
                    staticStyle: { width: "180px", "margin-left": "10px" },
                    attrs: {
                      clearable: "",
                      codeType: "register_source",
                      placeholder: _vm.$i18n.t("common.brand")
                    },
                    model: {
                      value: _vm.query.registerSource,
                      callback: function($$v) {
                        _vm.$set(_vm.query, "registerSource", $$v)
                      },
                      expression: "query.registerSource"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c("dataSelect", {
                    staticClass: "filter-item",
                    staticStyle: { width: "150px", "margin-left": "10px" },
                    attrs: {
                      clearable: "",
                      codeType: "user_type",
                      placeholder: _vm.$i18n.t("common.UserType")
                    },
                    model: {
                      value: _vm.query.userType,
                      callback: function($$v) {
                        _vm.$set(_vm.query, "userType", $$v)
                      },
                      expression: "query.userType"
                    }
                  })
                ],
                1
              ),
              _c(
                "div",
                { staticStyle: { padding: "auto" } },
                [
                  _c(
                    "el-form-item",
                    [
                      _c("el-date-picker", {
                        attrs: {
                          type: "daterange",
                          "range-separator": _vm.$i18n.t("common.to"),
                          "start-placeholder": _vm.$i18n.t(
                            "common.StartDateOfMembership"
                          ),
                          "end-placeholder": _vm.$i18n.t(
                            "common.StartDateOfMembership"
                          ),
                          format: "yyyy-MM-dd",
                          "default-time": ["00:00:00", "23:59:59"]
                        },
                        model: {
                          value: _vm.vipPicker,
                          callback: function($$v) {
                            _vm.vipPicker = $$v
                          },
                          expression: "vipPicker"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    [
                      _c("el-date-picker", {
                        attrs: {
                          type: "datetimerange",
                          "range-separator": _vm.$i18n.t("common.to"),
                          "start-placeholder": _vm.$i18n.t("common.Startdate"),
                          "end-placeholder": _vm.$i18n.t("common.Enddate"),
                          "picker-options": _vm.pickerOptions
                        },
                        model: {
                          value: _vm.createPicker,
                          callback: function($$v) {
                            _vm.createPicker = $$v
                          },
                          expression: "createPicker"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    [
                      _c("el-date-picker", {
                        attrs: {
                          type: "datetimerange",
                          "range-separator": _vm.$i18n.t("common.to"),
                          "start-placeholder": _vm.$i18n.t(
                            "common.Lastlogintime"
                          ),
                          "end-placeholder": _vm.$i18n.t(
                            "common.Lastlogintime"
                          ),
                          "picker-options": _vm.pickerOptions
                        },
                        model: {
                          value: _vm.loginPicker,
                          callback: function($$v) {
                            _vm.loginPicker = $$v
                          },
                          expression: "loginPicker"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    [
                      _c(
                        "el-button",
                        {
                          staticClass: "filter-item",
                          staticStyle: { "margin-left": "10px" },
                          attrs: { icon: "el-icon-search", type: "primary" },
                          on: { click: _vm.handleQuery }
                        },
                        [
                          _vm._v(
                            "\n                        " +
                              _vm._s(_vm.$i18n.t("common.query")) +
                              "\n                    "
                          )
                        ]
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    [
                      _c(
                        "el-button",
                        {
                          staticClass: "filter-item",
                          staticStyle: { "margin-left": "10px" },
                          attrs: {
                            icon: "el-icon-search",
                            type: "primary",
                            size: "small "
                          },
                          on: { click: _vm.cleanQuery }
                        },
                        [
                          _vm._v(
                            "\n                        " +
                              _vm._s(_vm.$i18n.t("common.Reset")) +
                              "\n                    "
                          )
                        ]
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    [
                      _c(
                        "el-button",
                        {
                          directives: [
                            {
                              name: "loading",
                              rawName: "v-loading.fullscreen.lock",
                              value: _vm.fullscreenLoading,
                              expression: "fullscreenLoading",
                              modifiers: { fullscreen: true, lock: true }
                            }
                          ],
                          staticClass: "filter-item",
                          staticStyle: { "margin-left": "10px" },
                          attrs: {
                            icon: "el-icon-search",
                            type: "primary",
                            size: "small"
                          },
                          on: { click: _vm.handleExport }
                        },
                        [
                          _vm._v(
                            "\n                        " +
                              _vm._s(_vm.$i18n.t("common.export")) +
                              "\n                    "
                          )
                        ]
                      )
                    ],
                    1
                  ),
                  !_vm.scene.isNonSelf
                    ? _c(
                        "el-form-item",
                        [
                          _c(
                            "el-button",
                            {
                              staticClass: "filter-item",
                              staticStyle: { "margin-left": "10px" },
                              attrs: {
                                icon: "el-icon-coin",
                                type: "primary",
                                size: "small"
                              },
                              on: { click: _vm.handleBatchRecharge }
                            },
                            [
                              _vm._v(
                                "\n                        " +
                                  _vm._s(_vm.$i18n.t("common.BatchRecharge")) +
                                  "\n                    "
                              )
                            ]
                          )
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading"
            }
          ],
          staticStyle: { width: "100%", "margin-top": "20px" },
          attrs: {
            data: _vm.list.list,
            border: "",
            stripe: "",
            size: "medium",
            height: "calc(100% - 170px)"
          }
        },
        [
          _c("el-table-column", {
            attrs: {
              label: _vm.$i18n.t("common.UserID"),
              prop: "userId",
              align: "center",
              "show-overflow-tooltip": ""
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "countryCode",
              label: _vm.$i18n.t("common.Registeredcountry"),
              align: "center",
              width: "80px"
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "email",
              label: _vm.$i18n.t("common.mailbox"),
              "show-overflow-tooltip": "",
              align: "center"
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "phoneNumber",
              label: _vm.$i18n.t("common.Mobilephonenumber"),
              "show-overflow-tooltip": "",
              align: "center"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    row.phoneNumber && row.phoneNumber != ""
                      ? _c("span", [
                          _vm._v(
                            "\n                    " +
                              _vm._s("+" + row.phoneNumber) +
                              "\n                "
                          )
                        ])
                      : _vm._e()
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              prop: "userCurrency",
              label: _vm.$i18n.t("common.Patrioticcoin"),
              width: "80px",
              align: "center"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    row.userCurrency != 0 && row.userCurrency != null
                      ? _c("span", [
                          _vm._v(
                            _vm._s(
                              _vm.$dict.getDictValue(
                                "country_currency",
                                row.countryCode
                              )
                            ) + "\n                "
                          )
                        ])
                      : _vm._e(),
                    _c("span", [
                      _vm._v(
                        _vm._s(row.userCurrency != null ? row.userCurrency : 0)
                      )
                    ])
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              prop: "addressCountryStr",
              label: _vm.$i18n.t("common.DeliveryCountry"),
              "show-overflow-tooltip": "",
              align: "center"
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "userType",
              label: _vm.$i18n.t("common.UserType"),
              align: "center",
              "show-overflow-tooltip": ""
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    _c("dataText", {
                      attrs: { codeType: "user_type" },
                      model: {
                        value: row.userType,
                        callback: function($$v) {
                          _vm.$set(row, "userType", $$v)
                        },
                        expression: "row.userType"
                      }
                    })
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              prop: "dealOrderNum",
              label: _vm.$i18n.t("common.NumberOfCompletedOrders"),
              "show-overflow-tooltip": "",
              align: "center"
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "isSubscribe",
              label: _vm.$i18n.t("common.Whethertosubscribe"),
              width: "80px",
              align: "center"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    row.isSubscribe == true
                      ? _c("span", [
                          _vm._v(" " + _vm._s(_vm.$i18n.t("common.yes")) + " ")
                        ])
                      : _vm._e(),
                    row.isSubscribe == false
                      ? _c("span", [_vm._v(_vm._s(_vm.$i18n.t("common.no")))])
                      : _vm._e()
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              label: _vm.$i18n.t("common.RegistrationInviterID"),
              prop: "inviterUserId",
              align: "center",
              "show-overflow-tooltip": ""
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    row.inviterUserId != ""
                      ? _c("span", [_vm._v(_vm._s(row.inviterUserId))])
                      : _vm._e(),
                    row.inviterUserId == null || row.inviterUserId == ""
                      ? _c("span", [
                          _vm._v(_vm._s(_vm.$i18n.t("common.nothing")))
                        ])
                      : _vm._e()
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              label: _vm.$i18n.t("common.memberOrNot"),
              prop: "userMember",
              align: "center",
              "show-overflow-tooltip": ""
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    row.userMember == true
                      ? _c("span", [_vm._v(_vm._s(_vm.$i18n.t("common.yes")))])
                      : _vm._e(),
                    row.userMember == false
                      ? _c("span", [_vm._v(_vm._s(_vm.$i18n.t("common.no")))])
                      : _vm._e()
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              label: _vm.$i18n.t("common.StartDateOfMembership"),
              prop: "startTime",
              align: "center",
              "show-overflow-tooltip": ""
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    row.startTime
                      ? _c("span", [
                          _vm._v(_vm._s(_vm._f("formatDateYmd")(row.startTime)))
                        ])
                      : _vm._e()
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              label: _vm.$i18n.t("common.EndDateOfMembership"),
              prop: "endTime",
              align: "center",
              "show-overflow-tooltip": ""
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    row.endTime
                      ? _c("span", [
                          _vm._v(_vm._s(_vm._f("formatDateYmd")(row.endTime)))
                        ])
                      : _vm._e()
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              label: _vm.$i18n.t("common.AIGOCoinsAreCloseToExpiring"),
              prop: "currencyExpireDay",
              align: "center",
              "show-overflow-tooltip": ""
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "registerSource",
              label: _vm.$i18n.t("common.brand"),
              "show-overflow-tooltip": "",
              align: "center"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    _vm._v(
                      "\n                " +
                        _vm._s(
                          _vm.$dict.getDictValue(
                            "register_source",
                            row.registerSource
                          )
                        ) +
                        "\n            "
                    )
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              label: _vm.$i18n.t("common.Creationtime"),
              prop: "createTime",
              align: "center",
              "show-overflow-tooltip": "",
              formatter: _vm.formatDate
            }
          }),
          !_vm.scene.isAigoSmartApp
            ? _c("el-table-column", {
                attrs: {
                  prop: "sourceUrlName",
                  label: _vm.$i18n.t("common.source"),
                  "show-overflow-tooltip": "",
                  align: "center"
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(ref) {
                        var row = ref.row
                        return [
                          _c("span", [
                            _vm._v(
                              _vm._s(_vm.showSourceTranslate(row.sourceUrlName))
                            )
                          ])
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  316653420
                )
              })
            : _vm._e(),
          _c("el-table-column", {
            attrs: {
              label: _vm.$i18n.t("common.Lastlogintime"),
              prop: "lastLoginTime",
              align: "center",
              "show-overflow-tooltip": "",
              formatter: _vm.formatDate
            }
          }),
          _c("el-table-column", {
            attrs: {
              label: _vm.$i18n.t("common.Loginstatus"),
              prop: "status",
              align: "center",
              "show-overflow-tooltip": ""
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    _c("el-switch", {
                      attrs: { "active-color": "#13ce66", disabled: true },
                      on: {
                        change: function($event) {
                          return _vm.loginStatus(row)
                        }
                      },
                      model: {
                        value: row.status,
                        callback: function($$v) {
                          _vm.$set(row, "status", $$v)
                        },
                        expression: "row.status"
                      }
                    })
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              label: _vm.$i18n.t("common.operation"),
              align: "center",
              width: "360"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    _c(
                      "el-button",
                      {
                        staticClass: "table-inner-button",
                        attrs: { type: "text", size: "small" },
                        on: {
                          click: function($event) {
                            return _vm.detailHandle(row)
                          }
                        }
                      },
                      [_vm._v(_vm._s(_vm.$i18n.t("common.check")))]
                    ),
                    _c(
                      "el-button",
                      {
                        staticClass: "table-inner-button",
                        attrs: { type: "text", size: "small" },
                        on: {
                          click: function($event) {
                            return _vm.orderHandle(row.userId)
                          }
                        }
                      },
                      [_vm._v(_vm._s(_vm.$i18n.t("common.orderform")))]
                    ),
                    _c(
                      "el-button",
                      {
                        staticClass: "table-inner-button",
                        attrs: { type: "text", size: "small" },
                        on: {
                          click: function($event) {
                            return _vm.consultHandle(row.userId)
                          }
                        }
                      },
                      [_vm._v(_vm._s(_vm.$i18n.t("common.consultingservice")))]
                    ),
                    _c(
                      "el-button",
                      {
                        staticClass: "table-inner-button",
                        attrs: { type: "text", size: "small" },
                        on: {
                          click: function($event) {
                            return _vm.addressHandle(row)
                          }
                        }
                      },
                      [_vm._v(_vm._s(_vm.$i18n.t("common.address")))]
                    ),
                    _c(
                      "el-button",
                      {
                        staticClass: "table-inner-button",
                        attrs: { type: "text", size: "small" },
                        on: {
                          click: function($event) {
                            return _vm.loginLogHandle(row.userId)
                          }
                        }
                      },
                      [_vm._v(_vm._s(_vm.$i18n.t("common.LoginLog")))]
                    ),
                    !_vm.scene.isNonSelf
                      ? _c(
                          "el-button",
                          {
                            staticClass: "table-inner-button",
                            attrs: { type: "text", size: "small" },
                            on: {
                              click: function($event) {
                                return _vm.rechargeHandle(row)
                              }
                            }
                          },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.$i18n.t("common.Rechargepatrioticcoins")
                              )
                            )
                          ]
                        )
                      : _vm._e(),
                    !_vm.scene.isNonSelf
                      ? _c(
                          "el-button",
                          {
                            staticClass: "table-inner-button",
                            attrs: { type: "text", size: "small" },
                            on: {
                              click: function($event) {
                                return _vm.vipHandle(row)
                              }
                            }
                          },
                          [_vm._v(_vm._s(_vm.$i18n.t("common.MemberSettings")))]
                        )
                      : _vm._e()
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "block" },
        [
          _c("el-pagination", {
            staticStyle: { "margin-top": "10px" },
            attrs: {
              background: "",
              "current-page": _vm.query.page,
              "page-size": _vm.query.size,
              layout: "total, prev, pager, next, jumper",
              total: _vm.list.total
            },
            on: { "current-change": _vm.handleUserInfoCurrentChange }
          })
        ],
        1
      ),
      _vm.detailVisible
        ? _c(
            "el-dialog",
            {
              attrs: {
                title: _vm.diologTitle,
                visible: _vm.detailVisible,
                width: "930px",
                "close-on-click-modal": false
              },
              on: {
                "update:visible": function($event) {
                  _vm.detailVisible = $event
                }
              }
            },
            [
              _c(
                "div",
                [
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary", plain: "", size: "medium" },
                          on: {
                            click: function($event) {
                              return _vm.orderHandle(_vm.userInfo.userId)
                            }
                          }
                        },
                        [_vm._v(_vm._s(_vm.$i18n.t("common.orderform")))]
                      ),
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary", plain: "", size: "medium" },
                          on: {
                            click: function($event) {
                              return _vm.returnHandle(_vm.userInfo.userId)
                            }
                          }
                        },
                        [
                          _vm._v(
                            _vm._s(_vm.$i18n.t("common.Returnsandexchanges"))
                          )
                        ]
                      ),
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary", plain: "", size: "medium" },
                          on: {
                            click: function($event) {
                              return _vm.consultHandle(_vm.userInfo.userId)
                            }
                          }
                        },
                        [
                          _vm._v(
                            _vm._s(_vm.$i18n.t("common.consultingservice"))
                          )
                        ]
                      ),
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary", plain: "", size: "medium" },
                          on: {
                            click: function($event) {
                              return _vm.commentHandle(_vm.userInfo.email)
                            }
                          }
                        },
                        [_vm._v(_vm._s(_vm.$i18n.t("common.evaluate")))]
                      ),
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary", plain: "", size: "medium" },
                          on: {
                            click: function($event) {
                              return _vm.diologAddressHandle()
                            }
                          }
                        },
                        [_vm._v(_vm._s(_vm.$i18n.t("common.Shippingaddress")))]
                      ),
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary", plain: "", size: "medium" },
                          on: {
                            click: function($event) {
                              return _vm.shoppingCarHandle()
                            }
                          }
                        },
                        [_vm._v(_vm._s(_vm.$i18n.t("common.shoppingcart")))]
                      ),
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary", plain: "", size: "medium" },
                          on: {
                            click: function($event) {
                              return _vm.collectionHandle()
                            }
                          }
                        },
                        [_vm._v(_vm._s(_vm.$i18n.t("common.collection")))]
                      ),
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary", plain: "", size: "medium" },
                          on: {
                            click: function($event) {
                              return _vm.invoiceHandle(_vm.userInfo.userId)
                            }
                          }
                        },
                        [_vm._v(_vm._s(_vm.$i18n.t("common.invoice")))]
                      ),
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary", plain: "", size: "medium" },
                          on: {
                            click: function($event) {
                              return _vm.userCurrencyHandle(_vm.userInfo.userId)
                            }
                          }
                        },
                        [_vm._v(_vm._s(_vm.$i18n.t("common.Patrioticcoin")))]
                      ),
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary", plain: "", size: "medium" },
                          on: {
                            click: function($event) {
                              return _vm.loginLogHandle(_vm.userInfo.userId)
                            }
                          }
                        },
                        [_vm._v(_vm._s(_vm.$i18n.t("common.LoginLog")))]
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "div",
                { staticStyle: { "margin-top": "15px" } },
                [
                  _c(
                    "el-descriptions",
                    {
                      attrs: {
                        title: _vm.$i18n.t("common.Account1"),
                        column: 3,
                        size: "medium"
                      }
                    },
                    [
                      _c("el-descriptions-item", { attrs: { label: "ID" } }, [
                        _vm._v(_vm._s(_vm.userInfo.userId))
                      ]),
                      _c(
                        "el-descriptions-item",
                        {
                          attrs: {
                            label: _vm.$i18n.t("common.Registeredcountry")
                          }
                        },
                        [
                          [
                            _vm._v(
                              "\n                        " +
                                _vm._s(
                                  _vm.$dict.getDictValue(
                                    "countrys_all",
                                    _vm.userInfo.countryCode
                                  )
                                ) +
                                "\n                    "
                            )
                          ]
                        ],
                        2
                      ),
                      _c(
                        "el-descriptions-item",
                        {
                          attrs: {
                            label: _vm.$i18n.t("common.nickname"),
                            contentStyle: { width: "250px" }
                          }
                        },
                        [
                          [
                            _vm.userInfo.nickname.indexOf("@") >= 0
                              ? _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.userInfo.nickname.substring(
                                        0,
                                        _vm.userInfo.nickname.indexOf("@") - 1
                                      )
                                    ) + "\n                        "
                                  )
                                ])
                              : _c("span", [
                                  _vm._v(_vm._s(_vm.userInfo.nickname))
                                ])
                          ]
                        ],
                        2
                      ),
                      _c(
                        "el-descriptions-item",
                        {
                          attrs: {
                            label: _vm.$i18n.t("common.RegistrationInviterID")
                          }
                        },
                        [
                          [
                            _vm.userInfo.inviterUserId != ""
                              ? _c("span", [
                                  _vm._v(_vm._s(_vm.userInfo.inviterUserId))
                                ])
                              : _vm._e(),
                            _vm.userInfo.inviterUserId == null ||
                            _vm.userInfo.inviterUserId == ""
                              ? _c("span", [
                                  _vm._v(_vm._s(_vm.$i18n.t("common.nothing")))
                                ])
                              : _vm._e()
                          ]
                        ],
                        2
                      ),
                      _c(
                        "el-descriptions-item",
                        { attrs: { label: _vm.$i18n.t("common.Loginstatus") } },
                        [
                          [
                            _vm.userInfo.status
                              ? _c("span", [
                                  _vm._v(_vm._s(_vm.$i18n.t("common.normal")))
                                ])
                              : _c("span", [
                                  _vm._v(_vm._s(_vm.$i18n.t("common.close")))
                                ])
                          ]
                        ],
                        2
                      ),
                      _c(
                        "el-descriptions-item",
                        { attrs: { label: _vm.$i18n.t("common.source") } },
                        [
                          !_vm.scene.isAigoSmartApp
                            ? [
                                _vm._v(
                                  "\n                        " +
                                    _vm._s(
                                      _vm.showSourceTranslate(
                                        _vm.userInfo.sourceUrlName
                                      )
                                    ) +
                                    "\n                    "
                                )
                              ]
                            : [
                                _vm._v(
                                  "\n                        " +
                                    _vm._s(
                                      _vm.$dict.getDictValue(
                                        "source_type",
                                        _vm.userInfo.sourceType
                                      )
                                    ) +
                                    "\n                    "
                                )
                              ]
                        ],
                        2
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-descriptions",
                    { attrs: { column: 2, size: "medium" } },
                    [
                      _c(
                        "el-descriptions-item",
                        { attrs: { label: _vm.$i18n.t("common.mailbox") } },
                        [_vm._v(_vm._s(_vm.userInfo.email))]
                      ),
                      _c(
                        "el-descriptions-item",
                        { attrs: { label: _vm.$i18n.t("common.brand") } },
                        [
                          [
                            _vm._v(
                              "\n                        " +
                                _vm._s(
                                  _vm.$dict.getDictValue(
                                    "register_source",
                                    _vm.userInfo.registerSource
                                  )
                                ) +
                                "\n                    "
                            )
                          ]
                        ],
                        2
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-descriptions",
                    { attrs: { column: 2, size: "medium" } },
                    [
                      _c(
                        "el-descriptions-item",
                        {
                          attrs: {
                            label: _vm.$i18n.t("common.Mobilephonenumber")
                          }
                        },
                        [
                          [
                            _vm.userInfo.phoneNumber &&
                            _vm.userInfo.phoneNumber != ""
                              ? _c("span", [
                                  _vm._v(
                                    "\n                            " +
                                      _vm._s("+" + _vm.userInfo.phoneNumber) +
                                      "\n                        "
                                  )
                                ])
                              : _vm._e()
                          ]
                        ],
                        2
                      ),
                      _c(
                        "el-descriptions-item",
                        { attrs: { label: _vm.$i18n.t("common.UserType") } },
                        [
                          [
                            _vm._v(
                              "\n                        " +
                                _vm._s(
                                  _vm.$dict.getDictValue(
                                    "user_type",
                                    _vm.userInfo.userType
                                  )
                                ) +
                                "\n                    "
                            )
                          ]
                        ],
                        2
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-descriptions",
                    { attrs: { column: 2, size: "medium" } },
                    [
                      _c(
                        "el-descriptions-item",
                        {
                          attrs: {
                            label: _vm.$i18n.t("common.Whethertosubscribe")
                          }
                        },
                        [
                          [
                            _vm.userInfo.isSubscribe
                              ? _c("span", [
                                  _vm._v(_vm._s(_vm.$i18n.t("common.yes")))
                                ])
                              : _c("span", [
                                  _vm._v(_vm._s(_vm.$i18n.t("common.no")))
                                ])
                          ]
                        ],
                        2
                      ),
                      _c(
                        "el-descriptions-item",
                        {
                          attrs: {
                            label: _vm.$i18n.t("common.Subscriptiontime")
                          }
                        },
                        [
                          _vm._v(
                            _vm._s(_vm._f("formatDate")(_vm.userInfo.subTime))
                          )
                        ]
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-descriptions",
                    { attrs: { column: 2, size: "medium" } },
                    [
                      _c(
                        "el-descriptions-item",
                        {
                          attrs: { label: _vm.$i18n.t("common.Creationtime") }
                        },
                        [
                          _vm._v(
                            _vm._s(
                              _vm._f("formatDate")(_vm.userInfo.createTime)
                            )
                          )
                        ]
                      ),
                      _c(
                        "el-descriptions-item",
                        {
                          attrs: { label: _vm.$i18n.t("common.Lastlogintime") }
                        },
                        [
                          _vm._v(
                            _vm._s(
                              _vm._f("formatDate")(_vm.userInfo.lastLoginTime)
                            )
                          )
                        ]
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-descriptions",
                    { attrs: { column: 2, size: "medium" } },
                    [
                      _c(
                        "el-descriptions-item",
                        {
                          attrs: {
                            label: _vm.$i18n.t("common.Thirdpartylogin")
                          }
                        },
                        [
                          [
                            _c("div", [
                              _c(
                                "span",
                                {
                                  staticStyle: { margin: "0px 100px 0px 50px" }
                                },
                                [_vm._v("Google")]
                              ),
                              (_vm.userInfo.externalProviders & 1) == 1
                                ? _c("span", [
                                    _vm._v(_vm._s(_vm.$i18n.t("common.Bound")))
                                  ])
                                : _vm._e(),
                              (_vm.userInfo.externalProviders & 1) == 0
                                ? _c("span", [
                                    _vm._v(
                                      _vm._s(_vm.$i18n.t("common.Unbound"))
                                    )
                                  ])
                                : _vm._e()
                            ])
                          ]
                        ],
                        2
                      ),
                      _c(
                        "el-descriptions-item",
                        {
                          attrs: {
                            label: _vm.$i18n.t("common.NumberOfCompletedOrders")
                          }
                        },
                        [_vm._v(_vm._s(_vm.userInfo.dealOrderNum))]
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "div",
                [
                  _c(
                    "el-menu",
                    {
                      staticClass: "el-menu-demo",
                      attrs: {
                        "default-active": _vm.contentIndex,
                        mode: "horizontal"
                      },
                      on: { select: _vm.handleContentSelect }
                    },
                    _vm._l(_vm.countrysDicts, function(item, index) {
                      return _c(
                        "el-menu-item",
                        { key: item.code, attrs: { index: item.code } },
                        [
                          _vm._v(
                            "\n                    " +
                              _vm._s(item.name) +
                              "\n                "
                          )
                        ]
                      )
                    }),
                    1
                  ),
                  _c(
                    "el-descriptions",
                    {
                      staticStyle: { "margin-top": "15px" },
                      attrs: { column: 2, size: "medium" }
                    },
                    [
                      _c(
                        "el-descriptions-item",
                        {
                          attrs: { label: _vm.$i18n.t("common.Patrioticcoin") }
                        },
                        [
                          _c(
                            "span",
                            [
                              _c(
                                "el-button",
                                {
                                  staticStyle: { padding: "0px" },
                                  attrs: { type: "text", size: "medium" },
                                  on: {
                                    click: function($event) {
                                      return _vm.showCurrencyDetail(
                                        _vm.userDetailIndex.userId,
                                        _vm.userDetailIndex.countryCode
                                      )
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.userDetailIndex.userCurrencyAmount
                                    ) + "\n                        "
                                  )
                                ]
                              )
                            ],
                            1
                          ),
                          _vm.userDetailIndex.firstExpireTime != null
                            ? _c("span", [
                                _vm._v(
                                  "\n                        ( " +
                                    _vm._s(
                                      _vm.$i18n.t("common.ValidityPeriod")
                                    ) +
                                    "\n                         " +
                                    _vm._s(
                                      _vm._f("formatDateYmd")(
                                        _vm.userDetailIndex.firstExpireTime
                                      )
                                    ) +
                                    ")\n                    "
                                )
                              ])
                            : _vm._e()
                        ]
                      ),
                      _c(
                        "el-descriptions-item",
                        {
                          attrs: { label: _vm.$i18n.t("common.Invitationcode") }
                        },
                        [_vm._v(_vm._s(_vm.userDetailIndex.invitationCode))]
                      ),
                      _c(
                        "el-descriptions-item",
                        { attrs: { label: _vm.$i18n.t("common.Inviteduser") } },
                        [_vm._v(_vm._s(_vm.userDetailIndex.invitedUsers))]
                      ),
                      _c(
                        "el-descriptions-item",
                        {
                          attrs: {
                            label: _vm.$i18n.t(
                              "common.Invitetoreceivepatrioticcoins"
                            )
                          }
                        },
                        [_vm._v(_vm._s(_vm.userDetailIndex.invitedCurrency))]
                      ),
                      _c(
                        "el-descriptions-item",
                        {
                          attrs: {
                            label: _vm.$i18n.t(
                              "common.Isthefirstorderfreeofshipping"
                            )
                          }
                        },
                        [
                          [
                            _vm.userDetailIndex.free == true
                              ? _c("span", [
                                  _vm._v(_vm._s(_vm.$i18n.t("common.yes")))
                                ])
                              : _c("span", [
                                  _vm._v(_vm._s(_vm.$i18n.t("common.no")))
                                ])
                          ]
                        ],
                        2
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "div",
                { staticStyle: { "margin-top": "15px" } },
                [
                  _c(
                    "el-descriptions",
                    {
                      attrs: {
                        title: _vm.$i18n.t("common.shopping"),
                        column: 3,
                        size: "medium"
                      }
                    },
                    [
                      _c(
                        "el-descriptions-item",
                        {
                          attrs: {
                            label: _vm.$i18n.t("common.Shippingaddress")
                          }
                        },
                        [_vm._v(_vm._s(_vm.userDetailIndex.addressAmount))]
                      ),
                      _c(
                        "el-descriptions-item",
                        {
                          attrs: {
                            label: _vm.$i18n.t("common.Collectproducts")
                          }
                        },
                        [_vm._v(_vm._s(_vm.userDetailIndex.collectionAmount))]
                      ),
                      _c(
                        "el-descriptions-item",
                        {
                          attrs: {
                            label: _vm.$i18n.t("common.ShoppingCartItems")
                          }
                        },
                        [_vm._v(_vm._s(_vm.userDetailIndex.shoppingCarAmount))]
                      ),
                      _c(
                        "el-descriptions-item",
                        {
                          attrs: { label: _vm.$i18n.t("common.PaymentOrder") }
                        },
                        [_vm._v(_vm._s(_vm.userDetailIndex.payOrderAmount))]
                      ),
                      _c(
                        "el-descriptions-item",
                        {
                          attrs: {
                            label: _vm.$i18n.t("common.Ordercumulativeamount")
                          }
                        },
                        [_vm._v(_vm._s(_vm.userDetailIndex.orderPriceAmount))]
                      ),
                      _c(
                        "el-descriptions-item",
                        {
                          attrs: { label: _vm.$i18n.t("common.Lastordertime") }
                        },
                        [_vm._v(_vm._s(_vm.userDetailIndex.lastOrderTime))]
                      ),
                      _c(
                        "el-descriptions-item",
                        {
                          attrs: {
                            label: _vm.$i18n.t("common.Returnandexchangeorders")
                          }
                        },
                        [_vm._v(_vm._s(_vm.userDetailIndex.cancelOrderAmount))]
                      ),
                      _c(
                        "el-descriptions-item",
                        { attrs: { label: _vm.$i18n.t("common.invoice") } },
                        [_vm._v(_vm._s(_vm.userDetailIndex.invoiceAmount))]
                      ),
                      _c(
                        "el-descriptions-item",
                        {
                          attrs: {
                            label: _vm.$i18n.t("common.Consultationfrequency")
                          }
                        },
                        [_vm._v(_vm._s(_vm.userDetailIndex.consultationAmount))]
                      ),
                      _c(
                        "el-descriptions-item",
                        {
                          attrs: {
                            label: _vm.$i18n.t("common.Numberofevaluations")
                          }
                        },
                        [_vm._v(_vm._s(_vm.userDetailIndex.commentAmount))]
                      ),
                      _c(
                        "el-descriptions-item",
                        {
                          attrs: {
                            label: _vm.$i18n.t(
                              "common.Numberofapprovedevaluations"
                            )
                          }
                        },
                        [_vm._v(_vm._s(_vm.userDetailIndex.auditCommentAmount))]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ]
          )
        : _vm._e(),
      _vm.passwordVisible
        ? _c(
            "el-dialog",
            {
              attrs: {
                title: "修改密码",
                visible: _vm.passwordVisible,
                width: "500px"
              },
              on: {
                "update:visible": function($event) {
                  _vm.passwordVisible = $event
                }
              }
            },
            [
              _c("div", [_vm._v("输入新密码：")]),
              _c("el-input", {
                staticStyle: { "margin-top": "15px" },
                attrs: {
                  placeholder: "请输入新密码",
                  onkeyup: "this.value=this.value.replace(/[^\\w]/g,'')",
                  maxlength: "20"
                },
                model: {
                  value: _vm.password,
                  callback: function($$v) {
                    _vm.password = $$v
                  },
                  expression: "password"
                }
              }),
              _c(
                "span",
                {
                  staticStyle: {
                    "margin-left": "320px",
                    "font-size": "10px",
                    color: "green"
                  }
                },
                [_vm._v("只允许输入数字+字母组合")]
              ),
              _c(
                "div",
                {
                  staticClass: "dialog-footer",
                  attrs: { slot: "footer" },
                  slot: "footer"
                },
                [
                  _c(
                    "el-button",
                    {
                      on: {
                        click: function($event) {
                          return _vm.closeForm()
                        }
                      }
                    },
                    [_vm._v(" " + _vm._s(_vm.$i18n.t("common.cancel")) + " ")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: {
                        click: function($event) {
                          return _vm.handleSubmit()
                        }
                      }
                    },
                    [_vm._v(" " + _vm._s(_vm.$i18n.t("common.confirm1")) + " ")]
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _vm.vipDialogVisible
        ? _c(
            "el-dialog",
            {
              attrs: {
                title: _vm.$i18n.t("common.MemberSettings"),
                center: "",
                visible: _vm.vipDialogVisible,
                width: "40%",
                "close-on-click-modal": false
              },
              on: {
                "update:visible": function($event) {
                  _vm.vipDialogVisible = $event
                },
                close: _vm.closeVip
              }
            },
            [
              _c(
                "div",
                [
                  _c(
                    "el-form",
                    {
                      ref: "vipForm",
                      attrs: {
                        model: _vm.vipForm,
                        rules: _vm.vipRules,
                        "label-width": "190px"
                      }
                    },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$i18n.t("common.Account1") + ":",
                            prop: "userMember"
                          }
                        },
                        [
                          _c("el-input", {
                            staticStyle: { width: "220px" },
                            attrs: {
                              disabled: true,
                              placeholder: _vm.$i18n.t(
                                "common.PleaseEnterTheAccountName"
                              )
                            },
                            model: {
                              value: _vm.vipForm.userMember,
                              callback: function($$v) {
                                _vm.$set(_vm.vipForm, "userMember", $$v)
                              },
                              expression: "vipForm.userMember"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label:
                              _vm.$i18n.t("common.StartDateOfMembership") + ":",
                            prop: "startTime"
                          }
                        },
                        [
                          _c("el-date-picker", {
                            attrs: {
                              type: "date",
                              "value-format": "timestamp",
                              placeholder: _vm.$i18n.t(
                                "common.Pleaseselectastartdate"
                              )
                            },
                            model: {
                              value: _vm.vipForm.startTime,
                              callback: function($$v) {
                                _vm.$set(_vm.vipForm, "startTime", $$v)
                              },
                              expression: "vipForm.startTime"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label:
                              _vm.$i18n.t("common.EndDateOfMembership") + ":",
                            prop: "endTime"
                          }
                        },
                        [
                          _c("el-date-picker", {
                            attrs: {
                              type: "date",
                              "value-format": "timestamp",
                              placeholder: _vm.$i18n.t(
                                "common.Pleaseselecttheenddate"
                              )
                            },
                            model: {
                              value: _vm.vipForm.endTime,
                              callback: function($$v) {
                                _vm.$set(_vm.vipForm, "endTime", $$v)
                              },
                              expression: "vipForm.endTime"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "div",
                {
                  staticClass: "dialog-footer",
                  attrs: { slot: "footer" },
                  slot: "footer"
                },
                [
                  _c("el-button", { on: { click: _vm.closeVip } }, [
                    _vm._v(" " + _vm._s(_vm.$i18n.t("common.Cancel")) + " ")
                  ]),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary", loading: _vm.vipLoading },
                      on: {
                        click: function($event) {
                          return _vm.handleVipSubmit("vipForm")
                        }
                      }
                    },
                    [
                      _vm._v(
                        "\n                " +
                          _vm._s(_vm.$i18n.t("common.Submit")) +
                          "\n            "
                      )
                    ]
                  )
                ],
                1
              )
            ]
          )
        : _vm._e(),
      _c("addressHandle", { ref: "addressHandle" }),
      _c("collectionHandle", { ref: "collectionHandle" }),
      _c("shoppingCarHandle", { ref: "shoppingCarHandle" }),
      _c("rechargeHandle", { ref: "rechargeHandle" }),
      _c("batchRechargeHandle", { ref: "batchRechargeHandle" }),
      _c("userCurrencyHandle", { ref: "userCurrencyHandle" })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }