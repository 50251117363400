var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.importFormLoading,
          expression: "importFormLoading"
        }
      ],
      attrs: {
        title: _vm.$i18n.t("common.SEOManagementImport"),
        visible: _vm.importFormVisible,
        width: "400px"
      },
      on: {
        "update:visible": function($event) {
          _vm.importFormVisible = $event
        }
      }
    },
    [
      _c(
        "el-upload",
        {
          attrs: {
            drag: "",
            action: _vm.importUrl,
            "on-success": _vm.onImportSuccess,
            "on-progress": _vm.onImportProgress,
            "on-error": _vm.onImportError,
            "show-file-list": false,
            headers: _vm.headerData
          }
        },
        [
          _c("i", { staticClass: "el-icon-upload" }),
          _c("div", { staticClass: "el-upload__text" }, [
            _vm._v(
              _vm._s(_vm.$i18n.t("common.DragtheExcelfilehere_orclickonupload"))
            )
          ])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }