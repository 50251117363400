<template>
    <div class="app-container" style="height: 100%;min-height:350px;">
        <div class="filter-container">
            <el-form :model="query" :inline="true" size="small" label-width="140px">
                <el-form-item>
                    <el-input
                        clearable
                        v-model="query.orderIdOrUserId"
                        :placeholder='$i18n.t("common.OrderNumber_UserID")'
                        style="width: 180px"
                        class="filter-item"
                        @keyup.enter.native="handleQuery"
                    />
                </el-form-item>
                <!--<el-form-item>
                    <dataSelect
                        style="width: 180px; margin-left: 10px"
                        class="filter-item"
                        v-model="query.brandCode"
                        clearable
                        :codeType="'brand'"
                        :placeholder='$i18n.t("common.brand")'
                    ></dataSelect>
                </el-form-item>-->
                <el-form-item>
                    <el-select
                        v-model="query.countryCode"
                        :multiple="true"
                        :placeholder='$i18n.t("common.country")'
                        style="width: 180px; margin-left: 10px"
                        class="filter-item"
                    >
                        <el-option
                            v-for="cc in countrysDicts"
                            :key="cc.code"
                            :label="cc.name + '(' + cc.code + ')'"
                            :value="cc.code"
                        ></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item>
                    <el-select
                        v-model="query.status"
                        :placeholder='$i18n.t("common.ReplyStatus")'
                        clearable
                        style="width: 180px; margin-left: 10px"
                        class="filter-item"
                    >
                        <el-option :label='$i18n.t("common.Replied")' value="1"></el-option>
                        <el-option :label='$i18n.t("common.Noresponse")' value="0"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item>
                    <dataSelect
                        style="width: 180px; margin-left: 10px"
                        class="filter-item"
                        v-model="query.orderStatus"
                        clearable
                        :codeType="'order_status'"
                        :placeholder='$i18n.t("common.OrderStatus")'
                    ></dataSelect>
                </el-form-item>
                <el-form-item>
                    <dataSelect
                        style="width: 180px; margin-left: 10px"
                        class="filter-item"
                        v-model="query.title"
                        clearable
                        :codeType="'consultationTitle'"
                        :placeholder='$i18n.t("common.content")'
                    ></dataSelect>
                </el-form-item>
                <el-form-item>
                    <el-select
                        v-model="query.replyAdmin"
                        :placeholder='$i18n.t("common.Respondent")'
                        clearable
                        style="width: 180px; margin-left: 10px"
                        class="filter-item"
                    >
                        <el-option label="111" value="111"></el-option>
                        <el-option label="222" value="222"></el-option>
                        <el-option label="333" value="333"></el-option>
                    </el-select>
                </el-form-item>
                <div style="padding: auto;">
                    <el-form-item>
                        <el-date-picker
                            v-model="valuePicker"
                            type="daterange"
                            :range-separator='$i18n.t("common.to")'
                            :start-placeholder='$i18n.t("common.Consultationdate")'
                            :end-placeholder='$i18n.t("common.Consultationdate")'
                            :picker-options="pickerOptions"
                        >
                        </el-date-picker>
                    </el-form-item>
                    <el-form-item>
                        <el-button class="filter-item" icon="el-icon-search" type="primary" @click="handleQuery" style="margin-left: 10px">
                           {{ $i18n.t("common.query")}}
                        </el-button>
                        <el-button
                            class="filter-item"
                            icon="el-icon-search"
                            type="primary"
                            @click="cleanQuery"
                            style="margin-left: 10px"
                            size="small"
                        >
                        {{ $i18n.t("common.Reset")}}
                        </el-button>
                    </el-form-item>
                </div>
            </el-form>

        </div>

        <el-table v-loading="listLoading" :data="list.list" border stripe style="width: 100%; margin-top: 20px" size="medium">

        <el-table-column :label='$i18n.t("common.UserID")' prop="userId" align="center" show-overflow-tooltip width='160'> </el-table-column>
            <el-table-column prop="countryCode" :label='$i18n.t("common.country")' align="center" width="50px"> </el-table-column>
            <el-table-column :label='$i18n.t("common.Ordernumber")' prop="orderNo" align="center" width="260px"> </el-table-column>
            <!-- <el-table-column prop="brandCode" :label='$i18n.t("common.brand")' show-overflow-tooltip align="center"> </el-table-column> -->
            <el-table-column prop="goodsCount" :label='$i18n.t("common.Numberofproductmodels")'  show-overflow-tooltip align="center"> </el-table-column>
            <el-table-column :label='$i18n.t("common.OrderStatus")' prop="orderStatus" align="center" show-overflow-tooltip>

                <template slot-scope="{ row }">
                    {{ $dict.getDictValue('order_status', row.orderStatus) }}
                </template>
            </el-table-column>
            <el-table-column prop="title" :label='$i18n.t("common.content")' show-overflow-tooltip align="center">
                <template slot-scope="{ row }">
                    {{ showConsultationTitle(row.title) }}
                </template>
            </el-table-column>
            <el-table-column prop="content" :label='$i18n.t("common.explain")' show-overflow-tooltip align="center">
                <template slot-scope="{ row }">
                    {{ row.content.slice(0, 200) }}
                </template>
            </el-table-column>
            <el-table-column :label='$i18n.t("common.Consultationtime")' prop="consultTime" align="center" show-overflow-tooltip :formatter="formatDate">
            </el-table-column>
            <el-table-column :label='$i18n.t("common.state")'   prop="status" align="center" show-overflow-tooltip>
                <template slot-scope="{ row }">
                    <span v-if="row.status == 0" style="color: red">{{$i18n.t("common.Noresponse") }}</span>
                    <span v-if="row.status == 1" style="color: grey">{{ $i18n.t("common.Replied") }}</span>
                </template>
            </el-table-column>
            <el-table-column :label='$i18n.t("common.Replytime")' prop="replyTime" align="center" show-overflow-tooltip :formatter="formatDate">
                <template slot-scope="{ row }">
                    <span v-if="row.status == 1">{{ formatTime(row.replyTime) }}</span>
                </template>
            </el-table-column>
            <el-table-column prop="replyAdmin" :label='$i18n.t("common.Respondent")' show-overflow-tooltip align="center"> </el-table-column>
            <el-table-column :label='$i18n.t("common.operation")'  align="center" width="230">
                <template slot-scope="{ row }">
                    <el-button v-if="row.status" type="primary" size="mini" @click="replyHandle(row)" class="table-inner-button"
                        >{{ $i18n.t("common.operation") }}</el-button
                    >
                    <el-button v-else type="danger" size="mini" @click="replyHandle(row)" class="table-inner-button">{{ $i18n.t("common.reply") }}</el-button>
                </template>
            </el-table-column>
        </el-table>
        <div class="block">
            <el-pagination
                style="margin-top: 10px"
                background
                @current-change="handleCurrentChange"
                :current-page="query.page"
                :page-size="query.size"
                layout="total, prev, pager, next, jumper"
                :total="list.total"
            ></el-pagination>
        </div>
        <el-dialog :title="diologTitle" :visible.sync="diologVisible" v-if="diologVisible" width="1000px" :close-on-click-modal="false" height="calc(100% - 140px)">
            <div>
                <span style="font-size: 125%">{{ $i18n.t("common.OrderInformation") }}</span>
                <el-table :data="replyList.orderDetails" style="margin-top: 10px" size="medium">
                    <el-table-column :label='$i18n.t("common.Ordernumber")' width="100px" align="center" prop="orderNo">
                        <template slot-scope="{ row }">
                            <!-- <el-button type="primary" plain size="medium" @click="orderHandle(row.orderNo)">{{row.orderNo}}}</el-button> -->
                            <el-link type="primary" @click="orderHandle(row.orderId)">{{ row.orderNo }}</el-link>
                        </template>
                    </el-table-column>

                     <!-- <el-table-column :label='$i18n.t("common.brand")'  show-overflow-tooltip align="center" prop="brandCode"></el-table-column> -->
                     <el-table-column :label='$i18n.t("common.country")' show-overflow-tooltip align="center" prop="countryCode"></el-table-column>
                    <el-table-column :label='$i18n.t("common.UserID")'  width="150px" align="center" prop="userId"></el-table-column>
                    <el-table-column :label='$i18n.t("common.Numberofproductmodels")' show-overflow-tooltip align="center">

                        <template>
                            <span>{{ replyList.goodsCount }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column :label='$i18n.t("common.source")' show-overflow-tooltip align="center" prop="source"></el-table-column>
                    <el-table-column :label='$i18n.t("common.money")'  show-overflow-tooltip align="center" prop="totalAmt">
                        <template slot-scope="{ row }">
                            <span>{{ $dict.getDictValue('country_currency', row.countryCode) }}{{ row.totalAmt }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column :label='$i18n.t("common.OrderStatus")' show-overflow-tooltip align="center" prop="orderStatus">
                        <template slot-scope="{ row }">
                            {{ $dict.getDictValue('order_status', row.orderStatus) }}
                        </template>
                    </el-table-column>
                    <el-table-column
                        :label='$i18n.t("common.Creationtime")'
                        width="150px"
                        align="center"
                        :formatter="formatDate"
                        prop="createTime"
                    ></el-table-column>
                    <el-table-column   :label='$i18n.t("common.Paymentmethod")' show-overflow-tooltip align="center" prop="payType">
                        <template slot-scope="{ row }">
                            <span v-if="row.orderStatus != 'UnPaid'">{{ replyList.payMethodName }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column
                    :label='$i18n.t("common.Paymenttime")'
                        width="150px"
                        align="center"
                        :formatter="formatDate"
                        prop="paymentTime"
                    ></el-table-column>
                    <el-table-column     :label='$i18n.t("common.Shipmentstatus")' show-overflow-tooltip align="center" prop="deliveryStatus">
                        <template slot-scope="{ row }">
                            {{ $dict.getDictValue('delivery_status', row.deliveryStatus) }}
                        </template>
                    </el-table-column>
                    <el-table-column
                    :label='$i18n.t("common.Deliverytime")'
                        width="150px"
                        align="center"
                        :formatter="formatDate"
                        prop="deliverTime"
                    ></el-table-column>
                </el-table>
            </div>
            <div>
                <el-descriptions :column="1" size="medium" style="margin-top: 30px; font-size: 125%">
                    <el-descriptions-item      :label='$i18n.t("common.Consultationcontent")' :contentStyle="{ width: '800px' }">{{ showConsultationTitle(replyList.title) }}</el-descriptions-item>
                    <el-descriptions-item      :label='$i18n.t("common.Consultationinstructions")' :contentStyle="{ width: '800px' }">
                        <template>
                            <span>
                                {{ replyList.content }}
                            </span>
                        </template>
                    </el-descriptions-item>
                    <el-descriptions-item  :label='$i18n.t("common.Replycontent")' v-if="!replyStatus" contentStyle="color:red;width:800px">
                        <template>
                            <span v-for="(item, index) in contentHandler" :key="index">
                                {{ item }}
                                <br v-if="contentHandler.length != 1 && index != contentHandler.length - 1" />
                            </span>
                        </template>
                        <!-- {{
                        replyList.replyContent
                    }} -->
                    </el-descriptions-item>
                </el-descriptions>
            </div>
            <div v-if="replyStatus">
                <span style="font-size: 125%">{{ $i18n.t("common.reply") }}:</span>
                <el-input
                    type="textarea"
                    :autosize="{ minRows: 2, maxRows: 10 }"
                    :placeholder='$i18n.t("common.EnterReply")'
                    v-model="replyContent"
                    style="margin-top: 10px"
                >
                </el-input>
                <el-button
                    type="primary"
                    size="medium"
                    @click="reply(replyList.consultationId)"
                    class="table-inner-button"
                    style="margin-top: 30px"
                    >{{ $i18n.t("common.reply") }}</el-button
                >
            </div>
            <div style="margin-top: 10px">
                <span style="font-size: 125%">{{ $i18n.t("common.Consultationrecords") }}</span>
                <el-table :data="replyList.consultations" style="margin-top: 10px" size="medium">
                    <el-table-column :label='$i18n.t("common.Serialnumber")' type="index" width="50" show-overflow-tooltip  align="center"></el-table-column>
                    <el-table-column :label='$i18n.t("common.content")'  align="center" prop="title" width="100px">
                        <template slot-scope="{ row }">
                            {{ showConsultationTitle(row.title) }}
                        </template>
                    </el-table-column>
                    <el-table-column :label='$i18n.t("common.explain")' align="center" prop="content" width="200px"></el-table-column>
                    <el-table-column
                    :label='$i18n.t("common.Consultationtime")'
                        width="150px"
                        align="center"
                        :formatter="formatDate"
                        prop="consultTime"
                    ></el-table-column>
                    <el-table-column :label='$i18n.t("common.state")'  show-overflow-tooltip align="center" prop="status">
                        <template slot-scope="{ row }">
                            <span v-if="row.status == 0" style="color: red">{{ $i18n.t("common.Noresponse")  }}</span>
                            <span v-if="row.status == 1" style="color: grey">{{$i18n.t("common.Replied")}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column :label='$i18n.t("common.Replycontent")' width="200px" align="center" prop="replyContent"></el-table-column>
                    <el-table-column :label='$i18n.t("common.Replytime")' width="150px" align="center" :formatter="formatDate" prop="replyTime">
                        <template slot-scope="{ row }">
                            <span v-if="row.status == 1">{{ formatTime(row.replyTime) }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column :label='$i18n.t("common.Respondent")'  show-overflow-tooltip align="center" prop="replyAdmin"></el-table-column>
                    <el-table-column :label='$i18n.t("common.operation")'  show-overflow-tooltip align="center">
                        <template slot-scope="{ row }">
                            <el-button v-if="row.status" type="primary" size="mini" @click="replyHandle(row)" class="table-inner-button"
                                >{{ $i18n.t("common.details") }}</el-button
                            >
                            <el-button v-else type="danger" size="mini" @click="replyHandle(row)" class="table-inner-button"
                                >{{ $i18n.t("common.reply") }}</el-button
                            >
                        </template>
                    </el-table-column>
                </el-table>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import dataSelect from '@/components/common/DataSelect.vue';
import dataText from '@/components/common/DataText.vue';
import Pagination from '@/components/common/Pagination/';
import moment from 'moment';
import ossUploader from '@/components/page/OssEleUploder.vue';

export default {
    name: 'consultation',
    components: {
        dataSelect,
        dataText,
        Pagination,
        moment,
        ossUploader
    },
    data() {
        var checkDateTime = (rule, value, callback) => {
            if (this.valuePicker.length > 0) {
                callback();
            } else {
                callback(new Error(this.$i18n.t("common.Pleaseselectatimerange")));
            }
        };
        return {
            list: [],
            replyList: [],
            contentHandler: [],
            orderDetails: [],
            total: 0,
            listLoading: true,
            countrysDicts: [],
            orderStatusDicts: [],
            deliveryStatusDicts: [],
            countryCurrencyDicts: [],
            consultationTitleDictMap: new Map(),
            query: {
                page: 1,
                limit: 10,
                orderIdOrUserId: '',
                brandCode: '',
                countryCode: [],
                status: '0',
                orderStatus: null,
                title: '',
                replyAdmin: '',
                consultStartTime: null,
                consultEndTime: null,
                userId: null
            },
            ossHost: process.env.VUE_APP_OSS_Head,
            ossSignature: { host: '' },
            valuePicker: [],
            diologTitle:this.$i18n.t("common.reply"),
            diologVisible: false,
            replyContent: '',
            replyStatus: true,
            pickerOptions: {
                shortcuts: [
                    {
                        text: this.$i18n.t("common.today"),
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime());
                            picker.$emit('pick', [start, end]);
                        }
                    },
                    {
                        text:this.$i18n.t("common.Lastweek"),
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 6);
                            picker.$emit('pick', [start, end]);
                        }
                    },
                    {
                        text: this.$i18n.t("common.Thismonth"),
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date(new Date().getFullYear(), new Date().getMonth(), 1);
                            picker.$emit('pick', [start, end]);
                        }
                    },
                    {
                        text: this.$i18n.t("common.Lastmonth1"),
                        onClick(picker) {
                            const start = new Date(new Date().getFullYear(), new Date().getMonth() - 1, 1);
                            const end = new Date(new Date().getFullYear(), new Date().getMonth(), 1);
                            end.setDate(end.getDate() - 1);
                            picker.$emit('pick', [start, end]);
                        }
                    },
                    {
                        text: this.$i18n.t("common.thisyear"),
                        onClick(picker) {
                            const start = new Date(new Date().getFullYear(), 0, 1);
                            const end = new Date();
                            picker.$emit('pick', [start, end]);
                        }
                    }
                ]
            }
        };
    },
    watch: {
        $route(to, from) {
            if (to.path === '/b2c/consultation/index') {
                // 在mounted函数执行的方法，放到该处
                this.getUserId();
            }
        }
    },
    created() {
        this.getDictItems();
        this.getUserId();
    },
    mounted() {
        this.getDictItems();
    },
    methods: {
        /**
         * 获取用户管理组件传来的信息
         */
        getUserId() {
            var userId = this.$route.params.userId;
            if (userId) {
                this.query.orderIdOrUserId = userId;
                this.query.status = '';
            }
            var orderId = this.$route.params.orderId;
            if (orderId) {
                this.query.orderIdOrUserId = orderId;
                this.query.status = '';
            }

            this.doQueryList({ page: 1 });
        },
        /**
         * 分页
         */
        handleCurrentChange(val) {
            this.query.page = val;
            this.doQueryList({ page: val });
        },
        /**
         * 时间戳转码
         */
        formatDate(row, column) {
            // 获取单元格数据
            let data = row[column.property];
            if (!data) {
                return '';
            } else {
                return moment(data).format('YYYY-MM-DD HH:mm:ss');
            }
        },
        /**
         * 时间转码
         */
        formatTime(time) {
            return moment(time).format('YYYY-MM-DD HH:mm:ss');
        },

        /**
         * 点击订单编号跳转
         */
        orderHandle(orderId) {
            this.$router.push({
                name: 'order_list',
                params: {
                    orderId: orderId
                }
            });
            this.diologVisible = false;
        },

        /**
         * 触发搜索操作
         */
        handleQuery() {
            if (this.valuePicker != null && this.valuePicker.length > 0) {
                this.query.consultStartTime = moment(this.valuePicker[0]).format('yyyy-MM-DD HH:mm:ss');
                this.query.consultEndTime = moment(this.valuePicker[1]).format('yyyy-MM-DD HH:mm:ss');
            } else {
                this.query.consultStartTime = null;
                this.query.consultEndTime = null;
            }
            this.doQueryList({ page: 1 });
        },
        /**
         * 执行列表查询
         */
        doQueryList({ page, limit }) {
            if (page) {
                this.query.page = page;
            }
            if (limit) {
                this.query.limit = limit;
            }
            if (this.query.orderIdOrUserId != '' || this.query.orderIdOrUserId != null) {
                //百分号校验
                if (this.query.orderIdOrUserId.includes('%')) {
                    this.list = [];
                    return;
                }
            }

            this.listLoading = true;
            this.$store
                .dispatch('getConsultationPage', this.query)
                .then((res) => {
                    this.list = res;
                    console.log(this.list);
                })
                .finally(() => {
                    this.listLoading = false;
                });
        },
        /**
         * 回复操作
         */
        replyHandle(row) {
            this.replyList = [];
            this.replyContent = '';
            if (!row.status) {
                this.replyStatus = true;
            } else {
                this.replyStatus = false;
            }
            var _this = this;
            this.$store.dispatch('getReply', { consultationId: row.consultationId }).then((res) => {
                _this.replyList = res;
                this.contentHandler = _this.replyList.replyContent.split('\n');
            });
            this.diologVisible = true;
        },
        /**
         * 回复内容
         */
        reply(consultationId) {
            if (this.replyContent == '' || this.replyContent == null) {
                this.$message({
                    showClose: false,
                    message:this.$i18n.t("common.Replycontentisrequired"),
                    type: 'warning'
                });
                return;
            }
            var replyQuery = { consultationId: consultationId, replyContent: this.replyContent };
            this.$store.dispatch('reply', replyQuery).then((res) => {
                this.doQueryList({ page: 1 });
                this.diologVisible = false;
                this.$message({
                    message: this.$i18n.t("common.Replysuccessful"),
                    type: 'success'
                });
            });
        },

        /**
         * 打开编辑表单
         */
        replyDetail(row) {
            this.doUpdate(row);
        },
        doUpdate(row) {
            this.$store.dispatch('subscribeEdit', { id: row.subId }).then((res) => {
                this.doQueryList({ page: 1 });
                this.$message({
                    message: this.$i18n.t("common.Successfullyedited"),
                    type: 'success'
                });
            });
        },

        getDictItems() {
            var _this = this;
            this.$store.dispatch('getDicItems', { tag: 'countrys' }).then((res) => {
                if (res != null) _this.countrysDicts = res;
            });
            this.$store.dispatch('getDicItems', { tag: 'order_status' }).then((res) => {
                if (res != null) _this.orderStatusDicts = res;
            });
            this.$store.dispatch('getDicItems', { tag: 'delivery_status' }).then((res) => {
                if (res != null) _this.deliveryStatusDicts = res;
            });
            this.$store.dispatch('getDicItems', { tag: 'country_currency' }).then((res) => {
                if (res != null) _this.countryCurrencyDicts = res;
            });
            this.$store.dispatch('getDicItems', { tag: 'consultationTitle' }).then((res) => {
                if (res != null) {
                    res.forEach(item => {
                        _this.consultationTitleDictMap.set(item.code, item.name)
                    })
                    console.log(_this.consultationTitleDictMap)
                }
            });
        },
        cleanQuery() {
            (this.query.orderIdOrUserId = ''),
                (this.query.brandCode = ''),
                (this.query.countryCode = []),
                (this.query.status = '0'),
                (this.query.orderStatus = null),
                (this.query.title = ''),
                (this.query.replyAdmin = ''),
                (this.query.consultStartTime = null),
                (this.query.consultEndTime = null),
                (this.valuePicker = []);
            this.doQueryList({ page: 1 });
        },
        showConsultationTitle(title) {
            const value = this.consultationTitleDictMap.get(title);
            if (value) {
                return value;
            }
            return title;
        }
    }
};
</script>
<style scoped>
.filter-container {
    padding: auto;
    margin-left: 20px;
    padding-top: 20px;
}
.my-content {
    background: #fde2e2;
}
</style>
