<template>
	<div id="app">
		<filter-container :arg="arg" :placeholder="placeholder"></filter-container>
		<el-table ref="pagedata" :data="pageData.list" style="width: 100%" border
			@selection-change="handleSelectionChange" :header-cell-style="{ background: '#EAEEF9', color: '#32353a' }">
			<el-table-column prop="goodsId" label="ID" align="center" show-overflow-tooltip></el-table-column>
			<el-table-column prop="brand" :label="$t('common.brand')" align="center" show-overflow-tooltip>
				<template slot-scope="scope">
					<span v-for="s in scope.row.brandCodes" :key="s.specialId"> {{ s }}<br /> </span>
				</template>
			</el-table-column>
			<el-table-column prop="classSetName" :label="$t('common.Class')" align="center" show-overflow-tooltip>
				<template slot-scope="scope">
					<div v-html="scope.row.classSetName"></div>
				</template>
			</el-table-column>			
			<el-table-column prop="barCode" :label="$t('common.Variantparameters')" align="center" show-overflow-tooltip></el-table-column>
			<!-- <el-table-column prop="platforms" label="显示平台" align="center" show-overflow-tooltip>
				<template slot-scope="scope">
					<span v-for="s in scope.row.platforms" :key="s"> {{ s }}<br /> </span>
				</template>
			</el-table-column> -->
			<el-table-column prop="updateTime" :label="$t('common.Creationtime')" align="center" show-overflow-tooltip></el-table-column>
			<el-table-column prop="updateTime" :label="$t('common.Updatetime')" align="center" show-overflow-tooltip></el-table-column>
			<el-table-column :label="$t('common.operation')" align="center" fixed="right">
				<template slot-scope="scope">
					<el-button @click="edit(scope.row)" type="text" size="small">{{ $t('common.edit') }}</el-button>
					<el-button @click="preview(scope.row)" type="text" size="small">{{ $t('common.Preview') }}</el-button>
				</template>
			</el-table-column>
		</el-table>
		<div class="block">
			<el-pagination @current-change="handleCurrentChange" :current-page="searchData.page"
				:page-size="searchData.size" layout="total, prev, pager, next, jumper" :total="pageData.total">
			</el-pagination>
		</div>
	</div>
</template>
<script>
	import FilterContainer from '@/components/common/FilterContainer.vue';
	export default {
		components: {
			FilterContainer
		},
		name: 'variant',
		data() {
			return {
				//组件标签是否显示
				arg:{
					name:true,
					category:true,
					brand:true
				},
				placeholder:{
					date:{
						start:i18n.t("common.Updatetime"),
						end:i18n.t("common.Updatetime")
					},
				},
				pageData: {},
				ossSignature: {
					host: ''
				},
				fullscreenLoading: false,
				goodsHomeRecomandTableLoading: false,
				mainTableHeight: 0,
				goodsHomeRecomandTable: {
					data: [],
					total: 1
				},
				goodsHomeRecomandQuery: {
					BeginTime: '',
					BrandCode: '',
					CountryCode: '',
					Enabled: '',
					EndTime: '',
					ModularCode: '',
					pageIndex: 1,
					pageSize: 10
				},
				goodsHomeRecomandRequest: {
					beginTime: '',
					brandCode: '',
					countryCode: '',
					enabled: false,
					endTime: '',
					goodsId: '',
					imageUrl: '',
					modularCode: '',
					platformCode: '',
					sort: '',
					goodsName: '',
					goodsBarcode: ''
				}, //查询项
				searchData: {
					title: '',
					enabled: null,
					brand: null,
					country: null,
					platform: null,
					classSetId: null,
					page: 1,
					limit: 10
				},
				goodsBarcode: '',
				valuePicker: '',
				multiple: false,
				valueSearchPicker: null,
				ossHost: process.env.VUE_APP_OSS_Head,
				showAddgoodsHomeRecomandLayer: false,
				brandDicts: [],
				pickerOptions: {
					shortcuts: [{
							text: i18n.t("common.today"),
							onClick(picker) {
								const end = new Date();
								const start = new Date();
								start.setTime(start.getTime());
								picker.$emit('pick', [start, end]);
							}
						},
						{
							text: i18n.t("common.Lastweek"),
							onClick(picker) {
								const end = new Date();
								const start = new Date();
								start.setTime(start.getTime() - 3600 * 1000 * 24 * 6);
								picker.$emit('pick', [start, end]);
							}
						},
						{
							text: i18n.t("common.Thismonth"),
							onClick(picker) {
								const end = new Date();
								const start = new Date(new Date().getFullYear(), new Date().getMonth(), 1);
								picker.$emit('pick', [start, end]);
							}
						},
						{
							text: i18n.t("common.Lastmonth1"),
							onClick(picker) {
								const start = new Date(new Date().getFullYear(), new Date().getMonth() - 1, 1);
								const end = new Date(new Date().getFullYear(), new Date().getMonth(), 1);
								end.setDate(end.getDate() - 1);
								picker.$emit('pick', [start, end]);
							}
						},
						{
							text: i18n.t("common.thisyear"),
							onClick(picker) {
								const start = new Date(new Date().getFullYear(), 0, 1);
								const end = new Date();
								picker.$emit('pick', [start, end]);
							}
						}
					]
				}
			};
		},
		mounted() {
			this.getOssSignature();
			this.getTableList();
			this.fetTableHeight();
			this.getDictItems('countrys');
		},
		methods: {
			getDictItems(codeType) {
				var _this = this;
				this.$store.dispatch('getDicItems', {
					tag: codeType
				}).then(res => {
					if (res != null) {
						_this.brandDicts = res;
					}
				});
			},
			getDictValue(tag, code) {
				var dict = this.brandDicts;
				if (dict == undefined || dict == null || dict.length == 0) {
					return code;
				}
				var codeInfo = dict.find(x => x.code == code);
				if (codeInfo) {
					return codeInfo.name;
				}
				return code;
			},
			searchBarcode(queryString, cb) {
				this.$store
					.dispatch('goodsSearchByBarCode', {
						barCode: queryString,
						countryCode: this.goodsHomeRecomandRequest.countryCode
					})
					.then(res => {
						cb(res);
					});
			},
			handleSelect(item) {
				var comfirmParams = '';
				if (item.params != null) {
					for (let index in item.params) {
						if (item.params[index] == 0) {
							comfirmParams += this.getDictValue('countrys', index);
						}
					}
				}
				if (comfirmParams) {
					this.$confirm(this.$i18n.t('common.TheInventoryOfCurrentProducts0Continue',{stock:comfirmParams}),this.$i18n.t('common.Prompt'), {
						confirmButtonText: i18n.t("common.confirm1"),
                		cancelButtonText: i18n.t("common.cancel"),
							type: 'warning'
						})
						.then(() => {
							this.goodsHomeRecomandRequest.goodsBarcode = item.goodsBarcode;
							this.goodsHomeRecomandRequest.imageUrl = item.imageUrl;
							this.goodsHomeRecomandRequest.goodsId = item.goodsId;
							this.goodsHomeRecomandRequest.goodsName = item.title;
						})
						.catch(() => {
							this.goodsHomeRecomandRequest.goodsBarcode = '';
							this.goodsHomeRecomandRequest.imageUrl = '';
							this.goodsHomeRecomandRequest.goodsId = '';
							this.goodsHomeRecomandRequest.goodsName = '';
							this.goodsBarcode = '';
						});
				} else {
					this.goodsHomeRecomandRequest.goodsBarcode = item.goodsBarcode;
					this.goodsHomeRecomandRequest.imageUrl = item.imageUrl;
					this.goodsHomeRecomandRequest.goodsId = item.goodsId;
					this.goodsHomeRecomandRequest.goodsName = item.title;
				}
			},
			getOssSignature() {
				var _this = this;
				this.$store.dispatch('getSignature', {
					dir: 'b2c'
				}).then(res => {
					_this.ossSignature = res;
					_this.ossSignature.host = _this.ossHost;
				});
			},
			resetHeight() {
				return new Promise((resolve, reject) => {
					this.mainTableHeight = 0;
					resolve();
				});
			},
			fetTableHeight() {
				this.resetHeight().then(res => {
					this.mainTableHeight = window.innerHeight - getElementTop(document.getElementById(
						'mainTable')) - 60;
				});
			},
			handleCurrentChange(val) {
				this.searchData.page = val;
				this.getTableList();
			},
			handleSizeChange(val) {
				this.searchData.limit = val;
				this.getTableList();
			},
			closeAddgoodsHomeRecomandLayer() {
				this.goodsHomeRecomandRequest.id = 0;
				this.showAddgoodsHomeRecomandLayer = false;
				this.goodsHomeRecomandRequest = {
					beginTime: '',
					brandCode: '',
					countryCode: undefined,
					enabled: false,
					endTime: '',
					goodsId: '',
					imageUrl: '',
					modularCode: '',
					platformCode: '',
					sort: '',
					goodsName: '',
					goodsBarcode: ''
				};
				this.goodsBarcode = '';
				this.valuePicker = '';
				this.$nextTick(function() {
					if (this.$refs.addgoodsHomeRecomandLayer !== undefined) {
						this.$refs.addgoodsHomeRecomandLayer.resetFields();
					}
					this.multiple = true;
					this.goodsHomeRecomandRequest.countryCode = [];
					this.$refs.mainImageUploader.setDefaultFiles([]);
				});
			},
			addgoodsHomeRecomand() {
				this.closeAddgoodsHomeRecomandLayer();
				this.showAddgoodsHomeRecomandLayer = true;
			},
			deletegoodsHomeRecomand(row) {
				this.$confirm(this.$i18n.t("common.confirmdeletion"), this.$i18n.t("common.prompt"), {
						confirmButtonText: this.$i18n.t('common.confirm1'),
						cancelButtonText: this.$i18n.t('common.cancel'),
						type: 'warning'
					})
					.then(() => {
						this.$store.dispatch('deleteGoodsHomeRecomand', {
							recomandId: row.recomandId
						}).then(res => {
							this.getTableList();
						});
					})
					.catch(() => {
						this.$message({
							type: 'info',
							message: this.$i18n.t('common.Cancelleddeletion')
						});
					});
			},
			editgoodsHomeRecomand(row) {
				this.multiple = false;
				this.$store.dispatch('getGoodsById', {
					id: row.goodsId
				}).then(res => {
					this.goodsHomeRecomandRequest.goodsBarcode = res.barCode;
					this.goodsBarcode = res.barCode;
					this.goodsHomeRecomandRequest.goodsName = res.title;
				});
				this.goodsHomeRecomandRequest.recomandId = row.recomandId;
				this.goodsHomeRecomandRequest.beginTime = moment(row.beginTime).format('yyyy-MM-DD HH:mm:ss');
				this.goodsHomeRecomandRequest.brandCode = row.brandCode;
				this.goodsHomeRecomandRequest.countryCode = row.countryCode;
				this.goodsHomeRecomandRequest.enabled = row.enabled;
				this.goodsHomeRecomandRequest.endTime = moment(row.endTime).format('yyyy-MM-DD HH:mm:ss');
				this.goodsHomeRecomandRequest.goodsId = row.goodsId;
				this.goodsHomeRecomandRequest.imageUrl = row.imageUrl;
				this.goodsHomeRecomandRequest.modularCode = row.modularCode;
				this.goodsHomeRecomandRequest.platformCode = row.platformCode;
				this.goodsHomeRecomandRequest.sort = row.sort;
				this.valuePicker = [this.goodsHomeRecomandRequest.beginTime, this.goodsHomeRecomandRequest.endTime];
				this.showAddgoodsHomeRecomandLayer = true;
				if (this.goodsHomeRecomandRequest.imageUrl != '') {
					var imageMD5 = '';
					//赋值图片
					var files = [{
						url: this.ossHost + this.goodsHomeRecomandRequest.imageUrl,
						type: 'old',
						md5: imageMD5,
						path: this.goodsHomeRecomandRequest.imageUrl
					}];
					this.$nextTick(function() {
						this.$refs.mainImageUploader.setDefaultFiles(files);
					});
				} else {
					this.$nextTick(function() {
						this.$refs.mainImageUploader.setDefaultFiles([]);
					});
				}
			},
			submitAddgoodsHomeRecomandForm() {
				if (this.goodsHomeRecomandRequest.imageUrl === 'undefined') {
					this.goodsHomeRecomandRequest.imageUrl = '';
				}
				if (this.goodsHomeRecomandRequest.sort == '') {
					this.goodsHomeRecomandRequest.sort = 0;
				}
				this.goodsHomeRecomandRequest.beginTime = moment(this.valuePicker[0]).format('yyyy-MM-DD HH:mm:ss');
				this.goodsHomeRecomandRequest.endTime = moment(this.valuePicker[1]).format('yyyy-MM-DD HH:mm:ss');
				if (!this.goodsHomeRecomandRequest.recomandId) {
					this.$store.dispatch('insertGoodsHomeRecomand', this.goodsHomeRecomandRequest).then(res => {
						this.showAddgoodsHomeRecomandLayer = false;
						this.$message({
							message: this.$i18n.t('common.Successfullyadded'),
							type: 'success'
						});
						this.getTableList();
					});
				} else {
					this.$store.dispatch('updateGoodsHomeRecomand', this.goodsHomeRecomandRequest).then(res => {
						this.showAddgoodsHomeRecomandLayer = false;
						this.$message({
							message: this.$i18n.t('common.Successfullymodified'),
							type: 'success'
						});
						this.getTableList();
					});
				}
			},
			getTableList() {
				this.goodsHomeRecomandTableLoading = true;
				var that = this;
				this.$store.dispatch('getGoodsHomeRecomandPage', this.searchData).then(res => {
					that.goodsHomeRecomandTable.data = res.list;
					that.goodsHomeRecomandTable.total = res.total;
					that.goodsHomeRecomandTableLoading = false;
				});
			},
			fileListChange(fileList, uploaderId, lang) {
				if (fileList.length > 0) {
					this.goodsHomeRecomandRequest.imageUrl = fileList[0].key;
				}
			}
		},
		updated() {}
	};

	function getElementTop(element) {
		var actualTop = element.offsetTop;
		var current = element.offsetParent;

		while (current !== null) {
			actualTop += current.offsetTop;
			current = current.offsetParent;
		}
		return actualTop;
	}
</script>
