<template>
    <div class="app-container" style="height: 100%; min-height: 400px">
        <div class="filter-container">
            <el-input
                clearable
                v-model="query.userId"
                :placeholder="$i18n.t('common.UserID')"
                style="width: 200px"
                class="filter-item"
                controls-position="right"
                oninput="value=value.replace(/[^\d]/g,'')"
            ></el-input>
            <el-input
                clearable
                v-model="query.email"
                :placeholder="$i18n.t('common.mailbox')"
                style="width: 200px"
                class="filter-item"
                controls-position="right"
            ></el-input>

            <el-select v-model="query.type" :placeholder="$i18n.t('common.type')" style="width: 200px; margin-left: 10px" clearable>
                <el-option v-for="item in types" :key="item.value" :label="item.label" :value="item.value"> </el-option>
            </el-select>
            <dataSelect
                style="width: 200px"
                class="filter-item"
                v-model="query.mode"
                clearable
                :codeType="'user_currency_mode'"
                :placeholder="$i18n.t('common.mode')"
            ></dataSelect>
            <el-input
                clearable
                v-model="query.orderNo"
                :placeholder="$i18n.t('common.AssociatedOrderNumber')"
                style="width: 200px"
                class="filter-item"
                maxlength="25"
                controls-position="right"
            ></el-input>

            <dataSelect
                style="width: 200px"
                class="filter-item"
                v-model="query.countryCode"
                clearable
                :codeType="'countrys'"
                :placeholder="$i18n.t('common.country')"
            ></dataSelect>

            <el-date-picker
                class="filter-item"
                v-model="query.createTimeStartAndEnd"
                type="datetimerange"
                :range-separator="$i18n.t('common.to')"
                :start-placeholder="$i18n.t('common.starttime')"
                :end-placeholder="$i18n.t('common.Endtime')"
                format="yyyy-MM-dd HH:mm:ss"
                value-format="yyyy-MM-dd HH:mm:ss"
                align="right"
            >
            </el-date-picker>

            <el-button class="filter-item" icon="el-icon-search" type="primary" @click="handleQuery">
                {{ $i18n.t('common.search') }}
            </el-button>

            <el-button class="filter-item" icon="el-icon-search" type="primary" @click="cleanQuery" style="margin-left: 10px" size="small ">
                {{ $i18n.t('common.Reset') }}
            </el-button>
        </div>

        <el-table
            v-loading="listLoading"
            class="table-fixed"
            ref="table"
            :data="list"
            @selection-change="selectionChange"
            border
            stripe
            style="width: 100%"
            height="calc(100% - 165px)"
            size="medium"
        >
            <el-table-column :label="$i18n.t('common.recordId')" prop="recordId" align="center" width="160">
                <template slot-scope="{ row }">
                    <span>{{ row.recordId }}</span>
                </template>
            </el-table-column>
            <el-table-column :label="$i18n.t('common.UserID')" prop="userid" align="center" width="160">
                <template slot-scope="{ row }">
                    <span>{{ row.userId }}</span>
                </template>
            </el-table-column>
            <el-table-column :label="$i18n.t('common.mailbox')" prop="userid" align="center" width="160">
                <template slot-scope="{ row }">
                    <span>{{ row.email }}</span>
                </template>
            </el-table-column>
            <el-table-column :label="$i18n.t('common.type')" prop="type" align="center" width="60">
                <template slot-scope="{ row }">
                    <dataText v-model="row.type" :codeType="'user_currency_type'"></dataText>
                </template>
            </el-table-column>
            <el-table-column :label="$i18n.t('common.mode')" prop="mode" align="center" width="200">
                <template slot-scope="{ row }">
                    <dataText v-model="row.mode" :codeType="'user_currency_mode'"></dataText>
                </template>
            </el-table-column>

            <el-table-column :label="$i18n.t('common.AssociatedOrderNumber')" prop="orderNo" align="center" width="200">
                <template slot-scope="{ row }">
                    <span>{{ row.orderNo }}</span>
                </template>
            </el-table-column>
            <el-table-column prop="remark" :label="$i18n.t('common.Remarks')" align="center" show-overflow-tooltip></el-table-column>
            <el-table-column :label="$i18n.t('common.Numberofchangesinpatrioticcoins')" prop="amount" align="center">
                <template slot-scope="{ row }">
                    <dataText v-model="row.currencySymbol" :codeType="'currency_code'">{{ row.amount }}</dataText>
                </template>
            </el-table-column>
            <el-table-column :label="$i18n.t('common.Numberofpatrioticcoinsafterchanges')" prop="balance" align="center">
                <template slot-scope="{ row }">
                    <dataText v-model="row.currencySymbol" :codeType="'currency_code'">{{ row.balance }}</dataText>
                </template>
            </el-table-column>

            <el-table-column :label="$i18n.t('common.CountryCode')" prop="countrycode" align="center">
                <template slot-scope="{ row }">
                    <span> {{ $dict.getDictValue('countrys', row.countryCode) }}</span>
                </template>
            </el-table-column>
            <el-table-column :label="$i18n.t('common.Changetime')" prop="createtime" align="center">
                <template slot-scope="{ row }">
                    <span>{{ new Date(row.createTime).toLocaleString() }}</span>
                </template>
            </el-table-column>
        </el-table>

        <el-pagination
            style="margin-top: 10px"
            background
            v-show="total > 0"
            @current-change="doQueryList"
            :current-page="query.page"
            :page-size="query.limit"
            layout="total, prev, pager, next, jumper"
            :total="total"
        ></el-pagination>
    </div>
</template>

<script>
import Pagination from '@/components/common/Pagination';
import DataSelect from '@/components/common/DataSelect';
import dataText from '@/components/common/DataText.vue';

export default {
    name: 'UserCurrencyRecordTable',
    components: {
        Pagination,
        DataSelect,
        dataText
    },
    data() {
        return {
            list: [],
            total: 0,
            listLoading: true,
            query: {
                page: 1,
                limit: 10,
                userId: '',
                eamail: '',
                targetid: undefined,
                type: this.types,
                mode: this.modes,
                remark: null,
                countrycode: null,
                createtimeStart: '',
                createtimeEnd: '',
                createTimeStartAndEnd: null,
                email: ''
            },

            selectItems: [],
            types: [
                {
                    value: '1',
                    label: this.$i18n.t('common.income')
                },
                {
                    value: '2',
                    label: this.$i18n.t('common.expenditure')
                }
            ],
            modes: [
                {
                    value: 'Register',
                    label: '注册'
                },
                {
                    value: 'Invite',
                    label: '邀请用户'
                },
                {
                    value: 'OrderExpend',
                    label: '订单支付'
                },
                {
                    value: 'OrderReward',
                    label: this.$i18n.t('common.Orderrewards')
                },
                {
                    value: 'OrderReturn',
                    label: this.$i18n.t('common.OrderCloseReturn')
                },
                {
                    value: 'Recharge',
                    label: this.$i18n.t('common.Backendrecharge')
                },
                {
                    value: 'ShoppingRebate',
                    label: this.$i18n.t('common.Orderrebate')
                }
            ]
        };
    },
    watch: {
        $route(to, from) {
            // 在mounted函数执行的方法，放到该处
            if (to.path === '/b2c/currency/list') {
                this.routerHandler();
            }
        }
    },
    created() {
        // this.doQueryList(1);
        this.routerHandler();
    },
    mounted() {
        this.routerHandler();
    },
    methods: {
        /**
         * 处理路由跳转
         */
        routerHandler() {
            var userId = this.$route.params.userId;
            if (userId) {
                this.query.userId = userId;
            }
            this.doQueryList(1);
        },
        /**
         * 选择框变化
         */
        selectionChange(val) {
            this.selectItems = val;
        },
        /**
         * 触发搜索操作
         */
        handleQuery() {
            this.doQueryList(1);
        },
        /**
         * 重置按钮
         */
        cleanQuery() {
            this.query = [];
            this.doQueryList(1);
        },
        /**
         * 执行列表查询
         */
        doQueryList(page) {
            this.query.page = page;
            this.listLoading = true;

            if (this.query.createTimeStartAndEnd != null) {
                console.log(this.query.createTimeStartAndEnd);
                this.query.beginTime = this.query.createTimeStartAndEnd[0];
                this.query.endTime = this.query.createTimeStartAndEnd[1];
            } else {
                this.query.beginTime = null;
                this.query.endTime = null;
            }

            this.$store
                .dispatch('getCruuencyRecordPage', this.query)
                .then((res) => {
                    this.list = res.list;
                    this.total = res.total;
                    this.$nextTick(() => {
                        //在数据加载完，重新渲染表格
                        this.$refs['table'].doLayout();
                    });
                })
                .finally(() => {
                    this.listLoading = false;
                    this.$refs['table'].doLayout();
                });
        },
        /**
         * 删除单条记录
         */
        handleDeleteSingle(row) {
            return this.$common
                .confirm(this.$i18n.t('common.confirmdeletion'))
                .then(() => userCurrencyRecordApi.deleteById(row.recordid))
                .then(() => {
                    this.$common.showMsg('success', this.$i18n.t('common.Successfullydeleted'));
                    return this.doQueryList(1);
                });
        },
        /**
         * 批量删除记录
         */
        handleDeleteBatch() {
            if (this.selectItems.length <= 0) {
                this.$common.showMsg('warning', this.$i18n.t('common.Pleaseselecttheuser_spatrioticcoinconsumptionrecord'));
                return;
            }
            return this.$common
                .confirm(this.$i18n.t('common.confirmdeletion'))
                .then(() => userCurrencyRecordApi.deleteBatch(this.selectItems.map((row) => row.recordid)))
                .then(() => {
                    this.$common.showMsg('success', this.$i18n.t('common.Successfullydeleted'));
                    return this.doQueryList(1);
                });
        },
        /**
         * 打开新建表单
         */
        handleCreate() {
            this.$refs.userCurrencyRecordAdd.handleCreate();
        },
        /**
         * 打开查看表单
         */
        handleShow(row) {
            this.$refs.userCurrencyRecordShow.handleShow(row.recordid);
        },
        /**
         * 打开编辑表单
         */
        handleUpdate(row) {
            this.$refs.userCurrencyRecordEdit.handleUpdate(row.recordid);
        }
    }
};
</script>
<style scoped>
.filter-container {
    padding: 20px;
}

.filter-item {
    margin-left: 10px;
    margin-bottom: 10px;
}

.table-fixed >>> .el-table__body-wrapper{
    height: calc(100%-44) !important;
}
</style>
