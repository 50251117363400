var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "app-container",
      staticStyle: { height: "100%", "min-height": "350px" }
    },
    [
      _c(
        "div",
        { staticClass: "filter-container" },
        [
          _c("el-input", {
            staticClass: "filter-item",
            staticStyle: { width: "200px" },
            attrs: {
              placeholder: "EAN",
              "controls-position": "right",
              oninput: "value=value.replace(/[%]/g,'')",
              clearable: ""
            },
            model: {
              value: _vm.query.barCode,
              callback: function($$v) {
                _vm.$set(_vm.query, "barCode", $$v)
              },
              expression: "query.barCode"
            }
          }),
          _c(
            "el-button",
            {
              staticClass: "filter-item",
              attrs: { icon: "el-icon-search", type: "primary" },
              on: { click: _vm.handleQuery }
            },
            [_vm._v(" " + _vm._s(_vm.$i18n.t("common.search")))]
          ),
          _c(
            "el-button",
            {
              staticClass: "filter-item",
              staticStyle: { "margin-left": "10px" },
              attrs: { type: "success", icon: "el-icon-edit" },
              on: {
                click: function($event) {
                  return _vm.handleClearQuery()
                }
              }
            },
            [
              _vm._v(
                "\n            " +
                  _vm._s(_vm.$i18n.t("common.Reset")) +
                  "\n        "
              )
            ]
          ),
          _c(
            "div",
            [
              _c(
                "el-button",
                {
                  staticClass: "filter-item",
                  staticStyle: { "margin-left": "0px", "margin-top": "10px" },
                  attrs: { type: "success" },
                  on: { click: _vm.showRecipesImage }
                },
                [
                  _vm._v(
                    "\n\n                " +
                      _vm._s(_vm.$i18n.t("common.RecipeDisplayImage")) +
                      "\n            "
                  )
                ]
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading"
            }
          ],
          ref: "multipleTable",
          staticStyle: { width: "100%", "margin-top": "10px" },
          attrs: {
            height: "calc(100% - 160px)",
            data: _vm.recipesList,
            border: "",
            stripe: ""
          }
        },
        [
          _c("el-table-column", { attrs: { type: "selection", width: "50" } }),
          _c("el-table-column", {
            attrs: {
              label: "ID",
              prop: "recipesId",
              align: "center",
              width: "160"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [_c("span", [_vm._v(_vm._s(row.recipesId))])]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              label: _vm.$i18n.t("common.FileName"),
              prop: "fileName",
              align: "center",
              width: "300"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [_c("span", [_vm._v(_vm._s(row.fileName))])]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              label: _vm.$i18n.t("common.FileSize"),
              prop: "fileSize",
              align: "center",
              width: "200px",
              formatter: _vm.formatSize
            }
          }),
          _c("el-table-column", {
            attrs: {
              label: _vm.$i18n.t("common.language"),
              prop: "langConvert",
              align: "center"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    _c("span", [_vm._v(_vm._s(row.langConvert)), _c("br")])
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              label: _vm.$i18n.t("common.BarcodeAssociated"),
              prop: "barCode",
              align: "center",
              width: "150"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [_c("span", [_vm._v(_vm._s(row.barCode))])]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              label: _vm.$i18n.t("common.FirstUploadTime"),
              prop: "createTime",
              align: "center",
              width: "150"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [_c("span", [_vm._v(_vm._s(row.createTime))])]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              label: _vm.$i18n.t("common.EditTime"),
              prop: "updateTime",
              align: "center",
              width: "150"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [_c("span", [_vm._v(_vm._s(row.updateTime))])]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              label: _vm.$i18n.t("common.TagType"),
              prop: "tagTypesName",
              align: "center",
              width: "120"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [_c("span", [_vm._v(_vm._s(row.tagTypesName))])]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              label: _vm.$i18n.t("common.operation"),
              align: "center",
              width: "160",
              fixed: "right"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    _c(
                      "el-button",
                      {
                        staticClass: "table-inner-button",
                        attrs: { type: "primary", size: "mini" },
                        on: {
                          click: function($event) {
                            return _vm.handleEdit(row)
                          }
                        }
                      },
                      [_vm._v(_vm._s(_vm.$i18n.t("common.edit")) + " ")]
                    )
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _c("pagination", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.total > 0,
            expression: "total > 0"
          }
        ],
        attrs: {
          total: _vm.total,
          pageNo: _vm.query.current,
          pageSize: _vm.query.limit
        },
        on: {
          "update:pageNo": function($event) {
            return _vm.$set(_vm.query, "current", $event)
          },
          "update:page-no": function($event) {
            return _vm.$set(_vm.query, "current", $event)
          },
          "update:pageSize": function($event) {
            return _vm.$set(_vm.query, "limit", $event)
          },
          "update:page-size": function($event) {
            return _vm.$set(_vm.query, "limit", $event)
          },
          pagination: _vm.doQueryList
        }
      }),
      _vm.dialogFormVisible
        ? _c(
            "el-dialog",
            {
              attrs: {
                title: _vm.dialogTitle,
                visible: _vm.dialogFormVisible,
                width: "800px"
              },
              on: {
                "update:visible": function($event) {
                  _vm.dialogFormVisible = $event
                }
              }
            },
            [
              _c(
                "el-tabs",
                {
                  model: {
                    value: _vm.activeName,
                    callback: function($$v) {
                      _vm.activeName = $$v
                    },
                    expression: "activeName"
                  }
                },
                [
                  _c(
                    "el-tab-pane",
                    {
                      attrs: {
                        label: _vm.$i18n.t("common.FileName1"),
                        name: "1"
                      }
                    },
                    [
                      _c(
                        "el-form",
                        {
                          ref: "fileNameFrom",
                          attrs: { "label-width": "100px" }
                        },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: _vm.$i18n.t("common.FileName1"),
                                prop: "fileName"
                              }
                            },
                            [
                              _vm._v(
                                "\n                        " +
                                  _vm._s(this.fileName) +
                                  "\n                    "
                              )
                            ]
                          ),
                          _vm._l(_vm.recipesLangList, function(item, index) {
                            return _c(
                              "el-form-item",
                              { key: item.id, attrs: { label: item.name } },
                              [
                                _c("el-input", {
                                  attrs: {
                                    placeholder: _vm.$i18n.t(
                                      "common.EnterName"
                                    ),
                                    maxlength: "40",
                                    "show-word-limit": ""
                                  },
                                  on: {
                                    input: function($event) {
                                      return _vm.forceUpdate($event)
                                    }
                                  },
                                  model: {
                                    value: item.fileName,
                                    callback: function($$v) {
                                      _vm.$set(item, "fileName", $$v)
                                    },
                                    expression: "item.fileName"
                                  }
                                })
                              ],
                              1
                            )
                          })
                        ],
                        2
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-tab-pane",
                    {
                      attrs: {
                        label: _vm.$i18n.t("common.CoverPicture"),
                        name: "2"
                      }
                    },
                    [
                      _c(
                        "el-form",
                        {
                          ref: "coverImageFrom",
                          attrs: { "label-width": "100px" }
                        },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                prop: "imageUrl",
                                label: _vm.$i18n.t("common.picture")
                              }
                            },
                            [
                              _c(
                                "el-col",
                                { attrs: { span: 10 } },
                                [
                                  _c("imgUpload", {
                                    ref: "imageUpload",
                                    attrs: { img: _vm.imageUrl },
                                    on: { event: _vm.changeUrl }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "el-col",
                                {
                                  staticStyle: {
                                    "margin-top": "135px",
                                    "margin-left": "10px"
                                  },
                                  attrs: { span: 10 }
                                },
                                [
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(_vm.$i18n.t("common.Imagesize")) +
                                        "：1*1024，＜ 1M"
                                    )
                                  ])
                                ]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "div",
                {
                  staticClass: "dialog-footer",
                  attrs: { slot: "footer" },
                  slot: "footer"
                },
                [
                  _c(
                    "el-button",
                    {
                      staticStyle: { width: "20%" },
                      on: {
                        click: function($event) {
                          _vm.dialogFormVisible = false
                        }
                      }
                    },
                    [_vm._v(_vm._s(_vm.$i18n.t("common.cancel")))]
                  ),
                  _c(
                    "el-button",
                    {
                      staticStyle: { width: "20%" },
                      attrs: { type: "primary" },
                      on: { click: _vm.saveRecipes }
                    },
                    [_vm._v(_vm._s(_vm.$i18n.t("common.confirm1")))]
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _vm.dialogShowRecipesImageVisible
        ? _c(
            "el-dialog",
            {
              attrs: {
                title: _vm.showRecipesImageTitle,
                visible: _vm.dialogShowRecipesImageVisible,
                width: "800px"
              },
              on: {
                "update:visible": function($event) {
                  _vm.dialogShowRecipesImageVisible = $event
                }
              }
            },
            [
              _c(
                "el-form",
                { ref: "showImageFrom", attrs: { "label-width": "100px" } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: _vm.$i18n.t("common.country"),
                        prop: "countryCodes"
                      }
                    },
                    [
                      _c(
                        "el-checkbox",
                        {
                          attrs: { indeterminate: _vm.isIndeterminate },
                          on: { change: _vm.handleCheckAllChange },
                          model: {
                            value: _vm.checkAll,
                            callback: function($$v) {
                              _vm.checkAll = $$v
                            },
                            expression: "checkAll"
                          }
                        },
                        [_vm._v(_vm._s(_vm.$i18n.t("common.SelectAll")))]
                      ),
                      _c(
                        "el-checkbox-group",
                        {
                          staticStyle: { width: "600px" },
                          on: { change: _vm.handleCheckedCitiesChange },
                          model: {
                            value: _vm.recipeShowImageForm.countryCodes,
                            callback: function($$v) {
                              _vm.$set(
                                _vm.recipeShowImageForm,
                                "countryCodes",
                                $$v
                              )
                            },
                            expression: "recipeShowImageForm.countryCodes"
                          }
                        },
                        _vm._l(_vm.dictionary.country, function(country) {
                          return _c(
                            "el-checkbox",
                            {
                              key: country.code,
                              attrs: { label: country.code }
                            },
                            [
                              _vm._v(
                                _vm._s(country.name) +
                                  "(" +
                                  _vm._s(country.code) +
                                  ")"
                              )
                            ]
                          )
                        }),
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: _vm.$i18n.t("common.Enableornot"),
                        prop: "enableStatus"
                      }
                    },
                    [
                      _c("el-switch", {
                        attrs: {
                          "active-color": "#13ce66",
                          "inactive-color": "#ff4949"
                        },
                        model: {
                          value: _vm.recipeShowImageForm.status,
                          callback: function($$v) {
                            _vm.$set(_vm.recipeShowImageForm, "status", $$v)
                          },
                          expression: "recipeShowImageForm.status"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        prop: "showImageUrl",
                        label: _vm.$i18n.t("common.picture")
                      }
                    },
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 10 } },
                        [
                          _c("imgUpload", {
                            ref: "imageUpload",
                            attrs: {
                              img: _vm.recipeShowImageForm.showImageUrl
                            },
                            on: { event: _vm.changeShowImageUrl }
                          })
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        {
                          staticStyle: {
                            "margin-top": "135px",
                            "margin-left": "10px"
                          },
                          attrs: { span: 10 }
                        },
                        [
                          _c("span", [
                            _vm._v(
                              _vm._s(_vm.$i18n.t("common.Imagesize")) +
                                "：1434*325px，＜ 1M"
                            )
                          ])
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "div",
                {
                  staticClass: "dialog-footer",
                  attrs: { slot: "footer" },
                  slot: "footer"
                },
                [
                  _c(
                    "el-button",
                    {
                      staticStyle: { width: "20%" },
                      on: {
                        click: function($event) {
                          _vm.dialogShowRecipesImageVisible = false
                        }
                      }
                    },
                    [_vm._v(_vm._s(_vm.$i18n.t("common.cancel")))]
                  ),
                  _c(
                    "el-button",
                    {
                      staticStyle: { width: "20%" },
                      attrs: { type: "primary" },
                      on: { click: _vm.saveRecipesShowImage }
                    },
                    [_vm._v(_vm._s(_vm.$i18n.t("common.confirm1")))]
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }