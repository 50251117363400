<template>
    <!-- 地址 -->
    <el-dialog :title='$i18n.t("common.Collectproducts") ' :visible.sync="collectionVisible" v-if="collectionVisible" width="1200px" :close-on-click-modal="false">
        <el-select
            v-model="collectionQuery.countryCodes"
            :multiple="true"
           :placeholder='$i18n.t("common.ShowCountry")'
            style="width: 180px; margin-left: 10px"
            class="filter-item"
        >
            <el-option v-for="cc in countrysDicts" :key="cc.code" :label="cc.name" :value="cc.code"></el-option>
        </el-select>
        <el-select
            v-model="collectionQuery.brandCode"
            :multiple="false"
            :placeholder='$i18n.t("common.brand")'
            style="width: 180px; margin-left: 10px"
            class="filter-item"
        >
            <el-option v-for="cc in brandDicts" :key="cc.code" :label="cc.name" :value="cc.code"></el-option>
        </el-select>
        <el-button class="filter-item" icon="el-icon-search" type="primary" @click="handleCollectionQuery" style="margin-left: 10px">
           {{ $i18n.t("common.query") }}
        </el-button>
        <el-table :data="collection.list" border stripe style="width: 100%; margin-top: 20px" size="small">
            <el-table-column prop="countryCode" :label='$i18n.t("common.country")' align="center" width="50px"> </el-table-column>
            <el-table-column prop="brandCode" :label='$i18n.t("common.brand")' align="center" width="150px"> </el-table-column>
            <el-table-column prop="goodsId" :label='$i18n.t("common.ProductID1")'  width="150px" align="center"> </el-table-column>
            <el-table-column prop="title" :label='$i18n.t("common.Productname1")' width="350px" align="center"> </el-table-column>
            <el-table-column prop="collectPrice" :label='$i18n.t("common.CollectionPrice")' width="150px" align="center"> </el-table-column>
            <el-table-column prop="finallyPrice" :label='$i18n.t("common.Currentprice")' width="150px" align="center"> </el-table-column>
            <el-table-column :label='$i18n.t("common.Collectiontime1")' prop="collectTime" align="center" width="159px" :formatter="formatDate"> </el-table-column>
        </el-table>
        <div class="block">
            <el-pagination
                style="margin-top: 10px"
                background
                @current-change="handleAddressCurrentChange"
                :current-page="collectionQuery.page"
                :page-size="collectionQuery.size"
                layout="total, prev, pager, next, jumper"
                :total="collection.total"
            ></el-pagination>
        </div>
    </el-dialog>
</template>

<script>
import dataSelect from '@/components/common/DataSelect.vue';
import dataText from '@/components/common/DataText.vue';
import moment from 'moment';

export default {
    name: 'collectionHandle',
    components: {
        dataSelect,
        dataText
    },
    data() {
        return {
            collection: [],
            countrysDicts: [],
            brandDicts: [],
            collectionQuery: {
                page: 1,
                limit: 10,
                countryCodes: [],
                brandCode: '',
                userId: null
            },
            collectionVisible: false,
            listLoading: true
        };
    },
    mounted() {
        this.getDictItems();
    },
    methods: {
        getDictItems() {
            var _this = this;
            this.$store.dispatch('getDicItems', { tag: 'countrys' }).then((res) => {
                if (res != null) {
                    _this.countrysDicts = res;
                }
            });
            this.$store.dispatch('getDicItems', { tag: 'brand' }).then((res) => {
                if (res != null) {
                    _this.brandDicts = res;
                }
            });
        },
        /**
         * 地址分页
         */
        handleAddressCurrentChange(val) {
            this.collectionQuery.page = val;
            this.doAddressQuery({ page: val });
        },
        /**
         * 时间戳转码
         */
        formatDate(row, column) {
            // 获取单元格数据
            let data = row[column.property];
            if (!data) {
                return '';
            } else {
                return moment(data).format('YYYY-MM-DD HH:mm:ss');
            }
        },
        /**
         * 收藏商品处理
         */
        collectionHandle(userId) {
            this.collection = [];
            this.collectionQuery.countryCodes = [];
            this.collectionQuery.userId = userId;
            this.collectionQuery.brandCode = '';
            this.handleCollectionQuery();
            this.collectionVisible = true;
        },
        handleCollectionQuery() {
            this.doCollectionQuery({ page: 1 });
        },
        doCollectionQuery({ page, limit }) {
            if (page) {
                this.collectionQuery.page = page;
            }
            if (limit) {
                this.collectionQuery.limit = limit;
            }
            this.$store.dispatch('getCollectionPage', this.collectionQuery).then((res) => {
                this.collection = res;
            });
        }
    }
};
</script>
