<template>
    <div class="app-container">
        <div class="filter-container">
            <el-select
                v-model="query.countryCodes"
                :placeholder='$i18n.t("common.country")'
                style="width: 180px; margin-left: 10px"
                class="filter-item"
            >
                <el-option v-for="cc in countrysDicts" :key="cc.code" :label="cc.name" :value="cc.code"></el-option>
            </el-select>
            <el-button class="filter-item" icon="el-icon-search" type="primary" @click="handleQuery" style="margin-left: 10px">
               {{ $i18n.t("common.query") }}
            </el-button>
            <el-button class="filter-item" icon="el-icon-search" type="primary" @click="cleanQuery" style="margin-left: 10px" size="small">
                {{ $i18n.t("common.Reset") }}
            </el-button>
            <div>
                <el-button class="filter-item" icon="el-icon-search" type="success" @click="handleAdd" style="margin-left: 10px;margin-top:10px">

                  {{ $i18n.t("common.Add") }}

            </el-button>
            </div>
        </div>

        <el-table v-loading="listLoading" :data="list.list" border stripe style="width: 100%; margin-top: 20px">
            <el-table-column label="ID" prop="logisticsPricingId" align="center" show-overflow-tooltip> </el-table-column>
            <el-table-column prop="countryCode" :label='$i18n.t("common.country")' width="50px" align="center"></el-table-column>
            <el-table-column :label='$i18n.t("common.Basicfreight")' prop="freightFee" align="center" width="100px"> </el-table-column>
            <el-table-column :label='$i18n.t("common.Freeshippingprice")' prop="freeFreightFeePrice" align="center" width="100px"> </el-table-column>
            <el-table-column :label='$i18n.t("common.Deliverytimeprompt")' prop="deliverDescribe" align="center" show-overflow-tooltip> </el-table-column>
            <el-table-column :label='$i18n.t("common.Receivingtimeprompt")' prop="receivingDescribe" align="center" show-overflow-tooltip> </el-table-column>
            <el-table-column :label='$i18n.t("common.logisticscompany")' prop="logisticsCompany[0]" align="center" show-overflow-tooltip>
                <template slot-scope="{ row }" v-if="row.logisticsCompany != null">
                    <a :href="row.logisticsCompany[0].link" target="_blank">{{row.logisticsCompany[0].title}}</a>
                </template>
            </el-table-column>
            <el-table-column :label='$i18n.t("common.Creationtime")' prop="createTime" align="center" show-overflow-tooltip :formatter="formatDate">
            </el-table-column>
            <el-table-column :label='$i18n.t("common.Updatetime")' prop="updateTime" align="center" show-overflow-tooltip :formatter="formatDate">
            </el-table-column>
            <el-table-column prop="opByName" :label='$i18n.t("common.Operator")'  align="center" show-overflow-tooltip></el-table-column>
            <el-table-column :label='$i18n.t("common.operation")' align="center" width="350">
                <template slot-scope="{ row }">
                    <el-button  type="primary" size="mini" @click="handleUpdate(row)" class="table-inner-button"
                        >{{ $i18n.t("common.edit") }}</el-button
                    >
                    <el-button  type="primary" size="mini" @click="handlePriceSetting(row)" class="table-inner-button">{{ $i18n.t("common.PricingSettings") }}</el-button>
                    <el-button  type="primary" size="mini" @click="handleFeeStandard(row)" class="table-inner-button">{{ $i18n.t("common.Freightstandard") }}</el-button>
                    <!-- <el-button  type="primary" size="mini" @click="handleDelete(row)" class="table-inner-button">删除</el-button> -->

                </template>
            </el-table-column>
        </el-table>
        <div class="block">
            <el-pagination
                style="margin-top: 10px"
                background
                @current-change="handleCurrentChange"
                :current-page="list.page"
                :page-size="list.size"
                layout="total, prev, pager, next, jumper"
                :total="list.total"
            ></el-pagination>
        </div>
        <!-- 编辑 -->
        <LogisticsEdit ref="LogisticsEdit" ></LogisticsEdit>

        <!-- 定价设置 -->
        <PriceSetting ref="PriceSetting" ></PriceSetting>

        <!-- 运费标准 -->
        <FreightStandard ref="FreightStandard"></FreightStandard>
    </div>
</template>

<script>
import dataSelect from '@/components/common/DataSelect.vue';
import dataText from '@/components/common/DataText.vue';
import Pagination from '@/components/common/Pagination/';
import moment from 'moment';
import ossUploader from '@/components/page/OssEleUploder.vue';
import LogisticsEdit from '@/views/logistics/update';
import imgUpload from '@/views/country/imgUpload';
import PriceSetting from '@/views/logistics/priceSetting';
import FreightStandard from '@/views/logistics/freightStandard'

export default {
    name: 'logistics',
    components: {
        dataSelect,
        dataText,
        Pagination,
        moment,
        ossUploader,
        LogisticsEdit,
        imgUpload,
        PriceSetting,
        FreightStandard
    },
    data() {
        var checkDateTime = (rule, value, callback) => {
            if (this.valuePicker.length > 0) {
                callback();
            } else {
                callback(new Error(this.$i18n.t("common.Pleaseselectatimerange")));
            }
        };
        return {
            list: [],
            total: 0,
            listLoading: true,
            countrysDicts: [],
            query: {
                page: 1,
                limit: 10,
                countryCodes: '',
            },
        };
    },
    created() {
        this.doQueryList({ page: 1 });
        this.getDictItems();
    },
    mounted() {
        this.getDictItems();
    },
    methods: {
        /**
         * 分页
         */
        handleCurrentChange(val) {
            this.query.page = val;
            this.doQueryList({ page: val });
        },
        /**
         * 时间戳转码
         */
        formatDate(row, column) {
            // 获取单元格数据
            let data = row[column.property];
            if (!data) {
                return '';
            } else {
                return moment(data).format('YYYY-MM-DD HH:mm:ss');
            }
        },
        /**
         * 触发搜索操作
         */
        handleQuery() {
            this.doQueryList({ page: 1 });
        },
        /**
         * 执行列表查询
         */
        doQueryList({ page, limit }) {
            if (page) {
                this.query.page = page;
            }
            if (limit) {
                this.query.limit = limit;
            }
            this.listLoading = true;
            this.$store
                .dispatch('getLogistics', this.query)
                .then((res) => {
                    this.list = res;
                    console.log(res);
                })
                .finally(() => {
                    this.listLoading = false;
                });
        },
        /**
         * 打开编辑表单
         */
        handleUpdate(row) {
            this.$refs.LogisticsEdit.handleUpdate(row);
        },
        /**
         * 打开价格设置菜单
         */
        handlePriceSetting(row){
            this.$refs.PriceSetting.handleUpdate(row);
        },
        /**
         * 打开运费标准菜单
         */
        handleFeeStandard(row){
            this.$refs.FreightStandard.handleUpdate(row);
        },
        /**
         * 打开新增菜单
         */
        handleAdd(){
            this.$refs.LogisticsEdit.handleAdd();
        },

        getDictItems() {
            var _this = this;
            this.$store.dispatch('getDicItems', { tag: 'countrys' }).then((res) => {
                if (res != null) _this.countrysDicts = res;
            });
        },
        cleanQuery() {
            this.query.countryCodes = '';
            this.doQueryList({ page: 1 });
        },
        handleDelete(row){
            this.$confirm(this.$i18n.t("common.confirmdeletion"), this.$i18n.t("common.prompt"), {
                // confirmButtonText: '确定',
                // cancelButtonText: '取消',
                type: 'warning'
            })
                .then(() => {
                    this.$store.dispatch('deleteLogistics', row.logisticsPricingId).then((res) => {
                        this.doQueryList({ page: 1 });
                        this.$message({
                            message: this.$i18n.t("common.Successfullydeleted"),
                            type: 'success'
                        });
                    });
                })
                .catch(() => {});

        }
    }
};
</script>
<style scoped>
.filter-container {
    padding: auto;
    margin-left: 20px;
    padding-top: 20px;
}
</style>
