<template>
  <el-dialog :title='$i18n.t("common.ViewInformationServices")' :visible.sync="formVisible">
    <el-form ref="dataForm" :model="form"
             label-position="left" size="small"
             label-width="100px" style="width: 400px; margin-left:50px;">
      <el-form-item label="Id">
        <span class="form-item-show">
          {{ form.informationid }}
        </span>
      </el-form-item>
      <el-form-item :label='$i18n.t("common.delete")'>
        <span class="form-item-show">
          {{ form.sydelete }}
        </span>
      </el-form-item>
      <el-form-item  :label='$i18n.t("common.ColumnName")'>
        <span class="form-item-show">
          {{ form.name }}
        </span>
      </el-form-item>
      <el-form-item :label='$i18n.t("common.title")'>
        <span class="form-item-show">
          {{ form.title }}
        </span>
      </el-form-item>
      <el-form-item :label='$i18n.t("common.Creationtime")'>
        <span class="form-item-show">
          {{ form.createtime }}
        </span>
      </el-form-item>
      <el-form-item :label='$i18n.t("common.Modificationtime")'>
        <span class="form-item-show">
          {{ form.updatetime }}
        </span>
      </el-form-item>
      <el-form-item :label='$i18n.t("common.Isitdisplayedatthebottomofthewebsite")'>
        <span class="form-item-show">
          {{ form.isshowbottom }}
        </span>
      </el-form-item>
      <el-form-item :label='$i18n.t("common.sort")' >
        <span class="form-item-show">
          {{ form.sort }}
        </span>
      </el-form-item>
      <el-form-item label="SyStamp">
        <span class="form-item-show">
          {{ form.systamp }}
        </span>
      </el-form-item>
      <el-form-item label="TenantId">
        <span class="form-item-show">
          {{ form.tenantid }}
        </span>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="formVisible = false">
        {{ $i18n.t("common.cancel") }}
      </el-button>
    </div>
  </el-dialog>
</template>

<script>

export default {
  name: 'InformationShow',
  data() {
    return {
      form: {
        informationid: null,
        sydelete: null,
        name: null,
        title: null,
        createtime: null,
        updatetime: null,
        isshowbottom: null,
        sort: null,
        systamp: null,
        tenantid: null
      },
      formVisible: false
    }
  },
  methods: {
    /**
     * 打开查看表单
     */
    handleShow(informationid) {
      informationApi.fetchById(informationid)
        .then(data => {
          this.form = data
          this.formVisible = true
        })
    }
  }
}
</script>
