// oss element 上传
<template>
    <div>
        <el-upload
            class="upload-demo"
            :action="ossSignature.host"
            :auto-upload="false"
            ref="upload1"
            :list-type="listType"
            :limit="limit"
            :on-change="handleChange"
            :on-remove="handleRemove"
            :accept="uploadType"
            :before-upload="beforeUpload"
            :show-file-list="this.fileType != 2"
            :file-list="uploadFiles"
        >
            <!-- accept=".jpg, .jpeg, .png" -->
            <el-button class="float-l" size="small" type="primary">点击上传</el-button>
            <span>&nbsp;&nbsp;{{ remark }}</span>
        </el-upload>
        <el-button v-show="this.fileType == 2 && videoUrl != ''" class="float-r" size="small" type="primary" @click="removeVideo"
            >移除</el-button
        >
        <video class="float-l" style="width: 100%" v-show="this.fileType == 2 && videoUrl != ''" :src="videoUrl" controls="controls">
            您的浏览器不支持视频播放
        </video>
    </div>
</template>

<script>
import X2JS from 'x2js'; //解析xml
export default {
    data() {
        return {
            ossHost: process.env.VUE_APP_OSS_Head,
            uploadFiles: [],
            uploadType:
                this.fileType == 1
                    ? '.jpg, .jpeg, .png'
                    : this.fileType == 2
                    ? '.mp4'
                    : this.fileType == 3
                    ? '.doc,.docx,.xls,.xlsx,.pdf,.txt'
                    : '*',
            videoUrl: '',
            fileUploaderId: this.uploaderId,
            listType: this.fileType == 1 ? 'picture' : '',
            fileObjList: [],
            defaultFileName: ''
        };
    },

    props: {
        ossSignature: {
            type: Object,
            default: { host: '' }
        },
        limit: {
            type: Number,
            default: 9
        },
        /* 1 图片 2 视频 3文件 */
        fileType: {
            type: Number,
            default: 1
        },
        uploaderId: {
            typeof: String,
            default: ''
        },
        lang: {
            typeof: String,
            default: ''
        },
        remark: {
            typeof: String,
            default: ''
        }
    },
    created() {},
    methods: {
        setDefaultFileName(name) {
            this.defaultFileName = name;
        },
        setDefaultFiles(files) {
            var _this = this;
            if (this.fileType == 2 && files.length > 0) this.videoUrl = files[0].url;
            else this.videoUrl = '';
            this.uploadFiles = files;
            this.fileObjList = files;
            _this.$emit('fileListChange', _this.fileObjList, _this.fileUploaderId, _this.lang);
        },
        // OnSuccess(response, file, fileList) {
        //     //解析xml
        //     var x2js = new X2JS();
        //     var jsonObj = x2js.xml2js(response);
        //     let ossName = jsonObj.PostResponse.Key;
        //     let ossUrl = jsonObj.PostResponse.Location;
        //     if (this.fileType == 2) {
        //         this.videoUrl = ossUrl;
        //     }

        //     file.ossUrl = ossUrl;
        //     file.uploaderId = this.fileUploaderId;

        //     this.$emit('fileListChange', fileList, this.fileUploaderId);
        // },
        removeVideo() {
            this.fileObjList = [];
            this.videoUrl = '';
            this.$refs.upload1.clearFiles();
            this.$emit('fileListChange', [], this.fileUploaderId, this.lang);
        },
        handleRemove(file, fileList) {
            //fileList自动会变

            let index = 0;
            this.fileObjList.forEach((e, i) => {
                if (e.md5 == file.md5) {
                    index = i;
                }
            });

            this.fileObjList.splice(index, 1);

            this.$emit('fileListChange', this.fileObjList, this.fileUploaderId, this.lang);
        },
        async handleChange(file, fileList) {
            var timestamp = new Date().getTime();
            var fileName = timestamp + '_' + parseInt(Math.round(Math.random() * 100));
            if (this.defaultFileName != '') {
                fileName = this.defaultFileName + '_' + fileName;
            }

            var ext = this.getSuffix(file.name);
            if (this.uploadType.indexOf(ext) < 0) {
                this.$message.error('仅支持' + this.uploadType + '格式文件');
                this.handleRemove(file);
                return;
            }

            if (this.fileObjList.length > this.limit) {
                return;
            }

            if (this.fileType == 1) {
                var size = file.size / 1024 / 1024;
                if (size > 3) {
                    this.$message.error('图片文件大小仅支持小于3M的图片');
                    console.log(size);
                    this.handleRemove(file);
                    return;
                }
            }

            var _this = this;
            let dataObj = {
                name: file.name,
                key: this.ossSignature.dir + '/' + new Date().getFullYear() + '/' + fileName + this.getSuffix(file.name),
                policy: this.ossSignature['policy'],
                OSSAccessKeyId: this.ossSignature['access_id'],
                success_action_status: '201',
                callback: '',
                signature: this.ossSignature['signature'],
                file: file.raw,
                uploadType: this.uploaderId,
                type: this.fileType == 1 ? 'image' : 'file',
                myFile: file
            };
            file.type = 'add';

            if (this.fileType == 2) {
                this.videoUrl = file.url;
            }

            await this.$common.calculateMD5(file);

            dataObj.md5 = file.md5;
            dataObj.lang = _this.lang;
            dataObj.path = dataObj.key;
            dataObj.size = file.raw.size;

            this.fileObjList.push(dataObj);

            setTimeout(function () {
                _this.beginUpload(dataObj);
            }, 1000);

            this.$emit('fileListChange', this.fileObjList, this.fileUploaderId, this.lang);
        },
        beforeUpload(file) {},
        beginUpload(dataObj) {
            var _this = this;

            if (dataObj.myFile.type != 'add') {
                return;
            }
            if (dataObj.myFile.ossUrl && dataObj.myFile.ossUrl != null && dataObj.myFile.ossUrl != '') {
                return;
            }

            var md5s = [];
            md5s.push({ md5: dataObj.md5, url: '', type: this.fileType == 1 ? 'image' : 'file', uploadType: this.uploaderId });

            _this.$store
                .dispatch('checkFileExists', md5s)
                .then((res) => {
                    if (res && res.length > 0 && res[0].url != '') {
                        dataObj.myFile.ossUrl = _this.ossHost + res[0].url;
                        dataObj.url = _this.ossHost + res[0].url;
                        dataObj.path = res[0].path;
                        dataObj.key = res[0].path;
                        dataObj.uploaded = true;
                        if (_this.fileType == 2) {
                            _this.videoUrl = _this.ossHost + res[0].url;
                        }
                        _this.$emit('fileListChange', _this.fileObjList, _this.fileUploaderId, _this.lang);
                        _this.$message.success('上传成功');
                    } else {
                        var msgOption = _this.$message.success('上传中...请耐心等待');
                        _this.$store
                            .dispatch('postOss', { params: dataObj, url: _this.ossSignature.host })
                            .then((response) => {
                                //解析xml
                                var x2js = new X2JS();
                                var jsonObj = x2js.xml2js(response);

                                let path = jsonObj.PostResponse.Key;
                                let ossUrl = jsonObj.PostResponse.Location;
                                dataObj.myFile.ossUrl = ossUrl;
                                dataObj.myFile.path = path;
                                dataObj.url = ossUrl;
                                dataObj.path = dataObj.key;
                                dataObj.uploaded = true;
                                if (_this.fileType == 2) {
                                    _this.videoUrl = ossUrl;
                                }
                                _this.$emit('fileListChange', _this.fileObjList, _this.fileUploaderId, _this.lang);
                                msgOption.close();
                                _this.$message.success('上传成功');

                                _this.$store.dispatch('addToFileLibrary', {
                                    type: _this.fileType == 2 ? 'file' : 'image',
                                    path: path,
                                    lang: _this.lang,
                                    md5: dataObj.md5
                                });
                            })
                            .catch((e) => {
                                console.error(e);
                                msgOption.close();
                                _this.$message.error('上传失败，请刷新界面重试');
                            });
                    }
                })
                .catch((e) => {
                    console.error(e);
                    _this.$message.error('上传失败');
                });
        }
    }
};
</script>

<style>
/* vue的上传动画，加上这个就会去掉，但是还是会显示之前的图片 */
/* .el-upload-list__item {
    transition: none !important;
  } */
</style>
