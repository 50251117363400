<template>
    <div class="app-container">
        <div class="filter-container">

            <el-date-picker
                    style="width: 450px"
                v-model="valuePicker"
                unlink-panels
                :type="dateType"
                :range-separator='$i18n.t("common.to")'
                :start-placeholder='$i18n.t("common.Startdate")'
                :end-placeholder='$i18n.t("common.Enddate")'
                :picker-options="pickerOptions"
                :clearable="false"
            >
            </el-date-picker>

            <dataSelect
                style="width: 200px"
                class="filter-item"
                v-model="query.countryCode"
                clearable
                :codeType="'countrys'"
                :placeholder="$i18n.t('common.country')"
            ></dataSelect>

            <el-input
                    v-model="query.searchKeyWord"
                    :placeholder="$i18n.t('common.PleaseEnterKeywords')"
                    style="width: 200px"
                    class="filter-item"
                    maxlength="100"
                    show-word-limit
                    controls-position="right"
                    oninput="value=value.replace(/[%]/g,'')"
                    clearable
            ></el-input>

            <el-button class="filter-item" icon="el-icon-search" type="primary" @click="handleQuery"> {{ $i18n.t('common.search') }}</el-button>

            <el-button
                    class="filter-item"
                    icon="el-icon-search"
                    type="primary"
                    @click="handleExport"
                    style="margin-left: 10px"
                    size="small"
                    v-loading.fullscreen.lock="fullscreenLoading"
            >
                {{ $i18n.t('common.export') }}
            </el-button>
<!--            <el-button class="filter-item" style="margin-left: 10px" type="success" icon="el-icon-edit" @click="handleClearQuery()">-->
<!--                {{ $i18n.t('common.Reset') }}-->
<!--            </el-button>-->

        </div>

        <el-table
            v-loading="listLoading"
            :data="searchRecordList"
            border
            stripe
            ref="multipleTable"
            style="width: 100%; margin-top: 10px"
        >
            <el-table-column type="selection" width="50" />
            <el-table-column :label="$i18n.t('common.Keywords')" prop="searchKeyWord" align="center">
                <template slot-scope="{ row }">
                    <span>{{ row.searchKeyWord }}</span>
                </template>
            </el-table-column>

            <el-table-column :label="$i18n.t('common.IPAddress')" prop="ip" align="center">
                <template slot-scope="{ row }">
                    <span>{{ row.ip }}</span>
                </template>
            </el-table-column>

            <el-table-column :label="$i18n.t('common.SearchTime')" prop="createTime" align="center" :formatter="formatDate">

            </el-table-column>

            <el-table-column :label="$i18n.t('common.country')" prop="countryCodeName" align="center">
                <template slot-scope="{ row }">
                    <span >{{ $dict.getDictValue('countrys', row.countryCode) }}<br /></span>
                </template>
            </el-table-column>

            <el-table-column :label="$i18n.t('common.NumbeOfTimes')" prop="searchNum" align="center">
                <template slot-scope="{ row }">
                    <span>{{ row.searchNum }}</span>
                </template>
            </el-table-column>


        </el-table>
        <pagination
            v-show="total > 0"
            :total="total"
            :pageNo.sync="query.current"
            :pageSize.sync="query.limit"
            @pagination="doQueryList"
        />

    </div>
</template>

<script>
import Pagination from '@/components/common/Pagination';
import DataSelect from '@/components/common/DataSelect';
import dataText from '@/components/common/DataText.vue';
import scene from '@/utils/scene';
import moment from 'moment';
import dictionaryUtil from '@/utils/dictionaryUtil';

const defaultDictionary = {
    // 国家
    country: dictionaryUtil.getDict('countrys')
};

export default {
    name: 'SearchRecordTable',
    components: {
        Pagination,
        dataText,
        DataSelect
    },
    data() {
        return {
            delBatchDisabled: false,
            searchRecordList: [],
            total: 0,
            listLoading: true,
            query: {
                current: 1,
                limit: 10,

                searchKeyWord: "",
                countryCode: null,
                beginTime: "",
                endTime: ""
            },
            countrys: [],
            dictionary: Object.assign({}, defaultDictionary),
            valuePicker: [moment().add(-7, 'days').format('yyyy-MM-DD 00:00:00'), moment().add(0, 'days').format('yyyy-MM-DD 23:59:59')],
            dateType: 'datetimerange',
            pickerOptions: {
            },
            fullscreenLoading: false,
        };
    },
    created() {
        this.routerHandler();
    },
    watch: {
        $route(to, from) {
            if (to.path === '/b2c/searchRecord/index') {
                this.routerHandler();
            }
        },

        deep: true
    },
    computed: {
        scene() {
            return scene;
        }
    },
    methods: {
        /**
         * 处理路由跳转
         */
        routerHandler() {
            this.doQueryList({ pageNo: 1 });
        },
        /**
         * 触发搜索操作
         */
        handleQuery() {
            this.doQueryList({ pageNo: 1 });
        },
        /**
         * 执行列表查询
         */
        doQueryList({ pageNo, pageSize }) {
            if (pageNo) {
                this.query.current = pageNo;
            }
            if (pageSize) {
                this.query.size = pageSize;
            }

            this.query.beginTime = moment(this.valuePicker[0]).format("YYYY-MM-DD HH:mm:ss");
            this.query.endTime = moment(this.valuePicker[1]).format("YYYY-MM-DD HH:mm:ss");

            this.listLoading = true;

            this.$store
                .dispatch('getSearchRecordPage', this.query)
                .then((res) => {
                    this.searchRecordList = res.list;
                    this.total = res.total;
                })
                .finally(() => {
                    this.listLoading = false;
                });
        },

        handleClearQuery() {
            let params = {
                current: 1,
                limit: 10,

                searchKeyWord: "",
                countryCode: null,
            };
            this.query = params;
            this.handleQuery();
        },
        /**
         * 时间戳转码
         */
        formatDate(row, column) {
            // 获取单元格数据
            let data = row[column.property];
            if (!data) {
                return '';
            } else {
                return moment(data).format('YYYY-MM-DD HH:mm:ss');
            }
        },
        /**
         * 导出excel
         */
        handleExport() {
            this.query.fileName = this.$i18n.t('common.SearchKeywords') + '.xlsx';
            this.$confirm(this.$i18n.t('common.Areyousuretoexportthefile'), this.$i18n.t('common.prompt'), {
                type: 'warning'
            }).then(() => {
                    this.fullscreenLoading = true;
                    this.$store.dispatch('exportSearchRecord', this.query).then((res) => {
                        this.fullscreenLoading = false;
                        this.$message({
                            type: 'success',
                            message: this.$i18n.t('common.Exportsuccessful')
                        });
                    });
                })
                .catch(() => {
                    this.fullscreenLoading = false;
                });
        },
    }
};
</script>
<style scoped>
.box {
    width: 600px;
    display: flex;
}
.filter-container {
    padding: auto;
    margin-left: 20px;
    padding-top: 20px;
}

.filter-item {
    margin-right: 10px;
    margin-top: 10px;
}
</style>
