<template>
    <div class="app-container" style="height: 100%;min-height:350px;">
        <div class="filter-container">
            <el-input
                v-model="query.idFuzzy"
                :placeholder="`SKU/EAN/`+$i18n.t('common.coding')"
                style="width: 200px"
                class="filter-item"
                controls-position="right"
                oninput="value=value.replace(/[%]/g,'')"
                clearable
            ></el-input>

            <dataSelect
                style="width: 150px"
                class="filter-item"
                v-model="query.countryCode"
                clearable
                :codeType="'countrys'"
                :placeholder="$i18n.t('common.country')"
            ></dataSelect>

            <el-button class="filter-item" icon="el-icon-search" type="primary" @click="handleQuery"> {{ $i18n.t('common.search') }}</el-button>

            <el-button class="filter-item" style="margin-left: 10px" type="success" icon="el-icon-edit" @click="handleClearQuery()">
                {{ $i18n.t('common.Reset') }}
            </el-button>

            <div>
                <el-button class="filter-item" type="success" @click="handleAdd" style="margin-left: 10px;margin-top:10px">

                    {{ $i18n.t("common.Add") }}

                </el-button>
            </div>

        </div>

        <el-table
            v-loading="listLoading"
            :data="giftList"
            border
            stripe
            ref="multipleTable"
            style="width: 100%; margin-top: 10px"
            height="calc(100% - 150px)"
        >
            <el-table-column type="selection" width="50" />
            <el-table-column :label="$i18n.t('common.GiftID')" prop="giftId" align="center" width="160">
                <template slot-scope="{ row }">
                    <span>{{ row.giftId }}</span>
                </template>
            </el-table-column>

            <el-table-column :label="$i18n.t('common.Productname1')" prop="title" align="center" width="300">
                <template slot-scope="{ row }">
                    <span>{{ row.title }}</span>
                </template>
            </el-table-column>

            <el-table-column label="EAN" prop="giftId" align="center" width="200px">
                <template slot-scope="{ row }">
                    <span>SKU:{{ row.sku }}</span>
                    <div>EAN:{{ row.barCode }}</div>
                    <div>{{ $i18n.t('common.coding') }}:{{ row.goodsCode }}</div>
                </template>
            </el-table-column>

            <el-table-column :label="$i18n.t('common.country')" prop="countryCodeName" align="center" width="100">
                <template slot-scope="{ row }">
                    <span >{{ $dict.getDictValue('countrys', row.countryCode) }}<br /></span>
                </template>
            </el-table-column>

            <el-table-column :label="$i18n.t('common.CurrentInventory')" prop="eComRemainQty" align="center" width="150">
                <template slot-scope="{ row }">
                    <span>{{ row.ecomRemainQty }}</span>
                </template>
            </el-table-column>

            <el-table-column prop="enableStatus" :label="$i18n.t('common.Enableornot')"  align="center" show-overflow-tooltip>
                <template slot-scope="scope">
                    <el-switch
                            v-model="scope.row.enableStatus"
                            active-color="#13ce66"
                            inactive-color="#ff4949"
                            disabled>
                    </el-switch>
                </template>
            </el-table-column>

            <el-table-column prop="updateName" :label='$i18n.t("common.Operator")' align="center" show-overflow-tooltip>
                <template slot-scope="{ row }">
                    <span>{{ row.updateName }}</span>
                </template>
            </el-table-column>

            <el-table-column prop="updateTime" :label="$i18n.t('common.Updatetime')" align="center" show-overflow-tooltip>
                <template slot-scope="scope">
                    <span>{{scope.row.updateTime | formatDate}}</span>
                </template>
            </el-table-column>


            <el-table-column :label="$i18n.t('common.operation')" align="center" width="160">
                <template slot-scope="{ row }">
                    <el-button type="primary" size="mini" @click="handleEdit(row)" class="table-inner-button">{{ $i18n.t('common.edit') }} </el-button>

                    <el-button type="danger" size="mini" @click="handleDel(row)" :disabled="row.bindGiftActivity"  class="table-inner-button">{{ $i18n.t('common.delete') }} </el-button>
                </template>
            </el-table-column>
        </el-table>
        <pagination
            v-show="total > 0"
            :total="total"
            :pageNo.sync="query.current"
            :pageSize.sync="query.limit"
            @pagination="doQueryList"
        />

        <el-dialog :title="dialogTitle" :visible.sync="dialogFormVisible" v-if="dialogFormVisible" width="600px">
            <el-form
                     ref="dataForm"
                     :model="form"
                     :rules="rules"
                     label-position="left"
                     size="small"
                     label-width="110px"
                     style="width: 400px; margin-left: 50px"
            >

                <el-form-item :label='$i18n.t("common.Enableornot")' prop="enableStatus">
                    <el-switch v-model="form.enableStatus" active-color="#13ce66" inactive-color="#ff4949"></el-switch>
                </el-form-item>

                <el-form-item :label='$i18n.t("common.GoodsForFree")' :required="true">
                    <div class="box">
                        <el-tooltip effect="light" :content="text" :disabled="isTooltip">
                            <input
                                    type="text"
                                    class="form-control text-ellipsis"
                                    v-model="text"
                                    :placeholder='$i18n.t("common.Barcode")'
                                    :disabled="true"
                                    style="text-indent: 15px"
                            />
                        </el-tooltip>
                        <el-button v-if="showSelectGoods" type="primary" style="margin-left: 10px" @click="showGoodsList">{{ $t('common.Selectproduct') }}</el-button>
                    </div>
                </el-form-item>

                <el-form-item :label='$i18n.t("common.country")' prop="countryCode">
                    <el-select v-model="form.countryCode" :placeholder='$i18n.t("common.Pleaseselectacountry")' :disabled="countryDisabled">

                        <el-option v-for="item in this.countrys" :key="item.id" :label="item.name" :value="item.code" :disabled="item.disabled">
                        </el-option>
                    </el-select>
                </el-form-item>

            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="dialogFormVisible = false">{{ $i18n.t('common.cancel') }}</el-button>
                <el-button type="primary" @click="saveGift">{{ $i18n.t('common.confirm1') }}</el-button>
            </div>
        </el-dialog>

        <el-dialog
                :title='$i18n.t("common.Pleaseselectaproduct")'
                :visible.sync="goodsVisible"
                @close="closeGoodsForm()"
                v-if="goodsVisible"
                :close-on-click-modal="false"
                width="1200px"
        >
            <goodsDialog ref="goodsDialog" @handleSelectGoods="handleSelectGoods"></goodsDialog>
        </el-dialog>

        <el-dialog
                :title="$t('common.Pleasecompletetheproductinformationbeforelistingit')"
                :visible.sync="dialogtipsVisible"
                :close-on-click-modal="false"
                append-to-body
        >
            <div v-if="dialogtipsVisible">
                <div v-for="(item, index) in tipsList" :key="index">{{ index + 1 }}:{{ item }}</div>
                <span slot="footer" class="dialog-footer">
                    <el-button style="width: 20%" @click="dialogtipsVisible = false">{{ $t('common.cancel') }}</el-button>
                    <el-button style="width: 20%" type="primary" @click="dialogtipsVisible = false">{{ $t('common.confirm1') }}</el-button>
                </span>
            </div>
        </el-dialog>

    </div>
</template>

<script>
import Pagination from '@/components/common/Pagination';
import DataSelect from '@/components/common/DataSelect';
import dataText from '@/components/common/DataText.vue';
import goodsDialog from '@/views/gift/goodsDialog.vue';
import scene from '@/utils/scene';
import i18n from '@/i18n/i18n';
import dictionaryUtil from '@/utils/dictionaryUtil';

const defaultDictionary = {
    // 国家
    country: dictionaryUtil.getDict('countrys')
};

export default {
    name: 'GiftTable',
    components: {
        Pagination,
        dataText,
        DataSelect,
        goodsDialog
    },
    data() {
        return {
            delBatchDisabled: false,
            giftList: [],
            total: 0,
            listLoading: true,
            query: {
                current: 1,
                limit: 10,

                idFuzzy: null,
                countryCode: null
            },
            dialogTitle: '',
            text: '',
            isTooltip: true,
            countrys: [],
            goodsVisible: false,
            selectItems: [],
            dialogFormVisible: false,
            form: {
                enableStatus: false,
                countryCode: null,
                goodsId: '',
                relationGoodsId: '',
                giftId: ''
            },
            dictionary: Object.assign({}, defaultDictionary),
            showSelectGoods: true,
            countryDisabled: false,
            tipsList: [],
            dialogtipsVisible: false,
            rules: {
                countryCode: [
                    { required: true, message:  this.$i18n.t("common.Pleaseselectacountry"), trigger: 'blur' },
                ]
            }
        };
    },
    created() {
        this.routerHandler();
    },
    watch: {
        $route(to, from) {
            if (to.path === '/b2c/gift/list') {
                this.routerHandler();
            }
        },

        deep: true
    },
    computed: {
        scene() {
            return scene;
        }
    },
    methods: {
        /**
         * 处理路由跳转
         */
        routerHandler() {
            this.doQueryList({ pageNo: 1 });
        },
        handleAdd() {

            this.showSelectGoods = true;
            this.countryDisabled = false;
            this.dialogTitle = this.$i18n.t('common.Add');
            this.handleClearForm();
            this.text = '';
            this.countrys = [];
            this.dialogFormVisible = true;
        },
        saveGift() {

            //新增
            if (this.showSelectGoods) {

                this.$refs['dataForm'].validate((valid) => {
                    if (valid) {

                        this.$store.dispatch('saveGift', this.form).then(() => {
                            this.$message({
                                message: this.$i18n.t("common.Successfullysaved"),
                                type: 'success'
                            });
                            this.dialogFormVisible = false;
                            this.doQueryList({ pageNo: 1 });
                        });

                    }
                });

            }else { //编辑

                //启用需要校验
                if (this.form.enableStatus) {

                    var params = [];
                    params.push(this.form.goodsId);

                    this.$store.dispatch('getGoodsInfo', params).then((res) => {

                        var row = res[0];

                        if (row.goodsSpecials && row.goodsSpecials.length > 0) {
                            let goodsSpecials = [];
                            row.goodsSpecials.forEach((item, index) => {
                                if (item.countryCode === this.form.countryCode) {
                                    goodsSpecials.push(item);
                                }
                            });

                            row.goodsSpecials = goodsSpecials;
                        }

                        this.checkGoodsInfo(row).then(data => {

                            if (this.tipsList.length > 0) {

                                this.dialogtipsVisible = true;
                                return;
                            }else {

                                this.$store.dispatch('updateEnableStatus', this.form).then(() => {
                                    this.$message({
                                        message: this.$i18n.t("common.Successfullysaved"),
                                        type: 'success'
                                    });
                                    this.dialogFormVisible = false;
                                    this.doQueryList({ pageNo: 1 });
                                });

                            }
                        })
                    });
                }else {

                    this.$store.dispatch('updateEnableStatus', this.form).then(() => {
                        this.$message({
                            message: this.$i18n.t("common.Successfullysaved"),
                            type: 'success'
                        });
                        this.dialogFormVisible = false;
                        this.doQueryList({ pageNo: 1 });
                    });
                }

            }
        },
        // 删除
        handleDel(row){
            this.$confirm( this.$i18n.t("common.DeleteOrNot" ) + '?',   this.$i18n.t("common.prompt"), {
                type: 'warning'
            }).then(() => {
                this.$store.dispatch('deleteGift', {
                    giftId: row.giftId
                }).then(res => {
                    let totalPage = Math.ceil((this.query.total - 1) / this.query.limit)
                    let currentPage = this.query.page > totalPage ? totalPage : this.query.page
                    let page = currentPage < 1 ? 1 : currentPage
                    this.$message.success(  this.$i18n.t("common.Successfullydeleted"));
                    this.doQueryList({ pageNo: page });
                });
            }).catch(() => {
            });
        },
        /**
         * 触发搜索操作
         */
        handleQuery() {

            this.doQueryList({ pageNo: 1 });
        },
        /**
         * 执行列表查询
         */
        doQueryList({ pageNo, pageSize }) {
            if (pageNo) {
                this.query.current = pageNo;
            }
            if (pageSize) {
                this.query.size = pageSize;
            }
            this.listLoading = true;

            this.$store
                .dispatch('getGiftPage', this.query)
                .then((res) => {
                    this.giftList = res.list;
                    this.total = res.total;
                })
                .finally(() => {
                    this.listLoading = false;
                });
        },
        /**
         * 删除单条记录
         */
        handleDeleteSingle(row) {
            return this.$common
                .confirm(this.$i18n.t("common.Areyousuretodelete"))
                .then(() => commentApi.deleteById(row.commentid))
                .then(() => {
                    this.$common.showMsg('success', this.$i18n.t("common.Successfullydeleted"));
                    return this.doQueryList({ pageNo: 1 });
                });
        },

        handleClearQuery() {
            let params = {
                current: 1,
                limit: 10,

                idFuzzy: null,
                countryCode: null,
            };
            this.query = params;
            this.handleQuery();
        },
        handleClearForm() {
            this.form = {
                enableStatus: false,
                countryCode: null,
                goodsId: '',
                relationGoodsId: ''
            };
        },
        showGoodsList() {
            this.goodsVisible = true;
        },
        closeGoodsForm() {
            this.goodsVisible = false;
        },
        // 选中商品
        handleSelectGoods(row) {

            this.checkGoodsInfo(row).then(data => {

                if (this.tipsList.length > 0) {

                    this.dialogtipsVisible = true;
                } else {

                    //清空上次设置的
                    this.countrys = [];
                    this.form.countryCode = '';

                    this.$store.dispatch('getByGoodsId', {
                        goodsId: row.goodsId
                    }).then(res => {


                        this.text = row.barCode;
                        this.form.goodsId = row.goodsId;
                        this.form.relationGoodsId = row.relationGoodsId;

                        row.goodsSpecials.forEach(goodsSpecial => {

                            if (goodsSpecial.shelf) {

                                var country;
                                var disabled = false;
                                this.dictionary.country.forEach(x => {

                                    if (x.code === goodsSpecial.countryCode) {
                                        country = x;
                                    }
                                });

                                if (res.length > 0) {

                                    res.forEach(x => {

                                        if (country.code === x.countryCode) {
                                            disabled = true;
                                        }
                                    });
                                }


                                this.countrys.push({

                                    id: country.id,
                                    name: country.name,
                                    code: country.code,
                                    disabled: disabled
                                })
                            }
                        });

                        this.closeGoodsForm();
                    });
                }
            })
        },
        handleEdit(row) {

            this.handleClearForm();
            this.countrys = [];
            this.dialogTitle = this.$i18n.t('common.edit');
            this.form.goodsId = row.goodsId;
            this.form.enableStatus = row.enableStatus;
            this.form.countryCode = row.countryCode;
            this.form.giftId = row.giftId;
            this.text = row.barCode;

            var country;
            this.dictionary.country.forEach(x => {

                if (x.code === row.countryCode) {
                    country = x;
                }
            });

            this.countrys.push({

                id: country.id,
                name: country.name,
                code: country.code,
                disabled: false
            })

            this.showSelectGoods = false;
            this.countryDisabled = true;
            this.dialogFormVisible = true;
        },

        checkGoodsInfo(row) {

            return new Promise((resolve, reject) => {

                this.tipsList = [];
                let preSaleCheck = false;
                this.$store.dispatch('getGoodsClassifycheck', [row.goodsId]).then((res) => {
                    if (res && res.length > 0) {
                        res.forEach((item, index) => {
                            if (item.checkType == 'Image') {
                                this.tipsList.push(i18n.t('common.ProductLargePicture'));
                            } else if (item.checkType == 'Title') {
                                this.tipsList.push(i18n.t('common.ProductTitle'));
                            } else if (item.checkType == 'PreSale') {
                                preSaleCheck = true;
                            }
                            // else if (item.checkType == 'Detail') {
                            //     this.tipsList.push('图文详情');
                            // }
                        });
                    }
                    setTimeout(() => {
                        if (preSaleCheck) {
                            this.$confirm(this.$i18n.t('common.PleaseCompleteThePresaleConfigurationBeforeListingThePresaleItem'), {
                                confirmButtonText: i18n.t('common.confirm1'),
                                cancelButtonText: i18n.t('common.cancel'),
                                type: 'warning'
                            }).then(() => {});
                            return;
                        }

                        if (!row.peso) {
                            this.tipsList.push(this.$i18n.t('common.ProductWeight'));
                        }
                        if (!row.sku && !scene.isNonSelf) {
                            this.tipsList.push('SKU');
                        }
                        let shelfCountrys = [];
                        if (row.goodsSpecials && row.goodsSpecials.length > 0) {
                            let arr = [];
                            row.goodsSpecials.forEach((item, index) => {
                                if (item.shelf) {
                                    shelfCountrys.push(item.countryCode);
                                }
                                arr.push(item.shelf);
                            });
                            if (arr.indexOf(true) == -1) {
                                this.tipsList.push(i18n.t('common.Countrieslisted'));
                            }
                        } else {
                            this.tipsList.push(i18n.t('common.Countrieslisted'));
                        }

                        if (row.prices) {
                            row.prices.forEach((item) => {
                                if (!scene.isNonSelf && !item.resultPrice) {
                                    this.tipsList.push();
                                }
                                if (scene.isNonSelf && shelfCountrys.includes(item.countryCode) && !item.fixedPrice) {
                                    this.tipsList.push(i18n.t('common.PleaseConfigureFixedPrice'));
                                }
                            });
                        } else {
                            this.tipsList.push(i18n.t('common.commodityprice'));
                        }

                        //校验没有通过
                        if (this.tipsList.length > 0) {
                            this.tipsList = Array.from(new Set(this.tipsList));
                        }
                        resolve();
                    });
                });

            })
        }
    }
};
</script>
<style scoped>
.box {
    width: 600px;
    display: flex;
}
.filter-container {
    padding: auto;
    margin-left: 20px;
    padding-top: 20px;
}

.filter-item {
    margin-right: 10px;
    margin-top: 10px;
}
</style>
