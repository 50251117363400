var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "app" } },
    [
      _c("filter-container", {
        attrs: { arg: _vm.arg, placeholder: _vm.placeholder }
      }),
      _c(
        "el-table",
        {
          ref: "pagedata",
          staticStyle: { width: "100%" },
          attrs: {
            data: _vm.pageData.list,
            border: "",
            "header-cell-style": { background: "#EAEEF9", color: "#32353a" }
          },
          on: { "selection-change": _vm.handleSelectionChange }
        },
        [
          _c("el-table-column", {
            attrs: {
              prop: "goodsId",
              label: "ID",
              align: "center",
              "show-overflow-tooltip": ""
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "brand",
              label: _vm.$t("common.brand"),
              align: "center",
              "show-overflow-tooltip": ""
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return _vm._l(scope.row.brandCodes, function(s) {
                    return _c("span", { key: s.specialId }, [
                      _vm._v(" " + _vm._s(s)),
                      _c("br")
                    ])
                  })
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              prop: "classSetName",
              label: _vm.$t("common.Class"),
              align: "center",
              "show-overflow-tooltip": ""
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("div", {
                      domProps: { innerHTML: _vm._s(scope.row.classSetName) }
                    })
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              prop: "barCode",
              label: _vm.$t("common.Variantparameters"),
              align: "center",
              "show-overflow-tooltip": ""
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "updateTime",
              label: _vm.$t("common.Creationtime"),
              align: "center",
              "show-overflow-tooltip": ""
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "updateTime",
              label: _vm.$t("common.Updatetime"),
              align: "center",
              "show-overflow-tooltip": ""
            }
          }),
          _c("el-table-column", {
            attrs: {
              label: _vm.$t("common.operation"),
              align: "center",
              fixed: "right"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text", size: "small" },
                        on: {
                          click: function($event) {
                            return _vm.edit(scope.row)
                          }
                        }
                      },
                      [_vm._v(_vm._s(_vm.$t("common.edit")))]
                    ),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text", size: "small" },
                        on: {
                          click: function($event) {
                            return _vm.preview(scope.row)
                          }
                        }
                      },
                      [_vm._v(_vm._s(_vm.$t("common.Preview")))]
                    )
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "block" },
        [
          _c("el-pagination", {
            attrs: {
              "current-page": _vm.searchData.page,
              "page-size": _vm.searchData.size,
              layout: "total, prev, pager, next, jumper",
              total: _vm.pageData.total
            },
            on: { "current-change": _vm.handleCurrentChange }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }