import { render, staticRenderFns } from "./index.vue?vue&type=template&id=52533aca&scoped=true&"
import script from "./index.vue?vue&type=script&lang=js&"
export * from "./index.vue?vue&type=script&lang=js&"
import style0 from "./index.vue?vue&type=style&index=0&id=52533aca&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "52533aca",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("G:\\jenkinsworkspace\\Aigo.B2CAdmin.Publish\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('52533aca')) {
      api.createRecord('52533aca', component.options)
    } else {
      api.reload('52533aca', component.options)
    }
    module.hot.accept("./index.vue?vue&type=template&id=52533aca&scoped=true&", function () {
      api.rerender('52533aca', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/userCurrencyRecord/index.vue"
export default component.exports