<template>
    <div style="height: 100%;min-height:350px;" class="app-container">
        <div class="filter-container">

            <el-input v-model="query.name" :placeholder='$i18n.t("common.name")' clearable style="width: 200px" class="filter-item" @keyup.enter.native="handleQuery" />
            <!--

            <dataSelect
                style="width: 200px"
                class="filter-item"
                v-model="query.brand"
                clearable
                :codeType="'brand'"
                :placeholder='$i18n.t("common.brand")'
            ></dataSelect>
            -->
             <dataSelect
                style="width: 200px"
                class="filter-item"
                v-model="query.lang"
                clearable
                :codeType="'lang'"
                :placeholder='$i18n.t("common.language")' 
            ></dataSelect>


            <el-button class="filter-item" icon="el-icon-search" type="primary" @click="handleQuery"> {{ $i18n.t("common.search") }} </el-button>

            <el-button
                    class="filter-item"
                    icon="el-icon-search"
                    type="primary"
                    @click="cleanQuery"
                    style="margin-left: 10px"
                    size="small "
                >
                {{ $i18n.t("common.Reset") }}
            </el-button>

            <div style="margin-bottom:10px">

                <el-button class="filter-item" type="success" icon="el-icon-plus" @click="handleCreate" style="margin-top: 20px;"> {{ $i18n.t("common.New") }}</el-button>
                <el-button class="filter-item" icon="el-icon-download" type="warning" @click="handleExport">  {{ $i18n.t("common.export") }} </el-button>
                <el-button icon="el-icon-upload2" type="primary" @click="handleImport"> {{ $i18n.t("common.Import") }} </el-button>
                <el-button @click="downloadTemplate" type="text" size="mini" style="margin-left:5px;vertical-align: -7px;text-decoration: underline;">{{ $i18n.t("common.DownloadImportTemplate") }}</el-button>

            </div>
        </div>

        <el-table v-loading="listLoading" height="calc(100% - 160px)" :data="list" @selection-change="selectionChange" border stripe style="width: 100%">
            <el-table-column label="Id" prop="id" width="250px" align="center">
                <template slot-scope="{ row }">
                    <span>{{ row.id }}</span>
                </template>
            </el-table-column>

            <!--
            <el-table-column label="品牌" prop="brand" align="center">

                <template slot-scope="{ row }">
                    <span>{{ row.brand }}</span>
                </template>
            </el-table-column>

            -->
            <el-table-column :label='$i18n.t("common.name")'  prop="name" align="center">

                <template slot-scope="{ row }">
                    <span>{{ row.name }}</span>
                </template>
            </el-table-column>

            <el-table-column :label='$i18n.t("common.PageURL")'  prop="url" align="center">
                <template slot-scope="{ row }">
                    <span>{{ row.url }}</span>
                </template>
            </el-table-column>
            <el-table-column :label='$i18n.t("common.language")'  prop="lang" width="70px" align="center">
                <template slot-scope="{ row }">
                    <span>{{ row.lang }}</span>
                </template>
            </el-table-column>
            <el-table-column :label='$i18n.t("common.PageTitle")' prop="title" align="center">
                <template slot-scope="{ row }">
                    <span>{{ row.title }}</span>
                </template>
            </el-table-column>
            <el-table-column :label='$i18n.t("common.Pagekeywords")' prop="keyword" width="200px" align="center">
                <template slot-scope="{ row }">
                    <span>{{ row.keyWord }}</span>
                </template>
            </el-table-column>
            <el-table-column :label='$i18n.t("common.PageDescription")' prop="description" align="center">
                <template slot-scope="{ row }">
                    <span>{{ row.description }}</span>
                </template>
            </el-table-column>
            <el-table-column :label='$i18n.t("common.Creationtime")' prop="createtime" align="center">
                <template slot-scope="scope">
                    <span>{{ scope.row.createTime | formatDate }}</span>
                </template>
            </el-table-column>
            <el-table-column :label='$i18n.t("common.Modificationtime")' prop="updatetime" align="center">
                <template slot-scope="scope">
                    <span>{{ scope.row.updatetime | formatDate }}</span>
                </template>
            </el-table-column>
            <el-table-column :label='$i18n.t("common.operation")'  align="center" width="200">
                <template slot-scope="{ row }">
                    <el-button type="primary" size="mini" @click="handleUpdate(row)" class="table-inner-button">{{ $i18n.t("common.edit") }}</el-button>
                    <el-button type="danger" size="mini" @click="handleDeleteSingle(row)" class="table-inner-button">{{ $i18n.t("common.delete") }}</el-button>
                </template>
            </el-table-column>
        </el-table>

        <pagination v-show="total > 0" :total="total" :pageNo.sync="query.current" :pageSize.sync="query.size" @pagination="doQueryList" />

        <!-- 新建编辑表单 -->
        <addOrUpdate ref="seoAdd" @created="doQueryList({ pageNo: 1 })" />

        <seoImport ref="seoImport" @imported="doQueryList({pageNo : 1})"/>
    </div>
</template>

<script>
import addOrUpdate from './addOrUpdate';
import seoImport from './import';
import Pagination from '@/components/common/Pagination';
import DataSelect from '@/components/common/DataSelect';

export default {
    name: 'SeoTable',
    components: {
        Pagination,
        seoImport,
        DataSelect,
        addOrUpdate
    },
    data() {
        return {
            list: [],
            total: 0,
            listLoading: true,
            query: {
                current: 1,
                size: 10,
                sydelete: null,
                name: null,
                brand: null,
                url: null,
                lang: null,
                title: null,
                keyword: null,
                description: null,
                createtimeStart: '',
                createtimeEnd: '',
                updatetimeStart: '',
                updatetimeEnd: '',
                systampStart: '',
                systampEnd: '',
                tenantid: undefined
            },
            selectItems: [],
        };
    },
    created() {
        this.doQueryList({ pageNo: 1 });
    },
    methods: {
        /**
         * 选择框变化
         */
        selectionChange(val) {
            this.selectItems = val;
        },
        /**
         * 触发搜索操作
         */
        handleQuery() {
            this.doQueryList({ pageNo: 1 });
        },
        /**
         * 执行列表查询
         */
        doQueryList({ pageNo, pageSize }) {
            if (pageNo) {
                this.query.current = pageNo;
            }
            if (pageSize) {
                this.query.size = pageSize;
            }
            this.listLoading = true;

            this.$store
                .dispatch('getSeoPage', this.query)
                .then((res) => {
                    this.list = res.list;
                    this.total = res.total;
                })
                .finally(() => {
                    this.listLoading = false;
                });
        },
        /**
         * 删除单条记录
         */
        handleDeleteSingle(row) {
            return this.$confirm( this.$i18n.t("common.Areyousuretocontinuedeletingdata"),   this.$i18n.t("common.prompt"), {
                // confirmButtonText: '确定',
                // cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.$store.dispatch('deleteSEO', row.id).then((res) => {
                     this.$message({
                            type: 'success',
                            message:   this.$i18n.t("common.Successfullydeleted")
                        });
                    this.doQueryList({ pageNo: 1 });
                });
            });
        },
        /**
         * 表单重置
         */
        cleanQuery() {
            this.query=[];
            this.doQueryList({ pageNo: 1 });
        },
        /**
         * 打开新建表单
         */
        handleCreate() {
            this.$refs.seoAdd.handleCreate();
        },
        /**
         * 打开编辑表单
         */
        handleUpdate(row) {
            this.$refs.seoAdd.handleCreate();
            this.$refs.seoAdd.form = JSON.parse(JSON.stringify(row));
        },
        /**
         * 导出excel
         */
        handleExport() {
            this.$confirm(this.$i18n.t("common.Areyousuretoexportthefile"), this.$i18n.t("common.prompt"), {
                // confirmButtonText: '确定',
                // cancelButtonText: '取消',
                type: 'warning'
            })
                .then(() => {
                    this.$store.dispatch('exportExcel', { fileName: 'SEO数据.xlsx' }).then((res) => {
                        this.$message({
                            type: 'success',
                            message: this.$i18n.t("common.Exportsuccessful")
                        });
                    });
                })
                .catch(() => {
                    this.$message({
                        type: 'info',
                        message: this.$i18n.t("common.Canceled")
                    });
                });
        },
        /**
         * 打开导入表单
         */
        handleImport() {
            this.$refs.seoImport.show();
        },
        /**
         * 下载模板
         */
        downloadTemplate() {
            this.$store.dispatch('downExcelTemplate', { fileName: 'SEO配置模板.xlsx' }).then((res) => {
                this.$message({
                    type: 'success',
                    message: this.$i18n.t("common.Downloadsuccessful")
                });
            });
        },
        /**
         * 时间戳转码
         */
        formatDate(row, column) {
            // 获取单元格数据
            let data = row[column.property];
            if (!data) {
                return '';
            } else {
                return moment(data).format('YYYY-MM-DD HH:mm:ss');
            }
        }
    }
};
</script>

<style scoped>
.filter-container {
    padding: auto;
    margin-left: 20px;
    padding-top: 20px;
}
.filter-item {
    margin-right: 10px;
}
</style>
