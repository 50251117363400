var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.formVisible
    ? _c(
        "el-dialog",
        {
          attrs: { title: _vm.formTitle, visible: _vm.formVisible },
          on: {
            "update:visible": function($event) {
              _vm.formVisible = $event
            }
          }
        },
        [
          _c(
            "el-form",
            {
              ref: "dataForm",
              attrs: {
                rules: _vm.formRules,
                model: _vm.form,
                "label-position": "left",
                size: "small",
                "label-width": "100px"
              }
            },
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: _vm.$i18n.t("common.Countryname"),
                    prop: "countrycode"
                  }
                },
                [
                  _c(
                    "el-select",
                    {
                      attrs: {
                        placeholder: _vm.$i18n.t("common.Pleaseselectacountry")
                      },
                      on: { change: _vm.changeCountry },
                      model: {
                        value: _vm.form.countryCode,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "countryCode", $$v)
                        },
                        expression: "form.countryCode"
                      }
                    },
                    _vm._l(_vm.counrtys, function(item) {
                      return _c("el-option", {
                        key: item.code,
                        attrs: { label: item.name, value: item.code }
                      })
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { prop: "icon", label: _vm.$i18n.t("common.icon") } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c("imgUpload", {
                        ref: "imageUpload",
                        staticClass: "imgUpload",
                        attrs: { img: _vm.imageUrl },
                        on: { event: _vm.changeUrl }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    {
                      staticStyle: { "margin-top": "120px" },
                      attrs: { span: 10 }
                    },
                    [
                      _c("span", [
                        _vm._v(
                          _vm._s(_vm.$i18n.t("common.Suggestedsize")) +
                            ":466*280 <=200KB"
                        )
                      ])
                    ]
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: { label: _vm.$i18n.t("common.language"), prop: "lang" }
                },
                [
                  _c("dataSelect", {
                    staticClass: "filter-item",
                    staticStyle: { width: "200px" },
                    attrs: {
                      disabled: true,
                      clearable: "",
                      codeType: "lang",
                      placeholder: _vm.$i18n.t("common.language")
                    },
                    model: {
                      value: _vm.form.lang,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "lang", $$v)
                      },
                      expression: "form.lang"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: _vm.$i18n.t("common.currency"),
                    prop: "currency"
                  }
                },
                [
                  _c("dataSelect", {
                    staticClass: "filter-item",
                    staticStyle: { width: "200px" },
                    attrs: {
                      disabled: true,
                      clearable: "",
                      codeType: "CurrencySymbol",
                      placeholder: _vm.$i18n.t("common.currency")
                    },
                    model: {
                      value: _vm.form.currency,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "currency", $$v)
                      },
                      expression: "form.currency"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: _vm.$i18n.t("common.Enableornot"),
                    prop: "status"
                  }
                },
                [
                  _c("el-switch", {
                    model: {
                      value: _vm.form.status,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "status", $$v)
                      },
                      expression: "form.status"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function($event) {
                      _vm.formVisible = false
                    }
                  }
                },
                [_vm._v("  " + _vm._s(_vm.$i18n.t("common.cancel")) + " ")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function($event) {
                      return _vm.doCreate()
                    }
                  }
                },
                [_vm._v(" " + _vm._s(_vm.$i18n.t("common.confirm")) + " ")]
              )
            ],
            1
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }