var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.logisticsVisible
        ? _c(
            "el-dialog",
            {
              attrs: {
                title: _vm.dialogTitle,
                visible: _vm.logisticsVisible,
                width: "900px",
                "close-on-click-modal": false
              },
              on: {
                "update:visible": function($event) {
                  _vm.logisticsVisible = $event
                }
              }
            },
            [
              _c(
                "el-form",
                {
                  ref: "dataForm",
                  staticStyle: { width: "750px", "margin-left": "50px" },
                  attrs: {
                    rules: _vm.formRules,
                    model: _vm.form,
                    "label-position": "left",
                    size: "small",
                    "label-width": "120px"
                  }
                },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: _vm.$i18n.t("common.country"),
                        prop: "countryCode"
                      }
                    },
                    [
                      _c("dataSelect", {
                        staticClass: "filter-item",
                        staticStyle: { width: "180px" },
                        attrs: {
                          clearable: "",
                          codeType: "countrys",
                          placeholder: _vm.$i18n.t("common.country")
                        },
                        model: {
                          value: _vm.form.countryCode,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "countryCode", $$v)
                          },
                          expression: "form.countryCode"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "div",
                    [
                      _c(
                        "el-divider",
                        { attrs: { "content-position": "left" } },
                        [
                          _vm._v(
                            _vm._s(_vm.$i18n.t("common.Freightallocation"))
                          )
                        ]
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: _vm.$i18n.t("common.FreightType"),
                        prop: "isFreeFreightFee"
                      }
                    },
                    [
                      _c(
                        "el-radio-group",
                        {
                          model: {
                            value: _vm.form.isFreeFreightFee,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "isFreeFreightFee", $$v)
                            },
                            expression: "form.isFreeFreightFee"
                          }
                        },
                        [
                          _c("el-radio", { attrs: { label: true } }, [
                            _vm._v(_vm._s(_vm.$i18n.t("common.Freeshipping")))
                          ]),
                          _c("el-radio", { attrs: { label: false } }, [
                            _vm._v(
                              _vm._s(
                                _vm.$i18n.t("common.Configureshippingcosts")
                              )
                            )
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  !_vm.form.isFreeFreightFee
                    ? _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$i18n.t("common.Basicfreight"),
                            prop: "freightFee"
                          }
                        },
                        [
                          _c("el-input", {
                            staticStyle: { width: "180px" },
                            model: {
                              value: _vm.form.freightFee,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "freightFee", $$v)
                              },
                              expression: "form.freightFee"
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  !_vm.form.isFreeFreightFee
                    ? _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$i18n.t("common.Fullreductionoffreight"),
                            prop: "freeFreightFeePrice"
                          }
                        },
                        [
                          _c(
                            "el-input",
                            {
                              staticStyle: { width: "300px" },
                              model: {
                                value: _vm.form.freeFreightFeePrice,
                                callback: function($$v) {
                                  _vm.$set(_vm.form, "freeFreightFeePrice", $$v)
                                },
                                expression: "form.freeFreightFeePrice"
                              }
                            },
                            [
                              _c("template", { slot: "prepend" }, [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$i18n.t("common.Orderamountfull")
                                    ) +
                                    " "
                                )
                              ]),
                              _c("template", { slot: "append" }, [
                                _vm._v(
                                  "  " +
                                    _vm._s(_vm.$i18n.t("common.Freeshipping")) +
                                    " "
                                )
                              ])
                            ],
                            2
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "div",
                    [
                      _c(
                        "el-divider",
                        { attrs: { "content-position": "left" } },
                        [
                          _vm._v(
                            _vm._s(
                              _vm.$i18n.t("common.Receivingandshippingreminder")
                            )
                          )
                        ]
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: _vm.$i18n.t("common.Deliverytimeprompt"),
                        prop: "deliverDescribe"
                      }
                    },
                    [
                      _c("el-input", {
                        staticStyle: { width: "500px" },
                        model: {
                          value: _vm.form.deliverDescribe,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "deliverDescribe", $$v)
                          },
                          expression: "form.deliverDescribe"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: _vm.$i18n.t("common.Receivingtimeprompt"),
                        prop: "receivingDescribe"
                      }
                    },
                    [
                      _c("el-input", {
                        staticStyle: { width: "500px" },
                        model: {
                          value: _vm.form.receivingDescribe,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "receivingDescribe", $$v)
                          },
                          expression: "form.receivingDescribe"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "div",
                    [
                      _c(
                        "el-divider",
                        { attrs: { "content-position": "left" } },
                        [_vm._v(_vm._s(_vm.$i18n.t("common.logisticscompany")))]
                      ),
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          on: {
                            click: function($event) {
                              return _vm.addCompany()
                            }
                          }
                        },
                        [_vm._v(" " + _vm._s(_vm.$i18n.t("common.Add1")))]
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-table",
                    {
                      staticStyle: {
                        width: "850px",
                        "margin-top": "20px",
                        "margin-bottom": "20px"
                      },
                      attrs: { data: _vm.logisticsCompany }
                    },
                    [
                      _c("el-table-column", {
                        attrs: {
                          label: _vm.$i18n.t("common.Serialnumber"),
                          width: "80px",
                          type: "index"
                        }
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "title",
                          label: _vm.$i18n.t("common.name"),
                          width: "150px"
                        }
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "logo",
                          label: _vm.$i18n.t("common.icon"),
                          width: "150px"
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function(scope) {
                                return [
                                  scope.row.logo
                                    ? _c("el-image", {
                                        staticStyle: {
                                          width: "100px",
                                          height: "100px"
                                        },
                                        attrs: {
                                          src: _vm.ossHost + scope.row.logo,
                                          fit: "fill"
                                        }
                                      })
                                    : _vm._e()
                                ]
                              }
                            }
                          ],
                          null,
                          false,
                          2996109819
                        )
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "link",
                          label: _vm.$i18n.t("common.link"),
                          width: "180px"
                        }
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "sort",
                          label: _vm.$i18n.t("common.sort"),
                          width: "50px"
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function(scope) {
                                return [
                                  scope.$index != 0
                                    ? _c(
                                        "el-button",
                                        {
                                          staticClass: "table-inner-button",
                                          attrs: {
                                            type: "text",
                                            size: "medium"
                                          },
                                          on: {
                                            click: function($event) {
                                              return _vm.moveUp(
                                                scope.$index,
                                                scope.row
                                              )
                                            }
                                          }
                                        },
                                        [_vm._v("⬆")]
                                      )
                                    : _vm._e(),
                                  scope.$index !=
                                  _vm.logisticsCompany.length - 1
                                    ? _c(
                                        "el-button",
                                        {
                                          staticClass: "table-inner-button",
                                          attrs: {
                                            type: "text",
                                            size: "medium"
                                          },
                                          on: {
                                            click: function($event) {
                                              return _vm.moveDown(
                                                scope.$index,
                                                scope.row
                                              )
                                            }
                                          }
                                        },
                                        [_vm._v("⬇")]
                                      )
                                    : _vm._e()
                                ]
                              }
                            }
                          ],
                          null,
                          false,
                          2999836008
                        )
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: _vm.$i18n.t("common.operation"),
                          align: "center",
                          width: "150px"
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function(scope) {
                                return [
                                  _c(
                                    "el-button",
                                    {
                                      staticClass: "table-inner-button",
                                      attrs: { type: "primary", size: "mini" },
                                      on: {
                                        click: function($event) {
                                          return _vm.updateCompany(
                                            scope.$index,
                                            scope.row
                                          )
                                        }
                                      }
                                    },
                                    [_vm._v(_vm._s(_vm.$i18n.t("common.edit")))]
                                  ),
                                  _c(
                                    "el-button",
                                    {
                                      staticClass: "table-inner-button",
                                      attrs: { type: "primary", size: "mini" },
                                      on: {
                                        click: function($event) {
                                          return _vm.deleteCompany(
                                            scope.$index,
                                            scope.row
                                          )
                                        }
                                      }
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(_vm.$i18n.t("common.delete"))
                                      )
                                    ]
                                  )
                                ]
                              }
                            }
                          ],
                          null,
                          false,
                          169663545
                        )
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "div",
                {
                  staticClass: "dialog-footer",
                  attrs: { slot: "footer" },
                  slot: "footer"
                },
                [
                  _c(
                    "el-button",
                    {
                      on: {
                        click: function($event) {
                          _vm.logisticsVisible = false
                        }
                      }
                    },
                    [_vm._v("  " + _vm._s(_vm.$i18n.t("common.cancel")) + " ")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: {
                        click: function($event) {
                          return _vm.doUpdate()
                        }
                      }
                    },
                    [_vm._v("  " + _vm._s(_vm.$i18n.t("common.confirm")) + " ")]
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _vm.companyVisible
        ? _c(
            "el-dialog",
            {
              attrs: { title: _vm.companyTitle, visible: _vm.companyVisible },
              on: {
                "update:visible": function($event) {
                  _vm.companyVisible = $event
                }
              }
            },
            [
              _c(
                "el-form",
                {
                  ref: "companyForm",
                  staticStyle: { width: "400px", "margin-left": "50px" },
                  attrs: {
                    rules: _vm.companyFormRules,
                    model: _vm.logisticsCompanyAdd,
                    "label-position": "left",
                    size: "small",
                    "label-width": "100px"
                  }
                },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: _vm.$i18n.t("common.name"),
                        prop: "title"
                      }
                    },
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.logisticsCompanyAdd.title,
                          callback: function($$v) {
                            _vm.$set(_vm.logisticsCompanyAdd, "title", $$v)
                          },
                          expression: "logisticsCompanyAdd.title"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: { label: _vm.$i18n.t("common.link"), prop: "link" }
                    },
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.logisticsCompanyAdd.link,
                          callback: function($$v) {
                            _vm.$set(_vm.logisticsCompanyAdd, "link", $$v)
                          },
                          expression: "logisticsCompanyAdd.link"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: { label: _vm.$i18n.t("common.icon"), prop: "logo" }
                    },
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 14 } },
                        [
                          _c("imgUpload", {
                            ref: "imageUpload",
                            attrs: { img: _vm.logo },
                            on: { event: _vm.changeUrl }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "div",
                {
                  staticClass: "dialog-footer",
                  attrs: { slot: "footer" },
                  slot: "footer"
                },
                [
                  _c(
                    "el-button",
                    {
                      on: {
                        click: function($event) {
                          _vm.companyVisible = false
                        }
                      }
                    },
                    [_vm._v("  " + _vm._s(_vm.$i18n.t("common.cancel")) + " ")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: {
                        click: function($event) {
                          return _vm.doAddCompany()
                        }
                      }
                    },
                    [_vm._v("  " + _vm._s(_vm.$i18n.t("common.confirm")) + " ")]
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }