var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("span", [
        _vm._v(
          _vm._s(
            _vm.$i18n.t("common.InvoiceAttachment") +
              "（" +
              _vm.$i18n.t("common.RequiredField") +
              "，" +
              _vm.$i18n.t("common.PDFFormat") +
              "，" +
              _vm.$i18n.t("common.FileSize3MB") +
              "）"
          )
        )
      ]),
      _c(
        "el-row",
        [
          _c(
            "el-col",
            { attrs: { span: 10 } },
            [
              _c("el-input", {
                staticStyle: { width: "400px" },
                attrs: {
                  placeholder: _vm.$i18n.t(
                    "common.PleaseUploadInvoiceAttachment"
                  ),
                  readonly: ""
                },
                model: {
                  value: _vm.upFileName,
                  callback: function($$v) {
                    _vm.upFileName = $$v
                  },
                  expression: "upFileName"
                }
              })
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 10 } },
            [
              _c(
                "el-upload",
                {
                  ref: "uploadFile",
                  staticClass: "upload",
                  attrs: {
                    "auto-upload": false,
                    "show-file-list": false,
                    action: _vm.ossInfo.ossSignature.host,
                    multiple: false,
                    data: _vm.fileDataObj,
                    "on-change": _vm.handleChange,
                    "on-error": _vm.uploadError,
                    accept: ".pdf"
                  }
                },
                [
                  _c(
                    "el-button",
                    {
                      attrs: {
                        slot: "trigger",
                        size: "small",
                        type: "primary"
                      },
                      slot: "trigger"
                    },
                    [_vm._v(_vm._s(_vm.$i18n.t("common.Upload")))]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }