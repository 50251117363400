<template>
    <div>
          <span>{{ $i18n.t("common.InvoiceAttachment")+"（"+ $i18n.t("common.RequiredField")+"，"+ $i18n.t("common.PDFFormat")+"，"+ $i18n.t("common.FileSize3MB")+"）" }}</span>
          <el-row>
            <el-col :span="10">
              <el-input style="width: 400px;" v-model="upFileName" :placeholder='$i18n.t("common.PleaseUploadInvoiceAttachment")' readonly ></el-input>
            </el-col>
            <el-col :span="10">
              <el-upload
                  class="upload"
                  ref="uploadFile"
                  :auto-upload="false"
                  :show-file-list="false"
                  :action="ossInfo.ossSignature.host"
                  :multiple="false"
                  :data="fileDataObj"
                  :on-change="handleChange"
                  :on-error="uploadError"
                  accept=".pdf"
              >
                <el-button slot="trigger" size="small" type="primary">{{ $i18n.t("common.Upload") }}</el-button>
              </el-upload>
            </el-col>

          </el-row>

    </div>
</template>

<script>
import X2JS from 'x2js'; //解析xml
export default {
    props: {
      url: String
    },
    mounted() {
        this.getOssSignature();
        this.clearUploadFile();
    },
    data() {
        return {
            fileDataObj: {},
            ossInfo: {
                ossHost: process.env.VUE_APP_OSS_Head,
                ossSignature: { host: '' },
                activityTranslates: [],
                langs: [],
                seoIndex: '0',
                newFiles: []
            },
            ossHost: process.env.VUE_APP_OSS_Head,
            upFileName:'',
            invoiceUrl:'',
        };
    },
    methods: {
        event() {
            this.$emit('event', this.invoiceUrl);
        },
        clearUploadFile(){
          this.$refs.uploadFile.clearFiles();
          this.upFileName = ''
          this.invoiceUrl = ''
          this.event();
        },
        handleChange(file, fileList) {
            //console.log('=========',fileList,file)
            const isLt = file.size / 1024  <= 3072;
            if (!isLt) {
                this.$message.error(this.$i18n.t("common.UploadFailedFileSizeShouldBe3MB"))
                this.clearUploadFile()
                return;
            }
            //获取后缀
            const ext = file.name.substr(file.name.lastIndexOf(".")+1);
            if(ext!='pdf' && ext!='PDF') {
              this.$message.error(this.$i18n.t("common.UploadFailedPDFFormatRequired"))
              this.clearUploadFile()
              return;
            }
            let dataObj = {
                name: file.name,
                key: this.ossInfo.ossSignature.dir + '/invoice/' + new Date().getFullYear() + '/' + this.randomString() + this.getSuffix(file.name),
                policy: this.ossInfo.ossSignature['policy'],
                OSSAccessKeyId: this.ossInfo.ossSignature['access_id'],
                success_action_status: '201',
                callback: '',
                signature: this.ossInfo.ossSignature['signature']
                // file: file.file,
            };
            file.type = 'add';
            this.fileDataObj = dataObj;
            console.log('dataObj', this.ossInfo.ossHost);
            let that = this;
            setTimeout(() => {
                this.$refs.uploadFile.submit();
                var x2js = new X2JS();
                var jsonObj = x2js.xml2js(file.response);
                let ossUrl = jsonObj.PostResponse.Location;
                if (ossUrl) {
                    this.upFileName = this.fileDataObj.name
                    that.invoiceUrl = ossUrl
                    console.log('invoiceUrl', this.invoiceUrl);
                }   
                this.$refs.uploadFile.clearFiles();
                that.event();
            }, 500);
        },
        uploadError(){
            this.$message.error(this.$i18n.t("common.UploadFailed"));
            this.clearUploadFile()
        },
        //获取OSS签名
        getOssSignature() {
            this.$store
                .dispatch('getSignature', {
                    dir: 'b2c'
                })
                .then((res) => {
                    console.log(res);
                    this.ossInfo.ossSignature = res;
                    this.ossInfo.ossSignature.host = this.ossInfo.ossHost;
                });
        },
        /**
         * 生成随机文件名
         * @param len
         * @returns {string}
         */
        randomString(len) {
            len = len || 32;
            let chars = 'ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678';
            let maxPos = chars.length;
            let pwd = '';
            for (let i = 0; i < len; i++) {
                pwd += chars.charAt(Math.floor(Math.random() * maxPos));
            }
            return pwd;
        },

        getSuffix(filename) {
            let pos = filename.lastIndexOf('.');
            let suffix = '';
            if (pos != -1) {
                suffix = filename.substring(pos);
            }
            return suffix;
        }
    }
};
</script>



 
