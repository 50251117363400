var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-upload",
        {
          ref: "upload",
          staticClass: "multiple-upload",
          attrs: {
            action: _vm.ossInfo.ossSignature.host,
            "show-file-list": true,
            "auto-upload": true,
            multiple: "",
            "before-upload": _vm.beforeAvatarUpload,
            "on-success": _vm.handleSuccess,
            "on-error": _vm.handleError,
            "on-remove": _vm.handleFileRemove,
            "http-request": _vm.customRequest,
            "on-exceed": _vm.handleExceed,
            accept: _vm.accept,
            limit: _vm.limit
          }
        },
        [
          _c(
            "el-button",
            { attrs: { type: "success", size: "small", loading: _vm.load } },
            [_vm._v(_vm._s(_vm.$i18n.t("common.Uploadimages")))]
          ),
          _vm._t("default")
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }