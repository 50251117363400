<template>
    <el-dialog :title="formTitle" :visible.sync="formVisible" v-if="formVisible">
        <el-form
            ref="dataForm"
            :rules="formRules"
            :model="form"
            label-position="left"
            size="small"
            label-width="100px"
        >
            <el-form-item  :label='$i18n.t("common.Countryname")' prop="countrycode">
                <el-select v-model="form.countryCode" :placeholder='$i18n.t("common.Pleaseselectacountry")' @change="changeCountry">
                    <el-option v-for="item in counrtys" :key="item.code" :label="item.name" :value="item.code"> </el-option>
                </el-select>
            </el-form-item>
            <el-form-item prop="icon" :label='$i18n.t("common.icon")' >
                <el-col :span="8">
                    <!-- <ossUploader
                        v-if="ossSignature.host != ''"
                        ref="mainImageUploader"
                        :ossSignature="ossSignature"
                        :limit="1"
                        uploaderId="mainimage"
                        @fileListChange="fileListChange"
                    /> -->
                    <imgUpload ref="imageUpload" :img="imageUrl" @event="changeUrl" class="imgUpload"></imgUpload>
                    <!-- <imgUpload ref="imageUpload" :img="imageUrl && ossHost + imageUrl" @event="changeUrl"></imgUpload> -->
                </el-col>
                <el-col :span="10"  style="margin-top:120px">
                        <span>{{$i18n.t("common.Suggestedsize")}}:466*280 <=200KB</span>
                    </el-col>
            </el-form-item>
            <el-form-item :label='$i18n.t("common.language")' prop="lang">
                <dataSelect
                    :disabled="true"
                    style="width: 200px"
                    class="filter-item"
                    v-model="form.lang"
                    clearable
                    :codeType="'lang'"
                    :placeholder='$i18n.t("common.language")'
                ></dataSelect>
            </el-form-item>

            <el-form-item :label='$i18n.t("common.currency")' prop="currency">
                <dataSelect
                    :disabled="true"
                    style="width: 200px"
                    class="filter-item"
                    v-model="form.currency"
                    clearable
                    :codeType="'CurrencySymbol'"
                    :placeholder='$i18n.t("common.currency")'
                ></dataSelect>
            </el-form-item>    
            <!--
            <el-form-item label="品牌" prop="brand">

                <el-checkbox-group v-model="checkedBrand" @change="handleCheckedCitiesChange">
                    <el-checkbox v-for="(item, index) in brands" :label="item.code" :key="index">{{ item.name }}</el-checkbox>
                </el-checkbox-group>
            </el-form-item>
            -->
            <el-form-item :label='$i18n.t("common.Enableornot")' prop="status">

                <el-switch v-model="form.status"></el-switch>
            </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
            <el-button @click="formVisible = false">  {{ $i18n.t("common.cancel") }} </el-button>
            <el-button type="primary" @click="doCreate()"> {{ $i18n.t("common.confirm") }} </el-button>
        </div>
    </el-dialog>
</template>

<script>
import DataSelect from '@/components/common/DataSelect';
import imgUpload from '@/views/country/imgUpload';
function initFormBean() {
    const formBean = {
        countryId: null,
        countrycode: null,
        icon: null,
        lang: null,
        currency: null,
        description: null,
        status: null
    };
    return formBean;
}

export default {
    name: 'CountryAdd',
    components: {
        DataSelect,
        imgUpload
    },
    data() {
        var validateCountry = (rule, value, callback) => {
            if (this.form.countryCode === '' || this.form.countryCode == null) {
                callback(new Error(this.$i18n.t("common.Pleaseselectacountry") ));
            } else {
                callback();
            }
        };

        // var validateBrand = (rule, value, callback) => {
        //     if (this.checkedBrand.length == 0) {
        //         callback(new Error('请选择品牌'));
        //     } else {
        //         callback();
        //     }
        // };

        var validateIcon = (rule, value, callback) => {
            if (this.form.icon === '' || this.form.icon == null) {
                callback(new Error(this.$i18n.t("common.Pleaseuploadpictures") ));
            } else {
                callback();
            }
        };
        return {
            ossHost: process.env.VUE_APP_OSS_Head,
            ossSignature: { host: '' },
            form: {},
            formVisible: false,
            formRules: {
                countrycode: [{ validator: validateCountry, trigger: 'change' }],
                // brand: [{ validator: validateBrand, trigger: 'change' }],
                icon: [{ validator: validateIcon, trigger: 'change' }]
            },
            counrtys: [],
            langs: [],
            counrtylangs: [],
            countryCurrencys: [],
            checkedBrand: [],
            brands: [],
            formTitle:'',

            countryWatch: null,
            imageUrl: '',
            reloadimageUrl:this.imageUrl
        };
    },
    mounted() {
        this.getDictItems();
    },
    methods: {
        changeUrl(data) {
            console.log("===",data)
            this.form.icon = data;
            console.log("===",this.form.icon)
        },
        handleCheckedCitiesChange(value) {
            this.checkedBrand = value;
        },
        getDictItems() {
            var _this = this;
            this.$store.dispatch('getDicItems', { tag: 'countrys' }).then((res) => {
                if (res != null) {
                    _this.counrtys = res;
                }
            });
            this.$store.dispatch('getDicItems', { tag: 'lang' }).then((res) => {
                if (res != null) {
                    _this.langs = res;
                }
            });
            this.$store.dispatch('getDicItems', { tag: 'countrylang' }).then((res) => {
                if (res != null) {
                    _this.counrtylangs = res;
                }
            });

            this.$store.dispatch('getDicItems', { tag: 'country_currency' }).then((res) => {
                if (res != null) {
                    _this.countryCurrencys = res;
                }
            });

            this.$store.dispatch('getDicItems', { tag: 'brand' }).then((res) => {
                if (res != null) {
                    _this.brands = res;
                }
            });
        },
        changeCountry(e) {
            this.form.lang = this.$dict.getDictValue('countrylang', e);
            this.form.currency = this.$dict.getDictValue('country_currency', e);
        },
        /**
         * 重置表单
         */
        resetForm() {
            this.form = initFormBean();
            this.imageUrl = "";
            this.checkedBrand = [];
            this.countryWatch = '';
        },
        /**
         * 打开新建表单
         */
        handleCreate() {
            this.formTitle = this.$i18n.t("common.NewCountryInformation")
            this.resetForm();
            this.formVisible = true;
            this.imageUrl=""
            this.$nextTick(() => {
                this.$refs['dataForm'].clearValidate();
            });
        },
        handleEdit(){
            this.formTitle = this.$i18n.t("common.EditCountryInformation")
            this.resetForm();
            this.formVisible = true;
            this.imageUrl=""
            this.$nextTick(() => {
                this.$refs['dataForm'].clearValidate();
            });
        },
        /**
         * 执行新建操作
         */
        doCreate() {
            console.log(this.form.icon);
            if(this.form.icon == '' || this.form.icon == null){
                this.$message({
                    showClose: false,
                    message:  this.$i18n.t("common.Picturesmustbetransmitted"),
                    type: 'warning'
                });
                return;
            }
            this.checkedBrand=[];
            this.checkedBrand.push(this.$tenantBrand.getBrand());
            this.form.brandCodeList = this.checkedBrand;
            this.$refs['dataForm'].validate().then(() => {
                console.log("新建国家信息表图片地址",this.imageUrl);
                if (this.form.countryId == null) {
                    this.$store.dispatch('addCountry', this.form).then(() => {
                        this.$parent.doQueryList({ pageNo: this.$parent.query.current });
                        this.formVisible = false;
                        this.$message.success( this.$i18n.t("common.Operationsuccessful"));
                    });
                } else {
                    this.$store.dispatch('updateCountry', this.form).then(() => {
                        this.$parent.doQueryList({ pageNo: this.$parent.query.current });
                        this.formVisible = false;
                        this.$message.success(this.$i18n.t("common.Operationsuccessful"));
                    });
                }
            });
        }
    },
    watch: {
        countryWatch: {
            // 子级下拉框的监听对象
            deep: true,
            handler: function (newval) {
                // 监听内容如下
                this.form.countryCode = newval;
                this.changeCountry(newval); // 值改变后的事件处理
            }
        },
        checkedBrand: {
            // 子级下拉框的监听对象
            deep: true,
            handler: function (newval) {
                // 监听内容如下
                this.handleCheckedCitiesChange(newval); // 值改变后的事件处理
            }
        }
    }
};
</script>

<style scoped>
.filter-container {
    padding: auto;
    margin-left: 20px;
    padding-top: 20px;
}
.avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    width: 178px !important;
    height: 178px !important;
}

.avatar-uploader .el-upload:hover {
    border-color: #409eff;
}

.avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
}

/deep/ .avatar-uploader .el-upload--picture-card {
    width: 178px !important;
    height: 178px !important;
}
/deep/ .avatar-uploader .el-progress--circle {
    width: 176px !important;
    height: 176px !important;
    margin-top: 0px !important;
}
/deep/ .avatar-uploader .el-progress {
    width: 176px !important;
    height: 176px !important;
    margin-top: 0px !important;
}
/deep/ .avatar-uploader .el-progress-circle {
    width: 176px !important;
    height: 176px !important;
    margin-top: 0px !important;
}
</style>
