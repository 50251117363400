<template>
    <el-dialog
        :title='$i18n.t("common.AIGOCoinDetails")'
        :visible="currencyVisible"
        v-if="currencyVisible"
        width="492px"
        :close-on-click-modal="false"
        @close="currencyVisible = false"
    >
        <el-table :data="currency" border stripe style="width: 100%" size="small">
            <el-table-column type="index" :label="$i18n.t('common.Serialnumber')" align="center" width="150px"> </el-table-column>
            <el-table-column prop="amount" :label="$i18n.t('common.TheRemainingAmount')" align="center" width="150px"> </el-table-column>
            <el-table-column prop="expireTime" :label="$i18n.t('common.ValidityPeriod')" width="150px" align="center"> </el-table-column>
        </el-table>

        <span>
            <el-button @click="currencyVisible = false" style="margin-left: 200px;margin-top: 20px;" size="medium">取 消</el-button>
        </span>
    </el-dialog>
</template>

<script>
import dataSelect from '@/components/common/DataSelect.vue';
import dataText from '@/components/common/DataText.vue';
import moment from 'moment';

export default {
    name: 'userCurrencyHandle',
    components: {
        dataSelect,
        dataText
    },
    data() {
        return {
            currency: [],
            currencyQuery: {
                countryCode: null,
                userId: null
            },
            currencyVisible: false,
            listLoading: true
        };
    },
    methods: {
        /**
         * 时间戳转码
         */
        formatDate(row, column) {
            // 获取单元格数据
            let data = row[column.property];
            if (!data) {
                return '';
            } else {
                return moment(data).format('YYYY-MM-DD HH:mm:ss');
            }
        },
        /**
         * 收藏商品处理
         */
        currencyHandle(userId, countryCode) {
            this.currency = [];
            this.currencyQuery.countryCode = countryCode;
            this.currencyQuery.userId = userId;
            this.doCollectionQuery();
            this.currencyVisible = true;
        },
        doCollectionQuery() {
            this.$store.dispatch('listUserCurrency', this.currencyQuery).then((res) => {
                this.currency = res;
            });
        }
    }
};
</script>
