<template> 
    <div v-if="isShow">
        
    
        <el-tabs v-model="activeName" @tab-click="handleClick">

            <el-tab-pane :label="$t('common.PresaleTime')" name="1">
                <el-form v-for="(item) in goodsPresaleConfigList" :ref="'titleFrom'+item.countryCode" :key="item.countryCode" label-width="100px" >
                    <el-form-item :label="$dict.getDictValue('countrys', item.countryCode)+'：'"  :required="true">                      
                        <el-date-picker v-model="item.time" clearable  type="datetimerange" value-format="yyyy-MM-dd HH:mm:ss"
                            format="yyyy-MM-dd HH:mm:ss"  range-separator="-" :start-placeholder="$i18n.t('common.Startdate')"  :end-placeholder="$i18n.t('common.Enddate')" >                   
                        </el-date-picker>
                    </el-form-item>
                </el-form>            
            </el-tab-pane>


            <el-tab-pane :label="$t('common.PresalePrice')" name="2">


                <el-form v-for="(item) in goodsPresaleConfigList" :ref="'valueFrom'+item.countryCode" :key="item.countryCode" label-width="100px" @submit.native.prevent>
                    <el-form-item :label="$dict.getDictValue('countrys', item.countryCode)+'：'" :required="true">
                        <el-radio-group v-model="item.preSaleDiscountType"  >
                            <el-radio label="Discount">{{ $i18n.t("common.discount") }}</el-radio>
                            <el-radio label="Price">{{ $i18n.t("common.price") }}</el-radio>
                        </el-radio-group>
                        <el-input v-model="item.preSaleValue"  style="width: 100px;margin-left: 10px;"  :placeholder="$t('common.PleaseEnter')"  @blur="handlePreSaleValue(item)" ></el-input>
                        

                        <span style="width: 100px;margin-left: 10px;">{{$t('common.PreSalePrice')}}:{{ item.preSalePrice }}{{$dict.getDictCurrencyValue(item.countryCode)}}</span>                      
                        <span style="width: 100px;margin-left: 10px;">{{$t('common.FixedPrice')}}:{{ Number(item.fixedPrice).toFixed(2) }}{{$dict.getDictCurrencyValue(item.countryCode)}}</span>
                        <span style="width: 100px;margin-left: 10px;">{{ $i18n.t("common.price1") }}:{{ Number(item.salePriceEbp).toFixed(2) }}{{$dict.getDictCurrencyValue(item.countryCode)}}</span>
                        <span style="width: 100px;margin-left: 10px;">{{ $i18n.t("common.fixedprice") }}:{{ Number(item.resultPrice).toFixed(2) }}{{$dict.getDictCurrencyValue(item.countryCode)}}</span>
                      
                    </el-form-item>
                </el-form>


               
            </el-tab-pane>

            <el-tab-pane :label="$t('common.PresaleQuantity')" name="3">
                <el-form v-for="(item) in goodsPresaleConfigList" :ref="'preSaleQuantityFrom'+item.countryCode" :key="item.countryCode" label-width="100px" @submit.native.prevent>
                    <el-form-item :label="$dict.getDictValue('countrys', item.countryCode)+'：'" :required="true">
                        
                        <el-input v-model="item.preSaleQuantity"  style="width: 150px;"  :placeholder="$t('common.PleaseEnter')"  @blur="handlePreSaleQuantity(item)"></el-input>
                          
                    </el-form-item>
                </el-form>
            </el-tab-pane>

            <el-tab-pane :label="$t('common.DepositRatio')" name="4">
                <el-form v-for="(item) in goodsPresaleConfigList" :ref="'depositFrom'+item.countryCode" :key="item.countryCode" label-width="100px" @submit.native.prevent>
                    <el-form-item :label="$dict.getDictValue('countrys', item.countryCode)+'：'">
                            <el-radio-group v-model="item.depositType"  @input="(val) => handleDepositType(val, item)" >
                                <el-radio label="DEPOSIT_AND_FINAL_PAYMENT">{{$t('common.ByProportion')}}</el-radio>
                                <el-radio label="FULL_PAYMENT">{{$t('common.ByFullPayment')}}</el-radio>
                            </el-radio-group>
                            <span v-if="item.depositType == 'DEPOSIT_AND_FINAL_PAYMENT'">
                                <el-input  v-model="item.depositValue"  style="width: 100px;margin-left: 10px;"  :placeholder="$t('common.PleaseEnter')" @blur="handleDepositValue(item)" ></el-input>%
                            </span>
                            
            
                    </el-form-item>
                </el-form>
            </el-tab-pane>

            <el-tab-pane :label="$t('common.AssociatedAGBSOnthewayOrders')" name="5">
                <el-form v-for="(item) in goodsPresaleConfigList" :ref="'agbsInTransitOrderNoFrom'+item.countryCode" :key="item.countryCode" label-width="100px" @submit.native.prevent>
                    <el-form-item :label="$dict.getDictValue('countrys', item.countryCode)+'：'">
                        
                        <el-input v-model="item.agbsInTransitOrderNo"  style="width: 250px;"  :placeholder="$t('common.PleaseEnter')" ></el-input>
                          
                    </el-form-item>
                </el-form>
            </el-tab-pane>

            <el-tab-pane :label="$t('common.EstimatedDeliveryTime')" name="6">
                <el-form v-for="(item) in goodsPresaleConfigList" :ref="'expectedShipmentTimeFrom'+item.countryCode" :key="item.countryCode" label-width="100px">
                    <el-form-item :label="$dict.getDictValue('countrys', item.countryCode)+'：'" :required="true">
                         
                        <el-date-picker v-model="item.expectedShipmentTime"   type="datetime"    :placeholder="$t('common.SelectTime')"></el-date-picker>
             
                    </el-form-item>
                </el-form>
            </el-tab-pane>

            <el-tab-pane :label="$t('common.FinalPaymentDeadline')" name="7">
                <el-form v-for="(item) in goodsPresaleConfigList" :ref="'finalPaymentDeadlineTimeFrom'+item.countryCode" :key="item.countryCode" label-width="100px">
                    <el-form-item :label="$dict.getDictValue('countrys', item.countryCode)+'：'" :required="true">                       
                        <el-date-picker v-model="item.finalPaymentDeadlineTime"   type="datetime"   :disabled="item.finalPaymentDeadlineTimeDisabled" :placeholder="$t('common.SelectTime')"></el-date-picker>
                    </el-form-item>
                </el-form>
            </el-tab-pane>

 
            
        </el-tabs>


        <span slot="footer" class="dialog-footer">
			<el-button style="width: 20%;" @click="closePreSaleDialog">{{$t('common.cancel')}}</el-button>
			<el-button style="width: 20%;" type="primary"  @click="onSubmit">{{$t('common.confirm1')}}</el-button>
		</span>
 
    </div>
</template>


<script>
 
export default {
    components: {  
    },
    props: {
        rowData: {
            type: Object,
            default: () => {
                return {}
            }
        }
    },
    data() {
        return {
            isShow: false,
            // isDepositShow: true,
            activeName: '1',
            goodsPresaleConfigList: [],
            defaultGoodsPresaleConfig:{             
                "goodsPreSaleConfigId": "",
                "goodsId": "",
                "countryCode": "",
                "preSaleStartTime": "",
                "preSaleEndTime": "",
                "preSaleDiscountType": "Discount",
                "preSaleValue": null,
                "preSaleQuantity": null,
                "salesCount": null,
                "depositType": "DEPOSIT_AND_FINAL_PAYMENT",
                "depositValue": null,
                "agbsInTransitOrderNo": "",
                "expectedShipmentTime": null,
                "finalPaymentDeadlineTime": null,
                "finalPaymentDeadlineTimeDisabled": false,         
                "fixedPrice":  null,
                "resultPrice":  null,
                "salePriceEbp":  null,
                "time":[],
                "preSalePrice": null
            }
        };
    },
    mounted() {
        this.$nextTick(()=>{
            this.initData()
        })      
    },
    methods: {
        initData() {    
            this.isShow = false;   
            let param = {
                "goodsId": this.rowData.goodsId
            };

            let priceMap =  new Map();
            this.rowData.prices.forEach((item)=>{
                priceMap.set(item.countryCode,item)
            })

            this.goodsPresaleConfigList = []; 
            this.rowData.goodsSpecials.forEach((item, index) => {  
                if(item.shelf){
                    let goodsPresaleConfig = Object.assign({}, this.defaultGoodsPresaleConfig); 
                    goodsPresaleConfig.countryCode =  item.countryCode;
                    goodsPresaleConfig.goodsId =  item.goodsId;

                    let price =  priceMap.get(item.countryCode);
                    if(price != null){
                        goodsPresaleConfig.fixedPrice =  price.fixedPrice;
                        goodsPresaleConfig.resultPrice =  price.resultPrice;
                        goodsPresaleConfig.salePriceEbp =  price.salePriceEbp;
                    }     
                    this.goodsPresaleConfigList.push(goodsPresaleConfig);
                }
            });

            let type='yyyy-MM-dd hh:mm:ss'

            this.$store.dispatch("getPresaleConfigList", param).then(res => {
                if(res!=null && res.length>0){
                    let resMap =  new Map();
                    res.forEach((item)=>{
                        resMap.set(item.countryCode,item)
                    })

                    for(let item of this.goodsPresaleConfigList){                        
                        let data =  resMap.get(item.countryCode);
                        if(data != null){
                            item.goodsPreSaleConfigId = data.goodsPreSaleConfigId;
                            item.goodsId = data.goodsId;                           
                            item.preSaleStartTime = data.preSaleStartTime;
                            item.preSaleEndTime = data.preSaleEndTime;
                            item.preSaleDiscountType = data.preSaleDiscountType;
                            item.preSaleValue = data.preSaleValue;
                            item.preSaleQuantity = data.preSaleQuantity;
                            item.salesCount = data.salesCount;
                            item.depositType = data.depositType;
                            item.depositValue = data.depositValue;
                            item.agbsInTransitOrderNo = data.agbsInTransitOrderNo;
                            item.expectedShipmentTime = data.expectedShipmentTime;
                            item.finalPaymentDeadlineTime = data.finalPaymentDeadlineTime;
                         
                            if(data.finalPaymentDeadlineTimeDisabled){                              
                                item.finalPaymentDeadlineTimeDisabled = data.finalPaymentDeadlineTimeDisabled;
                            }                         
                            let beginTime=this.$options.filters['formatDate'](data.preSaleStartTime,type)
                            let endTime=this.$options.filters['formatDate'](data.preSaleEndTime,type)
                            item.time = [];
                            item.time.push(beginTime);
                            item.time.push(endTime);  
                        }                        
                        item.preSalePrice = this.calculatePreSalePrice(item);
                    }
                }              
                this.isShow = true;   
            });         
        },
        calculatePreSalePrice(item){
            let preSalePrice = 0;
            let price = this.getPricePriority(item);
            if(item.preSaleDiscountType == 'Discount'){
                // 计算预售价
                preSalePrice = price - price * item.preSaleValue /100;         
            } else {
                // 计算预售价
                preSalePrice = item.preSaleValue;
            }
            preSalePrice =  Number( preSalePrice ).toFixed(2);
            return preSalePrice;
        },
        handleDepositType(val, item){
            if(item.depositType == 'FULL_PAYMENT'){
                item.depositValue = 100;
                // this.isDepositShow = false;
            }else {
                item.depositValue = null;
                // this.isDepositShow = true;              
            }

        },  
        handlePreSaleValue(item){
            // 输入限制：不允许高于对应划线价，高于划线价默认等于划线价。
            item.preSalePrice = 0;
            // var pattern = /^(([1-9]{1}\d*))(\.(\d){0,2})?$/;
            var pattern = /^(([1-9]{1}\d*)|([0]{1}))(\.(\d){1,2})?$/;
            if (!pattern.test(item.preSaleValue)) {
                this.$message({
                    message: this.$i18n.t("common.OnlyNumbersGreaterThan0CanBeEnteredUpToTwoDecimalPlaces"),
                    type: 'warning',
                    duration: 1000
                });
                return;
            }  

            if (item.preSaleValue<=0) {
                this.$message({
                    message: this.$i18n.t("common.OnlyNumbersGreaterThan0CanBeEnteredUpToTwoDecimalPlaces"),
                    type: 'warning',
                    duration: 1000
                });
                return;
            } 

            let price =  Number(this.getPricePriority(item)).toFixed(2);
            if(item.preSaleDiscountType == 'Discount'){            
                if(Number(item.preSaleValue).toFixed(2) - Number(100).toFixed(2) >0){
                    item.preSaleValue = 100;
                }
            } else { 
                if(Number(item.preSaleValue).toFixed(2) - price >0){
                    item.preSaleValue = price;
                }
            }

            // 计算预售价
            item.preSalePrice = this.calculatePreSalePrice(item);
           
        },  
        closePreSaleDialog(){
            this.$emit("closePreSaleDialog")
        },
        getPricePriority(item){ 
            let price =  0;
            if(item.fixedPrice){
                 //固定价格
                price = item.fixedPrice;
            }else if(item.salePriceEbp){
                //售价
                price = item.salePriceEbp;
            }else if(item.resultPrice){
                //定价
                price = item.resultPrice;
            }
            return price;
        },
        checkForm(){
            for (let item of this.goodsPresaleConfigList) {
                // 预售时间
                if(item.time == null){
                    this.$message({
                        message: this.$i18n.t("common.PresaleTimeCannotBeEmpty"),
                        type: 'warning',
                        duration: 1000
                    });
                    this.activeName = "1";
                    return false;
                }
                // 预售价格
                if(item.preSaleDiscountType == null){
                    this.$message({
                        message: this.$i18n.t("common.PresalePriceTypeCannotBeEmpty"),
                        type: 'warning',
                        duration: 1000
                    });
                    this.activeName = "2";
                    return false;
                }
                if(item.preSaleValue == null){
                    this.$message({
                        message: this.$i18n.t("common.PresalePriceCannotBeEmpty"),
                        type: 'warning',
                        duration: 1000
                    });
                    this.activeName = "2";
                    return false;
                }
            
                // var pattern = /^(([1-9]{1}\d*))(\.(\d){0,2})?$/;
                var pattern = /^(([1-9]{1}\d*)|([0]{1}))(\.(\d){1,2})?$/;
                if (!pattern.test(item.preSaleValue)) {
                    this.$message({
                        message: this.$i18n.t("common.OnlyNumbersGreaterThan0CanBeEnteredUpToTwoDecimalPlaces"),
                        type: 'warning',
                        duration: 1000
                    });
                    this.activeName = "2";
                    return;
                }   
                if (item.preSaleValue<=0) {
                    this.$message({
                        message: this.$i18n.t("common.OnlyNumbersGreaterThan0CanBeEnteredUpToTwoDecimalPlaces"),
                        type: 'warning',
                        duration: 1000
                    });
                    this.activeName = "2";
                    return;
                }
                
  
                // 预售数量
                if(item.preSaleQuantity == null){
                    this.$message({
                        message: this.$i18n.t("common.PresaleTimeCannotBeEmpty"),
                        type: 'warning',
                        duration: 1000
                    });
                    this.activeName = "3";
                    return false;
                }
                var pattern = /^([1-9]{1}\d*)$/;
                if (!pattern.test(item.preSaleQuantity)) {
                    this.$message({
                        message: this.$i18n.t("common.OnlyPositiveIntegersCanBeEnteredCannotStartWith0"),
                        type: 'warning',
                        duration: 1000
                    });
                    this.activeName = "3";
                    return false;
                }  

                // 定金比例
                if(item.depositType == null){
                    this.$message({
                        message: this.$i18n.t("common.DepositRatioTypeCannotBeEmpty"),
                        type: 'warning',
                        duration: 1000
                    });
                    this.activeName = "4";
                    return false;
                }

                if(item.depositType != null && item.depositType == 'FULL_PAYMENT'){
                    item.depositValue = 100;        
                }
                
                if(item.depositValue == null && item.depositType != 'FULL_PAYMENT'){
                    this.$message({
                        message: this.$i18n.t("common.DepositRatioCannotBeEmpty"),
                        type: 'warning',
                        duration: 1000
                    });
                    this.activeName = "4";
                    return false;
                }

                if(item.depositType != null && item.depositType != 'FULL_PAYMENT'){
                    var pattern = /^(\d*)$/;
                    if(!pattern.test(item.depositValue)){
                        this.$message({
                            message: this.$i18n.t("common.OnlyIntegersBetween0And100CanBeEnteredExcluding0And100"),
                            type: 'warning',
                            duration: 1000
                        }); 
                        this.activeName = "4"; 
                        return false;
                    }
                    if(item.depositValue <= 0){
                        item.depositValue =1
                    }
                    if(item.depositValue >= 100){
                        item.depositValue =99
                    }                
                }
   
               

                if(item.expectedShipmentTime == null){
                    this.$message({
                        message: this.$i18n.t("common.EstimatedDeliveryTimeCannotBeEmpty"),
                        type: 'warning',
                        duration: 1000
                    });
                    this.activeName = "6";
                    return false;
                }

                if(item.finalPaymentDeadlineTime == null){
                    this.$message({
                        message: this.$i18n.t("common.FinalPaymentDeadlineCannotBeEmpty"),
                        type: 'warning',
                        duration: 1000
                    });
                    this.activeName = "7";
                    return false;
                }
                
                let finalPaymentDeadlineDate = new Date(item.finalPaymentDeadlineTime).getTime();

                let preSaleEndTimeDate = new Date(item.preSaleEndTime).getTime();
                
                if( finalPaymentDeadlineDate - preSaleEndTimeDate < 1000 * 3600 * 5){
                    this.$message({
                        message: this.$i18n.t("common.FinalPaymentShouldBeDoneBefore5HoursBeforeTheEndOfPresalePromotion"),
                        type: 'warning',
                        duration: 1000
                    });
                    this.activeName = "7";
                    return false;
                }
            }
            return true;
        },
        onSubmit(){
            console.log(this.goodsPresaleConfigList)
            let check = this.checkForm();
            
            if(check){
                this.$confirm(this.$i18n.t("common.Whethertosubmitdata"), this.$i18n.t("common.prompt"), {
                    confirmButtonText: this.$i18n.t("common.confirm"),
                    cancelButtonText: this.$i18n.t("common.cancel"),
                    type: 'warning'
                }).then(() => {  
                    let dataList = [];                 
                    for(let item of this.goodsPresaleConfigList){
                        let data = Object.assign({}, item); 
                        data.preSaleStartTime = new Date(item.time[0]).getTime();
                        data.preSaleEndTime = new Date(item.time[1]).getTime();
                        data.expectedShipmentTime = new Date(item.expectedShipmentTime).getTime();
                        data.finalPaymentDeadlineTime = new Date(item.finalPaymentDeadlineTime).getTime();
                        dataList.push(data);
                    }
                    let param = {
                        "list": dataList,
                        "goodsId": this.rowData.goodsId
                    }
                    this.$store.dispatch("postPresalesConfig", param).then(res => {
                        this.$message({
                            type: 'success',
                            message: this.$i18n.t('common.Successfullysubmitted'),
                        });
                        this.closePreSaleDialog()
                    });
                }); 
            
            }
        },
        handlePreSaleQuantity(item){
            var pattern = /^([1-9]{1}\d*)$/;
            if (!pattern.test(item.preSaleQuantity)) {
                this.$message({
                    message: this.$i18n.t("common.OnlyPositiveIntegersCanBeEnteredCannotStartWith0"),
                    type: 'warning',
                    duration: 1000
                });
             
                return false;
            }
            
            let salesCountA  =  item.salesCount == null ? 0:item.salesCount
            let preSaleQuantityA  =  item.preSaleQuantity 

            if( preSaleQuantityA < salesCountA){
                item.preSaleQuantity = salesCountA;
            }  
        },
        handleDepositValue(item){
            if(item.depositType != null && item.depositType != 'FULL_PAYMENT'){
                var pattern = /^(\d*)$/;
                if(!pattern.test(item.depositValue)){
                    this.$message({
                        message: this.$i18n.t("common.OnlyIntegersBetween0And100CanBeEnteredExcluding0And100"),
                        type: 'warning',
                        duration: 1000
                    });  
                    return false;
                }
                if(item.depositValue <= 0){
                    item.depositValue =1
                }
                if(item.depositValue >= 100){
                    item.depositValue =99
                }  
            } 
            if(item.depositType != null && item.depositType == 'FULL_PAYMENT'){
                item.depositValue = 100;        
            }
        },
        handleClick(tab, event) {
            // console.log('tab', tab, event)
            // this.mitName = tab.name.toString()
            // console.log('mitName', typeof (this.mitName))
        }, 
        /**
         * 时间戳转码
         */
		formatDate(row, column) {
            // 获取单元格数据
            let data = row[column.property];
            if (!data) {
                return '';
            } else {
                return moment(data).format('YYYY-MM-DD HH:mm:ss');
            }
        }
    },
    watch: {     
    }
};
</script>
<style scoped>
.dialog-footer {
    margin-top: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
}
</style>