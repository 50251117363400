<template>
    <!-- 地址 -->
    <el-dialog :title='$i18n.t("common.ShoppingCartItems") ' :visible.sync="shoppingCarVisible" v-if="shoppingCarVisible" width="1200px" :close-on-click-modal="false">
        <el-select
            v-model="shoppingCarQuery.countryCode"
            :multiple="true"
            :placeholder='$i18n.t("common.ShowCountry")'
            style="width: 180px; margin-left: 10px"
            class="filter-item"
        >
            <el-option v-for="cc in countrysDicts" :key="cc.code" :label="cc.name" :value="cc.code"></el-option>
        </el-select>
        <el-select
            v-model="shoppingCarQuery.brandCode"
            :multiple="false"
            :placeholder='$i18n.t("common.brand")'
            style="width: 180px; margin-left: 10px"
            class="filter-item"
        >
            <el-option v-for="cc in brandDicts" :key="cc.code" :label="cc.name" :value="cc.code"></el-option>
        </el-select>
        <el-button class="filter-item" icon="el-icon-search" type="primary" @click="handleShoppingCarQuery" style="margin-left: 10px">
           {{ $i18n.t("common.query") }}
        </el-button>
        <el-table :data="collection.list" border stripe style="width: 100%; margin-top: 20px" size="small">
            <el-table-column prop="countryCode" :label='$i18n.t("common.country")' align="center" width="50px"> </el-table-column>
            <el-table-column prop="brandCode" :label='$i18n.t("common.brand")' align="center" width="100px"> </el-table-column>
            <el-table-column prop="goodsId" :label='$i18n.t("common.ProductID1")' width="150px" align="center"> </el-table-column>
            <el-table-column prop="title" :label='$i18n.t("common.Productname1")' width="309px" align="center"> </el-table-column>
            <el-table-column prop="joinPrice" :label='$i18n.t("common.Additionalpurchaseprice")' width="120px" align="center"> </el-table-column>
            <el-table-column prop="prices[0].finallyPrice" :label='$i18n.t("common.Currentprice")' width="120px" align="center"> </el-table-column>
            <el-table-column :label='$i18n.t("common.Additionalpurchasetime")'  prop="createTime" align="center" width="200px" :formatter="formatDate"> </el-table-column>
            <el-table-column :label='$i18n.t("common.Listingstatus")'  prop="shelf" show-overflow-tooltip align="center">
                <template slot-scope="{ row }">
                    <span v-if="row.shelf == true">{{ $i18n.t("common.Listed") }}</span>
                    <span v-if="row.shelf == false">{{ $i18n.t("common.Notlisted") }}</span>
                </template>
            </el-table-column>
        </el-table>
        <div class="block">
            <el-pagination
                style="margin-top: 10px"
                background
                @current-change="handleAddressCurrentChange"
                :current-page="shoppingCarQuery.page"
                :page-size="shoppingCarQuery.size"
                layout="total, prev, pager, next, jumper"
                :total="collection.total"
            ></el-pagination>
        </div>
    </el-dialog>
</template>

<script>
import dataSelect from '@/components/common/DataSelect.vue';
import dataText from '@/components/common/DataText.vue';
import moment from 'moment';

export default {
    name: 'shoppingCarHandle',
    components: {
        dataSelect,
        dataText
    },
    data() {
        return {
            collection: [],
            countrysDicts: [],
            brandDicts: [],
            shoppingCarQuery: {
                page: 1,
                limit: 10,
                countryCode: [],
                brandCode: '',
                userId: null
            },
            shoppingCarVisible: false,
            listLoading: true
        };
    },
    mounted() {
        this.getDictItems();
    },
    methods: {
        getDictItems() {
            var _this = this;
            this.$store.dispatch('getDicItems', { tag: 'countrys' }).then((res) => {
                if (res != null) {
                    _this.countrysDicts = res;
                }
            });
            this.$store.dispatch('getDicItems', { tag: 'brand' }).then((res) => {
                if (res != null) {
                    _this.brandDicts = res;
                }
            });
        },
        /**
         * 分页
         */
        handleAddressCurrentChange(val) {
            this.shoppingCarQuery.page = val;
            this.doShoppingCarQuery({ page: val });
        },
        /**
         * 时间戳转码
         */
        formatDate(row, column) {
            // 获取单元格数据
            let data = row[column.property];
            if (!data) {
                return '';
            } else {
                return moment(data).format('YYYY-MM-DD HH:mm:ss');
            }
        },
        /**
         * 购物车处理
         */
        shoppingCarHandle(userId) {
            this.collection = [];
            this.shoppingCarQuery.countryCode = [];
            this.shoppingCarQuery.brandCode = '';
            this.shoppingCarQuery.userId = userId;
            this.handleShoppingCarQuery();
            this.shoppingCarVisible = true;
        },
        handleShoppingCarQuery() {
            this.doShoppingCarQuery({ page: 1 });
        },
        doShoppingCarQuery({ page, limit }) {
            if (page) {
                this.shoppingCarQuery.page = page;
            }
            if (limit) {
                this.shoppingCarQuery.limit = limit;
            }
            this.$store.dispatch('getShoppingCar', this.shoppingCarQuery).then((res) => {
                this.collection = res;
                console.log(this.collection);
            });
        }
    }
};
</script>
