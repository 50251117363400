<template>
    <div>
        <el-dialog :title='$i18n.t("common.Freightstandard") ' :visible.sync="freightVisible" width="780px" v-if="freightVisible" :close-on-click-modal='false'>
            <div>
                {{$i18n.t("common.country")}}：
                <span>{{ countryCode }}</span>
            </div>
            <el-button type="primary" @click="addFreight" style="margin-top: 10px">{{$i18n.t("common.Add1")}}</el-button>
            <el-table :data="list" style="width: 750px; margin-top: 20px; margin-bottom: 20px">
                <el-table-column prop="freightFeeStandardId" label="ID" width="180px"> </el-table-column>
                <el-table-column prop="minWeight" :label='$i18n.t("common.Weightrange_start_kg")' width="130px"> </el-table-column>
                <el-table-column prop="maxWeight" :label='$i18n.t("common.Weightrange_end_kg")' width="130px"> </el-table-column>
                <el-table-column prop="freightFee" :label='$i18n.t("common.freight")' width="130px"> </el-table-column>
                <el-table-column :label='$i18n.t("common.operation")' align="center" width="150px">
                    <template slot-scope="scope">
                        <el-button type="primary" size="mini" @click="updateFreight(scope.$index, scope.row)" class="table-inner-button"
                            >{{ $i18n.t("common.edit") }}</el-button
                        >
                        <el-button type="primary" size="mini" @click="deleteFreight(scope.$index, scope.row)" class="table-inner-button"
                            >{{ $i18n.t("common.delete") }}</el-button
                        >
                    </template>
                </el-table-column>
            </el-table>
        </el-dialog>
        <el-dialog :title="freightTitle" :visible.sync="formVisible" v-if="formVisible" width="700px">
            <el-form
                ref="dataForm"
                :rules="formRules"
                :model="form"
                label-position="left"
                size="small"
                label-width="180px"
                style=" margin-left: 50px"
            >
                <el-form-item :label='$i18n.t("common.Weightrange_start_kg")' prop="minWeight">
                    <el-input v-model="form.minWeight" style="width: 250px">
                        <template slot="append"> kg </template>
                    </el-input>
                </el-form-item>
                <el-form-item :label='$i18n.t("common.Weightrange_end_kg")'prop="maxWeight">
                    <el-input v-model="form.maxWeight" style="width: 250px">
                        <template slot="append"> kg </template>
                    </el-input>
                </el-form-item>
                <el-form-item  :label='$i18n.t("common.freight")' prop="freightFee">
                    <el-input v-model="form.freightFee" style="width: 250px"> </el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="formVisible = false">  {{ $i18n.t("common.cancel") }} </el-button>
                <el-button type="primary" @click="doUpdate()">  {{ $i18n.t("common.confirm") }} </el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import dataSelect from '@/components/common/DataSelect.vue';
import dataText from '@/components/common/DataText.vue';
import imgUpload from '@/views/country/imgUpload';
import { number } from 'echarts';

function initFormBean() {
    const formBean = {
        logisticsPricingId: null,
        freightFeeStandardId: null,
        countryCode: null,
        minWeight: null,
        maxWeight: null,
        freightFee: null
    };
    return formBean;
}

export default {
    name: 'FreightStandard',
    components: {
        dataSelect,
        dataText,
        imgUpload
    },
    data() {
        var isMinFreight = (rule, value, callback) => {
            var pattern = /^(([0])|([1-9][0-9]*)|(([0]\.\d{1,4}|[1-9][0-9]*\.\d{1,4})))$/;
            if (value > 0 && !pattern.test(value)) {
                return callback(new Error(this.$i18n.t("common.YoucanonlyenteruptofourdigitsaftertheDecimalseparator")));
            } else if (!pattern.test(value)) {
                return callback(new Error(this.$i18n.t("common.Pleaseinputthenumbercorrectly")));
            } else {
                return callback();
            }
        };
        var isMaxFreight = (rule, value, callback) => {
            var pattern = /^(([0])|([1-9][0-9]*)|(([0]\.\d{1,4}|[1-9][0-9]*\.\d{1,4})))$/;
            console.log(value,this.form.maxWeight+0,this.form.minWeight+0,Number(this.form.maxWeight)<Number(this.form.minWeight));
            if (value > 0 && !pattern.test(value)) {
                // console.log('小数点后最多只能输入四位')
                return callback(new Error(this.$i18n.t("common.YoucanonlyenteruptofourdigitsaftertheDecimalseparator")));
            } else if (!pattern.test(value)) {
                // console.log('请正确输入数字')
                return callback(new Error(this.$i18n.t("common.Pleaseinputthenumbercorrectly")));
            } else if (Number(this.form.maxWeight)<Number(this.form.minWeight)) {
                this.form.maxWeight = this.form.minWeight;
                return callback(new Error(this.$i18n.t("common.Theendvaluemustbegreaterthanorequaltothestartvalue")));
            } else {
                return callback();
            }
        };
        var isFreightFee = (rule, value, callback) => {
            var pattern = /^(([0])|([1-9][0-9]*)|(([0]\.\d{1,2}|[1-9][0-9]*\.\d{1,2})))$/;
            if (value > 0 && !pattern.test(value)) {
                return callback(new Error(this.$i18n.t("common.AtmosttwodigitscanbeenteredaftertheDecimalseparator")));
            } else if (!pattern.test(value)) {
                return callback(new Error(this.$i18n.t("common.Pleaseinputthenumbercorrectly")));
            } else {
                return callback();
            }
        };
        return {
            list: [],
            countryCode: '',
            logisticsPricingId: null,
            old: initFormBean(),
            form: initFormBean(),
            freightVisible: false,
            formVisible: false,
            freightTitle: '',
            formRules: {
                minWeight: [
                    { required: true, message: this.$i18n.t("common.Pleasecompletetheweightrangeconfiguration"), trigger: 'blur' },
                    { validator: isMinFreight, trigger: 'blur' }
                ],
                maxWeight: [
                    { required: true, message: this.$i18n.t("common.Pleasecompletetheweightrangeconfiguration"), trigger: 'blur' },
                    { validator: isMaxFreight, trigger: 'blur' }
                ],
                freightFee: [
                    { required: true, message: this.$i18n.t("common.Pleaseentertheshippingcost"), trigger: 'blur' },
                    { validator: isFreightFee, trigger: 'blur' }
                ]
            }
        };
    },
    methods: {
        /**
         * 重置表单
         */
        resetForm() {
            for (const key in initFormBean()) {
                this.form[key] = this.old[key];
            }
        },
        //查询
        doQueryList(logisticsPricingId) {
            this.list = [];
            this.$store.dispatch('getFreight', logisticsPricingId).then((res) => {
                if (res.length > 0) {
                    for (var item of res) {
                        this.list.push(item);
                    }
                }
                console.log(res);
                console.log(this.list);
            });
        },
        /**
         * 打开编辑表单
         */
        handleUpdate(row) {
            this.countryCode = '';
            this.logisticsPricingId = null;
            this.countryCode = row.countryCode;
            this.logisticsPricingId = row.logisticsPricingId;
            this.doQueryList(this.logisticsPricingId);
            this.freightVisible = true;
        },
        /**
         * 执行修改操作
         */
        doUpdate() {
            if (this.form.freightFeeStandardId == null) {
                this.$refs['dataForm'].validate((valid) => {
                    if (valid) {
                        this.$store.dispatch('addFreight', this.form).then((data) => {
                            this.$parent.doQueryList({ page: 1 });
                            this.$message({
                                message: this.$i18n.t("common.Successfullyadded"),
                                type: 'success'
                            });
                            this.doQueryList(this.logisticsPricingId);
                            this.formVisible = false;
                        });
                    } else {
                        this.$message({
                            message: this.$i18n.t("common.AddFailed"),
                            type: 'error'
                        });

                        return false;
                    }
                });
            } else {
                this.$refs['dataForm'].validate((valid) => {
                    if (valid) {
                        this.$store.dispatch('updateFreight', this.form).then((data) => {
                            this.$parent.doQueryList({ page: 1 });
                            this.$message({
                                message: this.$i18n.t("common.Successfullyedited"),
                                type: 'success'
                            });
                            this.doQueryList(this.logisticsPricingId);
                            this.formVisible = false;
                        });
                    } else {
                        this.$message({
                            message: this.$i18n.t("common.Editingfailed"),
                            type: 'error'
                        });
                        return false;
                    }
                });
            }
        },
        addFreight() {
            this.resetForm();
            this.freightTitle = this.$i18n.t("common.Addshippingstandard")
            this.form.logisticsPricingId = this.logisticsPricingId;
            this.form.countryCode = this.countryCode;
            this.formVisible = true;
        },
        updateFreight(index, row) {
            this.resetForm();
            this.freightTitle = this.$i18n.t("common.EditFreightStandard")
            this.form.logisticsPricingId = row.logisticsPricingId;
            this.form.freightFeeStandardId = row.freightFeeStandardId;
            this.form.minWeight = row.minWeight;
            this.form.maxWeight = row.maxWeight;
            this.form.freightFee = row.freightFee;
            this.form.countryCode = this.countryCode;
            this.formVisible = true;
        },
        deleteFreight(index, row) {
            this.$confirm( this.$i18n.t("common.confirmdeletion")  , this.$i18n.t("common.prompt"), {
                // confirmButtonText: '确定',
                // cancelButtonText: '取消',
                type: 'warning'
            })
                .then(() => {
                    this.$store.dispatch('deleteFreight', {id:row.freightFeeStandardId}).then((res) => {
                        this.doQueryList(this.logisticsPricingId);
                        this.$message({
                            message:this.$i18n.t("common.Successfullydeleted"),
                            type: 'success'
                        });
                    });
                })
                .catch(() => {});
        }
    }
};
</script>
