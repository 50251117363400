<template>
    <el-dialog :title='$i18n.t("common.Successfullyedited")' :visible.sync="formVisible" :close-on-click-modal='false'>
        <el-form
            ref="dataForm"
            :rules="formRules"
            :model="form"
            label-position="left"
            size="small"
            label-width="100px"
            style="width: 400px; margin-left: 50px"
        >
            <el-form-item :label='$i18n.t("common.delete")' prop="sydelete">
                <el-input v-model="form.sydelete" />
            </el-form-item>
            <el-form-item :label='$i18n.t("common.ColumnName")' prop="name">
                <el-input v-model="form.name" />
            </el-form-item>
            <el-form-item :label='$i18n.t("common.title")' prop="title">
                <el-input v-model="form.title" />
            </el-form-item>
            <el-form-item :label='$i18n.t("common.Creationtime")'  prop="createtime">
                <el-date-picker
                    v-model="form.createtime"
                    type="datetime"
                    value-format="yyyy-MM-dd HH:mm:ss"
                    :placeholder='$i18n.t("common.SelectDateTime")'
                ></el-date-picker>
            </el-form-item>
            <el-form-item :label='$i18n.t("common.Modificationtime")' prop="updatetime">
                <el-date-picker
                    v-model="form.updatetime"
                    type="datetime"
                    value-format="yyyy-MM-dd HH:mm:ss"
                    :placeholder='$i18n.t("common.SelectDateTime")'
                ></el-date-picker>
            </el-form-item>
            <el-form-item :label='$i18n.t("common.Isitdisplayedatthebottomofthewebsite")'  prop="isshowbottom">
                <el-input v-model="form.isshowbottom" />
            </el-form-item>
            <el-form-item :label='$i18n.t("common.sort")'  prop="sort">
                <el-input-number v-model="form.sort" style="width: 100%" controls-position="right"></el-input-number>
            </el-form-item>
            <el-form-item label="SyStamp" prop="systamp">
                <el-date-picker
                    v-model="form.systamp"
                    type="datetime"
                    value-format="yyyy-MM-dd HH:mm:ss"
                    :placeholder='$i18n.t("common.SelectDateTime")'
                ></el-date-picker>
            </el-form-item>
            <el-form-item label="TenantId" prop="tenantid">
                <el-input-number v-model="form.tenantid" style="width: 100%" controls-position="right"></el-input-number>
            </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
            <el-button @click="formVisible = false">  {{ $i18n.t("common.cancel") }} </el-button>
            <el-button type="primary" @click="doUpdate()">  {{ $i18n.t("common.confirm") }} </el-button>
        </div>
    </el-dialog>
</template>

<script>
function initFormBean() {
    const formBean = {
        informationid: null,
        sydelete: null,
        name: null,
        title: null,
        createtime: null,
        updatetime: null,
        isshowbottom: false,
        sort: null,
        systamp: null,
        tenantid: null
    };
    return formBean;
}

export default {
    name: 'InformationEdit',
    data() {
        return {
            old: initFormBean(),
            form: initFormBean(),
            formVisible: false,
            formRules: {
                sydelete: [{ required: true, message: '请输入删除', trigger: 'blur' }],
                name: [
                    { required: true, message: this.$i18n.t("common.Pleaseenteracolumnname"), trigger: 'blur' },
                    { max: 50, message:  this.$i18n.t("common.Thelengthcannotexceed50characters"), trigger: 'blur' }
                ],
                title: [
                    { required: true, message: this.$i18n.t("common.Pleaseenteratitle"), trigger: 'blur' },
                    { max: 100, message: this.$i18n.t("common.Thelengthcannotexceed100characters"), trigger: 'blur' }
                ],
                createtime: [{ required: true, message: this.$i18n.t("common.Pleaseenterthecreationtime"), trigger: 'change' }],
                updatetime: [],
                isshowbottom: [{ required: true, message: this.$i18n.t("common.Pleaseenterwhethertodisplayatthebottomofthewebsite"), trigger: 'blur' }],
                sort: [{ required: true, message:  this.$i18n.t("common.Pleaseentersorting"), trigger: 'blur' }],
                systamp: [],
                tenantid: []
            }
        };
    },
    methods: {
        /**
         * 重置表单
         */
        resetForm() {
            for (const key in initFormBean()) {
                this.form[key] = this.old[key];
            }
        },
        /**
         * 打开编辑表单
         */
        handleUpdate(informationid) {
            informationApi.fetchById(informationid).then((data) => {
                this.old = data;
                this.resetForm();
                this.formVisible = true;
                this.$nextTick(() => {
                    this.$refs['dataForm'].clearValidate();
                });
            });
        },
        /**
         * 执行修改操作
         */
        doUpdate() {
            this.$refs['dataForm']
                .validate()
                .then(() => informationApi.update(this.form))
                .then((data) => {
                    this.formVisible = false;
                    this.$common.showMsg('success',this.$i18n.t("common.Successfullymodified"));
                    this.$emit('updated', data);
                });
        }
    }
};
</script>
