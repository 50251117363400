var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.addressVisible
    ? _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.$i18n.t("common.Shippingaddress"),
            visible: _vm.addressVisible,
            width: "1200px",
            "close-on-click-modal": false
          },
          on: {
            "update:visible": function($event) {
              _vm.addressVisible = $event
            }
          }
        },
        [
          _c(
            "el-select",
            {
              staticClass: "filter-item",
              staticStyle: { width: "180px", "margin-left": "10px" },
              attrs: {
                multiple: true,
                placeholder: _vm.$i18n.t("common.ShowCountry")
              },
              model: {
                value: _vm.addressQuery.countryCode,
                callback: function($$v) {
                  _vm.$set(_vm.addressQuery, "countryCode", $$v)
                },
                expression: "addressQuery.countryCode"
              }
            },
            _vm._l(_vm.countrysDicts, function(cc) {
              return _c("el-option", {
                key: cc.code,
                attrs: { label: cc.name, value: cc.code }
              })
            }),
            1
          ),
          _c(
            "el-button",
            {
              staticClass: "filter-item",
              staticStyle: { "margin-left": "10px" },
              attrs: { icon: "el-icon-search", type: "primary" },
              on: { click: _vm.handleAddressQuery }
            },
            [_vm._v("\n    " + _vm._s(_vm.$i18n.t("common.query")) + "\n    ")]
          ),
          _c(
            "el-table",
            {
              staticStyle: { width: "100%", "margin-top": "20px" },
              attrs: {
                data: _vm.address.list,
                border: "",
                stripe: "",
                size: "small"
              }
            },
            [
              _c("el-table-column", {
                attrs: {
                  prop: "addressId",
                  label: "ID",
                  width: "150px",
                  align: "center"
                }
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "userName",
                  label: _vm.$i18n.t("common.name1"),
                  width: "80px",
                  align: "center"
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(ref) {
                        var row = ref.row
                        return [
                          _c("span", [
                            _vm._v(_vm._s(row.userName + " " + row.userSurname))
                          ])
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  2206132148
                )
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "taxNumber",
                  label: _vm.$i18n.t("common.TaxID"),
                  "show-overflow-tooltip": "",
                  align: "center"
                }
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "countryCode",
                  label: _vm.$i18n.t("common.country"),
                  align: "center",
                  width: "50px"
                }
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "province",
                  label: _vm.$i18n.t("common.province"),
                  width: "100px",
                  align: "center"
                }
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "city",
                  label: _vm.$i18n.t("common.city"),
                  width: "100px",
                  align: "center"
                }
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "addressContent",
                  label: _vm.$i18n.t("common.Detailedaddress"),
                  "show-overflow-tooltip": "",
                  align: "center"
                }
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "houseNum",
                  label: _vm.$i18n.t("common.Housenumber"),
                  align: "center",
                  width: "100px"
                }
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "recipientCompany",
                  label: _vm.$i18n.t("common.RecipientCompany"),
                  "show-overflow-tooltip": "",
                  align: "center",
                  width: "100px"
                }
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "addressAdditionalContent",
                  label: _vm.$i18n.t("common.AddressAdditionalContent"),
                  "show-overflow-tooltip": "",
                  align: "center",
                  width: "100px"
                }
              }),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$i18n.t("common.Defaultshippingaddress"),
                  prop: "isDefaultReceive",
                  align: "center",
                  width: "100px"
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(ref) {
                        var row = ref.row
                        return [
                          row.isDefaultReceive
                            ? _c("span", [
                                _vm._v(_vm._s(_vm.$i18n.t("common.yes")))
                              ])
                            : _c("span", [
                                _vm._v(_vm._s(_vm.$i18n.t("common.no")))
                              ])
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  3669456138
                )
              }),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$i18n.t("common.Defaultinvoiceaddress"),
                  prop: "isDefaultInvoice",
                  align: "center",
                  width: "100px"
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(ref) {
                        var row = ref.row
                        return [
                          row.isDefaultInvoice
                            ? _c("span", [
                                _vm._v(_vm._s(_vm.$i18n.t("common.yes")))
                              ])
                            : _c("span", [
                                _vm._v(_vm._s(_vm.$i18n.t("common.no")))
                              ])
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  524320912
                )
              }),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$i18n.t("common.Creationtime"),
                  prop: "createTime",
                  align: "center",
                  width: "150px",
                  formatter: _vm.formatDate
                }
              })
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "block" },
            [
              _c("el-pagination", {
                staticStyle: { "margin-top": "10px" },
                attrs: {
                  background: "",
                  "current-page": _vm.addressQuery.page,
                  "page-size": _vm.addressQuery.size,
                  layout: "total, prev, pager, next, jumper",
                  total: _vm.address.total
                },
                on: { "current-change": _vm.handleAddressCurrentChange }
              })
            ],
            1
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }