<template>
    <div class="app-container" style="height: 100%;min-height:350px;">
        <div class="filter-container">
            <el-form :model="query" :inline="true" size="small" label-width="140px">
                <el-form-item>
                    <el-input
                        clearable
                        v-model="query.userIdOrEmailOrInviterUserId"
                        :placeholder="$i18n.t('common.UserID_loginemail_registrationinviterID_phonenumber')"
                        style="width: 210px"
                        class="filter-item"
                        @keyup.enter.native="handleQuery"
                    />
                </el-form-item>
                <el-form-item>
                    <el-select
                        v-model="query.countryCode"
                        :multiple="true"
                        :placeholder="$i18n.t('common.country')"
                        style="width: 180px; margin-left: 10px"
                        class="filter-item"
                    >
                        <el-option
                            v-for="cc in countrysDicts"
                            :key="cc.code"
                            :label="cc.name + '(' + cc.code + ')'"
                            :value="cc.code"
                        ></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item>
                    <el-select
                        v-model="query.isSubscribe"
                        :placeholder="$i18n.t('common.Whethertosubscribe')"
                        clearable
                        style="width: 180px; margin-left: 10px"
                        class="filter-item"
                    >
                        <el-option :label="$i18n.t('common.yes')" value="1"></el-option>
                        <el-option :label="$i18n.t('common.no')" value="0"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item>
                    <el-select
                        v-model="query.status"
                        :placeholder="$i18n.t('common.Loginstatus')"
                        clearable
                        style="width: 180px; margin-left: 10px"
                        class="filter-item"
                    >
                        <el-option :label="$i18n.t('common.normal')" value="1"></el-option>
                        <el-option :label="$i18n.t('common.Disabled')" value="0"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item>
                    <el-select
                        v-model="query.userMember"
                        :placeholder="$i18n.t('common.memberOrNot')"
                        clearable
                        style="width: 180px; margin-left: 10px"
                        class="filter-item"
                    >
                        <el-option :label="$i18n.t('common.yes')" value="1"></el-option>
                        <el-option :label="$i18n.t('common.no')" value="0"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item>
                    <dataSelect
                        style="width: 180px; margin-left: 10px"
                        class="filter-item"
                        v-model="query.registerSource"
                        clearable
                        :codeType="'register_source'"
                        :placeholder="$i18n.t('common.brand')"
                    ></dataSelect>
                </el-form-item>
                <!--<el-form-item>
                    <el-input
                        v-model="query.sourceUrlName"

                        :placeholder='$i18n.t("common.Pleaseenterthesource")'
                        style="width: 180px"

                        class="filter-item"
                        v-if="!scene.isAigoSmartApp"
                        @keyup.enter.native="handleQuery"
                    />
                </el-form-item>-->
                <el-form-item>
                    <dataSelect
                        style="width: 150px; margin-left: 10px"
                        class="filter-item"
                        v-model="query.userType"
                        clearable
                        :codeType="'user_type'"
                        :placeholder="$i18n.t('common.UserType')"
                    ></dataSelect>
                </el-form-item>
                <!-- <el-form-item>
                    <dataSelect
                        style="width: 180px; margin-left: 10px"
                        class="filter-item"
                        v-model="query.sourceType"
                        clearable
                        :codeType="'source_type'"
                        placeholder="来源"
                    ></dataSelect>
                </el-form-item> -->

                <div style="padding: auto">
                    <el-form-item>
                        <el-date-picker
                            v-model="vipPicker"
                            type="daterange"
                            :range-separator="$i18n.t('common.to')"
                            :start-placeholder="$i18n.t('common.StartDateOfMembership')"
                            :end-placeholder="$i18n.t('common.StartDateOfMembership')"
                            format="yyyy-MM-dd"
                            :default-time="['00:00:00', '23:59:59']"
                        />
                    </el-form-item>
                    <el-form-item>
                        <el-date-picker
                            v-model="createPicker"
                            type="datetimerange"
                            :range-separator="$i18n.t('common.to')"
                            :start-placeholder="$i18n.t('common.Startdate')"
                            :end-placeholder="$i18n.t('common.Enddate')"
                            :picker-options="pickerOptions"
                        >
                        </el-date-picker>
                    </el-form-item>
                    <el-form-item>
                        <el-date-picker
                            v-model="loginPicker"
                            type="datetimerange"
                            :range-separator="$i18n.t('common.to')"
                            :start-placeholder="$i18n.t('common.Lastlogintime')"
                            :end-placeholder="$i18n.t('common.Lastlogintime')"
                            :picker-options="pickerOptions"
                        >
                        </el-date-picker>
                    </el-form-item>
                    <el-form-item>
                        <el-button class="filter-item" icon="el-icon-search" type="primary" @click="handleQuery" style="margin-left: 10px">
                            {{ $i18n.t('common.query') }}
                        </el-button>
                    </el-form-item>
                    <el-form-item>
                        <el-button
                            class="filter-item"
                            icon="el-icon-search"
                            type="primary"
                            @click="cleanQuery"
                            style="margin-left: 10px"
                            size="small "
                        >
                            {{ $i18n.t('common.Reset') }}
                        </el-button>
                    </el-form-item>
                    <el-form-item>
                        <el-button
                            class="filter-item"
                            icon="el-icon-search"
                            type="primary"
                            @click="handleExport"
                            style="margin-left: 10px"
                            size="small"
                            v-loading.fullscreen.lock="fullscreenLoading"
                        >
                            {{ $i18n.t('common.export') }}
                        </el-button>
                    </el-form-item>
                    <el-form-item v-if="!scene.isNonSelf">
                        <el-button
                            class="filter-item"
                            icon="el-icon-coin"
                            type="primary"
                            @click="handleBatchRecharge"
                            style="margin-left: 10px"
                            size="small"
                        >
                            {{ $i18n.t('common.BatchRecharge') }}
                        </el-button>
                    </el-form-item>
                </div>
            </el-form>
        </div>

        <el-table v-loading="listLoading" :data="list.list" border stripe style="width: 100%; margin-top: 20px" size="medium" height="calc(100% - 170px)">
            <el-table-column :label="$i18n.t('common.UserID')" prop="userId" align="center" show-overflow-tooltip> </el-table-column>
            <el-table-column prop="countryCode" :label="$i18n.t('common.Registeredcountry')" align="center" width="80px"> </el-table-column>
            <el-table-column prop="email" :label="$i18n.t('common.mailbox')" show-overflow-tooltip align="center"> </el-table-column>
            <el-table-column prop="phoneNumber" :label="$i18n.t('common.Mobilephonenumber')" show-overflow-tooltip align="center">
                <template slot-scope="{ row }">
                    <span v-if="row.phoneNumber && row.phoneNumber != ''">
                        {{ '+' + row.phoneNumber }}
                    </span>
                </template>
            </el-table-column>
            <el-table-column prop="userCurrency" :label="$i18n.t('common.Patrioticcoin')" width="80px" align="center">
                <template slot-scope="{ row }">
                    <span v-if="row.userCurrency != 0 && row.userCurrency != null"
                        >{{ $dict.getDictValue('country_currency', row.countryCode) }}
                    </span>
                    <span>{{ row.userCurrency != null ? row.userCurrency : 0 }}</span>
                </template>
            </el-table-column>

            <el-table-column prop="addressCountryStr" :label="$i18n.t('common.DeliveryCountry')" show-overflow-tooltip align="center">
            </el-table-column>

            <el-table-column prop="userType" :label="$i18n.t('common.UserType')" align="center" show-overflow-tooltip>
                <template slot-scope="{ row }">
                    <dataText v-model="row.userType" :codeType="'user_type'"></dataText>
                </template>
            </el-table-column>
            <el-table-column prop="dealOrderNum" :label="$i18n.t('common.NumberOfCompletedOrders')" show-overflow-tooltip align="center">
            </el-table-column>
            <el-table-column prop="isSubscribe" :label="$i18n.t('common.Whethertosubscribe')" width="80px" align="center">
                <template slot-scope="{ row }">
                    <span v-if="row.isSubscribe == true"> {{ $i18n.t('common.yes') }} </span>
                    <span v-if="row.isSubscribe == false">{{ $i18n.t('common.no') }}</span>
                </template>
            </el-table-column>
            <el-table-column :label="$i18n.t('common.RegistrationInviterID')" prop="inviterUserId" align="center" show-overflow-tooltip>
                <template slot-scope="{ row }">
                    <span v-if="row.inviterUserId != ''">{{ row.inviterUserId }}</span>
                    <span v-if="row.inviterUserId == null || row.inviterUserId == ''">{{ $i18n.t('common.nothing') }}</span>
                </template>
            </el-table-column>

            <el-table-column :label="$i18n.t('common.memberOrNot')" prop="userMember" align="center" show-overflow-tooltip>
                <template slot-scope="{ row }">
                    <span v-if="row.userMember == true">{{ $i18n.t('common.yes') }}</span>
                    <span v-if="row.userMember == false">{{ $i18n.t('common.no') }}</span>
                </template>
            </el-table-column>
            <el-table-column :label="$i18n.t('common.StartDateOfMembership')" prop="startTime" align="center" show-overflow-tooltip>
                <template slot-scope="{ row }">
                    <span v-if="row.startTime">{{ row.startTime | formatDateYmd }}</span>
                </template>
            </el-table-column>
            <el-table-column :label="$i18n.t('common.EndDateOfMembership')" prop="endTime" align="center" show-overflow-tooltip>
                <template slot-scope="{ row }">
                    <span v-if="row.endTime">{{ row.endTime | formatDateYmd }}</span>
                </template>
            </el-table-column>
            <el-table-column :label="$i18n.t('common.AIGOCoinsAreCloseToExpiring')" prop="currencyExpireDay" align="center" show-overflow-tooltip></el-table-column>
            <el-table-column prop="registerSource" :label="$i18n.t('common.brand')" show-overflow-tooltip align="center">
                <template slot-scope="{ row }">
                    {{ $dict.getDictValue('register_source', row.registerSource) }}
                </template>
            </el-table-column>
            <!-- <el-table-column prop="sourceType" label="来源" show-overflow-tooltip align="center">
                <template slot-scope="{ row }">
                    {{ $dict.getDictValue('source_type', row.sourceType) }}
                </template>
            </el-table-column> -->
            <el-table-column
                :label="$i18n.t('common.Creationtime')"
                prop="createTime"
                align="center"
                show-overflow-tooltip
                :formatter="formatDate"
            >
            </el-table-column>

            <el-table-column
                prop="sourceUrlName"
                :label="$i18n.t('common.source')"
                show-overflow-tooltip
                align="center"
                v-if="!scene.isAigoSmartApp"
            >
                <template slot-scope="{ row }">
                    <span>{{ showSourceTranslate(row.sourceUrlName) }}</span>
                </template>
            </el-table-column>
            <el-table-column
                :label="$i18n.t('common.Lastlogintime')"
                prop="lastLoginTime"
                align="center"
                show-overflow-tooltip
                :formatter="formatDate"
            >
            </el-table-column>
            <el-table-column :label="$i18n.t('common.Loginstatus')" prop="status" align="center" show-overflow-tooltip>
                <template slot-scope="{ row }">
                    <el-switch v-model="row.status" active-color="#13ce66" @change="loginStatus(row)" :disabled="true"> </el-switch>
                </template>
            </el-table-column>
            <el-table-column :label="$i18n.t('common.operation')" align="center" width="360" >
                <template slot-scope="{ row }">
                    <el-button type="text" size="small" @click="detailHandle(row)" class="table-inner-button">{{
                        $i18n.t('common.check')
                    }}</el-button>
                    <el-button type="text" size="small" @click="orderHandle(row.userId)" class="table-inner-button">{{
                        $i18n.t('common.orderform')
                    }}</el-button>
                    <el-button type="text" size="small" @click="consultHandle(row.userId)" class="table-inner-button">{{
                        $i18n.t('common.consultingservice')
                    }}</el-button>
                    <el-button type="text" size="small" @click="addressHandle(row)" class="table-inner-button">{{
                        $i18n.t('common.address')
                    }}</el-button>
                    <!-- <el-button type="text" size="small" @click="changePassword(row)" class="table-inner-button">修改密码</el-button> -->

                    <el-button type="text" size="small" @click="loginLogHandle(row.userId)" class="table-inner-button">{{
                        $i18n.t('common.LoginLog')
                    }}</el-button>
                    <el-button type="text" size="small" @click="rechargeHandle(row)" class="table-inner-button" v-if="!scene.isNonSelf">{{
                        $i18n.t('common.Rechargepatrioticcoins')
                    }}</el-button>
                    <el-button type="text" size="small" @click="vipHandle(row)" class="table-inner-button" v-if="!scene.isNonSelf">{{
                        $i18n.t('common.MemberSettings')
                    }}</el-button>
                </template>
            </el-table-column>
        </el-table>
        <div class="block">
            <el-pagination
                style="margin-top: 10px"
                background
                @current-change="handleUserInfoCurrentChange"
                :current-page="query.page"
                :page-size="query.size"
                layout="total, prev, pager, next, jumper"
                :total="list.total"
            ></el-pagination>
        </div>
        <!-- 查看 -->
        <el-dialog :title="diologTitle" :visible.sync="detailVisible" v-if="detailVisible" width="930px" :close-on-click-modal="false">
            <div>
                <el-row>
                    <el-button type="primary" plain size="medium" @click="orderHandle(userInfo.userId)">{{
                        $i18n.t('common.orderform')
                    }}</el-button>
                    <el-button type="primary" plain size="medium" @click="returnHandle(userInfo.userId)">{{
                        $i18n.t('common.Returnsandexchanges')
                    }}</el-button>
                    <el-button type="primary" plain size="medium" @click="consultHandle(userInfo.userId)">{{
                        $i18n.t('common.consultingservice')
                    }}</el-button>
                    <el-button type="primary" plain size="medium" @click="commentHandle(userInfo.email)">{{
                        $i18n.t('common.evaluate')
                    }}</el-button>
                    <el-button type="primary" plain size="medium" @click="diologAddressHandle()">{{
                        $i18n.t('common.Shippingaddress')
                    }}</el-button>
                    <el-button type="primary" plain size="medium" @click="shoppingCarHandle()">{{
                        $i18n.t('common.shoppingcart')
                    }}</el-button>
                    <el-button type="primary" plain size="medium" @click="collectionHandle()">{{ $i18n.t('common.collection') }}</el-button>
                    <el-button type="primary" plain size="medium" @click="invoiceHandle(userInfo.userId)">{{
                        $i18n.t('common.invoice')
                    }}</el-button>
                    <el-button type="primary" plain size="medium" @click="userCurrencyHandle(userInfo.userId)">{{
                        $i18n.t('common.Patrioticcoin')
                    }}</el-button>
                    <el-button type="primary" plain size="medium" @click="loginLogHandle(userInfo.userId)">{{
                        $i18n.t('common.LoginLog')
                    }}</el-button>
                </el-row>
            </div>

            <div style="margin-top: 15px">
                <el-descriptions :title="$i18n.t('common.Account1')" :column="3" size="medium">
                    <el-descriptions-item label="ID">{{ userInfo.userId }}</el-descriptions-item>
                    <el-descriptions-item :label="$i18n.t('common.Registeredcountry')">
                        <template>
                            {{ $dict.getDictValue('countrys_all', userInfo.countryCode) }}
                        </template>
                    </el-descriptions-item>
                    <el-descriptions-item :label="$i18n.t('common.nickname')" :contentStyle="{ width: '250px' }">
                        <template>
                            <span v-if="userInfo.nickname.indexOf('@') >= 0"
                                >{{ userInfo.nickname.substring(0, userInfo.nickname.indexOf('@') - 1) }}
                            </span>
                            <span v-else>{{ userInfo.nickname }}</span>
                        </template>
                    </el-descriptions-item>
                    <el-descriptions-item :label="$i18n.t('common.RegistrationInviterID')">
                        <template>
                            <span v-if="userInfo.inviterUserId != ''">{{ userInfo.inviterUserId }}</span>
                            <span v-if="userInfo.inviterUserId == null || userInfo.inviterUserId == ''">{{
                                $i18n.t('common.nothing')
                            }}</span>
                        </template>
                    </el-descriptions-item>
                    <el-descriptions-item :label="$i18n.t('common.Loginstatus')">
                        <template>
                            <span v-if="userInfo.status">{{ $i18n.t('common.normal') }}</span>
                            <span v-else>{{ $i18n.t('common.close') }}</span>
                        </template>
                    </el-descriptions-item>

                    <el-descriptions-item :label="$i18n.t('common.source')">
                        <template v-if="!scene.isAigoSmartApp">
                            {{ showSourceTranslate(userInfo.sourceUrlName) }}
                        </template>
                        <template v-else>
                            {{ $dict.getDictValue('source_type', userInfo.sourceType) }}
                        </template>
                    </el-descriptions-item>
                </el-descriptions>
                <el-descriptions :column="2" size="medium">
                    <el-descriptions-item :label="$i18n.t('common.mailbox')">{{ userInfo.email }}</el-descriptions-item>
                    <el-descriptions-item :label="$i18n.t('common.brand')">
                        <template>
                            {{ $dict.getDictValue('register_source', userInfo.registerSource) }}
                        </template>
                    </el-descriptions-item>
                </el-descriptions>

                <el-descriptions :column="2" size="medium">
                    <el-descriptions-item :label="$i18n.t('common.Mobilephonenumber')">
                        <template>
                            <span v-if="userInfo.phoneNumber && userInfo.phoneNumber != ''">
                                {{ '+' + userInfo.phoneNumber }}
                            </span>
                        </template>
                    </el-descriptions-item>
                    <el-descriptions-item :label="$i18n.t('common.UserType')">
                        <template>
                            {{ $dict.getDictValue('user_type', userInfo.userType) }}
                        </template>
                    </el-descriptions-item>
                </el-descriptions>
                <el-descriptions :column="2" size="medium">
                    <el-descriptions-item :label="$i18n.t('common.Whethertosubscribe')">
                        <template>
                            <span v-if="userInfo.isSubscribe">{{ $i18n.t('common.yes') }}</span>
                            <span v-else>{{ $i18n.t('common.no') }}</span>
                        </template>
                    </el-descriptions-item>
                    <el-descriptions-item :label="$i18n.t('common.Subscriptiontime')">{{
                        userInfo.subTime | formatDate
                    }}</el-descriptions-item>
                </el-descriptions>
                <el-descriptions :column="2" size="medium">
                    <el-descriptions-item :label="$i18n.t('common.Creationtime')">{{
                        userInfo.createTime | formatDate
                    }}</el-descriptions-item>
                    <el-descriptions-item :label="$i18n.t('common.Lastlogintime')">{{
                        userInfo.lastLoginTime | formatDate
                    }}</el-descriptions-item>
                </el-descriptions>

                <el-descriptions :column="2" size="medium">
                    <el-descriptions-item :label="$i18n.t('common.Thirdpartylogin')">
                        <template>
                            <div>
                                <span style="margin: 0px 100px 0px 50px">Google</span>
                                <span v-if="(userInfo.externalProviders & 1) == 1">{{ $i18n.t('common.Bound') }}</span>
                                <span v-if="(userInfo.externalProviders & 1) == 0">{{ $i18n.t('common.Unbound') }}</span>
                            </div>
                        </template>
                    </el-descriptions-item>
                    <el-descriptions-item :label="$i18n.t('common.NumberOfCompletedOrders')">{{
                        userInfo.dealOrderNum
                    }}</el-descriptions-item>
                </el-descriptions>
            </div>

            <div>
                <el-menu :default-active="contentIndex" class="el-menu-demo" mode="horizontal" @select="handleContentSelect">
                    <el-menu-item v-for="(item, index) in countrysDicts" :key="item.code" :index="item.code">
                        {{ item.name }}
                    </el-menu-item>
                </el-menu>
                <el-descriptions :column="2" size="medium" style="margin-top: 15px">
                    <el-descriptions-item :label="$i18n.t('common.Patrioticcoin')">
                        <span>
                            <el-button
                                type="text"
                                size="medium"
                                style="padding: 0px"
                                @click="showCurrencyDetail(userDetailIndex.userId, userDetailIndex.countryCode)"
                                >{{ userDetailIndex.userCurrencyAmount }}
                            </el-button>
                            
                        </span>
                        <span v-if="userDetailIndex.firstExpireTime != null ">
                            ( {{$i18n.t('common.ValidityPeriod') }}
                             {{ userDetailIndex.firstExpireTime | formatDateYmd }})
                        </span>
                    </el-descriptions-item>
                    <el-descriptions-item :label="$i18n.t('common.Invitationcode')">{{
                        userDetailIndex.invitationCode
                    }}</el-descriptions-item>
                    <el-descriptions-item :label="$i18n.t('common.Inviteduser')">{{ userDetailIndex.invitedUsers }}</el-descriptions-item>
                    <el-descriptions-item :label="$i18n.t('common.Invitetoreceivepatrioticcoins')">{{
                        userDetailIndex.invitedCurrency
                    }}</el-descriptions-item>
                    <el-descriptions-item :label="$i18n.t('common.Isthefirstorderfreeofshipping')">
                        <template>
                            <span v-if="userDetailIndex.free == true">{{ $i18n.t('common.yes') }}</span>
                            <span v-else>{{ $i18n.t('common.no') }}</span>
                        </template>
                    </el-descriptions-item>
                </el-descriptions>
            </div>

            <div style="margin-top: 15px">
                <el-descriptions :title="$i18n.t('common.shopping')" :column="3" size="medium">
                    <el-descriptions-item :label="$i18n.t('common.Shippingaddress')">{{
                        userDetailIndex.addressAmount
                    }}</el-descriptions-item>
                    <el-descriptions-item :label="$i18n.t('common.Collectproducts')">{{
                        userDetailIndex.collectionAmount
                    }}</el-descriptions-item>
                    <el-descriptions-item :label="$i18n.t('common.ShoppingCartItems')">{{
                        userDetailIndex.shoppingCarAmount
                    }}</el-descriptions-item>
                    <el-descriptions-item :label="$i18n.t('common.PaymentOrder')">{{
                        userDetailIndex.payOrderAmount
                    }}</el-descriptions-item>
                    <el-descriptions-item :label="$i18n.t('common.Ordercumulativeamount')">{{
                        userDetailIndex.orderPriceAmount
                    }}</el-descriptions-item>
                    <el-descriptions-item :label="$i18n.t('common.Lastordertime')">{{
                        userDetailIndex.lastOrderTime
                    }}</el-descriptions-item>
                    <el-descriptions-item :label="$i18n.t('common.Returnandexchangeorders')">{{
                        userDetailIndex.cancelOrderAmount
                    }}</el-descriptions-item>
                    <el-descriptions-item :label="$i18n.t('common.invoice')">{{ userDetailIndex.invoiceAmount }}</el-descriptions-item>
                    <el-descriptions-item :label="$i18n.t('common.Consultationfrequency')">{{
                        userDetailIndex.consultationAmount
                    }}</el-descriptions-item>
                    <el-descriptions-item :label="$i18n.t('common.Numberofevaluations')">{{
                        userDetailIndex.commentAmount
                    }}</el-descriptions-item>
                    <el-descriptions-item :label="$i18n.t('common.Numberofapprovedevaluations')">{{
                        userDetailIndex.auditCommentAmount
                    }}</el-descriptions-item>
                </el-descriptions>
            </div>
        </el-dialog>

        <!-- 修改密码 -->
        <el-dialog title="修改密码" :visible.sync="passwordVisible" v-if="passwordVisible" width="500px">
            <div>输入新密码：</div>
            <el-input
                v-model="password"
                placeholder="请输入新密码"
                onkeyup="this.value=this.value.replace(/[^\w]/g,'')"
                style="margin-top: 15px"
                maxlength="20"
            >
            </el-input>
            <span style="margin-left: 320px; font-size: 10px; color: green">只允许输入数字+字母组合</span>

            <div slot="footer" class="dialog-footer">
                <el-button @click="closeForm()"> {{ $i18n.t('common.cancel') }} </el-button>
                <el-button type="primary" @click="handleSubmit()"> {{ $i18n.t('common.confirm1') }} </el-button>
            </div>
        </el-dialog>
        <el-dialog
            :title="$i18n.t('common.MemberSettings')"
            center
            :visible.sync="vipDialogVisible"
            v-if="vipDialogVisible"
            width="40%"
            @close="closeVip"
            :close-on-click-modal="false"
        >
            <div>
                <el-form ref="vipForm" :model="vipForm" :rules="vipRules" label-width="190px">
                    <el-form-item :label="$i18n.t('common.Account1') + ':'" prop="userMember">
                        <el-input
                            style="width: 220px"
                            :disabled="true"
                            v-model="vipForm.userMember"
                            :placeholder="$i18n.t('common.PleaseEnterTheAccountName')"
                        >
                        </el-input>
                    </el-form-item>
                    <el-form-item :label="$i18n.t('common.StartDateOfMembership') + ':'" prop="startTime">
                        <el-date-picker
                            v-model="vipForm.startTime"
                            type="date"
                            value-format="timestamp"
                            :placeholder="$i18n.t('common.Pleaseselectastartdate')"
                        >
                        </el-date-picker>
                    </el-form-item>
                    <el-form-item :label="$i18n.t('common.EndDateOfMembership') + ':'" prop="endTime">
                        <el-date-picker
                            v-model="vipForm.endTime"
                            type="date"
                            value-format="timestamp"
                            :placeholder="$i18n.t('common.Pleaseselecttheenddate')"
                        >
                        </el-date-picker>
                    </el-form-item>
                </el-form>
            </div>

            <div slot="footer" class="dialog-footer">
                <el-button @click="closeVip"> {{ $i18n.t('common.Cancel') }} </el-button>
                <el-button type="primary" :loading="vipLoading" @click="handleVipSubmit('vipForm')">
                    {{ $i18n.t('common.Submit') }}
                </el-button>
            </div>
        </el-dialog>
        <!-- 地址 -->
        <addressHandle ref="addressHandle"></addressHandle>

        <!-- 收藏商品 -->
        <collectionHandle ref="collectionHandle"></collectionHandle>

        <!-- 购物车 -->
        <shoppingCarHandle ref="shoppingCarHandle"></shoppingCarHandle>

        <!-- 充值爱国币 -->
        <rechargeHandle ref="rechargeHandle"></rechargeHandle>

        <!-- 充值爱国币 -->
        <batchRechargeHandle ref="batchRechargeHandle"></batchRechargeHandle>

        <!-- 爱国币详情 -->
        <userCurrencyHandle ref="userCurrencyHandle"></userCurrencyHandle>
    </div>
</template>

<script>
import { formatDate } from '@/utils/date';
import dataSelect from '@/components/common/DataSelect.vue';
import dataText from '@/components/common/DataText.vue';
import Pagination from '@/components/common/Pagination/';
import moment from 'moment';
import ossUploader from '@/components/page/OssEleUploder.vue';
import addressHandle from '@/views/userManagement/address';
import collectionHandle from '@/views/userManagement/collection';
import shoppingCarHandle from '@/views/userManagement/shoppingCar';
import rechargeHandle from '@/views/userManagement/recharge';
import batchRechargeHandle from '@/views/userManagement/batchRecharge';
import userCurrencyHandle from '@/views/userManagement/userCurrency';
import Config from '@/utils/config';
import scene from '../../utils/scene';
export default {
    name: 'userManagement',
    computed: {
        scene() {
            return scene;
        }
    },
    components: {
        dataSelect,
        dataText,
        Pagination,
        moment,
        ossUploader,
        addressHandle,
        collectionHandle,
        shoppingCarHandle,
        rechargeHandle,
        batchRechargeHandle,
        userCurrencyHandle
    },
    data() {
        var checkEndTime = (rule, value, callback) => {
            if (!value) {
                return callback(new Error(this.$i18n.t('common.Pleaseselecttheenddate')));
            } else if (this.vipForm.endTime < this.vipForm.startTime) {
                return callback(new Error(this.$i18n.t('common.MembershipEndDateCannotBeLessThanMembershipStartDate')));
            } else {
                callback();
            }
        };
        var checkDateTime = (rule, value, callback) => {
            if (this.valuePicker.length > 0) {
                callback();
            } else {
                callback(new Error(this.$i18n.t('common.Shippingaddress')));
            }
        };
        return {
            AigoStar: Config.AigoStar,
            TaylorSwoden: Config.TaylorSwoden,
            Nobleza: Config.Nobleza,
            list: [],
            old: [],
            userInfo: [],
            userDetail: [],
            userDetailIndex: [],
            total: 0,
            password: '',
            listLoading: true,
            vipLoading: false,
            countrysDicts: [],
            countrysAllDicts: [],
            defaultVipForm: {
                userMember: '',
                startTime: '',
                endTime: ''
            },
            vipIsRow: false,
            vipForm: {},
            vipRules: {
                userMember: [
                    { required: true, message: this.$i18n.t('common.Account1'), trigger: 'blur' },
                    { min: 1, max: 30, message: this.$i18n.t('common.CharactersLimited'), trigger: 'blur' }
                ],
                startTime: [{ type: 'date', required: true, message: this.$i18n.t('common.Pleaseselectastartdate'), trigger: 'change' }],
                endTime: [{ type: 'date', required: true, validator: checkEndTime, trigger: 'change' }]
            },
            query: {
                page: 1,
                limit: 10,
                countryCode: [],
                userIdOrEmailOrInviterUserId: '',
                status: null,
                registerSource: '',
                sourceType: '',
                sourceUrlName: '',
                userType: '',
                userMember: null,
                isSubscribe: null,
                createStartTime: null,
                createEndTime: null,
                lastLoginBeginTime: null,
                lastLoginEndTime: null,
                startTimeStart: null,
                startTimeEnd: null
            },
            ossHost: process.env.VUE_APP_OSS_Head,
            ossSignature: { host: '' },
            createPicker: [],
            loginPicker: [],
            vipPicker: [],
            diologTitle: this.$i18n.t('common.UserDetails'),

            detailVisible: false,
            passwordVisible: false,
            vipDialogVisible: false,
            fullscreenLoading: false,
            contentIndex: 'CN',
            pickerOptions: {
                shortcuts: [
                    {
                        text: this.$i18n.t('common.today'),
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime());
                            picker.$emit('pick', [start, end]);
                        }
                    },
                    {
                        text: this.$i18n.t('common.Lastweek'),
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 6);
                            picker.$emit('pick', [start, end]);
                        }
                    },
                    {
                        text: this.$i18n.t('common.Thismonth'),
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date(new Date().getFullYear(), new Date().getMonth(), 1);
                            picker.$emit('pick', [start, end]);
                        }
                    },
                    {
                        text: this.$i18n.t('common.Lastmonth1'),
                        onClick(picker) {
                            const start = new Date(new Date().getFullYear(), new Date().getMonth() - 1, 1);
                            const end = new Date(new Date().getFullYear(), new Date().getMonth(), 1);
                            end.setDate(end.getDate() - 1);
                            picker.$emit('pick', [start, end]);
                        }
                    },
                    {
                        text: this.$i18n.t('common.thisyear'),
                        onClick(picker) {
                            const start = new Date(new Date().getFullYear(), 0, 1);
                            const end = new Date();
                            picker.$emit('pick', [start, end]);
                        }
                    }
                ]
            }
        };
    },
    filters: {
        formatDateYmd(time, type = 'yyyy-MM-dd') {
            if (time == null || time === '') {
                return 'N/A';
            }
            let date = new Date(time);
            return formatDate(date, type);
        }
    },
    created() {
        this.doQueryList({ page: 1 });
        this.getDictItems();
    },
    mounted() {
        this.getDictItems();
        this.vipForm = Object.assign({}, this.defaultVipForm);
    },
    methods: {
        /**
         * userInfo分页
         */
        handleUserInfoCurrentChange(val) {
            this.query.page = val;
            console.log(val);
            this.doQueryList({ page: val });
        },
        /**
         * 菜单切换操作
         */
        handleContentSelect(key, keyPath) {
            var userId = this.userDetailIndex.userId;
            this.userDetailIndex = this.userDetail.find((item) => item.countryCode == key);
            console.log(this.userDetailIndex);
            if (this.userDetailIndex == undefined) {
                this.userDetailIndex = [];
                this.userDetailIndex.addressAmount = 0;
                this.userDetailIndex.aigoStarFree = null;
                this.userDetailIndex.auditCommentAmount = 0;
                this.userDetailIndex.cancelOrderAmount = 0;
                this.userDetailIndex.collectionAmount = 0;
                this.userDetailIndex.commentAmount = 0;
                this.userDetailIndex.consultationAmount = 0;
                this.userDetailIndex.free = false;
                this.userDetailIndex.invitedCurrency = 0;
                this.userDetailIndex.invitedUsers = 0;
                this.userDetailIndex.invoiceAmount = 0;
                this.userDetailIndex.lastOrderTime = null;
                this.userDetailIndex.noblezaFree = null;
                this.userDetailIndex.orderPriceAmount = 0;
                this.userDetailIndex.payOrderAmount = 0;
                this.userDetailIndex.shoppingCarAmount = 0;
                this.userDetailIndex.userCurrencyAmount = 0;
                this.userDetailIndex.userId = userId;
                this.userDetailIndex.invitationCode = null;
                this.userDetailIndex.invitedUsers = 0;
            } else {
                if (this.userDetailIndex.lastOrderTime != null) {
                    console.log(this.userDetailIndex.lastOrderTime);
                    this.userDetailIndex.lastOrderTime = moment(this.userDetailIndex.lastOrderTime).format('YYYY-MM-DD HH:mm:ss');
                }
            }
            console.log(this.userDetailIndex);
        },

        /**
         * 地址分页
         */
        handleAddressCurrentChange(val) {
            this.addressQuery.page = val;
            this.doAddressQuery({ page: val });
        },
        /**
         * 时间戳转码
         */
        formatDate(row, column) {
            // 获取单元格数据
            let data = row[column.property];
            if (!data) {
                return '';
            } else {
                return moment(data).format('YYYY-MM-DD HH:mm:ss');
            }
        },
        /**
         * 触发搜索操作
         */
        handleQuery() {
            if (this.loginPicker != null && this.loginPicker.length > 0) {
                this.query.lastLoginBeginTime = moment(this.loginPicker[0]).format('yyyy-MM-DD HH:mm:ss');
                this.query.lastLoginEndTime = moment(this.loginPicker[1]).format('yyyy-MM-DD HH:mm:ss');
            } else {
                this.query.lastLoginBeginTime = null;
                this.query.lastLoginEndTime = null;
            }
            if (this.createPicker != null && this.createPicker.length > 0) {
                this.query.createStartTime = moment(this.createPicker[0]).format('yyyy-MM-DD HH:mm:ss');
                this.query.createEndTime = moment(this.createPicker[1]).format('yyyy-MM-DD HH:mm:ss');
            } else {
                this.query.createStartTime = null;
                this.query.createEndTime = null;
            }
            if (this.vipPicker != null && this.vipPicker.length > 0) {
                this.query.startTimeStart = this.vipPicker[0].getTime();
                this.query.startTimeEnd = this.vipPicker[1].getTime();
            } else {
                this.query.startTimeStart = null;
                this.query.startTimeEnd = null;
            }

            this.doQueryList({ page: 1 });
        },
        /**
         * 执行列表查询
         */
        doQueryList({ page, limit }) {
            if (page) {
                this.query.page = page;
            }
            if (limit) {
                this.query.limit = limit;
            }
            this.listLoading = true;
            this.$store
                .dispatch('getUserInfoPage', this.query)
                .then((res) => {
                    this.list = res;
                    console.log(this.list);
                })
                .finally(() => {
                    this.listLoading = false;
                });
        },
        /**
         * 查看详情
         */
        detailHandle(row) {
            this.userDetail = [];
            this.userDetailIndex = [];
            this.old = row;
            for (const key in this.old) {
                this.userInfo[key] = this.old[key];
            }
            //菜单默认选中用户国家
            this.contentIndex = row.countryCode;
            this.$store.dispatch('getUserDetailPage', { userId: row.userId }).then((res) => {
                this.userDetail = res;
                this.userDetailIndex = this.userDetail.find((item) => item.countryCode == this.contentIndex);
                if (this.userDetailIndex == undefined) {
                    this.userDetailIndex = [];
                    this.userDetailIndex.addressAmount = 0;
                    this.userDetailIndex.aigoStarFree = null;
                    this.userDetailIndex.auditCommentAmount = 0;
                    this.userDetailIndex.cancelOrderAmount = 0;
                    this.userDetailIndex.collectionAmount = 0;
                    this.userDetailIndex.commentAmount = 0;
                    this.userDetailIndex.consultationAmount = 0;
                    this.userDetailIndex.free = false;
                    this.userDetailIndex.invitedCurrency = 0;
                    this.userDetailIndex.invitedUsers = 0;
                    this.userDetailIndex.invoiceAmount = 0;
                    this.userDetailIndex.lastOrderTime = null;
                    this.userDetailIndex.noblezaFree = null;
                    this.userDetailIndex.orderPriceAmount = 0;
                    this.userDetailIndex.payOrderAmount = 0;
                    this.userDetailIndex.shoppingCarAmount = 0;
                    this.userDetailIndex.userCurrencyAmount = 0;
                    this.userDetailIndex.userId = row.userId;
                    this.userDetailIndex.invitationCode = null;
                    this.userDetailIndex.invitedUsers = 0;
                }
                console.log(this.userDetailIndex);
                if (this.userDetailIndex.lastOrderTime != null) {
                    this.userDetailIndex.lastOrderTime = moment(this.userDetailIndex.lastOrderTime).format('YYYY-MM-DD HH:mm:ss');
                }
            });

            this.detailVisible = true;
        },

        /**
         * 改变登录状态
         */
        loginStatus(row) {
            console.log(row.status);
            const h = this.$createElement;
            if (!row.status) {
                this.$msgbox({
                    title: this.$i18n.t('common.ProhibitLogin'),
                    message: h('p', null, [
                        h('span', null, this.$i18n.t('common.confirm')),
                        h('span', { style: 'color: red' }, this.$i18n.t('common.Prohibit')),
                        h('span', null, this.$i18n.t('common.IsTheUserLoggedIn'))
                    ]),
                    showCancelButton: true,
                    confirmButtonText: this.$i18n.t('common.Confirm'),
                    cancelButtonText: this.$i18n.t('common.Cancel')
                })
                    .then((action) => {
                        this.$store.dispatch('updateUserInfoStatus', { userId: row.userId }).then((res) => {
                            this.doQueryList({});
                            this.$message({
                                message: this.$i18n.t('common.ProhibitedSuccessfully'),
                                type: 'success'
                            });
                        });
                    })
                    .catch(() => {
                        this.doQueryList({});
                        this.$message({
                            type: 'info',
                            message: this.$i18n.t('common.OperationCanceled')
                        });
                    });
            } else {
                this.$msgbox({
                    title: this.$i18n.t('common.EnableLogin'),
                    message: h('p', null, [
                        h('span', null, this.$i18n.t('common.confirm')),
                        h('span', { style: 'color: green' }, this.$i18n.t('common.EnableLogin')),
                        h('span', null, this.$i18n.t('common.IsTheUserLoggedIn'))
                    ]),
                    showCancelButton: true,
                    confirmButtonText: this.$i18n.t('common.Confirm'),
                    cancelButtonText: this.$i18n.t('common.Cancel')
                })
                    .then((action) => {
                        this.$store.dispatch('updateUserInfoStatus', { userId: row.userId }).then((res) => {
                            this.doQueryList({});
                            this.$message({
                                message: this.$i18n.t('common.EnabledSuccessfully'),
                                type: 'success'
                            });
                        });
                    })
                    .catch(() => {
                        this.doQueryList({});
                        this.$message({
                            type: 'info',
                            message: this.$i18n.t('common.OperationCanceled')
                        });
                    });
            }
        },

        /**
         * 修改密码
         */
        changePassword(row) {
            this.password = '';
            this.passwordVisible = true;
        },
        /**
         * 导出excel
         */
        handleExport() {
            this.query.fileName = this.$i18n.t('common.UserData') + '.xlsx';
            this.$confirm(this.$i18n.t('common.Areyousuretoexportthefile'), this.$i18n.t('common.prompt'), {
                // confirmButtonText: '确定',
                // cancelButtonText: '取消',
                type: 'warning'
            })
                .then(() => {
                    this.fullscreenLoading = true;
                    this.$store.dispatch('exportUserInfoExcel', this.query).then((res) => {
                        this.fullscreenLoading = false;
                        this.$message({
                            type: 'success',
                            message: this.$i18n.t('common.Exportsuccessful')
                        });
                    });
                })
                .catch(() => {
                    this.fullscreenLoading = false;
                });
        },
        /**
         * 批量充值
         */
        handleBatchRecharge() {
            this.$refs.batchRechargeHandle.openRechargeDialog();
        },
        closeForm() {
            this.passwordVisible = false;
        },

        getDictItems() {
            var _this = this;
            this.$store.dispatch('getDicItems', { tag: 'countrys' }).then((res) => {
                if (res != null) _this.countrysDicts = res;
                console.log(this.countrysDicts);
            });
            this.$store.dispatch('getDicItems', { tag: 'countrys_all' }).then((res) => {
                if (res != null) _this.countrysAllDicts = res;
                console.log(this.countrysAllDicts);
            });
        },
        cleanQuery() {
            (this.query.userIdOrEmailOrInviterUserId = ''),
                (this.query.registerSource = ''),
                (this.query.countryCode = []),
                (this.query.status = null),
                (this.query.isSubscribe = null),
                (this.query.sourceType = ''),
                (this.query.sourceUrlName = ''),
                (this.query.userType = ''),
                (this.query.createStartTime = null),
                (this.query.lastLoginStartTime = null),
                (this.query.createEndTime = null),
                (this.query.lastLoginEndTime = null),
                (this.query.createEndTime = null),
                (this.query.startTimeStart = null),
                (this.query.startTimeEnd = null),
                (this.query.userMember = null),
                (this.createPicker = []);
            this.loginPicker = [];
            this.doQueryList({ page: 1 });
        },

        /**
         * 地址处理
         */
        addressHandle(row) {
            this.$refs.addressHandle.addressHandle(row);
        },
        diologAddressHandle() {
            this.$refs.addressHandle.diologAddressHandle(this.userInfo.userId);
        },
        /**
         * 收藏处理
         */
        collectionHandle() {
            this.$refs.collectionHandle.collectionHandle(this.userInfo.userId);
        },

        /**
         * 购物车处理
         */
        shoppingCarHandle() {
            this.$refs.shoppingCarHandle.shoppingCarHandle(this.userInfo.userId);
        },

        /**
         * 充值爱国币处理
         */
        rechargeHandle(row) {
            this.$refs.rechargeHandle.rechargeHandle(row.userId, row.email);
        },
        /**
         * 查看爱国币详情
         */
        showCurrencyDetail(userId, countryCode) {
            this.$refs.userCurrencyHandle.currencyHandle(userId, countryCode);
        },
        //会员设置
        vipHandle(row) {
            this.vipForm.userMember = row.email;
            this.vipForm.startTime = row.startTime;
            this.vipForm.endTime = row.endTime;
            if (row.endTime) {
                this.vipIsRow = true;
            }
            this.vipForm.userId = row.userId;
            this.vipDialogVisible = true;
        },
        /**
         * 订单跳转
         */
        orderHandle(userId) {
            this.$router.push({
                name: 'order_list',
                params: {
                    userId: userId
                }
            });
            this.detailVisible = false;
        },

        /**
         * 咨询跳转处理
         */
        consultHandle(userId) {
            this.$router.push({
                name: 'consultation_list',
                params: {
                    userId: userId
                }
            });
            this.detailVisible = false;
        },
        /**
         * 登录日志跳转
         */
        loginLogHandle(userId) {
            this.$router.push({
                name: 'login_list',
                params: {
                    userId: userId
                }
            });
            this.detailVisible = false;
        },

        /**
         * 评论跳转
         */
        commentHandle(email) {
            this.$router.push({
                name: 'comment_list',
                params: {
                    email: email
                }
            });
            this.detailVisible = false;
        },

        /**
         * 发票跳转
         */
        invoiceHandle(userId) {
            this.$router.push({
                name: 'invoice_list',
                params: {
                    userId: userId
                }
            });
            this.detailVisible = false;
        },

        /**
         * 退换货跳转
         */
        returnHandle(userId) {
            this.$router.push({
                name: 'postSale_list',
                params: {
                    userId: userId
                }
            });
            this.detailVisible = false;
        },

        /**
         * 爱国币日志跳转
         */
        userCurrencyHandle(userId) {
            this.$router.push({
                name: 'currency_list',
                params: {
                    userId: userId
                }
            });
            this.detailVisible = false;
        },
        //关闭vip弹窗
        closeVip() {
            this.vipDialogVisible = false;
            this.vipIsRow = false;
            this.vipForm = Object.assign({}, this.defaultVipForm);
        },
        //提交vip数据
        handleVipSubmit(vipForm) {
            this.$refs[vipForm].validate((vipForm) => {
                if (vipForm) {
                    this.vipLoading = true;
                    if (!this.vipIsRow) {
                        this.vipForm.endTime = this.vipForm.endTime + 24 * 60 * 60 * 1000 - 1;
                    }
                    this.$store
                        .dispatch('vipMember', this.vipForm)
                        .then((res) => {
                            this.$message({
                                message: this.$i18n.t('common.SubmittedSuccessfully'),
                                type: 'success',
                                duration: 1000
                            });
                            this.vipLoading = false;
                            this.vipDialogVisible = false;
                            this.vipIsRow = false;
                            this.vipForm = Object.assign({}, this.defaultVipForm);
                            this.doQueryList({ page: 1 });
                        })
                        .catch((err) => {
                            this.vipLoading = false;
                        });
                } else {
                    console.log('error submit!!');
                    this.vipLoading = false;
                    return false;
                }
            });
        },
        /**
         * 显示来源翻译
         */
        showSourceTranslate(sourceNameUrl) {
            if (!sourceNameUrl) {
                return '';
            }
            if ('直接访问' == sourceNameUrl) {
                return this.$t('common.DirectVisit');
            } else if ('其他' == sourceNameUrl) {
                return this.$t('common.other');
            } else {
                return sourceNameUrl;
            }
        }
    }
};
</script>
<style scoped>
.filter-container {
    padding: auto;
    margin-left: 20px;
    padding-top: 20px;
}
.my-content {
    background: #fde2e2;
}
</style>
