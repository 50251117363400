var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading"
            }
          ],
          staticStyle: { width: "100%", "margin-top": "20px" },
          attrs: { data: _vm.list, border: "", stripe: "" }
        },
        [
          _c("el-table-column", {
            attrs: {
              label: "ID",
              type: "index",
              align: "center",
              width: "50px"
            }
          }),
          _c("el-table-column", {
            attrs: {
              label: _vm.$i18n.t("common.name"),
              prop: "title",
              align: "center",
              "show-overflow-tooltip": ""
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("span", [
                      _vm._v(_vm._s(_vm.getTitleById(scope.row.remarkId)))
                    ])
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              label: _vm.$i18n.t("common.Enableornot"),
              prop: "postCode",
              align: "center",
              "show-overflow-tooltip": ""
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c(
                      "span",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: scope.row.status,
                            expression: "scope.row.status"
                          }
                        ]
                      },
                      [_vm._v(_vm._s(_vm.$i18n.t("common.yes")))]
                    ),
                    _c(
                      "span",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: scope.row.status == false,
                            expression: "scope.row.status == false"
                          }
                        ]
                      },
                      [_vm._v(_vm._s(_vm.$i18n.t("common.no")))]
                    )
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              label: _vm.$i18n.t("common.Updatetime"),
              prop: "updateTime",
              align: "center",
              "show-overflow-tooltip": "",
              formatter: _vm.formatDate
            }
          }),
          _c("el-table-column", {
            attrs: {
              label: _vm.$i18n.t("common.Operator"),
              prop: "updateUserName",
              align: "center",
              "show-overflow-tooltip": ""
            }
          }),
          _c("el-table-column", {
            attrs: {
              label: _vm.$i18n.t("common.operation"),
              align: "center",
              fixed: "right",
              width: "400"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text", size: "small" },
                        on: {
                          click: function($event) {
                            return _vm.openRule(scope.row)
                          }
                        }
                      },
                      [_vm._v(_vm._s(_vm.$i18n.t("common.RuleConfiguration")))]
                    ),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text", size: "small" },
                        on: {
                          click: function($event) {
                            return _vm.openLang(scope.row)
                          }
                        }
                      },
                      [
                        _vm._v(
                          _vm._s(
                            _vm.$i18n.t("common.Multilingualconfiguration")
                          )
                        )
                      ]
                    )
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _vm.ruleVisible
        ? _c(
            "el-dialog",
            {
              attrs: {
                title: _vm.$i18n.t("common.RuleConfiguration"),
                visible: _vm.ruleVisible,
                "close-on-click-modal": false
              },
              on: {
                "update:visible": function($event) {
                  _vm.ruleVisible = $event
                },
                close: function($event) {
                  return _vm.closeForm()
                }
              }
            },
            [
              _c(
                "el-form",
                {
                  attrs: { "label-position": "right", "label-width": "220px" }
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: _vm.$i18n.t("common.name") } },
                    [
                      _vm._v(
                        "\n                " +
                          _vm._s(_vm.title) +
                          "\n            "
                      )
                    ]
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: _vm.$i18n.t(
                          "common.ObtainingPatrioticCoinObject"
                        )
                      }
                    },
                    [
                      _vm._v(
                        "\n                " +
                          _vm._s(_vm.target) +
                          "\n            "
                      )
                    ]
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: _vm.$i18n.t("common.Rewardconditionsobtained")
                      }
                    },
                    [
                      _vm._v(
                        "\n                " +
                          _vm._s(_vm.condition) +
                          "\n            "
                      )
                    ]
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: _vm.$i18n.t(
                          "common.Obtainpatrioticcoinallocation"
                        )
                      }
                    },
                    [
                      _c("span", { staticStyle: { color: "red" } }, [
                        _vm._v(
                          _vm._s(_vm.$i18n.t("common.Numberoflocalcurrencies"))
                        )
                      ])
                    ]
                  )
                ],
                1
              ),
              _c(
                "el-form",
                {
                  ref: "dataForm",
                  staticStyle: {
                    width: "400px",
                    "margin-left": "140px",
                    "margin-top": "20px"
                  },
                  attrs: {
                    model: _vm.ruleEdit,
                    "label-position": "left",
                    size: "small",
                    "label-width": "100px"
                  }
                },
                _vm._l(_vm.countrysDicts, function(item, index) {
                  return _c(
                    "el-form-item",
                    {
                      key: item.code,
                      attrs: {
                        index: item.code,
                        label: item.name + "：",
                        prop: "countryCode"
                      }
                    },
                    [
                      _c("el-input-number", {
                        staticStyle: { "margin-left": "-35px", width: "190px" },
                        attrs: {
                          placeholder: _vm.$i18n.t("common.Inputquantity"),
                          min: 0,
                          max: 999,
                          controls: false
                        },
                        model: {
                          value: _vm.addCountryCount[item.code],
                          callback: function($$v) {
                            _vm.$set(_vm.addCountryCount, item.code, $$v)
                          },
                          expression: "addCountryCount[item.code]"
                        }
                      })
                    ],
                    1
                  )
                }),
                1
              ),
              _c(
                "div",
                { staticStyle: { "margin-left": "80px" } },
                [
                  _vm._v(
                    "\n            " +
                      _vm._s(_vm.$i18n.t("common.Enableornot")) +
                      "\n            "
                  ),
                  _c("el-switch", {
                    staticStyle: { "margin-left": "10px" },
                    attrs: { "active-color": "#13ce669" },
                    model: {
                      value: _vm.ruleEdit.status,
                      callback: function($$v) {
                        _vm.$set(_vm.ruleEdit, "status", $$v)
                      },
                      expression: "ruleEdit.status"
                    }
                  })
                ],
                1
              ),
              _c(
                "div",
                {
                  staticClass: "dialog-footer",
                  attrs: { slot: "footer" },
                  slot: "footer"
                },
                [
                  _c(
                    "el-button",
                    {
                      on: {
                        click: function($event) {
                          return _vm.closeForm()
                        }
                      }
                    },
                    [_vm._v(" " + _vm._s(_vm.$i18n.t("common.cancel")))]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: {
                        click: function($event) {
                          return _vm.handleSubmitRule()
                        }
                      }
                    },
                    [_vm._v("  " + _vm._s(_vm.$i18n.t("common.confirm")) + " ")]
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _vm.langVisible
        ? _c(
            "el-dialog",
            {
              attrs: {
                title: _vm.$i18n.t("common.Multilingualconfiguration"),
                visible: _vm.langVisible,
                "close-on-click-modal": false
              },
              on: {
                "update:visible": function($event) {
                  _vm.langVisible = $event
                },
                close: function($event) {
                  return _vm.closeForm()
                }
              }
            },
            [
              _c(
                "el-form",
                {
                  ref: "dataForm",
                  staticStyle: {
                    width: "400px",
                    "margin-left": "140px",
                    "margin-top": "20px"
                  },
                  attrs: {
                    model: _vm.langEdit,
                    "label-position": "left",
                    size: "small",
                    "label-width": "120px"
                  }
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: _vm.$i18n.t("common.name") } },
                    [
                      _c("span", { staticStyle: { "margin-left": "-35px" } }, [
                        _vm._v(
                          "\n                    " +
                            _vm._s(_vm.title) +
                            "\n                "
                        )
                      ])
                    ]
                  ),
                  _vm._l(_vm.lang, function(item, index) {
                    return _c(
                      "el-form-item",
                      {
                        key: item.code,
                        attrs: {
                          index: item.code,
                          label: item.name + "：",
                          prop: "lang"
                        }
                      },
                      [
                        _c("el-input", {
                          staticStyle: {
                            "margin-left": "-35px",
                            width: "190px"
                          },
                          attrs: {
                            placeholder: _vm.$i18n.t("common.EnterName")
                          },
                          model: {
                            value: _vm.addLang[item.code],
                            callback: function($$v) {
                              _vm.$set(_vm.addLang, item.code, $$v)
                            },
                            expression: "addLang[item.code]"
                          }
                        })
                      ],
                      1
                    )
                  })
                ],
                2
              ),
              _c(
                "div",
                {
                  staticClass: "dialog-footer",
                  attrs: { slot: "footer" },
                  slot: "footer"
                },
                [
                  _c(
                    "el-button",
                    {
                      on: {
                        click: function($event) {
                          return _vm.closeForm()
                        }
                      }
                    },
                    [_vm._v("  " + _vm._s(_vm.$i18n.t("common.cancel")) + " ")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: {
                        click: function($event) {
                          return _vm.handleSubmitLang()
                        }
                      }
                    },
                    [_vm._v(" " + _vm._s(_vm.$i18n.t("common.confirm")) + " ")]
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }