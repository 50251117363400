<template>
    <!-- 地址 -->
    <el-dialog :title='$i18n.t("common.Shippingaddress")' :visible.sync="addressVisible" v-if="addressVisible" width="1200px" :close-on-click-modal='false'>
      <el-select v-model="addressQuery.countryCode" :multiple="true" :placeholder='$i18n.t("common.ShowCountry")' style="width: 180px;margin-left: 10px;" class="filter-item">
                  <el-option v-for="cc in countrysDicts" :key="cc.code" :label="cc.name" :value="cc.code"></el-option>
      </el-select>
      <el-button class="filter-item" icon="el-icon-search" type="primary"
                 @click="handleAddressQuery" style="margin-left: 10px;">
        {{ $i18n.t("common.query") }}
        </el-button>
      <el-table 
            :data="address.list"
            border
            stripe
            style="width: 100%; margin-top: 20px"
            size="small">

      <el-table-column prop="addressId" label="ID" width="150px" align="center">    
      </el-table-column>
      <el-table-column prop="userName" :label=' $i18n.t("common.name1")' width="80px" align="center">   
        <template slot-scope="{row}">
          <span >{{row.userName + " " + row.userSurname}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="taxNumber" :label='$i18n.t("common.TaxID")' show-overflow-tooltip align="center">    
      </el-table-column>
      <el-table-column prop="countryCode" :label='$i18n.t("common.country")'  align="center" width="50px">    
      </el-table-column>
      <el-table-column prop="province" :label='$i18n.t("common.province")' width="100px" align="center">    
      </el-table-column>
      <el-table-column prop="city" :label='$i18n.t("common.city")' width="100px" align="center">    
      </el-table-column>
      <el-table-column prop="addressContent" :label='$i18n.t("common.Detailedaddress")' show-overflow-tooltip align="center">    
      </el-table-column>
      <el-table-column prop="houseNum" :label='$i18n.t("common.Housenumber")' align="center" width="100px">
      </el-table-column>
      <el-table-column prop="recipientCompany" :label='$i18n.t("common.RecipientCompany")' show-overflow-tooltip align="center" width="100px">
      </el-table-column>
          <el-table-column prop="addressAdditionalContent" :label='$i18n.t("common.AddressAdditionalContent")' show-overflow-tooltip align="center" width="100px">
      </el-table-column>
      <el-table-column :label='$i18n.t("common.Defaultshippingaddress")'
                       prop="isDefaultReceive"
                       align="center"
                       width="100px">
        <template slot-scope="{row}">
          <span v-if="row.isDefaultReceive">{{$i18n.t("common.yes")}}</span>
          <span v-else>{{ $i18n.t("common.no") }}</span>
        </template>
      </el-table-column>
      <el-table-column :label='$i18n.t("common.Defaultinvoiceaddress")'
                       prop="isDefaultInvoice"
                       align="center"
                       width="100px">
        <template slot-scope="{row}">
          <span v-if="row.isDefaultInvoice">{{$i18n.t("common.yes")}}</span>
          <span v-else>{{ $i18n.t("common.no") }}</span>
        </template>
      </el-table-column>
      <el-table-column :label='$i18n.t("common.Creationtime")'
                       prop="createTime"
                       align="center"
                       width="150px"
                       :formatter="formatDate">
      </el-table-column>
    </el-table>
      <div class="block">
            <el-pagination
                style="margin-top: 10px"
                background
                @current-change="handleAddressCurrentChange"
                :current-page="addressQuery.page"
                :page-size="addressQuery.size"
                layout="total, prev, pager, next, jumper"
                :total="address.total"
            ></el-pagination>
    </div>
    </el-dialog>
</template>

<script>
import dataSelect from '@/components/common/DataSelect.vue';
import dataText from '@/components/common/DataText.vue';
import moment from 'moment';

export default {
    name: 'addressHandle',
    components: {
        dataSelect,
        dataText
    },
    data() {
        return {
            address:[],
            countrysDicts: [],
            addressQuery:{
                page: 1,
                limit: 10,
                countryCode:[],
                userId:null,
            },
            addressVisible:false,
            listLoading: true,

        };
    },
    mounted() {
        this.getDictItems();
    },
    methods: {
        getDictItems() {
            var _this = this;
            this.$store.dispatch('getDicItems', { tag: 'countrys' }).then((res) => {
                if (res != null) {
                    _this.countrysDicts = res;
                }
            })
        },
        /**
         * 地址分页
         */
        handleAddressCurrentChange(val){
            this.addressQuery.page = val;
            this.doAddressQuery({ page: val})
        },
        /**
         * 时间戳转码
         */
        formatDate(row, column) {
                // 获取单元格数据
                let data = row[column.property];
                if (!data) {
                    return '';
                } else {
                    return moment(data).format('YYYY-MM-DD HH:mm:ss');
                }
        },
        /**
         * 地址处理
         */
        addressHandle(row){
            this.address = [];
            this.addressQuery.countryCode = [],
            this.addressQuery.userId = row.userId
            this.handleAddressQuery();
            this.addressVisible = true;
        },
        diologAddressHandle(userId){
            this.address = [];
            this.addressQuery.countryCode = [],
            this.addressQuery.userId = userId
            this.handleAddressQuery();
            this.addressVisible = true;
        },
        handleAddressQuery(){
            this.doAddressQuery({ page : 1});
        },
        doAddressQuery({ page, limit }){
            if (page) {
                this.addressQuery.page = page
            }
            if (limit) {
                this.addressQuery.limit = limit
            }
            this.$store.dispatch('getAddressPage',this.addressQuery).then((res) =>{
                    this.address = res;
            });
        },
    }
};
</script>
