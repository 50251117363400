var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "app-container",
      staticStyle: { height: "100%", "min-height": "350px" }
    },
    [
      _c(
        "div",
        { staticClass: "filter-container" },
        [
          _c("el-input", {
            staticClass: "filter-item",
            staticStyle: { width: "200px" },
            attrs: {
              placeholder: "SKU/EAN/" + _vm.$i18n.t("common.coding"),
              "controls-position": "right",
              oninput: "value=value.replace(/[%]/g,'')",
              clearable: ""
            },
            model: {
              value: _vm.query.idFuzzy,
              callback: function($$v) {
                _vm.$set(_vm.query, "idFuzzy", $$v)
              },
              expression: "query.idFuzzy"
            }
          }),
          _c("dataSelect", {
            staticClass: "filter-item",
            staticStyle: { width: "150px" },
            attrs: {
              clearable: "",
              codeType: "countrys",
              placeholder: _vm.$i18n.t("common.country")
            },
            model: {
              value: _vm.query.countryCode,
              callback: function($$v) {
                _vm.$set(_vm.query, "countryCode", $$v)
              },
              expression: "query.countryCode"
            }
          }),
          _c(
            "el-button",
            {
              staticClass: "filter-item",
              attrs: { icon: "el-icon-search", type: "primary" },
              on: { click: _vm.handleQuery }
            },
            [_vm._v(" " + _vm._s(_vm.$i18n.t("common.search")))]
          ),
          _c(
            "el-button",
            {
              staticClass: "filter-item",
              staticStyle: { "margin-left": "10px" },
              attrs: { type: "success", icon: "el-icon-edit" },
              on: {
                click: function($event) {
                  return _vm.handleClearQuery()
                }
              }
            },
            [
              _vm._v(
                "\n            " +
                  _vm._s(_vm.$i18n.t("common.Reset")) +
                  "\n        "
              )
            ]
          ),
          _c(
            "div",
            [
              _c(
                "el-button",
                {
                  staticClass: "filter-item",
                  staticStyle: { "margin-left": "10px", "margin-top": "10px" },
                  attrs: { type: "success" },
                  on: { click: _vm.handleAdd }
                },
                [
                  _vm._v(
                    "\n\n                " +
                      _vm._s(_vm.$i18n.t("common.Add")) +
                      "\n\n            "
                  )
                ]
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading"
            }
          ],
          ref: "multipleTable",
          staticStyle: { width: "100%", "margin-top": "10px" },
          attrs: {
            data: _vm.giftList,
            border: "",
            stripe: "",
            height: "calc(100% - 150px)"
          }
        },
        [
          _c("el-table-column", { attrs: { type: "selection", width: "50" } }),
          _c("el-table-column", {
            attrs: {
              label: _vm.$i18n.t("common.GiftID"),
              prop: "giftId",
              align: "center",
              width: "160"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [_c("span", [_vm._v(_vm._s(row.giftId))])]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              label: _vm.$i18n.t("common.Productname1"),
              prop: "title",
              align: "center",
              width: "300"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [_c("span", [_vm._v(_vm._s(row.title))])]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              label: "EAN",
              prop: "giftId",
              align: "center",
              width: "200px"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    _c("span", [_vm._v("SKU:" + _vm._s(row.sku))]),
                    _c("div", [_vm._v("EAN:" + _vm._s(row.barCode))]),
                    _c("div", [
                      _vm._v(
                        _vm._s(_vm.$i18n.t("common.coding")) +
                          ":" +
                          _vm._s(row.goodsCode)
                      )
                    ])
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              label: _vm.$i18n.t("common.country"),
              prop: "countryCodeName",
              align: "center",
              width: "100"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    _c("span", [
                      _vm._v(
                        _vm._s(
                          _vm.$dict.getDictValue("countrys", row.countryCode)
                        )
                      ),
                      _c("br")
                    ])
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              label: _vm.$i18n.t("common.CurrentInventory"),
              prop: "eComRemainQty",
              align: "center",
              width: "150"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [_c("span", [_vm._v(_vm._s(row.ecomRemainQty))])]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              prop: "enableStatus",
              label: _vm.$i18n.t("common.Enableornot"),
              align: "center",
              "show-overflow-tooltip": ""
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("el-switch", {
                      attrs: {
                        "active-color": "#13ce66",
                        "inactive-color": "#ff4949",
                        disabled: ""
                      },
                      model: {
                        value: scope.row.enableStatus,
                        callback: function($$v) {
                          _vm.$set(scope.row, "enableStatus", $$v)
                        },
                        expression: "scope.row.enableStatus"
                      }
                    })
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              prop: "updateName",
              label: _vm.$i18n.t("common.Operator"),
              align: "center",
              "show-overflow-tooltip": ""
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [_c("span", [_vm._v(_vm._s(row.updateName))])]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              prop: "updateTime",
              label: _vm.$i18n.t("common.Updatetime"),
              align: "center",
              "show-overflow-tooltip": ""
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("span", [
                      _vm._v(_vm._s(_vm._f("formatDate")(scope.row.updateTime)))
                    ])
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              label: _vm.$i18n.t("common.operation"),
              align: "center",
              width: "160"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    _c(
                      "el-button",
                      {
                        staticClass: "table-inner-button",
                        attrs: { type: "primary", size: "mini" },
                        on: {
                          click: function($event) {
                            return _vm.handleEdit(row)
                          }
                        }
                      },
                      [_vm._v(_vm._s(_vm.$i18n.t("common.edit")) + " ")]
                    ),
                    _c(
                      "el-button",
                      {
                        staticClass: "table-inner-button",
                        attrs: {
                          type: "danger",
                          size: "mini",
                          disabled: row.bindGiftActivity
                        },
                        on: {
                          click: function($event) {
                            return _vm.handleDel(row)
                          }
                        }
                      },
                      [_vm._v(_vm._s(_vm.$i18n.t("common.delete")) + " ")]
                    )
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _c("pagination", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.total > 0,
            expression: "total > 0"
          }
        ],
        attrs: {
          total: _vm.total,
          pageNo: _vm.query.current,
          pageSize: _vm.query.limit
        },
        on: {
          "update:pageNo": function($event) {
            return _vm.$set(_vm.query, "current", $event)
          },
          "update:page-no": function($event) {
            return _vm.$set(_vm.query, "current", $event)
          },
          "update:pageSize": function($event) {
            return _vm.$set(_vm.query, "limit", $event)
          },
          "update:page-size": function($event) {
            return _vm.$set(_vm.query, "limit", $event)
          },
          pagination: _vm.doQueryList
        }
      }),
      _vm.dialogFormVisible
        ? _c(
            "el-dialog",
            {
              attrs: {
                title: _vm.dialogTitle,
                visible: _vm.dialogFormVisible,
                width: "600px"
              },
              on: {
                "update:visible": function($event) {
                  _vm.dialogFormVisible = $event
                }
              }
            },
            [
              _c(
                "el-form",
                {
                  ref: "dataForm",
                  staticStyle: { width: "400px", "margin-left": "50px" },
                  attrs: {
                    model: _vm.form,
                    rules: _vm.rules,
                    "label-position": "left",
                    size: "small",
                    "label-width": "110px"
                  }
                },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: _vm.$i18n.t("common.Enableornot"),
                        prop: "enableStatus"
                      }
                    },
                    [
                      _c("el-switch", {
                        attrs: {
                          "active-color": "#13ce66",
                          "inactive-color": "#ff4949"
                        },
                        model: {
                          value: _vm.form.enableStatus,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "enableStatus", $$v)
                          },
                          expression: "form.enableStatus"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: _vm.$i18n.t("common.GoodsForFree"),
                        required: true
                      }
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "box" },
                        [
                          _c(
                            "el-tooltip",
                            {
                              attrs: {
                                effect: "light",
                                content: _vm.text,
                                disabled: _vm.isTooltip
                              }
                            },
                            [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.text,
                                    expression: "text"
                                  }
                                ],
                                staticClass: "form-control text-ellipsis",
                                staticStyle: { "text-indent": "15px" },
                                attrs: {
                                  type: "text",
                                  placeholder: _vm.$i18n.t("common.Barcode"),
                                  disabled: true
                                },
                                domProps: { value: _vm.text },
                                on: {
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.text = $event.target.value
                                  }
                                }
                              })
                            ]
                          ),
                          _vm.showSelectGoods
                            ? _c(
                                "el-button",
                                {
                                  staticStyle: { "margin-left": "10px" },
                                  attrs: { type: "primary" },
                                  on: { click: _vm.showGoodsList }
                                },
                                [_vm._v(_vm._s(_vm.$t("common.Selectproduct")))]
                              )
                            : _vm._e()
                        ],
                        1
                      )
                    ]
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: _vm.$i18n.t("common.country"),
                        prop: "countryCode"
                      }
                    },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: {
                            placeholder: _vm.$i18n.t(
                              "common.Pleaseselectacountry"
                            ),
                            disabled: _vm.countryDisabled
                          },
                          model: {
                            value: _vm.form.countryCode,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "countryCode", $$v)
                            },
                            expression: "form.countryCode"
                          }
                        },
                        _vm._l(this.countrys, function(item) {
                          return _c("el-option", {
                            key: item.id,
                            attrs: {
                              label: item.name,
                              value: item.code,
                              disabled: item.disabled
                            }
                          })
                        }),
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "div",
                {
                  staticClass: "dialog-footer",
                  attrs: { slot: "footer" },
                  slot: "footer"
                },
                [
                  _c(
                    "el-button",
                    {
                      on: {
                        click: function($event) {
                          _vm.dialogFormVisible = false
                        }
                      }
                    },
                    [_vm._v(_vm._s(_vm.$i18n.t("common.cancel")))]
                  ),
                  _c(
                    "el-button",
                    { attrs: { type: "primary" }, on: { click: _vm.saveGift } },
                    [_vm._v(_vm._s(_vm.$i18n.t("common.confirm1")))]
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _vm.goodsVisible
        ? _c(
            "el-dialog",
            {
              attrs: {
                title: _vm.$i18n.t("common.Pleaseselectaproduct"),
                visible: _vm.goodsVisible,
                "close-on-click-modal": false,
                width: "1200px"
              },
              on: {
                "update:visible": function($event) {
                  _vm.goodsVisible = $event
                },
                close: function($event) {
                  return _vm.closeGoodsForm()
                }
              }
            },
            [
              _c("goodsDialog", {
                ref: "goodsDialog",
                on: { handleSelectGoods: _vm.handleSelectGoods }
              })
            ],
            1
          )
        : _vm._e(),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.$t(
              "common.Pleasecompletetheproductinformationbeforelistingit"
            ),
            visible: _vm.dialogtipsVisible,
            "close-on-click-modal": false,
            "append-to-body": ""
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogtipsVisible = $event
            }
          }
        },
        [
          _vm.dialogtipsVisible
            ? _c(
                "div",
                [
                  _vm._l(_vm.tipsList, function(item, index) {
                    return _c("div", { key: index }, [
                      _vm._v(_vm._s(index + 1) + ":" + _vm._s(item))
                    ])
                  }),
                  _c(
                    "span",
                    {
                      staticClass: "dialog-footer",
                      attrs: { slot: "footer" },
                      slot: "footer"
                    },
                    [
                      _c(
                        "el-button",
                        {
                          staticStyle: { width: "20%" },
                          on: {
                            click: function($event) {
                              _vm.dialogtipsVisible = false
                            }
                          }
                        },
                        [_vm._v(_vm._s(_vm.$t("common.cancel")))]
                      ),
                      _c(
                        "el-button",
                        {
                          staticStyle: { width: "20%" },
                          attrs: { type: "primary" },
                          on: {
                            click: function($event) {
                              _vm.dialogtipsVisible = false
                            }
                          }
                        },
                        [_vm._v(_vm._s(_vm.$t("common.confirm1")))]
                      )
                    ],
                    1
                  )
                ],
                2
              )
            : _vm._e()
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }