var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-upload",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading.fullscreen.lock",
              value: _vm.fullscreenLoading,
              expression: "fullscreenLoading",
              modifiers: { fullscreen: true, lock: true }
            }
          ],
          ref: "uploadImg",
          staticClass: "upload-demo",
          attrs: {
            action: _vm.ossInfo.ossSignature.host,
            "show-file-list": false,
            "auto-upload": false,
            disabled: _vm.percent > 0,
            "on-progress": _vm.uploadVideoProcess,
            "before-upload": _vm.beforeAvatarUpload,
            "list-type": "picture-card",
            "on-change": _vm.handleChange,
            data: _vm.fileDataObj,
            accept: _vm.accept
          }
        },
        [_vm._t("default")],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }