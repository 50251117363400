var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: _vm.$i18n.t("common.ViewInformationServices"),
        visible: _vm.formVisible
      },
      on: {
        "update:visible": function($event) {
          _vm.formVisible = $event
        }
      }
    },
    [
      _c(
        "el-form",
        {
          ref: "dataForm",
          staticStyle: { width: "400px", "margin-left": "50px" },
          attrs: {
            model: _vm.form,
            "label-position": "left",
            size: "small",
            "label-width": "100px"
          }
        },
        [
          _c("el-form-item", { attrs: { label: "Id" } }, [
            _c("span", { staticClass: "form-item-show" }, [
              _vm._v("\n        " + _vm._s(_vm.form.informationid) + "\n      ")
            ])
          ]),
          _c(
            "el-form-item",
            { attrs: { label: _vm.$i18n.t("common.delete") } },
            [
              _c("span", { staticClass: "form-item-show" }, [
                _vm._v("\n        " + _vm._s(_vm.form.sydelete) + "\n      ")
              ])
            ]
          ),
          _c(
            "el-form-item",
            { attrs: { label: _vm.$i18n.t("common.ColumnName") } },
            [
              _c("span", { staticClass: "form-item-show" }, [
                _vm._v("\n        " + _vm._s(_vm.form.name) + "\n      ")
              ])
            ]
          ),
          _c(
            "el-form-item",
            { attrs: { label: _vm.$i18n.t("common.title") } },
            [
              _c("span", { staticClass: "form-item-show" }, [
                _vm._v("\n        " + _vm._s(_vm.form.title) + "\n      ")
              ])
            ]
          ),
          _c(
            "el-form-item",
            { attrs: { label: _vm.$i18n.t("common.Creationtime") } },
            [
              _c("span", { staticClass: "form-item-show" }, [
                _vm._v("\n        " + _vm._s(_vm.form.createtime) + "\n      ")
              ])
            ]
          ),
          _c(
            "el-form-item",
            { attrs: { label: _vm.$i18n.t("common.Modificationtime") } },
            [
              _c("span", { staticClass: "form-item-show" }, [
                _vm._v("\n        " + _vm._s(_vm.form.updatetime) + "\n      ")
              ])
            ]
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: _vm.$i18n.t(
                  "common.Isitdisplayedatthebottomofthewebsite"
                )
              }
            },
            [
              _c("span", { staticClass: "form-item-show" }, [
                _vm._v(
                  "\n        " + _vm._s(_vm.form.isshowbottom) + "\n      "
                )
              ])
            ]
          ),
          _c("el-form-item", { attrs: { label: _vm.$i18n.t("common.sort") } }, [
            _c("span", { staticClass: "form-item-show" }, [
              _vm._v("\n        " + _vm._s(_vm.form.sort) + "\n      ")
            ])
          ]),
          _c("el-form-item", { attrs: { label: "SyStamp" } }, [
            _c("span", { staticClass: "form-item-show" }, [
              _vm._v("\n        " + _vm._s(_vm.form.systamp) + "\n      ")
            ])
          ]),
          _c("el-form-item", { attrs: { label: "TenantId" } }, [
            _c("span", { staticClass: "form-item-show" }, [
              _vm._v("\n        " + _vm._s(_vm.form.tenantid) + "\n      ")
            ])
          ])
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer"
        },
        [
          _c(
            "el-button",
            {
              on: {
                click: function($event) {
                  _vm.formVisible = false
                }
              }
            },
            [
              _vm._v(
                "\n      " + _vm._s(_vm.$i18n.t("common.cancel")) + "\n    "
              )
            ]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }