<template> 
    <div>
        <el-form :model="formData"   ref="variantFrom"  >
            
            <el-form-item label='' :label-width='formLabelWidth'>
                <div>{{ $i18n.t("common.CommenOnPicturesVideos") }}（{{ $i18n.t("common.PicturesVideos") }}）</div>

                <div  class="avatar-uploader" >
                    <ul class="el-upload-list el-upload-list--picture-card">
                        <li tabindex="0" class="el-upload-list__item is-success"  v-for="(item, idx) in imageList" :key="idx" >
                            <div>

                                <video v-if="item.fileType=='Video' && item.fileUrl " class='float-l' style='width: 178px;height: 178px;'  :src='item.fileUrl'
                                    controls='controls'>
                                    {{ $i18n.t("common.YourBrowserDoesNotSupportVideoPlayback") }}
                                </video>
                            
                                <img
                                    v-else
                                    class="el-upload-list__item-thumbnail img-center"
                                    :src="item.fileUrl + '?x-oss-process=image/auto-orient,1/resize,m_fixed,h_200,w_200/format,webp'" alt=""
                                >
                     
                                <span class="el-upload-list__item-actions">
                                    <span
                                    class="el-upload-list__item-preview"
                                    @click="handlePictureCardPreview(item,idx)"
                                    >
                                    <i class="el-icon-zoom-in"></i>
                                    </span>
                         
                                    <span
                                    class="el-upload-list__item-delete"
                                    @click="handleRemove(item,idx)"
                                    >
                                    <i class="el-icon-delete"></i>
                                    </span>
                                </span>
                            </div>  
                        </li>
                        <li class="el-upload-list__item is-success" v-if="imageList.length < 10">
                           
                            <fileUpload ref="fileUpload"   @uploadSuccess='uploadSuccess'   > </fileUpload>  
                        </li>
                    </ul>

                </div>
  
            </el-form-item>
 
        </el-form>


        <div slot="footer" class="dialog-footer">
			<el-button style="width: 20%;" @click="closeDialog(false)"> {{ $i18n.t("common.cancel") }}</el-button>
			<el-button style="width: 20%;" type="primary" @click="onSubmit()"> {{ $i18n.t("common.confirm") }}</el-button>
		</div>



        <el-dialog  :append-to-body="true" width="700px" v-if="dialogVisible" :visible.sync="dialogVisible">
            <img width="100%"   :src="dialogImageUrl" >
        </el-dialog>

        <el-dialog  :append-to-body="true" width="700px" style='height: 700px;'  v-if="dialogVideoVisible" :visible.sync="dialogVideoVisible">

            <video style='width: 90%;'  :src='dialogImageUrl' autoplay="true"
                controls='controls'>
                {{ $i18n.t("common.YourBrowserDoesNotSupportVideoPlayback") }}
            </video>

        </el-dialog>


    </div>
</template>
<script>
 
 
import fileUpload from './fileUpload.vue';

 
export default {
    components: {
        fileUpload
    },
    props: {
        formData: {
            type: Object,
            default: () => {
                return {}
            }
        },
        imageList: {
            type: Array,
            default: () => {
                return []
            }
        }
    },
    data() {
        return {
            loading: false,
            formLabelWidth: '40px',
            // 图片预览
            dialogImageUrl: '',
            dialogVisible: false,
            dialogVideoVisible: false,
             
        };
    },
    created(){
        
    },
    mounted() {
        
    },
    methods: {
        // 图片删除
        handleRemove(file,idx) {
            this.imageList.splice(idx, 1);
        },
        // 图片预览
        handlePictureCardPreview(file) {
            this.dialogImageUrl = file.fileUrl;
            if(file.fileType == 'Video'){
                this.dialogVideoVisible = true;
            }else{
                this.dialogVisible = true;
            }
        },

        uploadSuccess(url,type){
            console.log('uploadSuccess',url,type)
            let item = {
                fileUrl: url,
                fileType: type
            }
            this.imageList.push(item);
        },
        closeDialog(status) {
            this.$emit('closeDialog',status);
        },
        onSubmit(){
            let param = {
                commentId: this.formData.commentId,
                commentFileList: this.imageList
            }
          
            this.$confirm(this.$i18n.t("common.Whethertosubmitdata"),this.$i18n.t("common.prompt"), {
                // confirmButtonText: '确定',
                // cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {   
                this.loading=true
                this.$store.dispatch('putCommentFile', param).then(response => {
                    this.$message({
                        message: this.$i18n.t("common.Successfullysaved"),
                        type: 'success',
                        duration: 1000
                    });
                    this.closeDialog(true);
                }).finally(() => {
                    this.loading = false;
                });            
                   
            }).catch(() => {});
 
        }
    }
};
</script>
 

<style scoped> 
.dialog-footer {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 30px;
}
</style>