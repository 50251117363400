var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.currencyVisible
    ? _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.$i18n.t("common.AIGOCoinDetails"),
            visible: _vm.currencyVisible,
            width: "492px",
            "close-on-click-modal": false
          },
          on: {
            close: function($event) {
              _vm.currencyVisible = false
            }
          }
        },
        [
          _c(
            "el-table",
            {
              staticStyle: { width: "100%" },
              attrs: {
                data: _vm.currency,
                border: "",
                stripe: "",
                size: "small"
              }
            },
            [
              _c("el-table-column", {
                attrs: {
                  type: "index",
                  label: _vm.$i18n.t("common.Serialnumber"),
                  align: "center",
                  width: "150px"
                }
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "amount",
                  label: _vm.$i18n.t("common.TheRemainingAmount"),
                  align: "center",
                  width: "150px"
                }
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "expireTime",
                  label: _vm.$i18n.t("common.ValidityPeriod"),
                  width: "150px",
                  align: "center"
                }
              })
            ],
            1
          ),
          _c(
            "span",
            [
              _c(
                "el-button",
                {
                  staticStyle: { "margin-left": "200px", "margin-top": "20px" },
                  attrs: { size: "medium" },
                  on: {
                    click: function($event) {
                      _vm.currencyVisible = false
                    }
                  }
                },
                [_vm._v("取 消")]
              )
            ],
            1
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }