var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "app-container",
      staticStyle: { height: "100%", "min-height": "350px" }
    },
    [
      _c(
        "div",
        { staticClass: "filter-container" },
        [
          _c("el-input", {
            staticClass: "filter-item",
            staticStyle: { width: "200px" },
            attrs: {
              placeholder: "SKU/EAN/" + _vm.$i18n.t("common.coding"),
              "controls-position": "right",
              oninput: "value=value.replace(/[%]/g,'')",
              clearable: ""
            },
            model: {
              value: _vm.query.barCode,
              callback: function($$v) {
                _vm.$set(_vm.query, "barCode", $$v)
              },
              expression: "query.barCode"
            }
          }),
          _c("el-input", {
            staticClass: "filter-item",
            staticStyle: { width: "200px" },
            attrs: {
              placeholder: _vm.$i18n.t("common.ProductID1"),
              oninput: "value=value.replace(/[^0-9]/g,'')",
              maxlength: "19",
              "controls-position": "right",
              clearable: ""
            },
            model: {
              value: _vm.query.goodId,
              callback: function($$v) {
                _vm.$set(_vm.query, "goodId", $$v)
              },
              expression: "query.goodId"
            }
          }),
          _c("el-input", {
            staticClass: "filter-item",
            staticStyle: { width: "200px" },
            attrs: {
              placeholder: _vm.$i18n.t("common.CommentID"),
              oninput: "value=value.replace(/[^0-9]/g,'')",
              maxlength: "19",
              "controls-position": "right",
              clearable: ""
            },
            model: {
              value: _vm.query.commentId,
              callback: function($$v) {
                _vm.$set(_vm.query, "commentId", $$v)
              },
              expression: "query.commentId"
            }
          }),
          _c("el-input", {
            staticClass: "filter-item",
            staticStyle: { width: "200px" },
            attrs: {
              clearable: "",
              placeholder: _vm.$i18n.t("common.Useremail"),
              "controls-position": "right",
              oninput: "value=value.replace(/[%]/g,'')"
            },
            model: {
              value: _vm.query.email,
              callback: function($$v) {
                _vm.$set(_vm.query, "email", $$v)
              },
              expression: "query.email"
            }
          }),
          _c(
            "el-select",
            {
              staticClass: "filter-item",
              staticStyle: { width: "150px" },
              attrs: {
                placeholder: _vm.$i18n.t("common.RatingStarRating"),
                clearable: ""
              },
              model: {
                value: _vm.query.score,
                callback: function($$v) {
                  _vm.$set(_vm.query, "score", $$v)
                },
                expression: "query.score"
              }
            },
            _vm._l(5, function(index) {
              return _c("el-option", {
                key: index,
                attrs: {
                  label: index + _vm.$i18n.t("common.star0"),
                  value: index
                }
              })
            }),
            1
          ),
          _c("dataSelect", {
            staticClass: "filter-item",
            staticStyle: { width: "150px" },
            attrs: {
              clearable: "",
              codeType: "countrys",
              placeholder: _vm.$i18n.t("common.country")
            },
            model: {
              value: _vm.query.countryCode,
              callback: function($$v) {
                _vm.$set(_vm.query, "countryCode", $$v)
              },
              expression: "query.countryCode"
            }
          }),
          _c(
            "el-select",
            {
              staticClass: "filter-item",
              staticStyle: { width: "150px" },
              attrs: {
                placeholder: _vm.$i18n.t("common.Auditstatus"),
                clearable: ""
              },
              model: {
                value: _vm.query.status,
                callback: function($$v) {
                  _vm.$set(_vm.query, "status", $$v)
                },
                expression: "query.status"
              }
            },
            [
              _c("el-option", {
                attrs: {
                  label: _vm.$i18n.t("common.Unaudited"),
                  value: "NOT_APPROVED"
                }
              }),
              _c("el-option", {
                attrs: { label: _vm.$i18n.t("common.Passed"), value: "ADOPT" }
              }),
              _c("el-option", {
                attrs: { label: _vm.$i18n.t("common.Failed"), value: "FAIL" }
              })
            ],
            1
          ),
          _c("dataSelect", {
            staticClass: "filter-item",
            staticStyle: { width: "150px" },
            attrs: {
              clearable: "",
              codeType: "comment_source",
              placeholder: _vm.$i18n.t("common.source")
            },
            model: {
              value: _vm.query.source,
              callback: function($$v) {
                _vm.$set(_vm.query, "source", $$v)
              },
              expression: "query.source"
            }
          }),
          _c("el-date-picker", {
            staticClass: "filter-item",
            attrs: {
              type: "datetimerange",
              "picker-options": _vm.pickerOptions,
              "range-separator": _vm.$i18n.t("common.to"),
              "start-placeholder": _vm.$i18n.t("common.Commentstartdate"),
              "end-placeholder": _vm.$i18n.t("common.CommentEndDate"),
              format: "yyyy-MM-dd HH:mm:ss",
              "value-format": "yyyy-MM-dd HH:mm:ss",
              align: "right"
            },
            model: {
              value: _vm.dateArr,
              callback: function($$v) {
                _vm.dateArr = $$v
              },
              expression: "dateArr"
            }
          }),
          _c(
            "el-button",
            {
              staticClass: "filter-item",
              attrs: { icon: "el-icon-search", type: "primary" },
              on: { click: _vm.handleQuery }
            },
            [_vm._v(" " + _vm._s(_vm.$i18n.t("common.search")))]
          ),
          _c(
            "el-button",
            {
              staticClass: "filter-item",
              staticStyle: { "margin-left": "10px" },
              attrs: { type: "success", icon: "el-icon-edit" },
              on: {
                click: function($event) {
                  return _vm.handleClearQuery()
                }
              }
            },
            [
              _vm._v(
                "\n                " +
                  _vm._s(_vm.$i18n.t("common.Reset")) +
                  "\n            "
              )
            ]
          ),
          _c(
            "el-button",
            {
              staticClass: "filter-item",
              staticStyle: { "margin-left": "10px" },
              attrs: { type: "primary" },
              on: { click: _vm.handleDeleteBatch }
            },
            [_vm._v(" " + _vm._s(_vm.$i18n.t("common.Batchreview")) + " ")]
          ),
          _c(
            "el-button",
            {
              staticClass: "filter-item",
              staticStyle: { "margin-left": "10px" },
              attrs: { type: "primary", disabled: _vm.delBatchDisabled },
              on: { click: _vm.handleDelBatch }
            },
            [_vm._v(" " + _vm._s(_vm.$i18n.t("common.Batchdeletion")) + " ")]
          ),
          _c(
            "el-button",
            {
              staticStyle: {
                float: "right",
                "margin-right": "30px",
                "margin-top": "10px"
              },
              attrs: { icon: "el-icon-upload2", type: "primary" },
              on: { click: _vm.handleImport }
            },
            [_vm._v(" " + _vm._s(_vm.$i18n.t("common.BatchInput")) + " ")]
          ),
          _c(
            "el-button",
            {
              staticClass: "filter-item",
              staticStyle: { float: "right" },
              attrs: { type: "primary" },
              on: { click: _vm.handleDownloadTemplate }
            },
            [
              _vm._v(
                " " + _vm._s(_vm.$i18n.t("common.DownloadImportTemplate")) + " "
              )
            ]
          )
        ],
        1
      ),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading"
            }
          ],
          ref: "multipleTable",
          staticStyle: { width: "100%", "margin-top": "10px" },
          attrs: {
            data: _vm.list,
            border: "",
            stripe: "",
            height: "calc(100% - 170px)"
          },
          on: { "selection-change": _vm.selectionChange }
        },
        [
          _c("el-table-column", { attrs: { type: "selection", width: "50" } }),
          _c("el-table-column", {
            attrs: {
              label: "ID",
              prop: "commentid",
              align: "center",
              width: "150"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [_c("span", [_vm._v(_vm._s(row.commentId))])]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              label: _vm.$i18n.t("common.Productimage"),
              prop: "commentid",
              align: "center",
              width: "120px"
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "default",
                  fn: function(ref) {
                    var row = ref.row
                    return row.goodsListDto != null
                      ? [
                          _c("listImage", {
                            attrs: { imgUrl: row.goodsListDto.imageUrl }
                          })
                        ]
                      : undefined
                  }
                }
              ],
              null,
              true
            )
          }),
          _c("el-table-column", {
            attrs: {
              label: _vm.$i18n.t("common.ProductInformation"),
              prop: "commentid",
              align: "center",
              width: "200px"
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "default",
                  fn: function(ref) {
                    var row = ref.row
                    return row.goodsListDto != null
                      ? [
                          _c("span", [
                            _vm._v(
                              _vm._s(_vm.$i18n.t("common.name")) +
                                ":" +
                                _vm._s(row.goodsListDto.title)
                            )
                          ]),
                          _c("div", [
                            _vm._v(
                              _vm._s(_vm.$i18n.t("common.ProductID1")) +
                                ":" +
                                _vm._s(row.goodsListDto.goodsId)
                            )
                          ]),
                          _c("div", [
                            _vm._v(
                              _vm._s(_vm.$i18n.t("common.barcode")) +
                                ":" +
                                _vm._s(row.goodsListDto.barCode)
                            )
                          ])
                        ]
                      : undefined
                  }
                }
              ],
              null,
              true
            )
          }),
          _c("el-table-column", {
            attrs: {
              label: _vm.$i18n.t("common.Evaluationcontent"),
              prop: "content",
              align: "center",
              width: "250px"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    _c(
                      "span",
                      [
                        _c("el-rate", {
                          attrs: {
                            disabled: "",
                            "show-score": "",
                            "text-color": "#ff9900",
                            "score-template": "{value}"
                          },
                          model: {
                            value: row.score,
                            callback: function($$v) {
                              _vm.$set(row, "score", $$v)
                            },
                            expression: "row.score"
                          }
                        }),
                        _c("span", [_vm._v(_vm._s(row.content))])
                      ],
                      1
                    ),
                    _c("div", [
                      row.imgFileList && row.imgFileList.length > 0
                        ? _c(
                            "span",
                            {
                              on: {
                                click: function($event) {
                                  return _vm.checkImg(row)
                                }
                              }
                            },
                            [
                              _vm._v(
                                "\n                            " +
                                  _vm._s(
                                    row.imgFileList != null
                                      ? row.imgFileList.length
                                      : 0
                                  ) +
                                  _vm._s(_vm.$i18n.t("common.picture")) +
                                  "\n                        "
                              )
                            ]
                          )
                        : _c("span", [
                            _vm._v(
                              " 0 " +
                                _vm._s(_vm.$i18n.t("common.picture")) +
                                " "
                            )
                          ]),
                      row.videoFileList && row.videoFileList.length > 0
                        ? _c(
                            "span",
                            {
                              on: {
                                click: function($event) {
                                  return _vm.checkVideo(row)
                                }
                              }
                            },
                            [
                              _vm._v(
                                "\n                            " +
                                  _vm._s(
                                    row.videoFileList != null
                                      ? row.videoFileList.length
                                      : 0
                                  ) +
                                  _vm._s(_vm.$i18n.t("common.video")) +
                                  "\n                        "
                              )
                            ]
                          )
                        : _c("span", [
                            _vm._v(
                              " 0 " + _vm._s(_vm.$i18n.t("common.video")) + " "
                            )
                          ])
                    ])
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              label: _vm.$i18n.t("common.ReviewerId"),
              prop: "auditby",
              align: "center",
              width: "150"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [_c("span", [_vm._v(_vm._s(row.auditBy))])]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              label: _vm.$i18n.t("common.user"),
              prop: "auditby",
              align: "center",
              width: "200px"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    row.source == "manualimport"
                      ? _c("div", [
                          _c("div", [
                            _vm._v(
                              _vm._s(_vm.$i18n.t("common.UserName")) +
                                ":" +
                                _vm._s(row.userName)
                            )
                          ]),
                          _c("div", [
                            _vm._v(
                              _vm._s(_vm.$i18n.t("common.country")) +
                                ":" +
                                _vm._s(row.countryCode)
                            )
                          ])
                        ])
                      : _vm._e(),
                    row.source != "manualimport"
                      ? _c("div", [
                          _c("div", [_vm._v("ID:" + _vm._s(row.userId))]),
                          _c("div", [
                            _vm._v(
                              _vm._s(_vm.$i18n.t("common.mailbox")) +
                                ":" +
                                _vm._s(row.userEmail)
                            )
                          ]),
                          _c("div", [
                            _vm._v(
                              _vm._s(_vm.$i18n.t("common.country")) +
                                ":" +
                                _vm._s(row.countryCode)
                            )
                          ])
                        ])
                      : _vm._e()
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              label: _vm.$i18n.t("common.source"),
              prop: "source",
              align: "center"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    _c("dataText", {
                      staticClass: "filter-item",
                      attrs: { codeType: "comment_source" },
                      model: {
                        value: row.source,
                        callback: function($$v) {
                          _vm.$set(row, "source", $$v)
                        },
                        expression: "row.source"
                      }
                    })
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              label: _vm.$i18n.t("common.Auditstatus"),
              prop: "status",
              align: "center"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    _c(
                      "el-tag",
                      {
                        attrs: {
                          type:
                            row.status == "ADOPT"
                              ? "success"
                              : row.status == "FAIL"
                              ? "danger"
                              : "warning"
                        }
                      },
                      [
                        _vm._v(
                          "\n                        " +
                            _vm._s(
                              row.status == "ADOPT"
                                ? _vm.$i18n.t("common.adopt")
                                : row.status == "FAIL"
                                ? _vm.$i18n.t("common.Notpassed")
                                : _vm.$i18n.t("common.Pendingreview")
                            ) +
                            "\n                    "
                        )
                      ]
                    )
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              label: _vm.$i18n.t("common.Evaluationtime"),
              prop: "createTime",
              align: "center",
              width: "150px"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    _c("span", [
                      _vm._v(_vm._s(_vm._f("formatDate")(row.createTime)))
                    ])
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              label: _vm.$i18n.t("common.Audittime"),
              prop: "audittime",
              align: "center",
              width: "150px"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    _c("span", [
                      _vm._v(_vm._s(_vm._f("formatDate")(row.auditTime)))
                    ])
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              label: _vm.$i18n.t("common.reason"),
              prop: "description",
              align: "center",
              width: "300"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [_c("span", [_vm._v(_vm._s(row.description))])]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              label: _vm.$i18n.t("common.ReviewerName"),
              prop: "auditbyname",
              align: "center"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [_c("span", [_vm._v(_vm._s(row.auditByName))])]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              label: _vm.$i18n.t("common.operation"),
              align: "center",
              width: "240",
              fixed: "right"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    _c(
                      "el-button",
                      {
                        staticClass: "table-inner-button",
                        attrs: { type: "primary", size: "mini" },
                        on: {
                          click: function($event) {
                            return _vm.handleEdit(row)
                          }
                        }
                      },
                      [_vm._v(_vm._s(_vm.$i18n.t("common.edit")) + " ")]
                    ),
                    _c(
                      "el-button",
                      {
                        staticClass: "table-inner-button",
                        attrs: { type: "primary", size: "mini" },
                        on: {
                          click: function($event) {
                            return _vm.handleAudit(row)
                          }
                        }
                      },
                      [_vm._v(_vm._s(_vm.$i18n.t("common.Auditing")) + " ")]
                    ),
                    _c(
                      "el-button",
                      {
                        staticClass: "table-inner-button",
                        attrs: {
                          type: "danger",
                          size: "mini",
                          disabled: !(
                            row.source == "amazon" ||
                            row.source == "manualimport"
                          )
                        },
                        on: {
                          click: function($event) {
                            return _vm.handleDel(row)
                          }
                        }
                      },
                      [_vm._v(_vm._s(_vm.$i18n.t("common.delete")) + " ")]
                    )
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "pagination-container" },
        [
          _c("el-pagination", {
            attrs: {
              background: _vm.background,
              "current-page": _vm.query.current,
              "page-size": _vm.query.limit,
              layout: "total, sizes, prev, pager, next, jumper",
              "page-sizes": _vm.pageSizes,
              total: _vm.total
            },
            on: {
              "size-change": _vm.handleSizeChange,
              "current-change": _vm.handleCurrentChange
            }
          })
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.$i18n.t("common.Auditing"),
            visible: _vm.dialogFormVisible,
            width: "600px"
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogFormVisible = $event
            }
          }
        },
        [
          _c(
            "el-form",
            { attrs: { model: _vm.form } },
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: _vm.$i18n.t("common.Numberofselectedcomments")
                  }
                },
                [_c("span", [_vm._v(_vm._s(_vm.selectItems.length))])]
              ),
              _c(
                "el-form-item",
                { attrs: { label: _vm.$i18n.t("common.CommentID") } },
                _vm._l(_vm.selectItems, function(item, key) {
                  return _c(
                    "el-tag",
                    { key: key, staticStyle: { "margin-right": "5px" } },
                    [
                      _vm._v(
                        "\n                        " +
                          _vm._s(item.commentId) +
                          "\n                    "
                      )
                    ]
                  )
                }),
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: _vm.$i18n.t("common.result") } },
                [
                  _c(
                    "el-radio",
                    {
                      attrs: { label: "ADOPT" },
                      model: {
                        value: _vm.form.status,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "status", $$v)
                        },
                        expression: "form.status"
                      }
                    },
                    [_vm._v(_vm._s(_vm.$i18n.t("common.adopt")))]
                  ),
                  _c(
                    "el-radio",
                    {
                      attrs: { label: "FAIL" },
                      model: {
                        value: _vm.form.status,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "status", $$v)
                        },
                        expression: "form.status"
                      }
                    },
                    [_vm._v(_vm._s(_vm.$i18n.t("common.Notpassed")))]
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: _vm.$i18n.t("common.reason") } },
                [
                  _c("el-input", {
                    staticStyle: { width: "500px" },
                    attrs: {
                      type: "textarea",
                      placeholder: _vm.$i18n.t("common.Pleaseentercontent"),
                      maxlength: "300",
                      "show-word-limit": ""
                    },
                    model: {
                      value: _vm.form.description,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "description", $$v)
                      },
                      expression: "form.description"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function($event) {
                      _vm.dialogFormVisible = false
                    }
                  }
                },
                [_vm._v(_vm._s(_vm.$i18n.t("common.cancel")))]
              ),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.audit } },
                [_vm._v(_vm._s(_vm.$i18n.t("common.confirm1")))]
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.$i18n.t("common.Picturepreview"),
            visible: _vm.dialogImgVisible,
            width: "30%"
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogImgVisible = $event
            }
          }
        },
        _vm._l(_vm.imgList, function(item, index) {
          return _c(
            "div",
            { key: index },
            [_c("el-image", { attrs: { src: item.fileUrl, fit: "fit" } })],
            1
          )
        }),
        0
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.$i18n.t("common.VideoPreview"),
            visible: _vm.dialogVideoVisible,
            width: "30%"
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogVideoVisible = $event
            }
          }
        },
        _vm._l(_vm.videoList, function(item, index) {
          return _c("div", { key: index }, [
            _c("video", {
              staticStyle: { width: "100%" },
              attrs: { src: item.fileUrl, controls: "", autoplay: "" }
            })
          ])
        }),
        0
      ),
      _c(
        "el-dialog",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.importFormLoading,
              expression: "importFormLoading"
            }
          ],
          attrs: {
            title: _vm.$i18n.t("common.BatchInputOfProductComments"),
            visible: _vm.importFormVisible,
            width: "400px"
          },
          on: {
            "update:visible": function($event) {
              _vm.importFormVisible = $event
            }
          }
        },
        [
          _c(
            "el-upload",
            {
              attrs: {
                drag: "",
                action: _vm.importUrl,
                "on-success": _vm.onImportSuccess,
                "on-progress": _vm.onImportProgress,
                "on-error": _vm.onImportError,
                "show-file-list": false,
                headers: _vm.headerData,
                accept: ".xlsx, .xls"
              }
            },
            [
              _c("i", { staticClass: "el-icon-upload" }),
              _c("div", { staticClass: "el-upload__text" }, [
                _vm._v(
                  _vm._s(
                    _vm.$i18n.t("common.DragtheExcelfilehere_orclickonupload")
                  )
                )
              ])
            ]
          )
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.excelDataLoading,
              expression: "excelDataLoading"
            }
          ],
          attrs: {
            title: _vm.$i18n.t("common.BatchInputOfProductComments"),
            visible: _vm.excelDataVisible,
            "close-on-click-modal": false
          },
          on: {
            "update:visible": function($event) {
              _vm.excelDataVisible = $event
            }
          }
        },
        [
          _c(
            "el-table",
            {
              staticStyle: { width: "100%" },
              attrs: { data: _vm.excelData, border: "", stripe: "" }
            },
            [
              _c("el-table-column", {
                attrs: { prop: "id", label: _vm.$i18n.t("common.Serialnumber") }
              }),
              _c("el-table-column", {
                attrs: { prop: "barCode", label: "EAN" }
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "countryCode",
                  label: _vm.$i18n.t("common.country")
                }
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "userName",
                  label: _vm.$i18n.t("common.UserName")
                }
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "content",
                  label: _vm.$i18n.t("common.Comments")
                }
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "score",
                  label: _vm.$i18n.t("common.StarRatingOfTheComment")
                }
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "createTime",
                  label: _vm.$i18n.t("common.CommentTime"),
                  width: "150px"
                }
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "fileCount",
                  label: _vm.$i18n.t("common.NumberOfAttachments")
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(ref) {
                      var row = ref.row
                      return [
                        _c(
                          "a",
                          {
                            staticStyle: { color: "blue", cursor: "pointer" },
                            on: {
                              click: function($event) {
                                return _vm.showFileInfo(row)
                              }
                            }
                          },
                          [_vm._v(_vm._s(row.fileCount))]
                        )
                      ]
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$i18n.t("common.operation"),
                  align: "center",
                  width: "130px",
                  fixed: "right"
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(ref) {
                      var row = ref.row
                      return [
                        _c(
                          "div",
                          {
                            staticStyle: {
                              display: "flex",
                              "justify-content": "flex-start",
                              "margin-bottom": "20px",
                              "flex-wrap": "wrap"
                            }
                          },
                          [
                            _c(
                              "oss-upload",
                              {
                                staticStyle: { "margin-left": "10px" },
                                attrs: {
                                  itT: "itTV",
                                  accept: "jpg, .jpeg, .png, .mp4",
                                  imgInfo: _vm.fileInfo
                                },
                                on: {
                                  "update:imgInfo": function($event) {
                                    _vm.fileInfo = $event
                                  },
                                  "update:img-info": function($event) {
                                    _vm.fileInfo = $event
                                  }
                                }
                              },
                              [
                                _c(
                                  "el-button",
                                  {
                                    attrs: { type: "success", size: "small" },
                                    on: {
                                      click: function($event) {
                                        return _vm.setGoodsIdAndKey(row)
                                      }
                                    }
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$i18n.t("common.UploadAttachment")
                                      )
                                    )
                                  ]
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ]
                    }
                  }
                ])
              })
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              staticStyle: {
                display: "flex",
                "justify-content": "center",
                "margin-top": "50px"
              }
            },
            [
              _c(
                "el-button",
                {
                  staticStyle: { width: "15%" },
                  attrs: { type: "primary" },
                  on: {
                    click: function($event) {
                      return _vm.batchImport()
                    }
                  }
                },
                [_vm._v(_vm._s(_vm.$i18n.t("common.confirm1")))]
              ),
              _c(
                "el-button",
                {
                  staticStyle: { width: "15%" },
                  on: {
                    click: function($event) {
                      return _vm.closeExcelDataDialog()
                    }
                  }
                },
                [_vm._v(_vm._s(_vm.$i18n.t("common.cancel")))]
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.fileDataLoading,
              expression: "fileDataLoading"
            }
          ],
          attrs: {
            title: _vm.$i18n.t("common.Appendix"),
            visible: _vm.fileDataVisible
          },
          on: {
            "update:visible": function($event) {
              _vm.fileDataVisible = $event
            }
          }
        },
        [
          _c(
            "el-table",
            {
              staticStyle: { width: "100%" },
              attrs: { data: _vm.fileData, border: "", stripe: "" }
            },
            [
              _c("el-table-column", {
                attrs: { prop: "id", label: _vm.$i18n.t("common.Serialnumber") }
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "fileName",
                  label: _vm.$i18n.t("common.FileName")
                }
              }),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$i18n.t("common.operation"),
                  align: "center",
                  width: "130px",
                  fixed: "right"
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(ref) {
                      var row = ref.row
                      return [
                        _c(
                          "el-button",
                          {
                            staticClass: "table-inner-button",
                            attrs: { type: "primary", size: "mini" },
                            on: {
                              click: function($event) {
                                return _vm.deleteFile(row)
                              }
                            }
                          },
                          [_vm._v(_vm._s(_vm.$i18n.t("common.delete")) + " ")]
                        )
                      ]
                    }
                  }
                ])
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.$t("common.edit"),
            visible: _vm.dialogImgAndVideoVisible,
            "before-close": _vm.closeImgAndVideoDialog2,
            "close-on-click-modal": false
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogImgAndVideoVisible = $event
            }
          }
        },
        [
          _vm.dialogImgAndVideoVisible
            ? _c(
                "div",
                [
                  _c("ImageAndVideoDialog", {
                    attrs: { imageList: _vm.imageList, formData: _vm.formData },
                    on: { closeDialog: _vm.closeImgAndVideoDialog }
                  })
                ],
                1
              )
            : _vm._e()
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }