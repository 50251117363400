var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-upload",
        {
          ref: "uploadImg",
          staticClass: "avatar-uploader",
          attrs: {
            action: _vm.ossInfo.ossSignature.host,
            "auto-upload": false,
            "list-type": "picture-card",
            "on-error": _vm.handleError,
            "on-success": _vm.handleSuccess,
            "on-progress": _vm.uploadVideoProcess,
            data: _vm.fileDataObj,
            accept: ".jpg, .jpeg, .png, .mp4",
            limit: 1,
            "on-change": _vm.handleChange
          }
        },
        [
          _vm.videoUploadPercent > 0
            ? _c("el-progress", {
                attrs: {
                  type: "circle",
                  percentage: _vm.videoUploadPercent,
                  tyle: "margin-top:30px;width: 178px;height: 178px;"
                }
              })
            : _c("i", {
                staticClass: "el-icon-plus avatar-uploader-icon",
                attrs: { slot: "default" },
                slot: "default"
              })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }