var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isShow
    ? _c(
        "div",
        [
          _c(
            "div",
            [
              _vm._v(
                "\n            " +
                  _vm._s(_vm.$i18n.t("common.Countrieslisted")) +
                  ":\n            "
              ),
              _vm._l(_vm.formData.goodsSpecials, function(item, index) {
                return _c("span", { key: index }, [
                  item.shelf
                    ? _c("span", [
                        _vm._v(
                          _vm._s(
                            _vm.$dict.getDictValue("countrys", item.countryCode)
                          )
                        )
                      ])
                    : _vm._e()
                ])
              })
            ],
            2
          ),
          _c(
            "el-tabs",
            {
              on: { "tab-click": _vm.handleClick },
              model: {
                value: _vm.activeName,
                callback: function($$v) {
                  _vm.activeName = $$v
                },
                expression: "activeName"
              }
            },
            [
              _c(
                "el-tab-pane",
                { attrs: { label: _vm.$i18n.t("common.title"), name: "1" } },
                [
                  _c(
                    "el-form",
                    { ref: "titleFrom", attrs: { "label-width": "100px" } },
                    _vm._l(_vm.languageList, function(item, index) {
                      return _c(
                        "el-form-item",
                        { key: item.id, attrs: { label: item.name } },
                        [
                          _c("el-input", {
                            attrs: {
                              placeholder: _vm.$i18n.t(
                                "common.Pleaseentertheproducttitle"
                              )
                            },
                            on: {
                              input: function($event) {
                                return _vm.forceUpdate($event)
                              }
                            },
                            model: {
                              value: item.title,
                              callback: function($$v) {
                                _vm.$set(item, "title", $$v)
                              },
                              expression: "item.title"
                            }
                          })
                        ],
                        1
                      )
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "el-tab-pane",
                {
                  attrs: { label: _vm.$i18n.t("common.Largeimage"), name: "2" }
                },
                [
                  _c(
                    "el-tabs",
                    {
                      on: { "tab-click": _vm.handleChildClick },
                      model: {
                        value: _vm.activeName2,
                        callback: function($$v) {
                          _vm.activeName2 = $$v
                        },
                        expression: "activeName2"
                      }
                    },
                    _vm._l(_vm.languageList, function(item, index2) {
                      return _c(
                        "el-tab-pane",
                        {
                          key: item.id,
                          attrs: { label: item.name, name: item.id }
                        },
                        [
                          _c(
                            "div",
                            {
                              staticStyle: {
                                display: "flex",
                                "justify-content": "flex-start",
                                "margin-bottom": "20px",
                                "flex-wrap": "wrap"
                              }
                            },
                            [
                              _c(
                                "el-button",
                                {
                                  attrs: { type: "success", size: "small" },
                                  on: { click: _vm.uploadImage }
                                },
                                [
                                  _vm._v(
                                    _vm._s(_vm.$i18n.t("common.Uploadimages"))
                                  )
                                ]
                              ),
                              _c(
                                "oss-upload",
                                {
                                  staticStyle: { "margin-left": "10px" },
                                  attrs: {
                                    itT: "itV",
                                    butName: _vm.$i18n.t("common.UploadVideo"),
                                    accept: ".mp4",
                                    imgInfo: _vm.imgInfo,
                                    percent: _vm.percent
                                  },
                                  on: {
                                    "update:imgInfo": function($event) {
                                      _vm.imgInfo = $event
                                    },
                                    "update:img-info": function($event) {
                                      _vm.imgInfo = $event
                                    },
                                    "update:percent": function($event) {
                                      _vm.percent = $event
                                    }
                                  }
                                },
                                [
                                  _vm.percent > 0
                                    ? _c(
                                        "el-button",
                                        {
                                          attrs: {
                                            type: "primary",
                                            size: "small",
                                            disabled: ""
                                          },
                                          on: { click: _vm.handleIsRep }
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t("common.UploadingVideo")
                                            ) +
                                              "..." +
                                              _vm._s(_vm.percent) +
                                              "%"
                                          )
                                        ]
                                      )
                                    : _c(
                                        "el-button",
                                        {
                                          attrs: {
                                            type: "success",
                                            size: "small"
                                          },
                                          on: { click: _vm.handleIsRep }
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$i18n.t("common.UploadVideo")
                                            )
                                          )
                                        ]
                                      )
                                ],
                                1
                              ),
                              _c(
                                "el-button",
                                {
                                  staticStyle: { "margin-left": "10px" },
                                  attrs: { type: "success", size: "small" },
                                  on: {
                                    click: function($event) {
                                      return _vm.handleIsShow(1)
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    _vm._s(_vm.$i18n.t("common.Batchdisplay"))
                                  )
                                ]
                              ),
                              _c(
                                "el-button",
                                {
                                  attrs: { type: "success", size: "small" },
                                  on: {
                                    click: function($event) {
                                      return _vm.handleIsShow(2)
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$i18n.t("common.BatchCloseDisplay")
                                    )
                                  )
                                ]
                              ),
                              _c(
                                "el-button",
                                {
                                  attrs: { type: "success", size: "small" },
                                  on: { click: _vm.handleDelete }
                                },
                                [
                                  _vm._v(
                                    _vm._s(_vm.$i18n.t("common.Batchdeletion"))
                                  )
                                ]
                              ),
                              _c(
                                "el-button",
                                {
                                  attrs: { type: "success", size: "small" },
                                  on: { click: _vm.openSynchroLang }
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$i18n.t(
                                        "common.Synchronizetootherlanguages"
                                      )
                                    )
                                  )
                                ]
                              ),
                              _c(
                                "el-button",
                                {
                                  attrs: { type: "success", size: "small" },
                                  on: { click: _vm.openGallery }
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$i18n.t(
                                        "common.Selectfrompicturelibrary"
                                      )
                                    )
                                  )
                                ]
                              )
                            ],
                            1
                          ),
                          _vm.bigImgList.length > 0
                            ? _c(
                                "div",
                                [
                                  _c(
                                    "el-table",
                                    {
                                      ref: "bigImgTable",
                                      refInFor: true,
                                      staticStyle: {
                                        width: "100%",
                                        "margin-top": "20px"
                                      },
                                      attrs: {
                                        data: _vm.bigImgList,
                                        border: "",
                                        "header-cell-style": {
                                          background: "#EAEEF9",
                                          color: "#32353a"
                                        }
                                      },
                                      on: {
                                        "selection-change":
                                          _vm.handleSelectionChange
                                      }
                                    },
                                    [
                                      _c("el-table-column", {
                                        attrs: {
                                          type: "selection",
                                          width: "55"
                                        }
                                      }),
                                      _c("el-table-column", {
                                        attrs: {
                                          prop: "type",
                                          label: _vm.$i18n.t("common.type"),
                                          align: "center",
                                          "show-overflow-tooltip": ""
                                        }
                                      }),
                                      _c("el-table-column", {
                                        attrs: {
                                          prop: "brand",
                                          label: _vm.$i18n.t(
                                            "common.Pictures_Videos"
                                          ),
                                          width: "120",
                                          align: "center"
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function(scope) {
                                                return [
                                                  scope.row.type == "图片"
                                                    ? _c(
                                                        "div",
                                                        [
                                                          scope.row.imageUrl
                                                            ? _c("el-image", {
                                                                staticStyle: {
                                                                  width:
                                                                    "100px",
                                                                  height:
                                                                    "100px"
                                                                },
                                                                attrs: {
                                                                  src:
                                                                    _vm.ossHost +
                                                                    scope.row
                                                                      .imageUrl,
                                                                  fit: "fill"
                                                                }
                                                              })
                                                            : _vm._e()
                                                        ],
                                                        1
                                                      )
                                                    : scope.row.type == "视频"
                                                    ? _c("div", [
                                                        _c(
                                                          "video",
                                                          {
                                                            staticStyle: {
                                                              width: "100px",
                                                              height: "100px"
                                                            },
                                                            attrs: {
                                                              src:
                                                                _vm.ossHost +
                                                                scope.row
                                                                  .imageUrl,
                                                              controls:
                                                                "controls"
                                                            }
                                                          },
                                                          [
                                                            _vm._v(
                                                              "\n                                                   " +
                                                                _vm._s(
                                                                  _vm.$t(
                                                                    "common.YourBrowserDoesNotSupportVideoPlayback"
                                                                  )
                                                                ) +
                                                                "\n                                            "
                                                            )
                                                          ]
                                                        )
                                                      ])
                                                    : _vm._e()
                                                ]
                                              }
                                            }
                                          ],
                                          null,
                                          true
                                        )
                                      }),
                                      _c("el-table-column", {
                                        attrs: {
                                          prop: "mainImg",
                                          label: _vm.$i18n.t("common.size"),
                                          align: "center",
                                          "show-overflow-tooltip": ""
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function(scope) {
                                                return [
                                                  scope.row.fileWidth &&
                                                  scope.row.fileHeight
                                                    ? _c("div", [
                                                        _vm._v(
                                                          "\n                                            " +
                                                            _vm._s(
                                                              scope.row
                                                                .fileWidth
                                                            ) +
                                                            "*" +
                                                            _vm._s(
                                                              scope.row
                                                                .fileHeight
                                                            ) +
                                                            "\n                                        "
                                                        )
                                                      ])
                                                    : _vm._e()
                                                ]
                                              }
                                            }
                                          ],
                                          null,
                                          true
                                        )
                                      }),
                                      _c("el-table-column", {
                                        attrs: {
                                          prop: "mainImg",
                                          label: _vm.$i18n.t(
                                            "common.Isitthemainimage"
                                          ),
                                          align: "center",
                                          "show-overflow-tooltip": ""
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function(scope) {
                                                return [
                                                  _c("el-switch", {
                                                    attrs: {
                                                      disabled:
                                                        scope.row
                                                          .imageTypeCode ==
                                                        "Video",
                                                      value: scope.row.mainImg,
                                                      "active-color": "#13ce66"
                                                    },
                                                    on: {
                                                      change: function($event) {
                                                        return _vm.putGoodsbjMainimage(
                                                          scope.row,
                                                          scope.$index
                                                        )
                                                      }
                                                    }
                                                  })
                                                ]
                                              }
                                            }
                                          ],
                                          null,
                                          true
                                        )
                                      }),
                                      _c("el-table-column", {
                                        attrs: {
                                          prop: "lang",
                                          label: _vm.$i18n.t("common.language"),
                                          align: "center",
                                          "show-overflow-tooltip": ""
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function(scope) {
                                                return [
                                                  _c("div", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$dict.getDictValue(
                                                          "lang",
                                                          scope.row.lang
                                                        )
                                                      )
                                                    )
                                                  ])
                                                ]
                                              }
                                            }
                                          ],
                                          null,
                                          true
                                        )
                                      }),
                                      _c("el-table-column", {
                                        attrs: {
                                          prop: "sort",
                                          label: _vm.$i18n.t("common.sort"),
                                          align: "center",
                                          "show-overflow-tooltip": ""
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function(scope) {
                                                return [
                                                  _c("el-input", {
                                                    attrs: {
                                                      oninput:
                                                        "value=value.replace(/[^0-9]/g,'');if(value.length>8)value=value.slice(0,8)",
                                                      placeholder: _vm.$i18n.t(
                                                        "common.Pleaseentersorting"
                                                      )
                                                    },
                                                    on: {
                                                      blur: function($event) {
                                                        return _vm.putGoodsbjSort(
                                                          scope.row
                                                        )
                                                      }
                                                    },
                                                    nativeOn: {
                                                      keyup: function($event) {
                                                        if (
                                                          !$event.type.indexOf(
                                                            "key"
                                                          ) &&
                                                          _vm._k(
                                                            $event.keyCode,
                                                            "enter",
                                                            13,
                                                            $event.key,
                                                            "Enter"
                                                          )
                                                        ) {
                                                          return null
                                                        }
                                                        return _vm.putGoodsbjSort(
                                                          scope.row
                                                        )
                                                      }
                                                    },
                                                    model: {
                                                      value: scope.row.sort,
                                                      callback: function($$v) {
                                                        _vm.$set(
                                                          scope.row,
                                                          "sort",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "scope.row.sort"
                                                    }
                                                  })
                                                ]
                                              }
                                            }
                                          ],
                                          null,
                                          true
                                        )
                                      }),
                                      _c("el-table-column", {
                                        attrs: {
                                          prop: "updataTime",
                                          label: _vm.$i18n.t(
                                            "common.Updatetime"
                                          ),
                                          align: "center",
                                          "show-overflow-tooltip": ""
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function(scope) {
                                                return [
                                                  scope.row.updateTime
                                                    ? _c("span", [
                                                        _vm._v(
                                                          "\n            \t\t\t\t\t\t\t\t" +
                                                            _vm._s(
                                                              _vm._f(
                                                                "formatDate"
                                                              )(
                                                                scope.row
                                                                  .updateTime
                                                              )
                                                            )
                                                        ),
                                                        _c("br")
                                                      ])
                                                    : _vm._e()
                                                ]
                                              }
                                            }
                                          ],
                                          null,
                                          true
                                        )
                                      }),
                                      _c("el-table-column", {
                                        attrs: {
                                          prop: "showed",
                                          label: _vm.$i18n.t(
                                            "common.Displayornot"
                                          ),
                                          align: "center",
                                          "show-overflow-tooltip": ""
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function(scope) {
                                                return !scope.row.mainImg
                                                  ? [
                                                      _c("el-switch", {
                                                        attrs: {
                                                          value:
                                                            scope.row.showed,
                                                          "active-color":
                                                            "#13ce66"
                                                        },
                                                        on: {
                                                          change: function(
                                                            $event
                                                          ) {
                                                            return _vm.putGoodsbjshow(
                                                              scope.row,
                                                              scope.$index
                                                            )
                                                          }
                                                        }
                                                      })
                                                    ]
                                                  : undefined
                                              }
                                            }
                                          ],
                                          null,
                                          true
                                        )
                                      }),
                                      _c("el-table-column", {
                                        attrs: {
                                          label: _vm.$i18n.t(
                                            "common.operation"
                                          ),
                                          align: "center",
                                          fixed: "right"
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function(scope) {
                                                return [
                                                  _c(
                                                    "el-button",
                                                    {
                                                      attrs: {
                                                        type: "text",
                                                        size: "small"
                                                      },
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          return _vm.deleteGoodsPage(
                                                            scope.row,
                                                            scope.$index
                                                          )
                                                        }
                                                      }
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.$i18n.t(
                                                            "common.delete"
                                                          )
                                                        ) +
                                                          "\n                                        "
                                                      )
                                                    ]
                                                  )
                                                ]
                                              }
                                            }
                                          ],
                                          null,
                                          true
                                        )
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            : _vm._e()
                        ]
                      )
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "el-tab-pane",
                {
                  attrs: {
                    label: _vm.$i18n.t("common.GraphicDetails"),
                    name: "3"
                  }
                },
                [
                  _vm.wangEditerContent && _vm.wangEditerContent.length > 0
                    ? _c(
                        "el-tabs",
                        {
                          on: { "tab-click": _vm.handleChildClick },
                          model: {
                            value: _vm.activeName3,
                            callback: function($$v) {
                              _vm.activeName3 = $$v
                            },
                            expression: "activeName3"
                          }
                        },
                        _vm._l(_vm.wangEditerContent, function(item, index) {
                          return _c(
                            "el-tab-pane",
                            {
                              key: index,
                              attrs: { label: item.name, name: item.id }
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "container" },
                                [
                                  _c("wangEditer", {
                                    ref: "editer1",
                                    refInFor: true,
                                    staticStyle: { "margin-top": "10px" },
                                    attrs: {
                                      content: item.content,
                                      lang: item.lang,
                                      editorId: "editor1"
                                    }
                                  })
                                ],
                                1
                              )
                            ]
                          )
                        }),
                        1
                      )
                    : _vm._e()
                ],
                1
              ),
              _c(
                "el-tab-pane",
                { attrs: { label: "SEO", name: "4" } },
                [
                  _c(
                    "el-tabs",
                    {
                      model: {
                        value: _vm.activeName4,
                        callback: function($$v) {
                          _vm.activeName4 = $$v
                        },
                        expression: "activeName4"
                      }
                    },
                    _vm._l(_vm.seoFromList, function(item, index) {
                      return _c(
                        "el-tab-pane",
                        {
                          key: item.code,
                          attrs: { name: item.id, label: item.name }
                        },
                        [
                          _c(
                            "el-form",
                            { ref: "variantFrom", refInFor: true },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "URL",
                                    prop: "head",
                                    "label-width": _vm.formLabelWidth
                                  }
                                },
                                [
                                  _c("el-input", {
                                    attrs: {
                                      type: "textarea",
                                      "show-word-limit": "",
                                      maxlength: "500",
                                      autosize: { minRows: 4 },
                                      placeholder: _vm.$i18n.t(
                                        "common.Pleaseentercontent"
                                      )
                                    },
                                    on: {
                                      input: function($event) {
                                        return _vm.forceUpdate($event)
                                      }
                                    },
                                    model: {
                                      value: item.seoUrlParameter,
                                      callback: function($$v) {
                                        _vm.$set(item, "seoUrlParameter", $$v)
                                      },
                                      expression: "item.seoUrlParameter"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: _vm.$i18n.t("common.PageTitle"),
                                    prop: "head",
                                    "label-width": _vm.formLabelWidth
                                  }
                                },
                                [
                                  _c("el-input", {
                                    attrs: {
                                      type: "textarea",
                                      "show-word-limit": "",
                                      maxlength: "500",
                                      autosize: { minRows: 4 },
                                      placeholder: _vm.$i18n.t(
                                        "common.Pleaseentercontent"
                                      )
                                    },
                                    on: {
                                      input: function($event) {
                                        return _vm.forceUpdate($event)
                                      }
                                    },
                                    model: {
                                      value: item.seoTitle,
                                      callback: function($$v) {
                                        _vm.$set(item, "seoTitle", $$v)
                                      },
                                      expression: "item.seoTitle"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: _vm.$i18n.t("common.Pagekeywords"),
                                    prop: "head",
                                    "label-width": _vm.formLabelWidth
                                  }
                                },
                                [
                                  _c("el-input", {
                                    attrs: {
                                      type: "textarea",
                                      "show-word-limit": "",
                                      maxlength: "500",
                                      autosize: { minRows: 4 },
                                      placeholder: _vm.$i18n.t(
                                        "common.Pleaseentercontent"
                                      )
                                    },
                                    on: {
                                      input: function($event) {
                                        return _vm.forceUpdate($event)
                                      }
                                    },
                                    model: {
                                      value: item.seoKeyword,
                                      callback: function($$v) {
                                        _vm.$set(item, "seoKeyword", $$v)
                                      },
                                      expression: "item.seoKeyword"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: _vm.$i18n.t(
                                      "common.PageDescription"
                                    ),
                                    prop: "head",
                                    "label-width": _vm.formLabelWidth
                                  }
                                },
                                [
                                  _c("el-input", {
                                    attrs: {
                                      type: "textarea",
                                      "show-word-limit": "",
                                      maxlength: "1000",
                                      autosize: { minRows: 4 },
                                      placeholder: _vm.$i18n.t(
                                        "common.Pleaseentercontent"
                                      )
                                    },
                                    on: {
                                      input: function($event) {
                                        return _vm.forceUpdate($event)
                                      }
                                    },
                                    model: {
                                      value: item.seoDescribe,
                                      callback: function($$v) {
                                        _vm.$set(item, "seoDescribe", $$v)
                                      },
                                      expression: "item.seoDescribe"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    }),
                    1
                  )
                ],
                1
              ),
              !_vm.scene.isNonSelf
                ? _c(
                    "el-tab-pane",
                    {
                      attrs: {
                        label: _vm.$i18n.t("common.parameter"),
                        name: "5"
                      }
                    },
                    [
                      _c(
                        "div",
                        [
                          _c("el-input", {
                            staticStyle: { width: "20%" },
                            attrs: {
                              placeholder: _vm.$i18n.t(
                                "common.Pleaseenteraparametername"
                              ),
                              autocomplete: "off"
                            },
                            model: {
                              value: _vm.parameterSearchVal,
                              callback: function($$v) {
                                _vm.parameterSearchVal = $$v
                              },
                              expression: "parameterSearchVal"
                            }
                          }),
                          _c(
                            "el-button",
                            {
                              staticStyle: { margin: "20px" },
                              attrs: { type: "primary", size: "small" },
                              on: { click: _vm.postGoodsParameter }
                            },
                            [
                              _vm._v(
                                "\n                        " +
                                  _vm._s(_vm.$i18n.t("common.query")) +
                                  "\n                    "
                              )
                            ]
                          ),
                          _c(
                            "el-checkbox",
                            {
                              on: { change: _vm.hideNull },
                              model: {
                                value: _vm.checkbox,
                                callback: function($$v) {
                                  _vm.checkbox = $$v
                                },
                                expression: "checkbox"
                              }
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.$i18n.t("common.Hideemptyparameters")
                                ) + "\n                    "
                              )
                            ]
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-row",
                        { attrs: { gutter: 20 } },
                        [
                          _c("el-col", { attrs: { span: 5 } }, [
                            _c(
                              "div",
                              {
                                staticClass: "tree-container",
                                attrs: { shadow: "never" }
                              },
                              [
                                _c("el-tree", {
                                  ref: "tree",
                                  attrs: {
                                    props: _vm.defaultProps,
                                    data: _vm.groupleft,
                                    "node-key": "parameterGroupId"
                                  },
                                  on: { "node-click": _vm.handleGroupLeft }
                                })
                              ],
                              1
                            )
                          ]),
                          _c(
                            "el-col",
                            { attrs: { span: 19 } },
                            [
                              _c(
                                "el-table",
                                {
                                  ref: "pagedata",
                                  staticStyle: { width: "100%" },
                                  attrs: {
                                    data: _vm.rightParamterList,
                                    border: "",
                                    "header-cell-style": {
                                      background: "#EAEEF9",
                                      color: "#32353a"
                                    }
                                  }
                                },
                                [
                                  _c("el-table-column", {
                                    attrs: {
                                      prop: "parameterItemName",
                                      label: _vm.$i18n.t("common.parameter"),
                                      align: "center",
                                      "show-overflow-tooltip": ""
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function(scope) {
                                            return [
                                              _c("span", [
                                                _vm._v(
                                                  _vm._s(
                                                    scope.row.parameterItemName
                                                  )
                                                )
                                              ])
                                            ]
                                          }
                                        }
                                      ],
                                      null,
                                      false,
                                      2984107613
                                    )
                                  }),
                                  _c("el-table-column", {
                                    attrs: {
                                      prop: "parameterValue",
                                      label: _vm.$i18n.t(
                                        "common.Parametervalue"
                                      ),
                                      align: "center",
                                      "show-overflow-tooltip": ""
                                    }
                                  }),
                                  _c("el-table-column", {
                                    attrs: {
                                      prop: "parameterType",
                                      label: _vm.$i18n.t(
                                        "common.ParameterProperties"
                                      ),
                                      align: "center",
                                      "show-overflow-tooltip": ""
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function(scope) {
                                            return [
                                              scope.row.parameterType == null
                                                ? _c("span")
                                                : scope.row.parameterType == 0
                                                ? _c("span", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t(
                                                          "common.SearchParameters"
                                                        )
                                                      )
                                                    )
                                                  ])
                                                : scope.row.parameterType == 1
                                                ? _c("span", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t(
                                                          "common.ImportantParameters"
                                                        )
                                                      )
                                                    )
                                                  ])
                                                : scope.row.parameterType == 2
                                                ? _c("span", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t(
                                                          "common.SearchParameters"
                                                        )
                                                      ) +
                                                        "，" +
                                                        _vm._s(
                                                          _vm.$t(
                                                            "common.ImportantParameters"
                                                          )
                                                        )
                                                    )
                                                  ])
                                                : _vm._e()
                                            ]
                                          }
                                        }
                                      ],
                                      null,
                                      false,
                                      2415238309
                                    )
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  staticStyle: { width: "20%" },
                  on: { click: _vm.closeDetailsDialog }
                },
                [_vm._v(_vm._s(_vm.$i18n.t("common.cancel")))]
              ),
              this.activeName != 5
                ? _c(
                    "el-button",
                    {
                      staticStyle: { width: "20%" },
                      attrs: { type: "primary" },
                      on: { click: _vm.onSubmit }
                    },
                    [_vm._v(_vm._s(_vm.$i18n.t("common.confirm1")))]
                  )
                : _vm._e()
            ],
            1
          ),
          _c(
            "el-dialog",
            {
              attrs: {
                title: _vm.$i18n.t("common.Synchronizetootherlanguages"),
                visible: _vm.synVisible,
                width: "35%",
                "append-to-body": ""
              },
              on: {
                "update:visible": function($event) {
                  _vm.synVisible = $event
                },
                close: _vm.closeSynVisible
              }
            },
            [
              _vm.synVisible
                ? _c("div", [
                    _vm.checkedAllLang.length > 0
                      ? _c(
                          "div",
                          [
                            _c(
                              "el-checkbox",
                              {
                                attrs: { indeterminate: _vm.isIndeterminate },
                                on: { change: _vm.handleCheckAllChange },
                                model: {
                                  value: _vm.checkAll,
                                  callback: function($$v) {
                                    _vm.checkAll = $$v
                                  },
                                  expression: "checkAll"
                                }
                              },
                              [
                                _vm._v(
                                  _vm._s(_vm.$i18n.t("common.SelectAll")) +
                                    "\n\t\t\t\t\t"
                                )
                              ]
                            ),
                            _c("div", { staticStyle: { margin: "15px 0" } }),
                            _c(
                              "el-checkbox-group",
                              {
                                on: { change: _vm.handleCheckedCitiesChange },
                                model: {
                                  value: _vm.checkedLang,
                                  callback: function($$v) {
                                    _vm.checkedLang = $$v
                                  },
                                  expression: "checkedLang"
                                }
                              },
                              _vm._l(_vm.checkedAllLang, function(item) {
                                return _c(
                                  "el-checkbox",
                                  {
                                    key: item.code,
                                    attrs: { name: item.id, label: item.name }
                                  },
                                  [_vm._v(_vm._s(item.name))]
                                )
                              }),
                              1
                            )
                          ],
                          1
                        )
                      : _vm._e(),
                    _c(
                      "span",
                      {
                        staticClass: "dialog-footer",
                        attrs: { slot: "footer" },
                        slot: "footer"
                      },
                      [
                        _c(
                          "el-button",
                          {
                            staticStyle: { width: "20%" },
                            on: { click: _vm.closeSynVisible }
                          },
                          [_vm._v(_vm._s(_vm.$i18n.t("common.cancel")))]
                        ),
                        _c(
                          "el-button",
                          {
                            staticStyle: { width: "20%" },
                            attrs: { type: "primary" },
                            on: { click: _vm.synchroLang }
                          },
                          [_vm._v(_vm._s(_vm.$i18n.t("common.confirm1")))]
                        )
                      ],
                      1
                    )
                  ])
                : _vm._e()
            ]
          ),
          _c(
            "el-dialog",
            {
              attrs: {
                title: _vm.$i18n.t("common.Selectfrompicturelibrary"),
                visible: _vm.galleryVisible,
                width: "35%",
                "append-to-body": ""
              },
              on: {
                "update:visible": function($event) {
                  _vm.galleryVisible = $event
                },
                close: _vm.closeDialogGallery
              }
            },
            [
              _vm.galleryVisible
                ? _c(
                    "div",
                    [
                      _c(
                        "div",
                        {
                          staticStyle: {
                            display: "flex",
                            "justify-content": "space-between",
                            "align-items": "center"
                          }
                        },
                        [
                          _c(
                            "el-select",
                            {
                              staticClass: "input-width",
                              attrs: {
                                clearable: "",
                                placeholder: _vm.$i18n.t(
                                  "common.PictureLanguage"
                                )
                              },
                              on: { change: _vm.getGalleryList },
                              model: {
                                value: _vm.galleryLang,
                                callback: function($$v) {
                                  _vm.galleryLang = $$v
                                },
                                expression: "galleryLang"
                              }
                            },
                            _vm._l(_vm.languageList, function(item) {
                              return _c("el-option", {
                                key: item.code,
                                attrs: { label: item.name, value: item.code }
                              })
                            }),
                            1
                          ),
                          _c(
                            "el-button",
                            {
                              attrs: {
                                type: "success",
                                size: "small",
                                loading: _vm.handleGalleryLoading
                              },
                              on: { click: _vm.handleGallery }
                            },
                            [_vm._v(_vm._s(_vm.$t("common.Select")))]
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-table",
                        {
                          directives: [
                            {
                              name: "loading",
                              rawName: "v-loading",
                              value: _vm.galleryLoading,
                              expression: "galleryLoading"
                            }
                          ],
                          ref: "galleryTable",
                          staticStyle: { width: "100%", "margin-top": "10px" },
                          attrs: {
                            "row-key": "goodsImageId",
                            data: _vm.galleryList,
                            height: "400",
                            border: "",
                            "header-cell-style": {
                              background: "#EAEEF9",
                              color: "#32353a"
                            }
                          }
                        },
                        [
                          _c("el-table-column", {
                            attrs: { fixed: "", type: "selection", width: "55" }
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "parameterItemName",
                              label: _vm.$i18n.t("common.Pictures_Videos"),
                              align: "center",
                              "show-overflow-tooltip": ""
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function(scope) {
                                    return [
                                      scope.row.imageTypeCode == "BigImg"
                                        ? _c("listImage", {
                                            attrs: {
                                              imgUrl: scope.row.imageUrl
                                            }
                                          })
                                        : scope.row.imageTypeCode == "Video" &&
                                          scope.row.imageUrl
                                        ? _c(
                                            "video",
                                            {
                                              staticClass: "float-l",
                                              staticStyle: {
                                                width: "100px",
                                                height: "100px"
                                              },
                                              attrs: {
                                                src:
                                                  _vm.ossHost +
                                                  scope.row.imageUrl,
                                                controls: "controls"
                                              }
                                            },
                                            [
                                              _vm._v(
                                                "\n                                " +
                                                  _vm._s(
                                                    _vm.$t(
                                                      "common.YourBrowserDoesNotSupportVideoPlayback"
                                                    )
                                                  ) +
                                                  "\n                            "
                                              )
                                            ]
                                          )
                                        : _vm._e()
                                    ]
                                  }
                                }
                              ],
                              null,
                              false,
                              2327814591
                            )
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "goodsImageId",
                              label: _vm.$i18n.t("common.ImageID"),
                              align: "center",
                              "show-overflow-tooltip": ""
                            }
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "lang",
                              label: _vm.$i18n.t("common.language"),
                              align: "center",
                              "show-overflow-tooltip": ""
                            }
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "parameterValue",
                              label: _vm.$i18n.t("common.size"),
                              align: "center",
                              "show-overflow-tooltip": ""
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function(scope) {
                                    return [
                                      scope.row.fileWidth &&
                                      scope.row.fileHeight
                                        ? _c("div", [
                                            _c("span", [
                                              _vm._v(
                                                _vm._s(scope.row.fileWidth)
                                              )
                                            ]),
                                            _vm._v("*"),
                                            _c("span", [
                                              _vm._v(
                                                _vm._s(scope.row.fileHeight)
                                              )
                                            ])
                                          ])
                                        : _vm._e()
                                    ]
                                  }
                                }
                              ],
                              null,
                              false,
                              2422912873
                            )
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e()
            ]
          ),
          _vm.uploadImgVisible
            ? _c(
                "el-dialog",
                {
                  attrs: {
                    title: _vm.$i18n.t("common.Uploadimages"),
                    visible: _vm.uploadImgVisible,
                    width: "35%",
                    "append-to-body": ""
                  },
                  on: {
                    "update:visible": function($event) {
                      _vm.uploadImgVisible = $event
                    }
                  }
                },
                [
                  _c("multiple-oss-upload", {
                    ref: "multipleOssUpload",
                    attrs: { limit: 20 },
                    on: { uploadAllSuccess: _vm.uploadAllSuccess }
                  })
                ],
                1
              )
            : _vm._e()
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }